import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useColorMode, useToken } from "@chakra-ui/react";

export const ThemedLogoIcon = ({ rotate, ...props }) => {
  const { colorMode } = useColorMode();
  const [orange500] = useToken("colors", ["orange.500"]);
  const iconColor = colorMode === "dark" ? orange500 : orange500;

  return (
    <FontAwesomeIcon
      {...props}
      color={iconColor}
      style={rotate ? { transform: `rotate(${rotate}deg)` } : {}}
    />
  );
};
