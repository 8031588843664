import React, { useState, useEffect } from 'react';
import { IconButton, useColorMode } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { motion, AnimatePresence } from 'framer-motion';

const ScrollToTopButton = () => {
  const [show, setShow] = useState(false);
  const [scrollTimeout, setScrollTimeout] = useState(null);

  const { colorMode } = useColorMode();
  const bgColor = colorMode === 'dark' ? 'orange.500' : 'blue.500';
  const hoverBgColor = colorMode === 'dark' ? 'orange.300' : 'blue.400';

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        setShow(true);
        clearTimeout(scrollTimeout);
        setScrollTimeout(setTimeout(() => setShow(false), 4000));
      } else {
        setShow(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(scrollTimeout);
    };
  }, [scrollTimeout]);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
          style={{
            position: 'fixed',
            bottom: '2.75rem',
            right: '1.2rem',
            zIndex: 100,
          }}
        >
          <IconButton
            onClick={handleClick}
            aria-label="Scroll to top"
            icon={<FontAwesomeIcon icon={faArrowUp} />}
            bg={bgColor}
            color={colorMode === 'dark' ? '#121212' : '#ffffff'}
            _hover={{ bgColor: hoverBgColor }}
            size="md"
            borderRadius="3px"
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ScrollToTopButton;
