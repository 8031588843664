import React from 'react';
import { DataTable } from '../../data-display/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPieChart } from '@fortawesome/free-solid-svg-icons';
import { formatNumber } from '../../../utils';

export const BitcoinSupplyTable = ({ 
  data, 
  bitcoinPrice, 
  animate,
  onOpenLexicon 
}) => {
  const tableData = data && bitcoinPrice
    ? [
        {
            label: 'Percentage Issued',
            value: data?.s12?.metrics?.data?.supply?.circulating
              ? `${formatNumber((data.s12.metrics.data.supply.circulating / 21000000) * 100, 2)}% of Supply`
              : "Loading...",
            tooltipText: "Total amount of bitcoins distributed to the network, represented as a percentage of the overall total supply.",
            clickableTerm: "Distribution Schedule",
        },
        {
            label: 'Left to Mine',
            value: data?.s12?.metrics?.data?.supply?.circulating
              ? `${formatNumber(21000000 - data.s12.metrics.data.supply.circulating, 0)} BTC`
              : "Loading...",
            tooltipText: "Total amount of bitcoins left to mine until all coins are distributed.",
            clickableTerm: "Distribution Schedule",
        },
        {
            label: 'Supply Held by Exchanges',
            value: data?.s12?.metrics?.data?.exchange_flows
              ? `${formatNumber(data.s12.metrics.data.exchange_flows.supply_exchange_native_units, 0)} BTC`
              : "Loading...",
            tooltipText: "Amount of Bitcoin held on exchanges",
            clickableTerm: "Exchange Supply",
        },
        {
            label: 'Supply Held by Miners',
            value: data?.s12?.metrics?.data?.miner_flows
              ? `${formatNumber(data.s12.metrics.data.miner_flows.supply_miners_native_units, 0)} BTC`
              : "Loading...",
            tooltipText: "Amount of Bitcoin held by miners",
            clickableTerm: "Bitcoin Supply",
        },
        {
            label: 'Liquid Supply',
            value: data?.s12?.metrics?.data?.miner_flows && data?.s12?.metrics?.data?.supply
              ? `${formatNumber(data.s12.metrics.data.supply.circulating - data.s12.metrics.data.miner_flows.supply_1hop_miners_native_units, 0)} BTC`
              : "Loading...",
            tooltipText: "Estimated number of bitcoins available for trading (excluding coins held by miners)",
        },
        {
            label: '25% Supply',
            value: '22 April 2010',
            tooltipText: "Date when 25% of the Bitcoin supply was mined.",
            clickableTerm: "Bitcoin Supply",
        },
        {
            label: '50% Supply',
            value: '14 December 2011',
            tooltipText: "Date when 50% of the Bitcoin supply was mined.",
            clickableTerm: "Bitcoin Supply",
        },
        {
            label: '75% Supply',
            value: '29 July 2015',
            tooltipText: "Date when 75% of the Bitcoin supply was mined.",
            clickableTerm: "Bitcoin Supply",
        },
        {
            label: '87.5% Supply',
            value: '24 May 2019',
            tooltipText: "Date when 87.5% of the Bitcoin supply was mined.",
            clickableTerm: "Bitcoin Supply",
        },
        {
            label: '90% Supply',
            value: '13 December 2021',
            tooltipText: "Date when 90% of the Bitcoin supply was mined.",
            clickableTerm: "Bitcoin Supply",
        },
        {
            label: '95% Supply',
            value: '~3 December 2025',
            tooltipText: "Date when 95% of the Bitcoin supply was mined.",
            clickableTerm: "Bitcoin Supply",
        },
        {
            label: '99% Supply',
            value: '~20 February 2035',
            tooltipText: "Date when 99% of the Bitcoin supply was mined.",
            clickableTerm: "Bitcoin Supply",
        }
    ]
    : [];

  return (
    <DataTable
      title="Bitcoin Supply"
      subtitle="Distribution schedule and other details"
      icon={<FontAwesomeIcon icon={faPieChart} />}
      rows={tableData}
      animate={animate}
      specificTerm="Bitcoin Supply"
      onOpenLexicon={onOpenLexicon}
      rowsPerPage={9}
    />
  );
};