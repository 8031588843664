import React, { useState } from 'react';
import { Box, IconButton, Popover, PopoverTrigger, PopoverContent, PopoverBody, Text, VStack, useColorModeValue } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

const GameInfo = () => {
  const [isOpen, setIsOpen] = useState(false);
  const backgroundColor = useColorModeValue('gray.200', 'gray.500');

  const gameRules = [
    "• Predict Bitcoin price movements or block times.",
    "• Spend your credits to make predictions.",
    "• Win by correctly guessing price action or block time.",
    "• Earn more credits with specific prediction conditions.",
    "• You can make up to 1 active prediction at a time.",
    "• 100 credits are topped up automatically if you're low.",
    "• Win an achievement by collecting 21M credits.",
    "• Remember, this is just for fun - no money involved!",
    "• Trying to time the Bitcoin market will get you REKT!",
  ];

  return (
    <Box position="absolute" top="2" right="12">
      <Popover isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <PopoverTrigger>
          <IconButton
            icon={<FontAwesomeIcon icon={faQuestion} />}
            aria-label="Game Info"
            size="sm"
            onClick={() => setIsOpen(!isOpen)}
          />
        </PopoverTrigger>
        <PopoverContent mr={7} borderColor={'white'} borderWidth={2} p={2} pb={4} backgroundColor={backgroundColor}>
          <PopoverBody>
            <VStack align="start" spacing={2}>
              <Text fontWeight="bold">Game Rules:</Text>
              {gameRules.map((rule, index) => (
                <Text key={index} fontSize="11px">{rule}</Text>
              ))}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default GameInfo;