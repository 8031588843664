import { SingleDataValue } from '.';
import { ThemedFlex } from '../../theme/ThemedFlex';

export const NetworkTileAvgUtxoAge = ({ data, formatNumber, animate }) => {
  const numberValue = data?.s12?.metrics?.data?.on_chain_data?.average_utxo_age != null
    ? `${formatNumber(data.s12.metrics.data.on_chain_data.average_utxo_age / 365, 1) ?? "Loading..."} Years`
    : '';

  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      className={animate ? 'gray-border-custom gray-border' : ''}
    >
      <SingleDataValue label="Avg UTXO Age" value={numberValue} />
    </ThemedFlex>
  );
};
