import { SingleDataValue } from '../data-display';
import { ThemedFlex } from '../../theme/ThemedFlex';

export const DataTilePercentMined = ({ data, animate }) => {
  const percentMinedToDate = data?.s12?.metrics?.data?.supply?.circulating !== null
    ? `${((data.s12.metrics.data.supply.circulating / 21000000) * 100).toFixed(2)}%`
    : '';

  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      className={animate ? 'gray-border-custom gray-border' : ''}
    >
      <SingleDataValue label="% Already Mined" value={percentMinedToDate} />
    </ThemedFlex>
  );
};
