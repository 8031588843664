import { ImageTileImage } from './ImageTileImage'
import { ThemedFlex } from '../../theme/ThemedFlex'

export const ImageTile = ({
    src,
    width,
    height,
    link,
}) => {
    return (
        <ThemedFlex
            direction="column"
            mb="12px"
            py="18px"
            px="20px"
            flex={1}
            borderRadius={3}
            align="center"
        >
            <ImageTileImage src={src} width={width} height={height} link={link} />
        </ThemedFlex>
    )
}
