import { DataTableHeader } from './DataTableHeader'
import { DataTableFooter } from './DataTableFooter'
import { WordTileBody } from './WordTileBody'
import { ThemedFlex } from '../../theme/ThemedFlex'

export const WordTile = ({
    body,
    title,
    icon,
    subtitle,
    link,
    linkLabel,
}) => {
    return (
        <ThemedFlex
            direction="column"
            mb="12px"
            py="18px"
            px="20px"
            flex={1}
            borderRadius={3}
        >
            <DataTableHeader title={title} subtitle={subtitle} icon={icon} />
            <WordTileBody body={body} />
            <DataTableFooter link={link} linkLabel={linkLabel} />
        </ThemedFlex>
    )
}
