import React from 'react';
import { DataTable } from '../../data-display/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPercent } from '@fortawesome/free-solid-svg-icons';
import { formatNumber } from '../../../utils';
import { currencySymbols } from '../../../utils/currencyConversion.utils';

export const ChangePercentagesTable = ({ 
  data, 
  bitcoinPrice, 
  conversionRates, 
  selectedCurrency, 
  animate,
  onOpenLexicon 
}) => {
  const tableData = data && bitcoinPrice && conversionRates && selectedCurrency
    ? [
        {
            label: 'Price Change % 1h',
            value: data?.s06?.bitcoin?.market_data?.price_change_percentage_1h_in_currency?.[selectedCurrency]
              ? `${formatNumber(data.s06.bitcoin.market_data.price_change_percentage_1h_in_currency[selectedCurrency], 2)}%`
              : "Loading...",
            tooltipText: "Price change percentage (1h)",
            clickableTerm: "Percent Change",
        },
        {
            label: 'Price Change 24h',
            value: data?.s06?.bitcoin?.market_data?.price_change_24h_in_currency?.[selectedCurrency]
              ? `${currencySymbols[selectedCurrency]}${formatNumber(data.s06.bitcoin.market_data.price_change_24h_in_currency[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()}`
              : "Loading...",
            tooltipText: "Price change in the last 24 hours",
            clickableTerm: "Price Change",
        },
        {
            label: 'Price Change % 24h',
            value: data?.s06?.bitcoin?.market_data?.price_change_percentage_24h_in_currency?.[selectedCurrency]
              ? `${formatNumber(data.s06.bitcoin.market_data.price_change_percentage_24h_in_currency[selectedCurrency], 2)}%`
              : "Loading...",
            tooltipText: "Price change percentage (24h)",
            clickableTerm: "Percent Change",
        },
        {
            label: 'Price Change % 7d',
            value: data?.s06?.bitcoin?.market_data?.price_change_percentage_7d_in_currency?.[selectedCurrency]
              ? `${formatNumber(data.s06.bitcoin.market_data.price_change_percentage_7d_in_currency[selectedCurrency], 2)}%`
              : "Loading...",
            tooltipText: "Price change percentage (7d)",
            clickableTerm: "Percent Change",
        },
        {
            label: 'Price Change % 14d',
            value: data?.s06?.bitcoin?.market_data?.price_change_percentage_14d_in_currency?.[selectedCurrency]
              ? `${formatNumber(data.s06.bitcoin.market_data.price_change_percentage_14d_in_currency[selectedCurrency], 2)}%`
              : "Loading...",
            tooltipText: "Price change percentage (14d)",
            clickableTerm: "Percent Change",
        },
        {
            label: 'Price Change % 30d',
            value: data?.s06?.bitcoin?.market_data?.price_change_percentage_30d_in_currency?.[selectedCurrency]
              ? `${formatNumber(data.s06.bitcoin.market_data.price_change_percentage_30d_in_currency[selectedCurrency], 2)}%`
              : "Loading...",
            tooltipText: "Price change percentage (30d)",
            clickableTerm: "Percent Change",
        },
        {
            label: 'Price Change % 60d',
            value: data?.s06?.bitcoin?.market_data?.price_change_percentage_60d_in_currency?.[selectedCurrency]
              ? `${formatNumber(data.s06.bitcoin.market_data.price_change_percentage_60d_in_currency[selectedCurrency], 2)}%`
              : "Loading...",
            tooltipText: "Price change percentage (60d)",
            clickableTerm: "Percent Change",
        },
        {
            label: 'Price Change % 200d',
            value: data?.s06?.bitcoin?.market_data?.price_change_percentage_200d_in_currency?.[selectedCurrency]
              ? `${formatNumber(data.s06.bitcoin.market_data.price_change_percentage_200d_in_currency[selectedCurrency], 2)}%`
              : "Loading...",
            tooltipText: "Price change percentage (200d)",
            clickableTerm: "Percent Change",
        },
        {
            label: 'Price Change % 1y',
            value: data?.s06?.bitcoin?.market_data?.price_change_percentage_1y_in_currency?.[selectedCurrency]
              ? `${formatNumber(data.s06.bitcoin.market_data.price_change_percentage_1y_in_currency[selectedCurrency], 2)}%`
              : "Loading...",
            tooltipText: "Price change percentage (1y)",
            clickableTerm: "Percent Change",
        },
        {
            label: 'All-Time High',
            value: data?.s12?.metrics?.data?.all_time_high
              ? `${currencySymbols[selectedCurrency]}${formatNumber(data.s12.metrics.data.all_time_high.price * conversionRates[selectedCurrency], 0)}`
              : "Loading...",
            tooltipText: "All-time High price",
            clickableTerm: "All Time High (ATH)",
        },
        {
            label: 'All-time High Change %',
            value: data?.s12?.metrics?.data?.all_time_high
              ? `${formatNumber(data.s12.metrics.data.all_time_high.percent_down, 2)}%`
              : "Loading...",
            tooltipText: "Percentage down from All-Time High",
            clickableTerm: "All Time High (ATH)",
        },
        {
            label: 'Days Since ATH',
            value: data?.s12?.metrics?.data?.all_time_high
              ? formatNumber(data.s12.metrics.data.all_time_high.days_since, 0)
              : "Loading...",
            tooltipText: "Number of days since Bitcoin reached its all-time high price",
            clickableTerm: "All Time High (ATH)",
        }
    ]
    : [];

  return (
    <DataTable
      title="Change Percentages"
      subtitle="Bitcoin price change percentages"
      icon={<FontAwesomeIcon icon={faPercent} />}
      rows={tableData}
      animate={animate}
      specificTerm="Change Percentages"
      onOpenLexicon={onOpenLexicon}
      rowsPerPage={9}
    />
  );
};