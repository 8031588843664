import { useState, useEffect, useMemo } from 'react';
import { Text } from '@chakra-ui/react'
import { DataTableHeader } from './DataTableHeader'
import { DataTableFooter } from './DataTableFooter'
import { ThemedFlex } from '../../theme/ThemedFlex'

export const QuoteTile = ({ title, subtitle, icon, link, linkLabel }) => {
    const quotes = useMemo(() => [
        {
            quote: '"The root problem with conventional currency is all the trust that\'s required to make it work. The central bank must be trusted not to debase the currency, but the history of fiat currencies is full of breaches of that trust. Banks must be trusted to hold our money and transfer it electronically, but they lend it out in waves of credit bubbles with barely a fraction in reserve. We have to trust them with our privacy, trust them not to let identity thieves drain our accounts."',
        },
        {
            quote: '"If you don\'t believe me or don\'t get it, I don\'t have the time to try to convince you, sorry."',
        },
        {
            quote: '"The nature of Bitcoin is such that once version 0.1 was released, the core design was set in stone for the rest of its lifetime... I don\'t believe a second, compatible implementation of Bitcoin will ever be a good idea. So much of the design depends on all nodes getting exactly identical results in lockstep that a second implementation would be a menace to the network."',
        },
        {
            quote: '"A lot of people automatically dismiss e-currency as a lost cause because of all the companies that failed since the 1990\'s. I hope it\'s obvious it was only the centrally controlled nature of those systems that doomed them. I think this is the first time we\'re trying a decentralized, non-trust-based system."',
        },
        {
            quote: '"I keep a list of all unresolved bugs I\'ve seen on the forum.  In some cases, I\'m still thinking about the best design for the fix. This isn\'t the kind of software where we can leave so many unresolved bugs that we need a tracker for them."',
        },
        {
            quote: '"SHA-256 is very strong. It\'s not like the incremental step from MD5 to SHA1. It can last several decades unless there\'s some massive breakthrough attack."',
        },
        {
            quote: '"Proof-of-work has the nice property that it can be relayed through untrusted middlemen. We don\'t have to worry about a chain of custody of communication. It doesn\'t matter who tells you a longest chain, the proof-of-work speaks for itself."',
        },
        {
            quote: '"Lost coins only make everyone else\'s coins worth slightly more. Think of it as a donation to everyone."',
        },
        {
            quote: '"[We will not find a solution to political problems in cryptography,] but we can win a major battle in the arms race and gain a new territory of freedom for several years. Governments are good at cutting off the heads of a centrally controlled networks like Napster, but pure P2P networks like Gnutella and Tor seem to be holding their own."',
        },
        {
            quote: '"In a few decades when the reward gets too small, the transaction fee will become the main compensation for [mining] nodes. I\'m sure that in 20 years there will either be very large transaction volume or no volume."',
        },
        {
            quote: '"Sigh... why delete a wallet instead of moving it aside and keeping the old copy just in case? You should never delete a wallet."',
        },
        {
            quote: '"The possibility to be anonymous or pseudonymous relies on you not revealing any identifying information about yourself in connection with the bitcoin addresses you use. If you post your bitcoin address on the web, then you\'re associating that address and any transactions with it with the name you posted under.  If you posted under a handle that you haven\'t associated with your real identity, then you\'re still pseudonymous. For greater privacy, it\'s best to use bitcoin addresses only once."',
        },
        {
            quote: '"As you figured out, the root problem is we shouldn\'t be counting or spending transactions until they have at least 1 confirmation. 0/unconfirmed transactions are very much second class citizens. At most, they are advice that something has been received, but counting them as balance or spending them is premature."',
        },
        {
            quote: '"As computers get faster and the total computing power applied to creating bitcoins increases, the difficulty increases proportionally to keep the total new production constant. Thus, it is known in advance how many new bitcoins will be created every year in the future."',
        },
        {
            quote: '"Writing a description for this thing for general audiences is bloody hard. There\'s nothing to relate it to."',
        },
        {
            quote: '"When you generate a new bitcoin address, it only takes disk space on your own computer (like 500 bytes). It\'s like generating a new PGP private key, but less CPU intensive because it\'s ECC. The address space is effectively unlimited. It doesn\'t hurt anyone, so generate all you want."',
        },
        {
            quote: '"We consider the scenario of an attacker trying to generate an alternate chain faster than the honest chain. Even if this is accomplished, it does not throw the system open to arbitrary changes, such as creating value out of thin air or taking money that never belonged to the attacker. Nodes are not going to accept an invalid transaction as payment, and honest nodes will never accept a block containing them. An attacker can only try to change one of his own transactions to take back money he recently spent."',
        },
        {
            quote: '"With e-currency based on cryptographic proof, without the need to trust a third party middleman, money can be secure and transactions effortless."',
        },
        {
            quote: '"It\'s very attractive to the libertarian viewpoint if we can explain it properly. I\'m better with code than with words though."',
        },
        {
            quote: '"When you generate a new bitcoin address, it only takes disk space on your own computer (like 500 bytes). It\'s like generating a new PGP private key, but less CPU intensive because it\'s ECC. The address space is effectively unlimited. It doesn\'t hurt anyone, so generate all you want."',
        },
        {
            quote: '"Once the latest transaction in a coin is buried under enough blocks, the spent transactions before it can be discarded to save disk space. To facilitate this without breaking the block\'s hash, transactions are hashed in a Merkle Tree, with only the root included in the block\'s hash. Old blocks can then be compacted by stubbing off branches of the tree. The interior hashes do not need to be stored."',
        },
        {
            quote: '"Although it would be possible to handle coins individually, it would be unwieldy to make a separate transaction for every cent in a transfer. To allow value to be split and combined, transactions contain multiple inputs and outputs. Normally there will be either a single input from a larger previous transaction or multiple inputs combining smaller amounts, and at most two outputs: one for the payment, and one returning the change, if any, back to the sender."',
        },
        {
            quote: '"I\'ve been working on a new electronic cash system that\'s fully peer-to-peer, with no trusted third party."',
        },
        {
            quote: '"The main properties: Double-spending is prevented with a peer-to-peer network. No mint or other trusted parties. Participants can be anonymous. New coins are made from Hashcash style proof-of-work. The proof-of-work for new coin generation also proof-of-workers the network to prevent double-spending."',
        },
        {
            quote: '"The result is a distributed system with no single point of failure. Users hold the crypto keys to their own money and transact directly with each other, with the help of the P2P network to check for double-spending."',
        },
        {
            quote: '"It is a global distributed database, with additions to the database by consent of the majority, based on a set of rules they follow: - Whenever someone finds proof-of-work to generate a block, they get some new coins - The proof-of-work difficulty is adjusted every two weeks to target an average of 6 blocks per hour (for the whole network) - The coins given per block is cut in half every 4 years."',
        },
        {
            quote: '"Eventually at most only 21 million coins for 6.8 billion people in the world if it really gets huge. But don\'t worry, there are another 6 decimal places that aren\'t shown, for a total of 8 decimal places internally. It shows 1.00 but internally it\'s 1.00000000."',
        },
        {
            quote: '"How does everyone feel about the B symbol with the two lines through the outside? Can we live with that as our logo?"',
        },
        {
            quote: '"Bitcoin addresses you generate are kept forever. A bitcoin address must be kept to show ownership of anything sent to it. If you were able to delete a bitcoin address and someone sent to it, the money would be lost. They\'re only about 500 bytes."',
        },
        {
            quote: '"The design supports a tremendous variety of possible transaction types that I designed years ago. Escrow transactions, bonded contracts, third party arbitration, multi-party signature, etc. If Bitcoin catches on in a big way, these are things we\'ll want to explore in the future, but they all had to be designed at the beginning to make sure they would be possible later."',
        },
        {
            quote: '"By convention, the first transaction in a block is a special transaction that starts a new coin owned by the creator of the block. This adds an incentive for nodes to support the network, and provides a way to initially distribute coins into circulation, since there is no central authority to issue them. The steady addition of a constant of amount of new coins is analogous to gold miners expending resources to add gold to circulation. In our case, it is CPU time and electricity that is expended."',
        },
        {
            quote: '"The incentive may help encourage nodes to stay honest. If a greedy attacker is able to assemble more CPU proof-of-worker than all the honest nodes, he would have to choose between using it to defraud people by stealing back his payments, or using it to generate new coins. He ought to find it more profitable to play by the rules, such rules that favour him with more new coins than everyone else combined, than to undermine the system and the validity of his own wealth."',
        },
        {
            quote: '"The fact that new coins are produced means the money supply increases by a planned amount, but this does not necessarily result in inflation. If the supply of money increases at the same rate that the number of people using it increases, prices remain stable. If it does not increase as fast as demand, there will be deflation and early holders of money will see its value increase. Coins have to get initially distributed somehow, and a constant rate seems like the best formula."',
        },
        {
            quote: '"There will be transaction fees, so nodes will have an incentive to receive and include all the transactions they can. Nodes will eventually be compensated by transaction fees alone when the total coins created hits the pre-determined ceiling."',
        },
        {
            quote: '"It might make sense just to get some in case it catches on. If enough people think the same way, that becomes a self fulfilling prophecy. Once it gets bootstrapped, there are so many applications if you could effortlessly pay a few cents to a website as easily as dropping coins in a vending machine."',
        },
        {
            quote: '"Indeed there is nobody to act as central bank or federal reserve to adjust the money supply as the population of users grows. That would have required a trusted party to determine the value, because I don\'t know a way for software to know the real world value of things."',
        },
        {
            quote: '"It\'s more typical of a precious metal... Instead of the supply changing to keep the value the same, the supply is predetermined and the value changes. As the number of users grows, the value per coin increases. It has the potential for a positive feedback loop; as users increase, the value goes up, which could attract more users to take advantage of the increasing value."',
        },
        {
            quote: '"I\'m sure that in 20 years there will either be very large transaction volume or no volume."',
        },
        {
            quote: '"The price of any commodity tends to gravitate toward the production cost. If the price is below cost, then production slows down. If the price is above cost, profit can be made by generating and selling more. At the same time, the increased production would increase the difficulty, pushing the cost of generating towards the price."',
        },
        {
            quote: '"It\'s the same situation as gold and gold mining. The marginal cost of gold mining tends to stay near the price of gold. Gold mining is a waste, but that waste is far less than the utility of having gold available as a medium of exchange. I think the case will be the same for Bitcoin. The utility of the exchanges made possible by Bitcoin will far exceed the cost of electricity used. Therefore, not having Bitcoin would be the net waste."',
        },
        {
            quote: '"Bitcoins have no dividend or potential future dividend, therefore not like a stock. More like a collectible or commodity."',
        },
        {
            quote: '"A purely peer-to-peer version of electronic cash would allow online payments to be sent directly from one party to another without going through a financial institution."',
        },
        {
            quote: '"What is needed is an electronic payment system based on cryptographic proof instead of trust, allowing any two willing parties to transact directly with each other without the need for a trusted third party. Transactions that are computationally impractical to reverse would protect sellers from fraud, and routine escrow mechanisms could easily be implemented to protect buyers."',
        },
        {
            quote: '"To compensate for increasing hardware speed and varying interest in running nodes over time, the proof-of-work difficulty is determined by a moving average targeting an average number of blocks per hour. If they\'re generated too fast, the difficulty increases."',
        },
        {
            quote: '"The difficulty adjustment is trying to keep it so the network as a whole generates an average of 6 blocks per hour."',
        },
        {
            quote: '"We propose a solution to the double-spending problem using a peer-to-peer network. The network timestamps transactions by hashing them into an ongoing chain of hash-based proof-of-work, forming a record that cannot be changed without redoing the proof-of-work. The longest chain not only serves as proof of the sequence of events witnessed, but proof that it came from the largest pool of CPU proof-of-worker. As long as a majority of CPU proof-of-worker is controlled by nodes that are not cooperating to attack the network, they\'ll generate the longest chain and outpace attackers. The network itself requires minimal structure."',
        },
        {
            quote: '"The problem of course is the payee can\'t verify that one of the owners did not double-spend the coin. A common solution is to introduce a trusted central authority, or mint, that checks every transaction for double spending. After each transaction, the coin must be returned to the mint to issue a new coin, and only coins issued directly from the mint are trusted not to be double-spent. The problem with this solution is that the fate of the entire money system depends on the company running the mint, with every transaction having to go through them, just like a bank."',
        },
        {
            quote: '"The attacker isn\'t adding blocks to the end. He has to go back and redo the block his transaction is in and all the blocks after it, as well as any new blocks the network keeps adding to the end while he\'s doing that. He\'s rewriting history. Once his branch is longer, it becomes the new valid one."',
        },
        {
            quote: '"There\'s no need for reporting of "proof of double spending" like that. If the same chain contains both spends, then the block is invalid and rejected. Same if a block didn\'t have enough proof-of-work. That block is invalid and rejected. There\'s no need to circulate a report about it. Every node could see that and reject it before relaying it."',
        },
        {
            quote: '"Bitcoin\'s solution is to use a peer-to-peer network to check for double-spending. In a nutshell, the network works like a distributed timestamp server, stamping the first transaction to spend a coin. It takes advantage of the nature of information being easy to spread but hard to stifle."',
        },
        {
            quote: '"When someone tries to buy all the world\'s supply of a scarce asset, the more they buy the higher the price goes. At some point, it gets too expensive for them to buy any more. It\'s great for the people who owned it beforehand because they get to sell it to the corner at crazy high prices. As the price keeps going up and up, some people keep holding out for yet higher prices and refuse to sell."',
        },
        {
            quote: '"A generation ago, multi-user time-sharing computer systems had a similar problem. Before strong encryption, users had to rely on password protection to secure their files, placing trust in the system administrator to keep their information private. Privacy could always be overridden by the admin based on his judgment call weighing the principle of privacy against other concerns, or at the behest of his superiors. Then strong encryption became available to the masses, and trust was no longer required. Data could be secured in a way that was physically impossible for others to access, no matter for what reason, no matter how good the excuse, no matter what."',
        },
        {
            quote: '"If you\'re sad about paying the fee, you could always turn the tables and run a node yourself and maybe someday rake in a 0.44 fee yourself."',
        },
        {
            quote: '"Would it be a good thing for thieves to know that everything you own has a kill switch and if they steal it, it\'ll be useless to them, although you still lose it too? If they give it back, you can re-activate it. Imagine if gold turned to lead when stolen. If the thief gives it back, it turns to gold again."',
        },
        {
            quote: '"The network is robust in its unstructured simplicity. Nodes work all at once with little coordination. They do not need to be identified, since messages are not routed to any particular place and only need to be delivered on a best effort basis. Nodes can leave and rejoin the network at will, accepting the proof-of-work chain as proof of what happened while they were gone. They vote with their CPU proof-of-worker, expressing their acceptance of valid blocks by working on extending them and rejecting invalid blocks by refusing to work on them. Any needed rules and incentives can be enforced with this consensus mechanism."',
        },
        {
            quote: '"It is strictly necessary that the longest chain is always considered the valid one. Nodes that were present may remember that one branch was there first and got replaced by another, but there would be no way for them to convince those who were not present of this. We can\'t have subfactions of nodes that cling to one branch that they think was first, others that saw another branch first, and others that joined later and never saw what happened. The CPU proof-of-worker proof-of-work vote must have the final say. The only way for everyone to stay on the same page is to believe that the longest chain is always the valid one, no matter what."',
        },
        {
            quote: '"The proof-of-work is a Hashcash style SHA-256 collision finding. It\'s a memoryless process where you do millions of hashes a second, with a small chance of finding one each time. The 3 or 4 fastest nodes\' dominance would only be proportional to their share of the total CPU proof-of-worker. Anyone\'s chance of finding a solution at any time is proportional to their CPU proof-of-worker."',
        },
        {
            quote: '"Think of it as a cooperative effort to make a chain. When you add a link, you must first find the current end of the chain. If you were to locate the last link, then go off for an hour and forge your link, come back and link it to the link that was the end an hour ago, others may have added several links since then and they\'re not going to want to use your link that now branches off the middle."',
        },
        {
            quote: '"The average total coins generated across the network per day stays the same. Faster machines just get a larger share than slower machines. If everyone bought faster machines, they wouldn\'t get more coins than before."',
        },
        {
            quote: '"We need a way for the payee to know that the previous owners did not sign any earlier transactions. For our purposes, the earliest transaction is the one that counts, so we don\'t care about later attempts to double-spend. The only way to confirm the absence of a transaction is to be aware of all transactions. In the mint based model, the mint was aware of all transactions and decided which arrived first. To accomplish this without a trusted party, transactions must be publicly announced, and we need a system for participants to agree on a single history of the order in which they were received. The payee needs proof that at the time of each transaction, the majority of nodes agreed it was the first received."',
        },
        {
            quote: '"The steps to run the network are as follows: 1) New transactions are broadcast to all nodes. 2) Each node collects new transactions into a block. 3) Each node works on finding a difficult proof-of-work for its block. 4) When a node finds a proof-of-work, it broadcasts the block to all nodes. 5) Nodes accept the block only if all transactions in it are valid and not already spent. 6) Nodes express their acceptance of the block by working on creating the next block in the chain, using the hash of the accepted block as the previous hash."',
        },
        {
            quote: '"Nodes always consider the longest chain to be the correct one and will keep working on extending it. If two nodes broadcast different versions of the next block simultaneously, some nodes may receive one or the other first. In that case, they work on the first one they received, but save the other branch in case it becomes longer. The tie will be broken when the next proof-of-work is found and one branch becomes longer; the nodes that were working on the other branch will then switch to the longer one."',
        },
        {
            quote: '"When a node receives a block, it checks the signatures of every transaction in it against previous transactions in blocks. Blocks can only contain transactions that depend on valid transactions in previous blocks or the same block. Transaction C could depend on transaction B in the same block and B depends on transaction A in an earlier block."',
        },
        {
            quote: '"When a node finds a proof-of-work, the new block is propagated throughout the network and everyone adds it to the chain and starts working on the next block after it. Any nodes that had the other transaction will stop trying to include it in a block, since it\'s now invalid according to the accepted chain."',
        },
        {
            quote: '"The design outlines a lightweight client that does not need the full block chain. In the design PDF it\'s called Simplified Payment Verification. The lightweight client can send and receive transactions, it just can\'t generate blocks. It does not need to trust a node to verify payments, it can still verify them itself. The lightweight client is not implemented yet, but the plan is to implement it when it\'s needed. For now, everyone just runs a full network node."',
        },
        {
            quote: '"I anticipate there will never be more than 100K nodes, probably less. It will reach an equilibrium where it\'s not worth it for more nodes to join in. The rest will be lightweight clients, which could be millions."',
        },
        {
            quote: '"The current system where every user is a network node is not the intended configuration for large scale. That would be like every Usenet user runs their own NNTP server. The design supports letting users just be users. The more burden it is to run a node, the fewer nodes there will be. Those few nodes will be big server farms. The rest will be client nodes that only do transactions and don\'t generate."',
        },
        {
            quote: '"In this paper, we propose a solution to the double-spending problem using a peer-to-peer distributed timestamp server to generate computational proof of the chronological order of transactions. The system is secure as long as honest nodes collectively control more CPU proof-of-worker than any cooperating group of attacker nodes."',
        },
        {
            quote: '"The proof-of-work involves scanning for a value that when hashed, such as with SHA-256, the hash begins with a number of zero bits. The average work required is exponential in the number of zero bits required and can be verified by executing a single hash."',
        },
        {
            quote: '"For our timestamp network, we implement the proof-of-work by incrementing a nonce in the block until a value is found that gives the block\'s hash the required zero bits. Once the CPU effort has been expended to make it satisfy the proof-of-work, the block cannot be changed without redoing the work. As later blocks are chained after it, the work to change the block would include redoing all the blocks after it."',
        },
        {
            quote: '"The proof-of-work also solves the problem of determining representation in majority decision making. If the majority were based on one-IP-address-one-vote, it could be subverted by anyone able to allocate many IPs. Proof-of-work is essentially one-CPU-one-vote. The majority decision is represented by the longest chain, which has the greatest proof-of-work effort invested in it. If a majority of CPU proof-of-worker is controlled by honest nodes, the honest chain will grow the fastest and outpace any competing chains."',
        },
        {
            quote: '"To modify a past block, an attacker would have to redo the proof-of-work of the block and all blocks after it and then catch up with and surpass the work of the honest nodes... The probability of a slower attacker catching up diminishes exponentially as subsequent blocks are added."',
        },
        {
            quote: '"To compensate for increasing hardware speed and varying interest in running nodes over time, the proof-of-work difficulty is determined by a moving average targeting an average number of blocks per hour. If they\'re generated too fast, the difficulty increases."',
        },
        {
            quote: '"The proof-of-work chain is the solution to the synchronisation problem, and to knowing what the globally shared view is without having to trust anyone.',
        },
        {
            quote: '"The proof-of-work chain is itself self-evident proof that it came from the globally shared view. Only the majority of the network together has enough CPU proof-of-worker to generate such a difficult chain of proof-of-work. Any user, upon receiving the proof-of-work chain, can see what the majority of the network has approved. Once a transaction is hashed into a link that\'s a few links back in the chain, it is firmly etched into the global history."',
        },
        {
            quote: '"Proof-of-work has the nice property that it can be relayed through untrusted middlemen. We don\'t have to worry about a chain of custody of communication. It doesn\'t matter who tells you a longest chain, the proof-of-work speaks for itself."',
        },
        {
            quote: '"There is no way for the software to automatically know if one chain is better than another except by the greatest proof-of-work. In the design it was necessary for it to switch to a longer chain no matter how far back it has to go."',
        },
        {
            quote: '"Announcing the first release of Bitcoin, a new electronic cash system that uses a peer-to-peer network to prevent double-spending. It\'s completely decentralized with no server or central authority."',
        },
        {
            quote: '"We define an electronic coin as a chain of digital signatures. Each owner transfers the coin to the next by digitally signing a hash of the previous transaction and the public key of the next owner and adding these to the end of the coin. A payee can verify the signatures to verify the chain of ownership."',
        },
        {
            quote: '"The solution we propose begins with a timestamp server. A timestamp server works by taking a hash of a block of items to be timestamped and widely publishing the hash, such as in a newspaper or Usenet post. The timestamp proves that the data must have existed at the time, obviously, in order to get into the hash. Each timestamp includes the previous timestamp in its hash, forming a chain, with each additional timestamp reinforcing the ones before it."',
        },
        {
            quote: '"New transaction broadcasts do not necessarily need to reach all nodes. As long as they reach many nodes, they will get into a block before long. Block broadcasts are also tolerant of dropped messages. If a node does not receive a block, it will request it when it receives the next block and realizes it missed one."',
        },
        {
            quote: '"Instantant non-repudiability is not a feature, but it\'s still much faster than existing systems. Paper cheques can bounce up to a week or two later. Credit card transactions can be contested up to 60 to 180 days later. Bitcoin transactions can be sufficiently irreversible in an hour or two."',
        },
        {
            quote: '"If a merchant actually has a problem with theft, they can make the customer wait 2 minutes, or wait for something in e-mail, which many already do. If they really want to optimize, and it\'s a large download, they could cancel the download in the middle if the transaction comes back double-spent. If it\'s website access, typically it wouldn\'t be a big deal to let the customer have access for 5 minutes and then cut off access if it\'s rejected. Many such sites have a free trial anyway."',
        },
        {
            quote: '"That would be nice at point-of-sale. The cash register displays a QR-code encoding a bitcoin address and amount on a screen and you photo it with your mobile."',
        },
        {
            quote: '"The design supports a tremendous variety of possible transaction types that I designed years ago. Escrow transactions, bonded contracts, third party arbitration, multi-party signature, etc. If Bitcoin catches on in a big way, these are things we\'ll want to explore in the future, but they all had to be designed at the beginning to make sure they would be possible later."',
        },
        {
            quote: '"Commerce on the Internet has come to rely almost exclusively on financial institutions serving as trusted third parties to process electronic payments. While the system works well enough for most transactions, it still suffers from the inherent weaknesses of the trust based model."',
        },
        {
            quote: '"Completely non-reversible transactions are not really possible, since financial institutions cannot avoid mediating disputes. The cost of mediation increases transaction costs, limiting the minimum practical transaction size and cutting off the possibility for small casual transactions, and there is a broader cost in the loss of ability to make non-reversible payments for non-reversible services. With the possibility of reversal, the need for trust spreads."',
        },
        {
            quote: '"Being open source means anyone can independently review the code. If it was closed source, nobody could verify the security. I think it\'s essential for a program of this nature to be open source."',
        },
    ], []);

    const [randomQuote, setRandomQuote] = useState(
        quotes[Math.floor(Math.random() * quotes.length)]
    );

    useEffect(() => {
        const changeQuote = () => {
            const newQuote = quotes[Math.floor(Math.random() * quotes.length)];
            setRandomQuote(newQuote);
        };

        const timer = setTimeout(changeQuote, 180000);

        return () => clearTimeout(timer);
    }, [randomQuote, quotes]);

    return (
        <ThemedFlex
            direction="column"
            mb="12px"
            py="18px"
            pb="24px"
            px="20px"
            flex={1}
            borderRadius={3}
        >
            <DataTableHeader title={title} subtitle={subtitle} icon={icon} />
            <Text fontStyle='italic' fontSize='18px' lineHeight='22px' mt={4}>{randomQuote.quote}</Text>
            <DataTableFooter link={link} linkLabel={linkLabel} />
        </ThemedFlex>
    )
}