import React, { useState } from 'react';
import { Box, IconButton, Popover, PopoverTrigger, PopoverContent, PopoverBody, Text, VStack, useColorModeValue } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

const GameInfoRoutingMan = () => {
  const [isOpen, setIsOpen] = useState(false);
  const backgroundColor = useColorModeValue('gray.200', 'gray.500');

  const gameRules = [
    "• Navigate through the Lightning Network maze.",
    "• Reach the destination node to complete transactions.",
    "• Collect coins and route through nodes for bonuses.",
    "• Avoid bad actors and force closes.",
    "• Open channels to pass through locked nodes.",
    "• Each move costs 1 sat.",
    "• Time and move penalties apply after thresholds.",
    "• Game gets harder with each level.",
    "• Manage your energy and credits wisely.",
    "• No real money involved - just for fun and learning!"
  ];

  return (
    <Box position="absolute" top="2" right="12">
      <Popover isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <PopoverTrigger>
          <IconButton
            icon={<FontAwesomeIcon icon={faQuestion} />}
            aria-label="Game Info"
            size="sm"
            onClick={() => setIsOpen(!isOpen)}
          />
        </PopoverTrigger>
        <PopoverContent mr={7} borderColor={'white'} borderWidth={2} p={2} pb={4} backgroundColor={backgroundColor}>
          <PopoverBody>
            <VStack align="start" spacing={2}>
              <Text fontWeight="bold">Game Rules:</Text>
              {gameRules.map((rule, index) => (
                <Text key={index} fontSize="11px">{rule}</Text>
              ))}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default GameInfoRoutingMan;