import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useBreakpointValue } from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Select,
  FormControl,
  FormLabel,
  useColorMode,
  Switch,
  Text,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  VStack,
  Box,
  IconButton,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBitcoinSign, faWallet, faCalculator, faTimes, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { useRecoilState } from 'recoil';
import { selectedCurrencyState } from '../../recoilState';
import { createCurrencyConverter, currencySymbols } from '../../utils/currencyConversion.utils';

const formatFiatAmount = (amount) => {
  return isNaN(amount) ? '0' : Math.round(amount).toLocaleString();
};

const formatSatsAmount = (amount) => {
  return isNaN(amount) ? '0' : parseInt(amount).toLocaleString();
};

const convertCryptoToAmount = (cryptoAmount, bitcoinPrice, conversionRate) => {
  const sanitizedValue = parseFloat(cryptoAmount.replace(/,/g, ''));
  if (!isNaN(sanitizedValue) && !isNaN(bitcoinPrice) && !isNaN(conversionRate)) {
    const convertedAmount = Math.round(sanitizedValue * bitcoinPrice * conversionRate);
    return formatFiatAmount(convertedAmount);
  } else {
    return '0';
  }
};

const formatLargeNumber = (num) => {
  if (typeof num !== 'number' || isNaN(num)) {
    return '0.00';
  }
  const absNum = Math.abs(num);
  if (absNum >= 1e15) return (num / 1e15).toFixed(2) + 'Qu';
  if (absNum >= 1e12) return (num / 1e12).toFixed(2) + 'T';
  if (absNum >= 1e9) return (num / 1e9).toFixed(2) + 'B';
  if (absNum >= 1e6) return (num / 1e6).toFixed(2) + 'M';
  if (absNum >= 1e3) return (num / 1e3).toFixed(2) + 'K';
  return num.toFixed(2);
};

export const BitcoinCalculatorAdvanced = ({ isOpen, onClose, data, bitcoinPrice, formatNumber }) => {
  const [isCalculatorView, setIsCalculatorView] = useState(true);
  const breakpointValue = useBreakpointValue({ base: 'column', md: 'column' });
  const gapValue = useBreakpointValue({ base: 4, md: 4 });
  const [cryptoAmount, setCryptoAmount] = useState('1');
  const [satsAmount, setSatsAmount] = useState('100,000,000');
  const [cryptoCurrency, setCryptoCurrency] = useState('BTC');
  const [fiatAmount, setFiatAmount] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useRecoilState(selectedCurrencyState);
  const [conversionRates, setConversionRates] = useState({});
  const [portfolio, setPortfolio] = useState([]);
  const [newEntry, setNewEntry] = useState({
    btcAmount: '',
    purchasePrice: '',
    purchaseDate: '',
  });
  const [editingEntry, setEditingEntry] = useState(null);
  const prevCurrencyRef = useRef(selectedCurrency);

  const { colorMode } = useColorMode();
  const bgColor = colorMode === 'dark' ? '#121212' : '#ffffff';
  const borderColor = colorMode === 'dark' ? '#ffffff' : '#121212';

  const isMobile = useBreakpointValue({ base: true, md: false });

  const [currencyChanged, setCurrencyChanged] = useState(false);

const shouldShowWarning = useCallback(() => {
  return currencyChanged && portfolio.length > 0;
}, [currencyChanged, portfolio.length]);

useEffect(() => {
  if (data) {
    const newConversionRates = createCurrencyConverter(data);
    setConversionRates(newConversionRates);
  }
  
  // Check if the currency has changed
  if (prevCurrencyRef.current !== selectedCurrency) {
    console.log('Currency changed from', prevCurrencyRef.current, 'to', selectedCurrency);
    prevCurrencyRef.current = selectedCurrency;
    setCurrencyChanged(true);
  }
}, [data, selectedCurrency]);

useEffect(() => {
  setCurrencyChanged(false);
}, [portfolio]);

  useEffect(() => {
    if (bitcoinPrice && conversionRates[selectedCurrency]) {
      setFiatAmount(convertCryptoToAmount(cryptoAmount, bitcoinPrice, conversionRates[selectedCurrency]));
    }
  }, [bitcoinPrice, selectedCurrency, cryptoAmount, conversionRates]);

  const handleCryptoAmountChange = useCallback((e) => {
    const value = e.target.value;
    setCryptoAmount(value);

    const satsValue = Math.floor(parseFloat(value) * 100000000);
    setSatsAmount(formatSatsAmount(satsValue));
    if (bitcoinPrice && conversionRates[selectedCurrency]) {
      setFiatAmount(convertCryptoToAmount(value, bitcoinPrice, conversionRates[selectedCurrency]));
    }
  }, [bitcoinPrice, conversionRates, selectedCurrency]);

  const handleSatsAmountChange = useCallback((e) => {
    const value = e.target.value.replace(/,/g, '');
    const numericValue = parseInt(value, 10);

    if (!isNaN(numericValue)) {
      setSatsAmount(formatSatsAmount(numericValue));

      const btcValue = (numericValue / 100000000).toFixed(8);
      setCryptoAmount(btcValue);
      if (bitcoinPrice && conversionRates[selectedCurrency]) {
        setFiatAmount(convertCryptoToAmount(btcValue, bitcoinPrice, conversionRates[selectedCurrency]));
      }
    } else {
      setSatsAmount('0');
      setCryptoAmount('0');
      setFiatAmount('0');
    }
  }, [bitcoinPrice, conversionRates, selectedCurrency]);

  const handleFiatAmountChange = useCallback((e) => {
    const value = e.target.value.replace(/,/g, '');
    const numericValue = parseFloat(value);

    if (!isNaN(numericValue) && bitcoinPrice && conversionRates[selectedCurrency]) {
      const formattedValue = numericValue.toLocaleString();
      setFiatAmount(formattedValue);

      const convertedBTCAmount = (numericValue / (bitcoinPrice * conversionRates[selectedCurrency])).toFixed(8);
      setCryptoAmount(convertedBTCAmount);

      const satsValue = Math.floor(parseFloat(convertedBTCAmount) * 100000000);
      setSatsAmount(formatSatsAmount(satsValue));
    } else {
      setFiatAmount('0');
      setCryptoAmount('0');
      setSatsAmount('0');
    }
  }, [bitcoinPrice, conversionRates, selectedCurrency]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setNewEntry(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleEditClick = useCallback((entry) => {
    setEditingEntry(entry);
    setNewEntry({
      btcAmount: entry.btcAmount,
      purchasePrice: entry.purchasePrice,
      purchaseDate: entry.purchaseDate,
    });
  }, []);

  const clearFields = useCallback(() => {
    setNewEntry({ btcAmount: '', purchasePrice: '', purchaseDate: '' });
  }, []);

  const addOrUpdateEntry = useCallback(() => {
    if (newEntry.btcAmount && newEntry.purchasePrice && newEntry.purchaseDate) {
      const entry = {
        ...newEntry,
        btcAmount: parseFloat(newEntry.btcAmount) || 0,
        purchasePrice: parseFloat(newEntry.purchasePrice) || 0,
        id: editingEntry ? editingEntry.id : Date.now()
      };

      setPortfolio(prevPortfolio => {
        if (editingEntry) {
          return prevPortfolio.map(item => item.id === editingEntry.id ? entry : item);
        } else {
          return [...prevPortfolio, entry];
        }
      });

      setEditingEntry(null);
      clearFields();
    }
  }, [newEntry, editingEntry, clearFields]);

  const cancelUpdate = useCallback(() => {
    setEditingEntry(null);
    clearFields();
  }, [clearFields]);

  const removeEntry = useCallback((id) => {
    setPortfolio(prevPortfolio => prevPortfolio.filter(entry => entry.id !== id));
  }, []);

  const calculateTotals = useCallback(() => {
    return portfolio.reduce((acc, entry) => {
      const currentValue = entry.btcAmount * (bitcoinPrice || 0) * conversionRates[selectedCurrency];
      const originalValue = entry.btcAmount * entry.purchasePrice;
      const profit = currentValue - originalValue;

      return {
        totalBtc: acc.totalBtc + (parseFloat(entry.btcAmount) || 0),
        totalValue: acc.totalValue + currentValue,
        totalCostBasis: acc.totalCostBasis + originalValue,
        totalProfit: acc.totalProfit + profit,
        totalOriginalOrderPrice: acc.totalOriginalOrderPrice + originalValue,
      };
    }, { totalBtc: 0, totalValue: 0, totalCostBasis: 0, totalProfit: 0, totalOriginalOrderPrice: 0 });
  }, [portfolio, bitcoinPrice, conversionRates, selectedCurrency]);

  const formatCurrency = useCallback((value) => {
    if (isNaN(value)) return '0.00';
    const formattedValue = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: selectedCurrency.toUpperCase(),
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
    return formatLargeNumber(parseFloat(formattedValue.replace(/[^0-9.-]+/g, '')));
  }, [selectedCurrency]);

  const calculateDaysHeld = useCallback((purchaseDate) => {
    const today = new Date();
    const purchase = new Date(purchaseDate);
    const diffTime = Math.abs(today - purchase);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }, []);

  const calculateProfitLossPercentage = (currentValue, originalValue) => {
    if (originalValue === 0) return 0;
    return ((currentValue - originalValue) / originalValue) * 100;
  };

  const renderMobileEntry = useCallback((entry) => {
    const currentValue = entry.btcAmount * (bitcoinPrice || 0) * conversionRates[selectedCurrency];
    const originalValue = entry.btcAmount * entry.purchasePrice;
    const profit = currentValue - originalValue;
    const profitPercentage = calculateProfitLossPercentage(currentValue, originalValue);
    const daysHeld = calculateDaysHeld(entry.purchaseDate);

    return (
      <Box key={entry.id} p={4} borderWidth="1px" borderRadius="lg" mb={4}>
        <VStack align="stretch" spacing={2}>
          <Text><strong>BTC Amount:</strong> {formatLargeNumber(entry.btcAmount)}</Text>
          <Text><strong>Purchase Price:</strong> {formatCurrency(entry.purchasePrice)}</Text>
          <Text><strong>Purchase Date:</strong> {entry.purchaseDate}</Text>
          <Text><strong>Original Order Total:</strong> {formatCurrency(originalValue)}</Text>
          <Text><strong>Current Value:</strong> {formatCurrency(currentValue)}</Text>
          <Text color={profit >= 0 ? 'green.500' : 'red.500'}>
            <strong>Profit/Loss:</strong> {formatCurrency(profit)} ({formatNumber(profitPercentage, 2)}%) ({daysHeld} days)
          </Text>
          <Flex>
            <IconButton
              icon={<FontAwesomeIcon icon={faPencilAlt} />}
              size="sm"
              colorScheme='orange'
              mr={2}
              onClick={() => handleEditClick(entry)}
              aria-label="Edit entry"
            />
            <IconButton
              icon={<FontAwesomeIcon icon={faTimes} />}
              size="sm"
              colorScheme="red"
              onClick={() => removeEntry(entry.id)}
              aria-label="Remove entry"
            />
          </Flex>
        </VStack>
      </Box>
    );
  }, [bitcoinPrice, formatCurrency, handleEditClick, removeEntry, calculateDaysHeld, formatNumber, conversionRates, selectedCurrency]);

  const totals = calculateTotals();

  if (!data || !bitcoinPrice) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay backgroundColor="rgba(16, 22, 26, 0.9)" />
        <ModalContent bg={bgColor} pb={6} borderWidth="2px" borderStyle="solid" borderColor={borderColor} borderRadius="6px" overflow="hidden">
          <ModalHeader>Loading...</ModalHeader>
          <ModalBody>
            <Text>Please wait while we fetch the necessary data.</Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }



  return (
    <Modal isOpen={isOpen} onClose={onClose} size={isCalculatorView ? "xl" : "6xl"}>
      <ModalOverlay backgroundColor="rgba(16, 22, 26, 0.9)" />
      <ModalContent bg={bgColor} pb={6} borderWidth="2px" borderStyle="solid" borderColor={borderColor} borderRadius="6px" overflow="hidden">
        <ModalHeader>
          <Flex align="center" justify="flex-start">
            <FontAwesomeIcon icon={isCalculatorView ? faCalculator : faWallet} style={{ marginRight: '10px' }} />
            <Text mr={4}>{isCalculatorView ? "BTC Calculator" : "Profit/Loss Calculator"}</Text>
            <Flex align="center">
              <Text fontSize="xs" mr={2}>{isCalculatorView ? "P/L Calculator" : "BTC Calculator"}</Text>
              <Switch
                size={'sm'}
                colorScheme='gray'
                isChecked={!isCalculatorView}
                onChange={() => setIsCalculatorView(!isCalculatorView)}
              />
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalCloseButton
          _hover={{
            bg: 'gray.500',
          }}
          _focus={{
            boxShadow: '0 0 0 1px rgba(66, 153, 225, 0.6)',
          }}
        />
        <ModalBody>
          {isCalculatorView ? (
            <Flex width="100%" direction={breakpointValue} alignItems="flex-end" gap={gapValue}>
              <FormControl>
                <FormLabel>Bitcoin</FormLabel>
                <InputGroup>
                  <InputLeftAddon><FontAwesomeIcon icon={faBitcoinSign} /></InputLeftAddon>
                  <Input type="number" value={cryptoAmount} onChange={handleCryptoAmountChange} borderWidth="1.5px" fontSize={'16px'} />
                  <InputRightElement width="auto">
                    <Select value={cryptoCurrency} onChange={(e) => setCryptoCurrency(e.target.value)} icon={'none'}>
                      <option value="BTC">BTC</option>
                    </Select>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
  
              <FormControl>
                <FormLabel>Sats</FormLabel>
                <InputGroup>
                  <InputLeftAddon fontSize={16}>ṩ</InputLeftAddon>
                  <Input
                    type="text"
                    inputMode="numeric"
                    pattern="\d{1,3}(,\d{3})*"
                    value={satsAmount}
                    onChange={handleSatsAmountChange}
                    borderWidth="1.5px"
                    fontSize={'16px'}
                  />
                  <InputRightElement width="auto">
                    <Select value={cryptoCurrency} onChange={(e) => setCryptoCurrency(e.target.value)} icon={'none'}>
                      <option value="Sats">Sats</option>
                    </Select>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
  
              <FormControl ml={[0, 4]} mt={[4, 0]}>
                <FormLabel>Conversion</FormLabel>
                <InputGroup>
                  <InputLeftAddon>{currencySymbols[selectedCurrency] || '$'}</InputLeftAddon>
                  <Input
                    type="text"
                    inputMode="numeric"
                    pattern="\d{1,3}(,\d{3})*"
                    value={fiatAmount}
                    onChange={handleFiatAmountChange}
                    borderWidth="1.5px"
                    fontSize={'16px'}
                  />
                  <InputRightElement width="auto" borderWidth="2px" borderRadius="6px" >
                    <Select value={selectedCurrency} onChange={(e) => setSelectedCurrency(e.target.value)}>
                      {Object.keys(currencySymbols).map((currency) => (
                        <option key={currency} value={currency}>{currency.toUpperCase()}</option>
                      ))}
                    </Select>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            </Flex>
          ) : (
            <>
              <Flex direction={{ base: 'column', md: 'row' }} gap={4} mb={4}>
                <FormControl>
                  <FormLabel>BTC Amount</FormLabel>
                  <InputGroup>
                    <InputLeftAddon><FontAwesomeIcon icon={faBitcoinSign} /></InputLeftAddon>
                    <Input
                      type="number"
                      name="btcAmount"
                      value={newEntry.btcAmount}
                      onChange={handleInputChange}
                      placeholder="0.00"
                    />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel>Purchase Price ({selectedCurrency.toUpperCase()})</FormLabel>
                  <InputGroup>
                    <InputLeftAddon>{currencySymbols[selectedCurrency] || '$'}</InputLeftAddon>
                    <Input
                      type="number"
                      name="purchasePrice"
                      value={newEntry.purchasePrice}
                      onChange={handleInputChange}
                      placeholder="0.00"
                    />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel>Purchase Date</FormLabel>
                  <Input
                    type="date"
                    name="purchaseDate"
                    value={newEntry.purchaseDate}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Flex>
              <Flex justifyContent="center" width="100%" gap={4}>
                <Button
                  onClick={addOrUpdateEntry}
                  mb={6}
                  size={{ base: "sm", md: "sm" }}
                  colorScheme={editingEntry ? "green" : "orange"}
                >
                  {editingEntry ? 'Update Entry' : 'Add Entry'}
                </Button>
  
                {editingEntry && (
                  <Button
                    onClick={cancelUpdate}
                    mb={6}
                    size={{ base: "sm", md: "sm" }}
                    colorScheme="red"
                  >
                    Cancel Update
                  </Button>
                )}
  
                <Button
                  onClick={clearFields}
                  mb={6}
                  size={{ base: "sm", md: "sm" }}
                  colorScheme="gray"
                >
                  Clear
                </Button>
              </Flex>
  
              {isMobile ? (
                <VStack mt={6} spacing={4} align="stretch">
                  {portfolio.map(renderMobileEntry)}
                </VStack>
              ) : (
                <Box overflowX="auto">
                  <Table variant="simple" mt={6} size="sm">
                    <Thead>
                      <Tr>
                        <Th>BTC Amount</Th>
                        <Th>Purchase Price</Th>
                        <Th>Purchase Date</Th>
                        <Th>Original Order Total</Th>
                        <Th>Current Value</Th>
                        <Th>Profit/Loss</Th>
                        <Th>P/L %</Th>
                        <Th>Days Held</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {portfolio.map((entry) => {
                        const currentValue = entry.btcAmount * (bitcoinPrice || 0) * conversionRates[selectedCurrency];
                        const originalValue = entry.btcAmount * entry.purchasePrice;
                        const profit = currentValue - originalValue;
                        const profitPercentage = calculateProfitLossPercentage(currentValue, originalValue);
                        const daysHeld = calculateDaysHeld(entry.purchaseDate);
                        return (
                          <Tr key={entry.id}>
                            <Td>{formatLargeNumber(entry.btcAmount)}</Td>
                            <Td>{formatCurrency(entry.purchasePrice)}</Td>
                            <Td>{entry.purchaseDate}</Td>
                            <Td>{formatCurrency(originalValue)}</Td>
                            <Td>{formatCurrency(currentValue)}</Td>
                            <Td color={profit >= 0 ? 'green.500' : 'red.500'}>
                              {formatCurrency(profit)}
                            </Td>
                            <Td color={profit >= 0 ? 'green.500' : 'red.500'}>
                              {formatNumber(profitPercentage, 2)}%
                            </Td>
                            <Td>{formatNumber(daysHeld, 0)}</Td>
                            <Td>
                              <Flex>
                                <IconButton
                                  icon={<FontAwesomeIcon icon={faPencilAlt} />}
                                  size="xs"
                                  colorScheme="orange"
                                  mr={2}
                                  onClick={() => handleEditClick(entry)}
                                  aria-label="Edit entry"
                                />
                                <IconButton
                                  icon={<FontAwesomeIcon icon={faTimes} />}
                                  size="xs"
                                  colorScheme="red"
                                  onClick={() => removeEntry(entry.id)}
                                  aria-label="Remove entry"
                                />
                              </Flex>
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </Box>
              )}
  
              <Flex 
                justifyContent="space-between" 
                alignItems="flex-end" 
                mt={6} 
                flexWrap="wrap"
              >
                <HStack spacing={4} align="flex-start" flexWrap="wrap">
                  <Box>
                    <Text fontWeight="bold">Stack Size</Text>
                    <Text>{formatLargeNumber(totals.totalBtc)} BTC</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="bold">Cost Basis</Text>
                    <Text>{formatCurrency(totals.totalOriginalOrderPrice)}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="bold">Current Value</Text>
                    <Text>{formatCurrency(totals.totalValue)}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="bold">Profit/Loss</Text>
                    <Text color={totals.totalProfit >= 0 ? 'green.500' : 'red.500'}>
                      {formatCurrency(totals.totalProfit)} ({formatNumber(calculateProfitLossPercentage(totals.totalValue, totals.totalOriginalOrderPrice), 2)}%)
                    </Text>
                  </Box>
                </HStack>
                <Flex
                  direction={{ base: "column", md: "row" }}
                  alignItems={{ base: "center", md: "flex-end" }}
                  mt={{ base: "20px", md: "0" }}
                  textAlign={{ base: "center", md: "right" }}
                  width={{ base: "100%", md: "auto" }}
                >
                  <Text
                    fontSize="xs"
                    color="gray.500"
                    mr={2}
                  >
                    For estimation or informational purposes only.
                  </Text>
                  {shouldShowWarning() && (
  <Text
    fontSize="xs"
    color="red.500"
    fontWeight="bold"
  >
    Currency changed. Some calculations may need to be updated.
  </Text>
)}
                </Flex>
              </Flex>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default BitcoinCalculatorAdvanced;