import React from 'react';
import { Spinner } from '@chakra-ui/react';

export const CustomSpinner = () => (
  <Spinner
    alignSelf="center"
    color="orange.500"
    thickness="3px"
    speed="0.21s"
    emptyColor="gray.200"
    size="md"
    mt={3}
  />
);
