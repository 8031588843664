import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Flex,
    useColorMode,
    Text,
    UnorderedList,
    ListItem
} from '@chakra-ui/react';

// Import the version number from package.json
const { version } = require('../../../package.json');

export const NotificationModal = ({ isOpen, onClose }) => {
    const { colorMode } = useColorMode();
    const bgColor = colorMode === 'dark' ? '#121212' : '#ffffff';
    const borderColor = colorMode === 'dark' ? '#ffffff' : '#121212';

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={{ base: "sm", md: "lg" }}>
            <ModalOverlay backgroundColor="rgba(16, 22, 26, 0.9)" />
            <ModalContent bg={bgColor} pb={6} borderWidth="2px" borderStyle="solid" borderColor={borderColor} borderRadius="6px" overflow="hidden">
                <ModalHeader>What's New?</ModalHeader>
                <ModalCloseButton
                    _hover={{
                        bg: 'gray.500',
                    }}
                    _focus={{
                        boxShadow: '0 0 0 1px rgba(66, 153, 225, 0.6)',
                    }}
                />
                <ModalBody>
                    <Flex width="100%">
                        <UnorderedList>
                            <ListItem mb={3}><strong>FIXED! Dashboard Loading Issues</strong> <Text fontSize={'12px'}>Some users reported a blank page</Text></ListItem>
                            <ListItem mb={3}><strong>New! Last Six Blocks Display</strong> <Text fontSize={'12px'}>See the most recent Bitcoin blocks</Text></ListItem>
                            <ListItem mb={3}><strong>Added Bitcoin Price to Preloader</strong> <Text fontSize={'12px'}>To help users with slow connections</Text></ListItem>
                            <ListItem mb={3}><strong>Code Cleanup</strong> <Text fontSize={'12px'}>Refactored components to optimize performance</Text></ListItem>
                        </UnorderedList>
                    </Flex>
                    <Flex width="100%" mt={5}>
                        <Text fontSize={'10px'} color={'gray.700'}>TimechainStats version {version}</Text>
                    </Flex>

                </ModalBody>
            </ModalContent>
        </Modal>
    );
};