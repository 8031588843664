import React from 'react';
import { ThemedFlex } from './ThemedFlex';

export const ChartTileFlex = (props) => {
  return (
    <ThemedFlex
      {...props}
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      style={{ minHeight: '337px', maxHeight: '100%' }}
      width={{ base: "100%", md: "48%", lg: "24.5%" }}
    />
  );
};
