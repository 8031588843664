// DonationsModal.jsx
import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Box,
  useColorMode,
  Text,
  useMediaQuery,
  Grid,
  Image,
  Link,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faGift } from '@fortawesome/free-solid-svg-icons';
import { BtcPayForm } from '../buttons';

const supportersData = [
  { link: 'https://twitter.com/PlebUnderground', imageSrc: 'https://timechainstats.com/img/2023pu.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/illiteratewithd', imageSrc: 'https://timechainstats.com/img/2023il.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/Meditation_Man', imageSrc: 'https://timechainstats.com/img/2023mm.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/honesthodl', imageSrc: 'https://timechainstats.com/img/2023uj.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/brian_trollz', imageSrc: 'https://timechainstats.com/img/2024shi.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/rwawoe', imageSrc: 'https://timechainstats.com/img/2023ro.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/HumbleBitcoiner', imageSrc: 'https://timechainstats.com/img/2023hb.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/S0cratesz', imageSrc: 'https://timechainstats.com/img/2024pt.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/kavanhak', imageSrc: 'https://timechainstats.com/img/2023kv.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/Clashdaddy69', imageSrc: 'https://timechainstats.com/img/2024cd.jpg', alt: 'Supporter' },
];

const Supporter = ({ link, imageSrc, alt }) => (
  <Link href={link} target={'_blank'}>
    <Image
      borderRadius={40}
      border={'2px'}
      borderColor={'gray.700'}
      objectFit="fill"
      src={imageSrc}
      alt={alt}
    />
  </Link>
);

const SupportersGrid = ({ supporters }) => (
  <Grid templateColumns="repeat(5, 1fr)" gap={5}>
    {supporters.slice(0, 10).map((supporter, index) => (
      <Supporter key={index} {...supporter} />
    ))}
  </Grid>
);

export const DonationsModal = ({ isOpen, onClose, donateForm }) => {
  const { colorMode } = useColorMode();
  const bgColor = colorMode === 'dark' ? '#121212' : '#ffffff';
  const borderColor = colorMode === 'dark' ? '#ffffff' : '#121212';
  const [isSmallScreen] = useMediaQuery('(max-width: 768px)');

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{ base: "2xl", md: "2xl" }}>
      <ModalOverlay backgroundColor="rgba(16, 22, 26, 0.9)" />
      <ModalContent bg={bgColor} pb={6} borderWidth="2px" borderStyle="solid" borderColor={borderColor} borderRadius="6px" overflow="hidden">
        <ModalHeader><FontAwesomeIcon icon={faGift} /> &nbsp;Help Support TimechainStats</ModalHeader>
        <ModalCloseButton
          _hover={{
            bg: 'gray.500',
          }}
          _focus={{
            boxShadow: '0 0 0 1px rgba(66, 153, 225, 0.6)',
          }}
        />
        <ModalBody>
          <Flex direction={isSmallScreen ? "column" : "row"} gap={12}>
            <Box flex="2">
              <Text>
                Contribute to TimechainStats.com with BTC tips.
                Your support will help keep this website running.
                On-chain or Lightning Network tips accepted.
              </Text>
              <Text mt={5} color={'#888'}>
                Questions? Contact <Link href='https://twitter.com/TimechainStats' target={'_blank'} textDecoration={'underline'}>@TimechainStats</Link> on Twitter.
              </Text>
              <Text mt={7} mb={3} fontSize={'18px'} fontWeight={'bold'}>
                <FontAwesomeIcon icon={faAward} size={'lg'} /> &nbsp;Top Supporters
                <Link color={colorMode === 'dark' ? 'orange' : 'blue'} ml={2} fontSize="xs" onClick={donateForm}>VIEW ALL</Link>
              </Text>
              <SupportersGrid supporters={supportersData} />
            </Box>
            <Box flex="1">
              <Text textAlign={'center'} mb={3} fontWeight={'bold'}>Donate Here</Text>
              <Text textAlign={'center'}><BtcPayForm onClose={onClose} /></Text>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};