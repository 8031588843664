import React from 'react';
import { Box, Flex, Divider } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBtc } from '@fortawesome/free-brands-svg-icons';

export const BitcoinDivider = () => {
  return (
    <Flex alignItems="center" justifyContent="center" w="100%" mt={2} mb={5}>
      <Divider w="calc(50% - 20px)" borderColor="gray.700" />
      <Box mx={2} color="orange.500">
        <FontAwesomeIcon icon={faBtc} size='lg' />
      </Box>
      <Divider w="calc(50% - 20px)" borderColor="gray.700" />
    </Flex>
  );
};
