import { SingleDataValue } from '.';
import { ThemedFlex } from '../../theme/ThemedFlex';

export const MiningTileHashRate = ({ data, formatLargeNumber, animate }) => {
  const numberValue = data?.ts04?.hashrate?.currentHashrate != null
    ? `${formatLargeNumber(data.ts04.hashrate.currentHashrate, 1, true) ?? "Loading..."}H/s`
    : '';

  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      className={animate ? 'gray-border-custom gray-border' : ''}
    >
      <SingleDataValue label="Hash Rate" value={numberValue} />
    </ThemedFlex>
  );
};
