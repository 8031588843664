// src/components/data-display/BtcLexicon.jsx
import React, { useState, useEffect, useMemo } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  VStack,
  Text,
  Box,
  Flex,
  useColorMode,
  Spinner,
  InputGroup,
  InputLeftElement,
  Button,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  Icon,
  Link,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faArrowLeft, faBookOpen, faExpand, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const BtcLexiconModal = ({ isOpen, onClose, glossaryData, initialTerm }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('ALL');
  const [showAllTerms, setShowAllTerms] = useState(false);
  const { colorMode } = useColorMode();

  const bgColor = colorMode === 'dark' ? '#121212' : '#ffffff';
  const borderColor = colorMode === 'dark' ? '#ffffff' : '#121212';

  useEffect(() => {
    if (initialTerm && glossaryData) {
      const foundTerm = Object.values(glossaryData)
        .flatMap(category => Object.values(category))
        .find(term => term && term.title && term.title.toLowerCase() === initialTerm.toLowerCase());
      
      if (foundTerm) {
        setSelectedTerm(foundTerm);
      } else {
        // If the term is not found, reset to the default view
        setSelectedTerm(null);
        setSearchTerm(initialTerm); // Set the search term to the initial term
      }
    }
  }, [initialTerm, glossaryData]);

  const filteredTerms = useMemo(() => {
    if (!glossaryData || typeof glossaryData !== 'object') {
      return [];
    }

    const searchLower = searchTerm.toLowerCase();

    return Object.entries(glossaryData)
      .filter(([category]) => category !== 'version')
      .flatMap(([category, terms]) =>
        Object.values(terms)
          .filter(term => {
            if (!term || !term.title || !term.definition) return false;
            
            const titleMatch = term.title.toLowerCase().includes(searchLower);
            const definitionMatch = term.definition.toLowerCase().includes(searchLower);
            const matchesFilter = selectedFilter === 'ALL' ||
              (selectedFilter === '0-9' && /^[0-9]/.test(term.title)) ||
              term.title.toLowerCase().startsWith(selectedFilter.toLowerCase()) ||
              (term.categories && term.categories.includes(selectedFilter));
            
            return (titleMatch || definitionMatch) && matchesFilter;
          })
          .map(term => ({
            ...term,
            relevance: term.title.toLowerCase().includes(searchLower) ? 2 : 1
          }))
      )
      .sort((a, b) => b.relevance - a.relevance);
  }, [glossaryData, searchTerm, selectedFilter]);

  const displayedTerms = showAllTerms ? filteredTerms : filteredTerms.slice(0, 7);

  const handleTermClick = (term) => {
    setSelectedTerm(term);
    setSearchTerm('');
  };

  const handleBackClick = () => {
    setSelectedTerm(null);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    if (selectedTerm) {
      setSelectedTerm(null);
    }
    setShowAllTerms(true);
  };

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
    setSelectedTerm(null);
    setShowAllTerms(false);
  };

  const handleCategoryClick = (category, e) => {
    e.stopPropagation();
    setSelectedFilter(category);
    setSelectedTerm(null);
    setShowAllTerms(false);
  };

  const handleViewAllClick = () => {
    setShowAllTerms(true);
  };

  const renderGlossaryItem = (item) => {
    if (!item || !item.id || !item.title || !item.definition) {
      return null;
    }

    const titleParts = item.title.split(new RegExp(`(${searchTerm})`, 'gi'));
    const definitionParts = item.definition.split(new RegExp(`(${searchTerm})`, 'gi'));

    return (
      <Box key={item.id} p={4} borderWidth="1px" borderRadius="lg" onClick={() => handleTermClick(item)} cursor="pointer">
        <Flex justify="space-between" align="baseline" mb={1}>
          <Flex align="baseline">
            <Text fontWeight="bold" fontSize="24px" mr={2} _hover={{ textDecoration: 'underline' }}>
              {titleParts.map((part, i) => 
                part.toLowerCase() === searchTerm.toLowerCase() ? 
                  <mark key={i} style={{ backgroundColor: 'yellow', color: 'black' }}>{part}</mark> : 
                  part
              )}
            </Text>
            <Text fontSize="sm" color="gray">
              {item.part_of_speech}
            </Text>
          </Flex>
          <Icon as={FontAwesomeIcon} icon={faExpand} color="gray" />
        </Flex>
        <Text fontSize="sm" color="gray" mb={1}>
          {item.pronunciation_respelling}
        </Text>
        <Text fontSize="sm" mb={4}>
          {definitionParts.map((part, i) => 
            part.toLowerCase() === searchTerm.toLowerCase() ? 
              <mark key={i} style={{ backgroundColor: 'yellow', color: 'black' }}>{part}</mark> : 
              part
          )}
        </Text>
        <Wrap spacing={2}>
          {item.categories && item.categories.map((category, index) => (
            <WrapItem key={index}>
              <Tag
                size="sm"
                variant="outline"
                colorScheme="gray"
                cursor="pointer"
                onClick={(e) => handleCategoryClick(category, e)}
                _hover={{ opacity: 0.8 }}
              >
                {category}
              </Tag>
            </WrapItem>
          ))}
        </Wrap>
      </Box>
    );
  };

  const renderTermPage = (term) => {
    const currentIndex = filteredTerms.findIndex(t => t.id === term.id);
    const nextTerm = filteredTerms[currentIndex + 1];

    return (
      <Box>
        <Flex justify="space-between" align="center" mb={4}>
          <Button 
            size="xs" 
            leftIcon={<FontAwesomeIcon icon={faArrowLeft} />} 
            onClick={handleBackClick}
          >
            View All Terms
          </Button>
          {nextTerm && (
            <Button
              size="xs"
              rightIcon={<FontAwesomeIcon icon={faArrowRight} />}
              onClick={() => setSelectedTerm(nextTerm)}
            >
              Next Term
            </Button>
          )}
        </Flex>
        <Flex justify="space-between" align="center" mb={4}>
          <Flex align="baseline">
            <Text fontWeight="bold" fontSize="28px" mr={2}>{term.title}</Text>
            <Text fontSize="lg" color="gray">{term.part_of_speech}</Text>
          </Flex>
        </Flex>
        <Text fontSize="md" color="gray" mb={4}>{term.pronunciation_respelling}</Text>
        <Text mb={4}>{term.definition}</Text>
        {term.example && (
          <Box mb={4}>
            <Text fontStyle="italic" fontSize="sm">"{term.example}"</Text>
          </Box>
        )}
        <Box mt={7}>
          <Wrap>
            {term.categories && term.categories.map((category, index) => (
              <WrapItem key={index}>
                <Tag
                  size="sm"
                  variant="outline"
                  colorScheme="gray"
                  cursor="pointer"
                  onClick={(e) => handleCategoryClick(category, e)}
                  _hover={{ opacity: 0.8 }}
                >
                  {category}
                </Tag>
              </WrapItem>
            ))}
          </Wrap>
        </Box>
      </Box>
    );
  };


  const alphabetFilters = ['0-9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl">
      <ModalOverlay backgroundColor="rgba(16, 22, 26, 0.9)" />
      <ModalContent bg={bgColor} pb={6} borderWidth="2px" borderStyle="solid" borderColor={borderColor} borderRadius="6px" overflow="hidden">
        <ModalHeader>
          <HStack>
            <FontAwesomeIcon icon={faBookOpen} />
            <Text>BTC Lexicon</Text>
          </HStack>
          <Flex justify="space-between" align="center" mt="7px">
            <Text fontSize="sm" fontWeight="normal" color="gray">
              Comprehensive glossary of Bitcoin terms
            </Text>
            <Text fontSize="xs" fontWeight="normal" color="gray">
              version {glossaryData?.version || 'N/A'}
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton
          _hover={{
            bg: 'gray.500',
          }}
          _focus={{
            boxShadow: '0 0 0 1px rgba(66, 153, 225, 0.6)',
          }}
        />
        <ModalBody>
          <InputGroup mb={4}>
            <InputLeftElement pointerEvents="none">
              <FontAwesomeIcon icon={faSearch} color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Search terms..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </InputGroup>
          {!selectedTerm && (
            <Box mb={4}>
              <HStack spacing={1} wrap="wrap">
                <Button size="xs" onClick={() => handleFilterClick('ALL')} variant={selectedFilter === 'ALL' ? 'solid' : 'outline'}>
                  ALL
                </Button>
                {alphabetFilters.map((filter) => (
                  <Button key={filter} size="xs" onClick={() => handleFilterClick(filter)} variant={selectedFilter === filter ? 'solid' : 'outline'}>
                    {filter}
                  </Button>
                ))}
              </HStack>
            </Box>
          )}
          {!glossaryData ? (
            <Flex justify="center" align="center" height="200px">
              <Spinner />
            </Flex>
          ) : selectedTerm ? (
            renderTermPage(selectedTerm)
          ) : (
            <VStack align="stretch" spacing={4} maxHeight="60vh" overflowY="auto">
              {displayedTerms.length > 0 ? (
                <>
                  {displayedTerms.map(renderGlossaryItem)}
                  {!showAllTerms && filteredTerms.length > 7 && (
                    <Box textAlign="center">
                      <Link color="orange.500" onClick={handleViewAllClick}>
                        View All
                      </Link>
                    </Box>
                  )}
                </>
              ) : (
                <Text>No matching terms found.</Text>
              )}
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default BtcLexiconModal;