import React, { useEffect, useState, useRef } from 'react';
import ApexCharts from 'apexcharts';
import { DataTableHeader } from './DataTableHeader';
import { DataTableFooter } from './DataTableFooter';
import { ThemedFlex } from '../../theme/ThemedFlex'
import { Box, useColorMode } from '@chakra-ui/react';
import { CustomSpinner } from './CustomSpinner';

const getBreakpoint = (width) => {
  if (width < 768) return 'base';
  if (width >= 768 && width < 992) return 'md';
  if (width >= 992 && width < 1200) return 'lg';
  return 'xl';
};

const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(() => getBreakpoint(window.innerWidth));

  useEffect(() => {
    const handleResize = () => setBreakpoint(getBreakpoint(window.innerWidth));
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return breakpoint;
};

export const LightningNodesTile = ({
  title,
  subtitle,
  icon,
  link,
  linkLabel = 'Learn More',
  data,
  uniqueChartId,
}) => {
  const [chartData, setChartData] = useState([]);

  const { colorMode } = useColorMode();
  const borderColor = colorMode === 'dark' ? '#121212' : '#ffffff';

  const chartRef = useRef(null);

  const formatCount = (count) => {
    return count >= 1000 ? `${(count / 1000).toFixed(1)}k` : count;
  };

  useEffect(() => {
    if (data && data.s11 && data.s11.lightning && data.s11.lightning.latest) {
      const latestData = data.s11.lightning.latest;
      const newData = [
        { y: latestData.tor_nodes, x: `${formatCount(latestData.tor_nodes)} Tor Nodes` },
        { y: latestData.clearnet_nodes, x: `${formatCount(latestData.clearnet_nodes)} Clearnet Nodes` },
        { y: latestData.unannounced_nodes, x: `${formatCount(latestData.unannounced_nodes)} Unannounced Nodes` },
        { y: latestData.clearnet_tor_nodes, x: `${formatCount(latestData.clearnet_tor_nodes)} Clearnet Tor Nodes` },
      ];
      setChartData(newData);
    }
  }, [data]);

  const breakpoint = useBreakpoint();

  useEffect(() => {
    if (chartData.length > 0 && data && data.s11 && data.s11.lightning && data.s11.lightning.latest) {
      const options = {
        chart: {
          fontFamily: 'Ubuntu, sans-serif',
          type: 'donut',
          height: '100%',
          width: '100%',
          toolbar: {
            offsetY: -10,
            tools: {
              download: false,
            },
            padding: {
              bottom: 20,
            },
            color: '#78909C',
          },
        },
        labels: chartData.map(data => data.x),
        series: chartData.map(data => data.y),
        colors: ["#f7931a", "#329239", "#0d579b", "#4d4d4d", "#7e57c2"],
        legend: {
          formatter: (seriesName, opts) => {
            if (opts.seriesIndex === 0) {
              const nodeCount = data.s11.lightning.latest.node_count;
              const formattedNodeCount = formatCount(nodeCount);
              return `${seriesName} (${formattedNodeCount} Total Nodes)`;
            }
            return seriesName;
          },
          labels: {
            colors: '#999',
          },
          show: breakpoint === 'md' || breakpoint === 'lg' || breakpoint === 'xl',
        },
        dataLabels: {
          dropShadow: {
            enabled: false,
          },
          style: {
            colors: ['#121212'],
          },
        },
        stroke: {
          colors: [borderColor],
        },
        tooltip: {
          theme: 'dark',
          y: {
            formatter: (value) => {
              return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
          },
          style: {
            backgroundColor: '#000000',
            color: '#ffffff',
          },
        },
      };
      
      const chart = new ApexCharts(chartRef.current, options);
      chart.render();

      const handleResize = () => {
        chart.updateOptions({
          chart: {
            width: '100%',
            height: '100%',
          },
        });
      };

      window.addEventListener('resize', handleResize);

      return () => {
        chart.destroy();
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [chartData, borderColor, breakpoint, data]);

  if (!data || !data.s11 || !data.s11.lightning || !data.s11.lightning.latest) {
    console.error("Error: Data is null, undefined, or incorrectly structured");
    return <CustomSpinner />;
  }

  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      style={{ minHeight: '337px', maxHeight: '100%' }}
    >
      <DataTableHeader title={title} subtitle={subtitle} icon={icon} />
      <Box flexGrow={1} minHeight='337px'>
        <div ref={chartRef} className={`chart-container-${uniqueChartId}`} style={{ height: '100%' }} />
      </Box>
      <DataTableFooter link={link} linkLabel={linkLabel} />
    </ThemedFlex>
  );
};