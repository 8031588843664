// src/utils/date.utils.js
export const daysSince = (dateString) => {
    const startDate = new Date(dateString);
    const currentDate = new Date();
    const msPerDay = 24 * 60 * 60 * 1000;
    return Math.floor((currentDate - startDate) / msPerDay);
};

export const daysLeft = (halvening_time) => {
    const targetDate = new Date(halvening_time);
    const currentDate = new Date();
    const msPerDay = 24 * 60 * 60 * 1000;
    return Math.floor((targetDate - currentDate) / msPerDay);
};

export const secondsLeft = (halvening_time) => {
    const targetDate = new Date(halvening_time);
    const currentDate = new Date();
    const msPerSecond = 1000;
    return Math.floor((targetDate - currentDate) / msPerSecond);
};


export const formatDate = (timestamp, isMilliseconds = false) => {
    const date = new Date(isMilliseconds ? timestamp : timestamp * 1000);
    const formattedDate = date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
    const formattedTime = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
    });

    return `${formattedDate} ${formattedTime}`;
};

export function formatTimeRemaining(seconds) {
    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const parts = [];
    if (days > 0) parts.push(`${days}d`);
    if (hours > 0) parts.push(`${hours}h`);
    if (minutes > 0) parts.push(`${minutes}m`);
    if (remainingSeconds > 0) parts.push(`${remainingSeconds}s`);

    return parts.join(' ');
}