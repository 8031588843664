import { SingleDataValue } from '.';
import { ThemedFlex } from '../../theme/ThemedFlex';

export const MiningTileMempoolSize = ({ formatLargeNumber, animate, mempoolInfo }) => {

  const mempoolTotalSize = mempoolInfo.usage && mempoolInfo.maxmempool
    ? `${formatLargeNumber(mempoolInfo.usage, 1, true)} / ${formatLargeNumber(mempoolInfo.maxmempool, 0, true)}`
    : "Loading...";


  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      className={animate ? 'gray-border-custom gray-border' : ''}
    >
      <SingleDataValue label="Mempool Size" value={mempoolTotalSize} />
    </ThemedFlex>
  );
};
