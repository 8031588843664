import { useEffect, useState, useRef } from 'react';
import ApexCharts from 'apexcharts';
import { DataTableHeader } from './DataTableHeader';
import { DataTableFooter } from './DataTableFooter';
import { ThemedFlex } from '../../theme/ThemedFlex';
import { Select, Box } from '@chakra-ui/react';
import { CustomSpinner } from './CustomSpinner';

export const TransactionsTile = ({
  title,
  subtitle,
  icon,
  link,
  linkLabel = 'Learn More',
  data,
}) => {
  const [chartData, setChartData] = useState([]);
  const [timeframe, setTimeframe] = useState('3y');
  const [latestTransaction, setLatestTransaction] = useState(null);
  const chartRef = useRef(null);

  const handleTimeframeChange = (event) => {
    setTimeframe(event.target.value);
  };

  useEffect(() => {
    if (data && data.ts14) {
      const allData = data.ts14.transactions.values.map(({ x, y }) => ({
        x: new Date(x * 1000),
        y,
      }));

      const days = {
        '3y': 1092,
        '2y': 728,
        '1y': 364,
        '6m': 182,
        '3m': 91,
        '7d': 8,
        '2d': 3,
      };

      const filteredData = allData.filter((dataPoint) => {
        const now = new Date();
        const daysAgo = now.setDate(now.getDate() - days[timeframe]);
        return dataPoint.x >= daysAgo;
      });

      setChartData(filteredData);
      setLatestTransaction(filteredData[filteredData.length - 1].y);
    }
  }, [data, timeframe]);

  useEffect(() => {
    if (chartData.length > 0 && chartRef.current) {
      const options = {
        chart: {
          fontFamily: 'Ubuntu, sans-serif',
          type: 'line',
          height: '100%',
          width: '100%',
          toolbar: {
            offsetY: -10,
            tools: {
              download: false,
            },
            padding: {
              bottom: 20,
            },
            color: '#78909C',
          },
        },
        annotations: {
          yaxis: [
            {
              y: latestTransaction,
              borderColor: '#f7931a',
              borderWidth: 0,
              fillColor: '#f7931a',
              label: {
                borderColor: '#f7931a',
                borderWidth: 2,
                style: {
                  color: '#000000',
                  background: '#f7931a',
                  fontWeight: 'bold',
                  fontSize: '13px',
                },
                text: `${latestTransaction.toLocaleString('en-US')}`,
                offsetY: 0,
                offsetX: 5,
                textAnchor: 'start',
              },
            },
          ],
        },
        series: [
          {
            name: 'Bitcoin Transactions',
            data: chartData,
          },
        ],
        xaxis: {
          type: 'datetime',
          labels: {
            style: {
              colors: '#999',
            },
          },
        },
        yaxis: [
          {
            opposite: true,
            title: {
              text: 'Transactions',
              style: {
                color: '#999',
              },
            },
            labels: {
              style: {
                colors: '#999',
              },
              formatter: function (value) {
                return value.toLocaleString();
              },
            },
          },
        ],
        colors: ['#f7931a'],
        stroke: {
          width: 2,
        },
        tooltip: {
          theme: 'dark',
          style: {
            backgroundColor: '#000000',
            color: '#ffffff',
          },
          x: {
            format: 'dd MMM yyyy',
          },
          y: {
            formatter: function (value) {
              return value.toLocaleString() + ' Transactions';
            },
          },
        },
      };

      const chart = new ApexCharts(chartRef.current, options);
      chart.render();

      const handleResize = () => {
        chart.updateOptions({
          chart: {
            width: '100%',
            height: '100%',
          },
        });
      };

      window.addEventListener('resize', handleResize);

      return () => {
        chart.destroy();
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [chartData, latestTransaction]);

  if (!data || !data.ts14) {
    console.error("Error: Data is missing or incomplete");
    return <CustomSpinner />;
  }

  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      style={{ minHeight: '337px', maxHeight: '100%' }}
    >
      <DataTableHeader title={title} subtitle={subtitle} icon={icon} />
      <Select
        value={timeframe}
        onChange={handleTimeframeChange}
        variant="filled"
        size="xs"
        alignSelf="flex-start"
        width="100px"
        mb={0}
      >
        <option value="3y">3 Years</option>
        <option value="2y">2 Years</option>
        <option value="1y">1 Year</option>
        <option value="6m">6 Months</option>
        <option value="3m">3 Months</option>
        <option value="7d">7 Days</option>
        <option value="2d">2 Days</option>
      </Select>
      <Box flexGrow={1} minHeight='337px'>
        <div ref={chartRef} style={{ height: '100%' }} />
      </Box>
      <DataTableFooter link={link} linkLabel={linkLabel} />
    </ThemedFlex>
  );
};