import { Flex, Box, Text } from '@chakra-ui/react'

export const WordTileBody = ({ body }) => {
    return (
        <Flex
            justify="space-between"
            gap="30px"
            mb="7px"
        >
            <Box>
                <Text
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="18px"
                    mb="7px"
                    mt={4}
                >
                    {body}
                </Text>
            </Box>
        </Flex>
    )
}
