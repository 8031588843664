import { SingleDataValue } from '../data-display';
import { ThemedFlex } from '../../theme/ThemedFlex';

export const ResourceTileShellingOut = ({ animate }) => {

  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      className={animate ? 'gray-border-custom gray-border' : ''}
    >
      <SingleDataValue
        label="Nick Szabo"
        value="Shelling Out"
        link="https://nakamotoinstitute.org/shelling-out/"
      />
    </ThemedFlex>
  );
};
