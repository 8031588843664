import React from 'react';
import { DataTable } from '../../data-display/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

export const FutureBitcoinHalvingsTable = ({ 
  animate,
  onOpenLexicon 
}) => {
  const tableData = [
    {
        label: '2028 - Est. Block Subsidy',
        value: '1.5625 BTC',
        tooltipText: "In 2028, the block subsidy is estimated to drop from 3.125 BTC to 1.5625 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
        clickableTerm: "Bitcoin Halving",
    },
    {
        label: '2032 - Est. Block Subsidy',
        value: '0.78125 BTC',
        tooltipText: "In 2032, the block subsidy is estimated to drop from 1.5625 BTC to 0.78125 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
        clickableTerm: "Subsidy",
    },
    {
        label: '2036 - Est. Block Subsidy',
        value: '0.390625 BTC',
        tooltipText: "In 2036, the block subsidy is estimated to drop from 0.78125 BTC to 0.390625 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
        clickableTerm: "Bitcoin Halving",
    },
    {
        label: '2040 - Est. Block Subsidy',
        value: '0.195313 BTC',
        tooltipText: "In 2040, the block subsidy is estimated to drop from 0.390625 BTC to 0.195313 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
        clickableTerm: "Subsidy",
    },
    {
        label: '2044 - Est. Block Subsidy',
        value: '0.097656 BTC',
        tooltipText: "In 2044, the block subsidy is estimated to drop from 0.195313 BTC to 0.097656 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
        clickableTerm: "Bitcoin Halving",
    },
    {
        label: '2048 - Est. Block Subsidy',
        value: '0.048828 BTC',
        tooltipText: "In 2048, the block subsidy is estimated to drop from 0.097656 BTC to 0.048828 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
        clickableTerm: "Subsidy",
    },
    {
        label: '2052 - Est. Block Subsidy',
        value: '0.024414 BTC',
        tooltipText: "In 2052, the block subsidy is estimated to drop from 0.048828 BTC to 0.024414 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
        clickableTerm: "Bitcoin Halving",
    },
    {
        label: '2056 - Est. Block Subsidy',
        value: '0.012207 BTC',
        tooltipText: "In 2056, the block subsidy is estimated to drop from 0.024414 BTC to 0.012207 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
        clickableTerm: "Subsidy",
    },
    {
        label: '2060 - Est. Block Subsidy',
        value: '0.0061035 BTC',
        tooltipText: "In 2060, the block subsidy is estimated to drop from 0.012207 BTC to 0.0061035 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
        clickableTerm: "Bitcoin Halving",
    },
    {
        label: '2064 - Est. Block Subsidy',
        value: '0.00305175 BTC',
        tooltipText: "In 2064, the block subsidy is estimated to drop from 0.0061035 BTC to 0.00305175 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
        clickableTerm: "Subsidy",
    },
    {
        label: '2068 - Est. Block Subsidy',
        value: '0.00152587 BTC',
        tooltipText: "In 2068, the block subsidy is estimated to drop from 0.00305175 BTC to 0.00152587 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
        clickableTerm: "Bitcoin Halving",
    },
    {
        label: '2072 - Est. Block Subsidy',
        value: '0.00076293 BTC',
        tooltipText: "In 2072, the block subsidy is estimated to drop from 0.00152587 BTC to 0.00076293 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
        clickableTerm: "Subsidy",
    },
    {
        label: '2076 - Est. Block Subsidy',
        value: '0.00038146 BTC',
        tooltipText: "In 2076, the block subsidy is estimated to drop from 0.00076293 BTC to 0.00038146 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
        clickableTerm: "Bitcoin Halving",
    },
    {
        label: '2080 - Est. Block Subsidy',
        value: '0.00019073 BTC',
        tooltipText: "In 2080, the block subsidy is estimated to drop from 0.00038146 BTC to 0.00019073 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
        clickableTerm: "Subsidy",
    },
    {
        label: '2084 - Est. Block Subsidy',
        value: '0.00009536 BTC',
        tooltipText: "In 2084, the block subsidy is estimated to drop from 0.00019073 BTC to 0.00009536 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
        clickableTerm: "Bitcoin Halving",
    },
    {
        label: '2088 - Est. Block Subsidy',
        value: '0.00004768 BTC',
        tooltipText: "In 2088, the block subsidy is estimated to drop from 0.00009536 BTC to 0.00004768 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
        clickableTerm: "Subsidy",
    },
    {
        label: '2092 - Est. Block Subsidy',
        value: '0.00002384 BTC',
        tooltipText: "In 2092, the block subsidy is estimated to drop from 0.00004768 BTC to 0.00002384 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
        clickableTerm: "Bitcoin Halving",
    },
    {
        label: '2096 - Est. Block Subsidy',
        value: '0.00001192 BTC',
        tooltipText: "In 2096, the block subsidy is estimated to drop from 0.00002384 BTC to 0.00001192 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
        clickableTerm: "Subsidy",
    },
    {
        label: '2100 - Est. Block Subsidy',
        value: '0.00000596 BTC',
        tooltipText: "In 2100, the block subsidy is estimated to drop from 0.00001192 BTC to 0.00000596 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
        clickableTerm: "Bitcoin Halving",
    },
    {
        label: '2104 - Est. Block Subsidy',
        value: '0.00000298 BTC',
        tooltipText: "In 2104, the block subsidy is estimated to drop from 0.00000596 BTC to 0.00000298 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
        clickableTerm: "Subsidy",
    },
    {
        label: '2108 - Est. Block Subsidy',
        value: '0.00000149 BTC',
        tooltipText: "In 2108, the block subsidy is estimated to drop from 0.00000298 BTC to 0.00000149 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
        clickableTerm: "Bitcoin Halving",
    },
    {
        label: '2112 - Est. Block Subsidy',
        value: '0.00000074 BTC',
        tooltipText: "In 2112, the block subsidy is estimated to drop from 0.00000149 BTC to 0.00000074 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
        clickableTerm: "Subsidy",
    },
    {
        label: '2116 - Est. Block Subsidy',
        value: '0.00000037 BTC',
        tooltipText: "In 2116, the block subsidy is estimated to drop from 0.00000074 BTC to 0.00000037 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
        clickableTerm: "Bitcoin Halving",
    },
    {
        label: '2120 - Est. Block Subsidy',
        value: '0.00000018 BTC',
        tooltipText: "In 2120, the block subsidy is estimated to drop from 0.00000037 BTC to 0.00000018 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
        clickableTerm: "Subsidy",
    },
    {
        label: '2124 - Est. Block Subsidy',
        value: '0.00000009 BTC',
        tooltipText: "In 2124, the block subsidy is estimated to drop from 0.00000018 BTC to 0.00000009 BTC per block. This is part of Bitcoin's halving schedule, where the block reward is reduced by 50% approximately every four years.",
        clickableTerm: "Bitcoin Halving",
    }
  ];

  return (
    <DataTable
      title="Future Bitcoin Halvings"
      subtitle="Track upcoming BTC halving events"
      icon={<FontAwesomeIcon icon={faCalendarAlt} />}
      rows={tableData}
      animate={animate}
      specificTerm="Distribution Schedule"
      onOpenLexicon={onOpenLexicon}
      rowsPerPage={9}
    />
  );
};