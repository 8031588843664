import React, { useRef, useEffect, useState } from 'react';
import { Box, Button, HStack, VStack, CloseButton } from '@chakra-ui/react';

const AchievementBadgeWinner = ({ onClose }) => {
  const canvasRef = useRef(null);
  const [canvasSize, setCanvasSize] = useState({ width: 320, height: 260 });

  const drawRays = (ctx, width, height) => {
    const centerX = width / 2;
    const centerY = height / 2;
    const numRays = 12;
    const rayLength = Math.sqrt(width * width + height * height) / 2;

    ctx.save();
    
    for (let i = 0; i < numRays; i++) {
      const angle = (i / numRays) * Math.PI * 2;
      const endX = centerX + Math.cos(angle) * rayLength;
      const endY = centerY + Math.sin(angle) * rayLength;

      const gradient = ctx.createLinearGradient(centerX, centerY, endX, endY);
      gradient.addColorStop(0, 'rgba(255, 255, 255, 0)');
      gradient.addColorStop(0.5, 'rgba(255, 255, 255, 0.05)');
      gradient.addColorStop(1, 'rgba(255, 255, 255, 0.1)');

      ctx.beginPath();
      ctx.moveTo(centerX, centerY);
      ctx.lineTo(
        centerX + Math.cos(angle - 0.1) * rayLength,
        centerY + Math.sin(angle - 0.1) * rayLength
      );
      ctx.lineTo(
        centerX + Math.cos(angle + 0.1) * rayLength,
        centerY + Math.sin(angle + 0.1) * rayLength
      );
      ctx.closePath();

      ctx.fillStyle = gradient;
      ctx.fill();
    }

    ctx.restore();
  };

  const drawTextWithShadow = (ctx, text, x, y, fontSize, fontWeight = 'normal') => {
    ctx.save();
    ctx.font = `${fontWeight} ${fontSize}px Ubuntu`;
    ctx.shadowColor = 'rgba(0, 0, 0, 0.3)';
    ctx.shadowBlur = 4;
    ctx.shadowOffsetX = 2;
    ctx.shadowOffsetY = 2;
    ctx.fillText(text, x, y);
    ctx.restore();
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    canvas.width = 320;
    canvas.height = 260;
    setCanvasSize({ width: 320, height: 260 });

    const fontFace = new FontFace('Ubuntu', 'url(https://fonts.gstatic.com/s/ubuntu/v20/4iCv6KVjbNBYlgoCxCvjsGyN.woff2)', { weight: '700' });
    document.fonts.add(fontFace);

    fontFace.load().then(() => {
      ctx.fillStyle = '#4CAF50';  // Green color for winning
      ctx.roundRect(0, 0, 320, 260, 20);
      ctx.fill();

      drawRays(ctx, 320, 260);

      ctx.save();
      ctx.shadowColor = 'rgba(0, 0, 0, 0.3)';
      ctx.shadowBlur = 4;
      ctx.shadowOffsetX = 2;
      ctx.shadowOffsetY = 2;
      ctx.fillStyle = '#FFFFFF';
      ctx.font = '70px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText('🏆💰🚀', 160, 80);  // Trophy, money bag, and rocket emojis
      ctx.restore();

      ctx.fillStyle = '#FFFFFF';
      ctx.textAlign = 'center';
      drawTextWithShadow(ctx, 'Winner, Winner!', 160, 142, 28, 'bold');
      drawTextWithShadow(ctx, 'You won a prediction!', 160, 170, 16, 'bold');

      ctx.textAlign = 'left';
      ctx.textBaseline = 'middle';
      
      ctx.font = 'bold 15px Ubuntu';
      const timechainWidth = ctx.measureText('Timechain').width;
      ctx.font = '15px Ubuntu';
      const statsWidth = ctx.measureText('Stats').width;
      
      const bitcoinSymbolWidth = ctx.measureText('₿').width;
      const spacingBetweenElements = 5;
      const totalWidth = bitcoinSymbolWidth + timechainWidth + statsWidth + (spacingBetweenElements * 2);
      const startX = (320 - totalWidth) / 2;
      const startY = 218;
      
      drawTextWithShadow(ctx, '₿', startX, startY + 1, 17, 'bold');
      drawTextWithShadow(ctx, 'Timechain', startX + bitcoinSymbolWidth + spacingBetweenElements, startY, 15, 'bold');
      drawTextWithShadow(ctx, 'Stats', startX + bitcoinSymbolWidth + timechainWidth + (spacingBetweenElements * 1.5), startY, 15);
    });
  }, []);

  const handleDownload = () => {
    const canvas = canvasRef.current;
    const image = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = image;
    link.download = 'prediction_master_badge.png';
    link.click();
  };

  const handleShare = async () => {
    const canvas = canvasRef.current;
    const image = canvas.toDataURL('image/png');
    const blob = await (await fetch(image)).blob();
    const file = new File([blob], 'prediction_master_badge.png', { type: 'image/png' });
    const shareData = {
      text: 'I won a prediction in the Get Rekt Simulator on TimechainStats.com! 🏆💰🚀',
      url: window.location.href,
      files: [file]
    };

    if (navigator.share && navigator.canShare(shareData)) {
      try {
        await navigator.share(shareData);
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareData.text)}&url=${encodeURIComponent(shareData.url)}`;
      window.open(twitterUrl, '_blank');
    }
  };

  return (
    <Box
      position="fixed"
      top="40%"
      left="50%"
      transform="translate(-50%, -50%)"
      bg="gray.900"
      color="white"
      p={4}
      borderRadius="md"
      textAlign="center"
      zIndex={1000}
      boxShadow="0 0 10px rgba(76, 175, 80, 0.3)"  // Green shadow
    >
      <CloseButton position="absolute" right="0px" top="0px" onClick={onClose} color="#121212" />
      <VStack spacing={4}>
        <Box position="relative" width={canvasSize.width} height={canvasSize.height}>
          <canvas 
            ref={canvasRef} 
            style={{ 
              width: `${canvasSize.width}px`, 
              height: `${canvasSize.height}px`, 
              maxWidth: '100%', 
            }} 
          />
        </Box>
        <HStack spacing={2}>
          <Button onClick={handleDownload} bg="green.500" size={'sm'} color="white" _hover={{ bg: 'green.600' }}>
            Download Badge
          </Button>
          <Button onClick={handleShare} bg="green.500" size={'sm'} color="white" _hover={{ bg: 'green.600' }}>
            Share on Twitter
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};

export default AchievementBadgeWinner;