import { Flex } from '@chakra-ui/react'

export const MasterWrap = ({ children }) => {
    return (
        <Flex
            minH="100vh"
            direction="column"
            justify="space-between"
        >
            { children }
        </Flex>
    )
}
