import React from 'react';
import { DataTable } from '../../data-display/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBasketShopping } from '@fortawesome/free-solid-svg-icons';
import { formatNumber, formatLargeNumber } from '../../../utils';

export const OneBtcCanBuyTable = ({ 
  data, 
  bitcoinPrice, 
  conversionRates, 
  selectedCurrency, 
  animate,
  onOpenLexicon,
}) => {
  const tableData = data && bitcoinPrice && conversionRates && selectedCurrency
    ? [
        {
            label: data && bitcoinPrice ? `Lambos @ ${formatLargeNumber((546847 * conversionRates[selectedCurrency]), 0, false)} ${selectedCurrency.toUpperCase()}` : "Loading...",
            value: data && bitcoinPrice ? `${formatNumber(bitcoinPrice / (546847), 2)} Lambos` : "Loading...",
            tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the cost of a 2020 Lamborghini Aventador (~${formatLargeNumber((546847 * conversionRates[selectedCurrency]), 0, false)} ${selectedCurrency.toUpperCase()})` : "Loading...",
            clickableTerm: "1 BTC Can Buy",
        },
        {
            label: data && bitcoinPrice ? `Houses @ ${formatLargeNumber(431000 * conversionRates[selectedCurrency], 0, false)} ${selectedCurrency.toUpperCase()}` : "Loading...",
            value: data && bitcoinPrice ? `${formatNumber(bitcoinPrice / (431000), 2)} Houses` : "Loading...",
            tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the average cost of a median U.S. existing house for all home types (~${formatLargeNumber(431000 * conversionRates[selectedCurrency], 0, false)} ${selectedCurrency.toUpperCase()})` : "Loading...",
            clickableTerm: "1 BTC Can Buy",
        },
        {
            label: 'Tesla Model 3',
            value: bitcoinPrice && data ? `${formatNumber(bitcoinPrice / 42990, 2)} Cars` : "Loading...",
            tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the base price of a Tesla Model 3 (Starting at $42,990 in 2024)` : "Loading...",
            clickableTerm: "1 BTC Can Buy",
        },
        {
            label: 'MtG: Black Lotus',
            value: bitcoinPrice && data ? `${formatNumber(bitcoinPrice / 3000000, 2)} Cards` : "Loading...",
            tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the cost of one (1) Beta edition 'Black Lotus' card from the Magic: the Gathering trading card game (Sold for ~$3M in 2024)` : "Loading...",
            clickableTerm: "1 BTC Can Buy",
        },
        {
            label: data?.s06?.bitcoin?.market_data?.current_price?.xau != null && bitcoinPrice != null
              ? `Gold @ ${formatNumber(bitcoinPrice / (data.s06.bitcoin.market_data.current_price.xau * conversionRates[selectedCurrency]), 2)} ${selectedCurrency.toUpperCase()}`
              : "Loading...",
            value: data?.s06?.bitcoin?.market_data?.current_price?.xau != null
              ? `${formatNumber((data.s06.bitcoin.market_data.current_price.xau * conversionRates[selectedCurrency]), 2)} oz.`
              : "Loading...",
            tooltipText: data?.s06?.bitcoin?.market_data?.current_price?.xau != null && bitcoinPrice != null
              ? `Dividing one (1) Bitcoin by the cost of 1oz of gold as reported by SwissQuotes Forex Data Feed (Currently trading at ${formatNumber(bitcoinPrice / (data.s06.bitcoin.market_data.current_price.xau * conversionRates[selectedCurrency]), 2)} ${selectedCurrency.toUpperCase()})`
              : "Loading...",
            clickableTerm: "1 BTC Can Buy",
        },
        {
            label: data?.s06?.bitcoin?.market_data?.current_price?.xag != null && bitcoinPrice != null
              ? `Silver @ ${formatNumber(bitcoinPrice / (data.s06.bitcoin.market_data.current_price.xag * conversionRates[selectedCurrency]), 2)} ${selectedCurrency.toUpperCase()}`
              : "Loading...",
            value: data?.s06?.bitcoin?.market_data?.current_price?.xag != null
              ? `${formatNumber((data.s06.bitcoin.market_data.current_price.xag * conversionRates[selectedCurrency]), 2)} oz.`
              : "Loading...",
            tooltipText: data?.s06?.bitcoin?.market_data?.current_price?.xag != null && bitcoinPrice != null
              ? `Dividing one (1) Bitcoin by the cost of 1oz of silver as reported by SwissQuotes Forex Data Feed (Currently trading at ${formatNumber(bitcoinPrice / (data.s06.bitcoin.market_data.current_price.xag * conversionRates[selectedCurrency]), 2)} ${selectedCurrency.toUpperCase()})`
              : "Loading...",
            clickableTerm: "1 BTC Can Buy",
        },
        {
            label: data && bitcoinPrice ? `Big Macs @ ${formatNumber(5.15 * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()}` : "Loading...",
            value: data && bitcoinPrice ? `${formatNumber(bitcoinPrice / (5.15), 0)} Big Macs` : "Loading...",
            tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the cost of one (1) Big Mac hamburger in the U.S. (~${formatNumber(5.15 * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()})` : "Loading...",
            clickableTerm: "1 BTC Can Buy",
        },
        {
            label: 'Amazing Spiderman #300',
            value: bitcoinPrice && data ? `${formatNumber(bitcoinPrice / 3000, 2)} Copies` : "Loading...",
            tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the cost of one (1)copy of Amazing Spiderman #300 (Sold for ~$3k in 2024)` : "Loading...",
            clickableTerm: "1 BTC Can Buy",
        },
        {
            label: 'iPhones @ $999 USD',
            value: bitcoinPrice && data ? `${formatNumber(bitcoinPrice / 999, 2)} Phones` : "Loading...",
            tooltipText: data && bitcoinPrice ? `Dividing one (1) Bitcoin by the price of an iPhone 15 Pro (Starting at $999 in 2024)` : "Loading...",
            clickableTerm: "1 BTC Can Buy",
        }
    ]
    : [];

  return (
    <DataTable
      title="One BTC Can Buy"
      subtitle="What can you buy with 1 Bitcoin?"
      icon={<FontAwesomeIcon icon={faBasketShopping} />}
      rows={tableData}
      animate={animate}
      onOpenLexicon={onOpenLexicon}
      rowsPerPage={9}
    />
  );
};