// src/components/data-display/ChartTile.jsx
import { useEffect, useState, useRef } from 'react';
import ApexCharts from 'apexcharts';
import { DataTableHeader } from './DataTableHeader';
import { DataTableFooter } from './DataTableFooter';
import { ChartTileFlex } from '../../theme/ChartTileFlex';
import { CustomSpinner } from './CustomSpinner';
import { Box, Select, useColorMode } from '@chakra-ui/react';

export const ChartTile = ({
  title,
  subtitle,
  icon,
  link,
  linkLabel = 'Learn More',
  data,
  uniqueChartId,
  formatNumber,
  formatLargeNumber
}) => {
  const [chartData, setChartData] = useState([]);
  const chartRef = useRef(null);
  const [latestPrice, setLatestPrice] = useState(null);
  const [timeframe, setTimeframe] = useState('3y');
  const handleTimeframeChange = (event) => {
    setTimeframe(event.target.value);
  };
  const [loading, setLoading] = useState(true);

  const { colorMode } = useColorMode();

  const gridColor = colorMode === 'dark' ? '#555555' : '#CCCCCC';
  const labelColor = colorMode === 'dark' ? '#999' : '#666';

  useEffect(() => {
    if (data && data.ts15 && data.ts15.price && Array.isArray(data.ts15.price.values)) {
      const allData = data.ts15.price.values.map((price) => ({
        x: new Date(price.x * 1000), // Convert timestamp to milliseconds
        y: price.y,
      }));

      const days = {
        '3y': 1095,
        '2y': 730,
        '1y': 365,
        '6m': 180,
        '3m': 90,
        '7d': 7,
        '2d': 2,
      };

      const filteredData = allData.filter((dataPoint) => {
        const now = new Date();
        const daysAgo = now.setDate(now.getDate() - days[timeframe]);
        return dataPoint.x >= daysAgo;
      });

      setChartData(filteredData);
      setLatestPrice(filteredData[filteredData.length - 1].y);
      setLoading(false);
    } else {
      console.error("Invalid or missing data structure");
      setLoading(false);
    }
  }, [data, timeframe]);

  useEffect(() => {
    if (chartData.length > 0) {
      const options = {
        chart: {
          fontFamily: 'Ubuntu, sans-serif',
          type: 'line',
          height: '100%',
          width: '100%',
          toolbar: {
            offsetY: -10,
            tools: {
              download: false,
            },
            padding: {
              bottom: 20,
            },
            color: '#78909C',
          }
        },
        annotations: {
          yaxis: [
            {
              y: latestPrice,
              borderColor: '#FFA500',
              borderWidth: 0,
              fillColor: '#FFA500',
              label: {
                borderColor: '#FFA500',
                borderWidth: 2,
                style: {
                  color: '#000000',
                  background: '#FFA500',
                  fontWeight: 'bold',
                  fontSize: '13px',
                },
                text: `$${formatNumber(latestPrice, 2)}`,
                offsetY: 0,
                offsetX: -14,
                textAnchor: 'start',
              }
            }
          ]
        },
        grid: {
          borderColor: gridColor,
          position: 'back',
        },
        series: [
          {
            name: 'Bitcoin Price (USD)',
            data: chartData,
          },
        ],
        xaxis: {
          type: 'datetime',
          labels: {
            style: {
              colors: labelColor,
            }
          }
        },
        yaxis: [
          {
            opposite: true,
            tooltip: {
              enabled: false,
            },
            title: {
              text: 'BTC~USD Price',
              style: {
                color: labelColor,
              },
            },
            labels: {
              formatter: function (value) {
                return `$${formatLargeNumber(value, 0, false)}`;
              },
              style: {
                colors: labelColor,
              },
            },
          },
        ],
        colors: ['#ffa500'],
        stroke: {
          width: 2,
        },
        tooltip: {
          theme: 'dark',
          style: {
            backgroundColor: '#000000',
            color: '#ffffff',
          },
          y: {
            formatter: function (value) {
              return `$${formatNumber(value, 0)}`;
            },
          },
        },
      };
      const chart = new ApexCharts(
        chartRef.current,
        options
      );
      chart.render();

      const handleResize = () => {
        chart.updateOptions({
          chart: {
            width: '100%',
            height: '100%',
          },
        });
      };

      window.addEventListener('resize', handleResize);

      return () => {
        chart.destroy();
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [chartData, latestPrice, formatNumber, gridColor, labelColor, formatLargeNumber]);

  if (!data) {
    console.error("Error: Data is null or undefined");
    return <CustomSpinner />;
  }

  return (
    <ChartTileFlex
      direction="column"
      mb="12px"
      py="18px"
      flex={1}
      borderRadius={3}
      style={{ minHeight: '337px', maxHeight: '100%' }}
    >
      <DataTableHeader title={title} subtitle={subtitle} icon={icon} />
      <Select
        value={timeframe}
        onChange={handleTimeframeChange}
        variant="filled"
        size="xs"
        alignSelf="flex-start"
        width="100px"
        mb={0}
      >
        <option value="3y">3 Years</option>
        <option value="2y">2 Years</option>
        <option value="1y">1 Year</option>
        <option value="6m">6 Months</option>
        <option value="3m">3 Months</option>
        <option value="7d">7 Day</option>
        <option value="2d">2 Day</option>
      </Select>
      <Box flexGrow={1} minHeight='337px' width="100%">
        {loading ? <CustomSpinner /> : <div ref={chartRef} className={`chart-container-${uniqueChartId}`} style={{ height: '100%' }} />}
      </Box>
      <DataTableFooter link={link} linkLabel={linkLabel} />
    </ChartTileFlex>
  );
}