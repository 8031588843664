// src/components/custom/CustomDashboard.jsx
import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Flex,
    Grid,
    Heading,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    useColorMode,
    Image,
    Input,
    FormControl,
    FormLabel,
    Divider,
    VStack,
    Text,
    Switch,
    SimpleGrid,
    useColorModeValue,
    Tooltip,
    IconButton
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faRocket,
    faChartLine,
    faCoins,
    faGear,
    faGears,
    faScissors,
    faRotateLeft,
    faGripLines,
    faSliders,
    faTable,
    faChartArea,
    faPaintBrush,
    faBitcoinSign,
    faLock,
    faPieChart,
    faGem,
    faPercent,
    faCalendarAlt,
    faFastForward,
    faClockRotateLeft,
    faDatabase,
    faBasketShopping,
    faGlobeAsia
} from '@fortawesome/free-solid-svg-icons';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { SingleDataValue } from '../data-display/SingleDataValue';
import { DataBanner } from '../features/data-banner/DataBanner';
import { useRecoilValue } from 'recoil';
import { selectedCurrencyState } from '../../recoilState';
import { createCurrencyConverter } from '../../utils/currencyConversion.utils';
import { formatNumber, formatLargeNumber } from '../../utils';
import { daysSince, daysLeft } from '../../utils/date.utils';
import { ThemedFlex } from '../../theme/ThemedFlex';
import { HashRatePriceTile } from '../data-display/HashRatePriceTile';
import { RecentBlocks } from '../data-display/RecentBlocks';
import { DifficultyAdjustmentTile } from '../data-display/DifficultyAdjustmentTile';
import { ProgressTile } from '../data-display/ProgressTile';
import { RewardsToFeesTile } from '../data-display/RewardsToFeesTile';
import { BitcoinHalvingTable } from '../dashboard/tables/BitcoinHalvingTable';
import { BitcoinNetworkTable } from '../dashboard/tables/BitcoinNetworkTable';
import { MarketDataTable } from '../dashboard/tables/MarketDataTable';
import { NetworkSecurityTable } from '../dashboard/tables/NetworkSecurityTable';
import { ChangePercentagesTable } from '../dashboard/tables/ChangePercentagesTable';
import { BitcoinSupplyTable } from '../dashboard/tables/BitcoinSupplyTable';
import { FutureBitcoinHalvingsTable } from '../dashboard/tables/FutureBitcoinHalvingsTable';
import { MiningInfoTable } from '../dashboard/tables/MiningInfoTable';
import { VelocityTable } from '../dashboard/tables/VelocityTable';
import { MempoolTable } from '../dashboard/tables/MempoolTable';
import { DifficultyAdjustmentTable } from '../dashboard/tables/DifficultyAdjustmentTable';
import { ApiProvidersTable } from '../dashboard/tables/ApiProvidersTable';
import { OneBtcCanBuyTable } from '../dashboard/tables/OneBtcCanBuyTable';
import { OwnershipEconomicsTable } from '../dashboard/tables/OwnershipEconomicsTable';

// Default metrics
const DEFAULT_METRICS = [
    'marketCap',
    'btcVol24H',
    'silverToBtc',
    'goldToBtc',
    'bigMacToBtc',
    'avgTxValue',
    'mempoolTotalSize',
].slice(0, 21);

const DEFAULT_TABLES = [
    'halvingTable',
    'networkTable',
    'securityTable',
    'marketTable',
    'supplyTable',
    'miningTable',
    'percentagesTable',
    'futureHalvingTable',
    'velocityTable',
    'mempoolTable',
    'difficultyTable',
    'apiProvidersTable',
    'oneBtcCanBuyTable',
    'ownershipEconomicsTable'
];

// Default section order
const DEFAULT_SECTION_ORDER = ['charts', 'dataTiles', 'progressTiles', 'recentBlocks', 'dataTables'];

// Sortable item components
const SortableItem = ({ id, section, visibility, onToggleVisibility }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id });

    return (
        <Box
            ref={setNodeRef}
            style={{
                transform: CSS.Transform.toString(transform),
                transition,
                zIndex: isDragging ? 1 : 0,
            }}
            bg={isDragging ? "transparent" : "transparent"}
            borderWidth="2px"
            borderColor="gray.500"
            borderRadius="md"
            p={3}
            mb={2}
            _hover={{ borderColor: 'orange.500' }}
            boxShadow={isDragging ? "lg" : "none"}
        >
            <Flex align="center" justify="space-between">
                <Flex align="center">
                    <Box
                        display="inline-flex"
                        mr={6}
                        cursor="grab"
                        _active={{ cursor: "grabbing" }}
                        {...attributes}
                        {...listeners}
                    >
                        <FontAwesomeIcon icon={faGripLines} />
                    </Box>
                    <Box>
                        <Flex align="center">
                            <Text mr={2}>{section.icon}</Text>
                            <Text fontWeight="medium">{section.title}</Text>
                        </Flex>
                        <Text fontSize="sm" color="gray">
                            {section.description}
                        </Text>
                    </Box>
                </Flex>
                <Switch
                    colorScheme="orange"
                    isChecked={visibility[id]}
                    onChange={() => onToggleVisibility(id)}
                // isDisabled={id === 'dataTiles'}
                />
            </Flex>
        </Box>
    );
};

const SortableTableItem = ({ id, table, isSelected, onToggleVisibility }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id });

    return (
        <Box
            ref={setNodeRef}
            style={{
                transform: CSS.Transform.toString(transform),
                transition,
                zIndex: isDragging ? 1 : 0,
            }}
            bg={isDragging ? "transparent" : "transparent"}
            borderWidth="2px"
            borderColor="gray.500"
            borderRadius="md"
            p={3}
            mb={2}
            _hover={{ borderColor: 'orange.500' }}
            boxShadow={isDragging ? "lg" : "none"}
        >
            <Flex align="center" justify="space-between">
                <Flex align="center">
                    <Box
                        display="inline-flex"
                        mr={6}
                        cursor="grab"
                        _active={{ cursor: "grabbing" }}
                        {...attributes}
                        {...listeners}
                    >
                        <FontAwesomeIcon icon={faGripLines} />
                    </Box>
                    <Box>
                        <Flex align="center">
                            <FontAwesomeIcon icon={table.icon} style={{ marginRight: '8px' }} />
                            <Text fontWeight="medium">{table.label}</Text>
                        </Flex>
                        <Text fontSize="sm" color="gray">
                            {table.description}
                        </Text>
                    </Box>
                </Flex>
                <Switch
                    colorScheme="orange"
                    isChecked={isSelected}
                    onChange={() => onToggleVisibility(id)}
                />
            </Flex>
        </Box>
    );
};

// Custom DataTile component with hover effects
const DataTile = ({ label, value, onClick, animate }) => {
    const hoverBg = useColorModeValue('gray.50', 'whiteAlpha.100');

    return (
        <ThemedFlex
            direction="column"
            mb="0px"
            py={7}
            px={5}
            flex={1}
            borderRadius={3}
            onClick={onClick}
            cursor="pointer"
            transition="all 0.2s"
            position="relative"
            className={`data-tile ${animate ? 'gray-border gray-border-custom' : ''}`}
            _hover={{
                transform: 'scale(1.02)',
                bg: hoverBg,
                boxShadow: 'sm'
            }}
            sx={{
                '&.data-tile': {
                    isolation: 'isolate',
                },
                '&.orange-border:before': {
                    bottom: '0 !important',
                    borderRadius: '3px',
                }
            }}
        >
            <SingleDataValue
                label={label}
                value={value}
            />
        </ThemedFlex>
    );
};

// Section container
const SectionContainer = ({ children, isVisible, ...props }) => {
    return (
        <Box
            opacity={isVisible ? 1 : 0}
            height={isVisible ? 'auto' : 0}
            overflow="hidden"
            transition="all 0.3s ease-in-out"
            {...props}
        >
            {children}
        </Box>
    );
};

const tableOptions = [
    {
        id: 'halvingTable',
        label: 'Bitcoin Halving',
        description: 'Halving schedule and countdown',
        icon: faScissors
    },
    {
        id: 'networkTable',
        label: 'Bitcoin Network',
        description: 'Network statistics and health',
        icon: faBitcoinSign
    },
    {
        id: 'securityTable',
        label: 'Network Security',
        description: 'Security metrics and data',
        icon: faLock
    },
    {
        id: 'marketTable',
        label: 'Market Data',
        description: 'Price and market statistics',
        icon: faChartLine
    },
    {
        id: 'supplyTable',
        label: 'Bitcoin Supply',
        description: 'Supply and distribution data',
        icon: faPieChart
    },
    {
        id: 'miningTable',
        label: 'Mining Info',
        description: 'Mining statistics and data',
        icon: faGem
    },
    {
        id: 'percentagesTable',
        label: 'Change Percentages',
        description: 'Price change statistics',
        icon: faPercent
    },
    {
        id: 'futureHalvingTable',
        label: 'Future Halvings',
        description: 'Future halving projections',
        icon: faCalendarAlt
    },
    {
        id: 'velocityTable',
        label: 'Velocity Data',
        description: 'Network velocity metrics',
        icon: faFastForward
    },
    {
        id: 'mempoolTable',
        label: 'Mempool Data',
        description: 'Mempool statistics',
        icon: faClockRotateLeft
    },
    {
        id: 'difficultyTable',
        label: 'Difficulty Adjustment',
        description: 'Mining difficulty metrics',
        icon: faGears
    },
    {
        id: 'apiProvidersTable',
        label: 'API Providers',
        description: 'Price API providers data',
        icon: faDatabase
    },
    {
        id: 'oneBtcCanBuyTable',
        label: 'One BTC Can Buy',
        description: 'What can you buy with 1 BTC',
        icon: faBasketShopping
    },
    {
        id: 'ownershipEconomicsTable',
        label: 'Ownership Economics',
        description: 'Bitcoin distribution metrics',
        icon: faGlobeAsia
    },
];



const CustomDashboard = ({
    data,
    bitcoinPrice,
    lastMempoolBlockData,
    fees,
    mempoolInfo,
    da,
    animate
}) => {

    const handleOpenLexicon = (term) => {
        // Add lexicon functionality!
        console.log(`Opening lexicon for term: ${term}`);
    };

    const { colorMode } = useColorMode();
    const modalBgColor = colorMode === 'dark' ? '#121212' : 'white';
    const modalTextColor = colorMode === 'dark' ? 'white' : '#121212';
    const modalBorderColor = colorMode === 'dark' ? 'white' : '#121212';

    const [isMaxMetricsModalOpen, setIsMaxMetricsModalOpen] = useState(false);

    // Basic state
    const [selectedMetrics, setSelectedMetrics] = useState(DEFAULT_METRICS);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isSwapModalOpen, setIsSwapModalOpen] = useState(false);
    const [currentlySwapping, setCurrentlySwapping] = useState(null);
    const selectedCurrency = useRecoilValue(selectedCurrencyState);
    const [conversionRates, setConversionRates] = useState(null);

    // Logo and message state
    const [logoLinkUrl, setLogoLinkUrl] = useState(localStorage.getItem('dashboardLogoLinkUrl') || '');
    const [logoUrl, setLogoUrl] = useState(localStorage.getItem('dashboardLogoUrl') || '');
    const [message, setMessage] = useState(localStorage.getItem('dashboardMessage') || 'PROTOTYPE - Bugs may exist!');


    const [selectedTables, setSelectedTables] = useState(() => {
        const savedTables = localStorage.getItem('customDashboardTables');
        return savedTables ? JSON.parse(savedTables) : DEFAULT_TABLES;
    });

    const [tableOrder, setTableOrder] = useState(() => {
        const savedOrder = localStorage.getItem('dashboardTableOrder');
        return savedOrder ? JSON.parse(savedOrder) : DEFAULT_TABLES;
    });

    // Table toggle handler
    const handleTableToggle = (tableId) => {
        setSelectedTables(prev => {
            if (prev.includes(tableId)) {
                return prev.filter(id => id !== tableId);
            }
            return [...prev, tableId];
        });
    };

    // Section visibility and order state
    const [sectionOrder, setSectionOrder] = useState(() => {
        const savedOrder = localStorage.getItem('dashboardSectionOrder');
        return savedOrder ? JSON.parse(savedOrder) : DEFAULT_SECTION_ORDER;
    });

    const [sectionVisibility, setSectionVisibility] = useState(() => {
        const savedVisibility = localStorage.getItem('dashboardSectionVisibility');
        return savedVisibility ? JSON.parse(savedVisibility) : {
            charts: true,
            dataTiles: true,
            progressTiles: true,
            recentBlocks: true,
            dataTables: true
        };
    });

    // Charts visibility state
    const [showPriceChart, setShowPriceChart] = useState(
        localStorage.getItem('showPriceChart') !== 'false'
    );
    const [showRewardsChart, setShowRewardsChart] = useState(
        localStorage.getItem('showRewardsChart') !== 'false'
    );

    // DND Kit sensors
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    // Section configuration
    const sectionConfig = {
        charts: {
            id: 'charts',
            title: 'Charts & Visualizations',
            icon: '📊',
            description: 'Price, hashrate, and rewards visualizations'
        },
        dataTiles: {
            id: 'dataTiles',
            title: 'Key Metrics',
            icon: '📱',
            description: 'Important Bitcoin network statistics'
        },
        progressTiles: {
            id: 'progressTiles',
            title: 'Network Progress',
            icon: '📈',
            description: 'Difficulty adjustment and halving progress'
        },
        recentBlocks: {
            id: 'recentBlocks',
            title: 'Recent Blocks',
            icon: '⛓️',
            description: 'Latest mined blocks and their details'
        },
        dataTables: {
            id: 'dataTables',
            title: 'Data Tables',
            icon: '📑',
            description: 'Detailed Bitcoin statistics tables'
        }
    };

    // Effects

    useEffect(() => {
        localStorage.setItem('dashboardTableOrder', JSON.stringify(tableOrder));
    }, [tableOrder]);

    useEffect(() => {
        if (selectedTables.length > 0) {
            localStorage.setItem('customDashboardTables', JSON.stringify(selectedTables));
        }
    }, [selectedTables]);

    useEffect(() => {
        localStorage.setItem('dashboardLogoLinkUrl', logoLinkUrl);
    }, [logoLinkUrl]);

    useEffect(() => {
        localStorage.setItem('dashboardSectionOrder', JSON.stringify(sectionOrder));
        localStorage.setItem('dashboardSectionVisibility', JSON.stringify(sectionVisibility));
    }, [sectionOrder, sectionVisibility]);

    useEffect(() => {
        localStorage.setItem('showPriceChart', showPriceChart);
    }, [showPriceChart]);

    useEffect(() => {
        localStorage.setItem('showRewardsChart', showRewardsChart);
    }, [showRewardsChart]);

    useEffect(() => {
        localStorage.setItem('dashboardLogoUrl', logoUrl);
        localStorage.setItem('dashboardMessage', message);
    }, [logoUrl, message]);

    useEffect(() => {
        if (data) {
            const rates = createCurrencyConverter(data);
            setConversionRates(rates);
        }
    }, [data]);

    useEffect(() => {
        const savedMetrics = localStorage.getItem('customDashboardMetrics');
        if (savedMetrics) {
            setSelectedMetrics(JSON.parse(savedMetrics));
        }
    }, []);

    useEffect(() => {
        if (selectedMetrics.length > 0) {
            localStorage.setItem('customDashboardMetrics', JSON.stringify(selectedMetrics));
        }
    }, [selectedMetrics]);

    // Handlers

    const normalizeUrl = (url) => {
        if (!url) return '';

        // Remove any leading/trailing whitespace
        url = url.trim();

        // If URL doesn't have a protocol, add https://
        if (!url.match(/^https?:\/\//i)) {
            return 'https://' + url;
        }

        return url;
    };

    const handleClearLogoLink = () => {
        setLogoLinkUrl('');
        localStorage.removeItem('dashboardLogoLinkUrl');
    };

    const handleClearLogo = () => {
        setLogoUrl('');
        setLogoLinkUrl('');
        localStorage.removeItem('dashboardLogoUrl');
        localStorage.removeItem('dashboardLogoLinkUrl');
    };

    const handleResetSections = () => {
        setSectionOrder(DEFAULT_SECTION_ORDER);
        setSectionVisibility({
            charts: true,
            dataTiles: true,
            progressTiles: true,
            recentBlocks: true,
            dataTables: true
        });
        setShowPriceChart(true);
        setShowRewardsChart(true);
        setSelectedTables(DEFAULT_TABLES);
        setTableOrder(DEFAULT_TABLES);
    };

    const handleResetMessage = () => {
        setMessage('PROTOTYPE - Bugs may exist!');
        localStorage.setItem('dashboardMessage', 'PROTOTYPE - Bugs may exist!');
    };

    const handleMetricToggle = (metricId) => {
        setSelectedMetrics(prev => {
            if (prev.includes(metricId)) {
                return prev.filter(id => id !== metricId);
            }
            if (prev.length >= 21) {
                setIsMaxMetricsModalOpen(true);
                return prev;
            }
            return [...prev, metricId];
        });
    };

    const handleMetricClick = (metricId) => {
        setCurrentlySwapping(metricId);
        setIsSwapModalOpen(true);
    };

    const handleSwap = (newMetricId) => {
        setSelectedMetrics(prev => {
            const newMetrics = [...prev];
            const currentIndex = newMetrics.findIndex(id => id === currentlySwapping);
            const newIndex = newMetrics.findIndex(id => id === newMetricId);

            if (currentIndex !== -1) {
                if (newIndex !== -1) {
                    [newMetrics[currentIndex], newMetrics[newIndex]] = [newMetrics[newIndex], newMetrics[currentIndex]];
                } else {
                    newMetrics[currentIndex] = newMetricId;
                }
            }
            return newMetrics;
        });
        setIsSwapModalOpen(false);
    };

    // Convert price to selected currency
    const convertBitcoinPrice = (price) => {
        if (selectedCurrency && conversionRates && conversionRates[selectedCurrency]) {
            return price * conversionRates[selectedCurrency];
        }
        return price;
    };

    // Value map for metrics
    const valueMap = {
        blockHeight: lastMempoolBlockData ? `${formatNumber(lastMempoolBlockData.height, 0)}` : 'Loading...',
        hashRate7d: data?.ts04?.hashrate?.currentHashrate != null ? `~${formatLargeNumber(data.ts04.hashrate.currentHashrate, 0, true)}H/s` : 'Loading...',
        unconfirmedTxs: mempoolInfo ? `~${formatLargeNumber((mempoolInfo.size), 0, false)} TX` : 'Loading...',
        highFeePerVbyte: fees ? `${formatNumber((fees.fastestFee), 0)} ṩ/vB` : 'Loading...',
        miningRevenue24h: data?.s12?.metrics?.data?.mining_stats?.mining_revenue_usd != null ? `~${selectedCurrency === 'usd' ? '$' : ''}${formatLargeNumber(convertBitcoinPrice(data.s12.metrics.data.mining_stats.mining_revenue_usd), 2, false)}` : 'Loading...',
        satsPerDollar: bitcoinPrice ? `${formatNumber((1 / convertBitcoinPrice(bitcoinPrice)) * 100000000, 0)}` : 'Loading...',
        blocksToHalving: lastMempoolBlockData ? `${formatNumber(1050000 - lastMempoolBlockData.height)}` : 'Loading...',
        daysToHalving: data?.s03?.halvening?.bitcoin_data?.halvening_time != null ? `~${formatNumber(daysLeft(data.s03.halvening.bitcoin_data.halvening_time), 0)} Days` : 'Loading...',
        blocksToDiffAdj: da != null ? `${da.remainingBlocks}` : 'Loading...',
        marketCap: data?.s04?.bitcoin?.data?.marketCapUsd != null ? `${selectedCurrency === 'usd' ? '$' : ''}${formatLargeNumber(convertBitcoinPrice(data.s04.bitcoin.data.marketCapUsd), 2, false)}` : 'Loading...',
        btcVol24H: data?.s04?.bitcoin?.data?.volumeUsd24Hr != null ? `${selectedCurrency === 'usd' ? '$' : ''}${formatLargeNumber(convertBitcoinPrice(data.s04.bitcoin.data.volumeUsd24Hr), 2, false)}` : 'Loading...',
        low24H: data?.s06?.bitcoin?.market_data?.low_24h?.[selectedCurrency] != null ? `${selectedCurrency === 'usd' ? '$' : ''}${formatNumber(data.s06.bitcoin.market_data.low_24h[selectedCurrency], 0)}` : 'Loading...',
        high24H: data?.s06?.bitcoin?.market_data?.high_24h?.[selectedCurrency] != null ? `${selectedCurrency === 'usd' ? '$' : ''}${formatNumber(data.s06.bitcoin.market_data.high_24h[selectedCurrency], 0)}` : 'Loading...',
        changePercent24H: data?.s06?.bitcoin?.market_data?.price_change_percentage_24h_in_currency?.[selectedCurrency] != null
            ? `${formatNumber(data.s06.bitcoin.market_data.price_change_percentage_24h_in_currency[selectedCurrency], 2)}%`
            : 'Loading...',
        change24H: data?.s06?.bitcoin?.market_data?.high_24h?.[selectedCurrency] && data?.s06?.bitcoin?.market_data?.low_24h?.[selectedCurrency]
            ? `${selectedCurrency === 'usd' ? '$' : ''}${formatNumber(
                data.s06.bitcoin.market_data.high_24h[selectedCurrency] -
                data.s06.bitcoin.market_data.low_24h[selectedCurrency],
                0
            )}`
            : 'Loading...',
        change200d: data?.s06?.bitcoin?.market_data?.price_change_percentage_200d_in_currency?.[selectedCurrency] != null
            ? `${formatNumber(data.s06.bitcoin.market_data.price_change_percentage_200d_in_currency[selectedCurrency], 2)}%`
            : 'Loading...',
        change1y: data?.s06?.bitcoin?.market_data?.price_change_percentage_1y_in_currency?.[selectedCurrency] != null
            ? `${formatNumber(data.s06.bitcoin.market_data.price_change_percentage_1y_in_currency[selectedCurrency], 2)}%`
            : 'Loading...',
        changeAth: data?.s06?.bitcoin?.market_data?.ath_change_percentage?.[selectedCurrency] != null
            ? `${formatNumber(data.s06.bitcoin.market_data.ath_change_percentage[selectedCurrency], 2)}%`
            : "Loading...",
        mempoolTotalSize: mempoolInfo ? `${formatLargeNumber(mempoolInfo.usage, 1, true)}B` : 'Loading...',
        flowInExchange: data?.s12?.metrics?.data?.exchange_flows?.flow_in_exchange_native_units != null ? `${formatNumber(data.s12.metrics.data.exchange_flows.flow_in_exchange_native_units, 0)}` : 'Loading...',
        flowOutExchange: data?.s12?.metrics?.data?.exchange_flows?.flow_out_exchange_native_units != null ? `${formatNumber(data.s12.metrics.data.exchange_flows.flow_out_exchange_native_units, 0)}` : 'Loading...',
        assetAge: `${formatNumber(daysSince("2009-01-03"))}`,
        lightningChannels: data?.s11?.lightning?.latest?.channel_count != null ? `${formatNumber(data.s11.lightning.latest.channel_count, 0)}` : 'Loading...',
        lightningCapacity: data?.s11?.lightning?.latest?.total_capacity != null ? `${formatNumber(data.s11.lightning.latest.total_capacity / 100000000, 0)} BTC` : 'Loading...',
        sixOneFive: bitcoinPrice ? `${selectedCurrency === 'usd' ? '$' : ''}${formatNumber(6.15 * convertBitcoinPrice(bitcoinPrice), 0)}` : 'Loading...',
        bigMacToBtc: bitcoinPrice ? `${formatNumber((bitcoinPrice / 5.15), 0)} 🍔` : "Loading...",
        silverToBtc: data?.s06?.bitcoin?.market_data?.current_price?.xag != null ? `${formatNumber(data.s06.bitcoin.market_data.current_price.xag, 0)} oz.` : 'Loading...',
        goldToBtc: data?.s06?.bitcoin?.market_data?.current_price?.xau != null ? `${formatNumber(data.s06.bitcoin.market_data.current_price.xau, 2)} oz.` : 'Loading...',
        avgUtxoAge: data?.s12?.metrics?.data?.on_chain_data?.average_utxo_age != null ? `${formatNumber(data.s12.metrics.data.on_chain_data.average_utxo_age / 365, 2)} Years` : 'Loading...',
        avgBlockTime: data?.s12?.metrics?.data?.on_chain_data?.average_block_interval != null ? `${formatNumber(data.s12.metrics.data.on_chain_data.average_block_interval / 60, 1)} Mins` : 'Loading...',
        predictedNextDiff: data?.s12?.metrics?.data?.mining_stats?.average_difficulty != null ? `${formatLargeNumber(data.s12.metrics.data.mining_stats.average_difficulty, 2, false)}` : 'Loading...',
        newBlocks24h: data?.s12?.metrics?.data?.blockchain_stats_24_hours?.count_of_blocks_added != null ? `${formatNumber(data.s12.metrics.data.blockchain_stats_24_hours.count_of_blocks_added)}` : 'Loading...',
        addressesOver1Btc: data?.s12?.metrics?.data?.on_chain_data?.addresses_balance_greater_1_native_units_count != null ? `${formatNumber(data.s12.metrics.data.on_chain_data.addresses_balance_greater_1_native_units_count, 0)}` : 'Loading...',
        transactions24h: data?.s12?.metrics?.data?.blockchain_stats_24_hours?.count_of_payments != null ? `${formatNumber(data.s12.metrics.data.blockchain_stats_24_hours.count_of_payments, 0)}` : 'Loading...',
        avgTxValue: data?.s12?.metrics?.data?.on_chain_data?.average_transfer_value_native_units != null ? `${formatNumber((data.s12.metrics.data.on_chain_data.average_transfer_value_native_units), 3)} BTC` : 'Loading...',
        txAllTime: data?.s08?.blockchain?.Data?.transaction_count_all_time != null ? `${formatLargeNumber(data.s08.blockchain.Data.transaction_count_all_time, 2, false)}` : 'Loading...',
        coinDaysDestroyed24h: data?.s03?.bitcoin_stats?.bitcoin_data?.cdd_24h != null ? `${formatLargeNumber(data.s03.bitcoin_stats.bitcoin_data.cdd_24h, 1, true)}` : 'Loading...',
        nextDifficultyEstimate: data?.s12?.metrics?.data?.mining_stats?.average_difficulty != null ? `${formatLargeNumber(data.s12.metrics.data.mining_stats.average_difficulty, 2, true)}` : 'Loading...',
        circulatingSupply: data?.s12?.metrics?.data?.supply?.circulating != null ? `~${formatLargeNumber(data.s12.metrics.data.supply.circulating, 2, false)}` : 'Loading...',
        percentageIssued: data?.s12?.metrics?.data?.supply?.circulating != null ? `${formatNumber((data.s12.metrics.data.supply.circulating / 21000000) * 100, 2)}%` : 'Loading...',
        realizedPrice: data?.s12?.metrics?.data?.marketcap?.realized_marketcap_usd != null && data?.s12?.metrics?.data?.supply?.circulating != null && bitcoinPrice != null
            ? `${selectedCurrency === 'usd' ? '$' : ''}${formatNumber(convertBitcoinPrice(data.s12.metrics.data.marketcap.realized_marketcap_usd / data.s12.metrics.data.supply.circulating), 0)}`
            : 'Loading...',
        realizedCap: data?.s12?.metrics?.data?.marketcap?.realized_marketcap_usd != null
            ? `${selectedCurrency === 'usd' ? '$' : ''}${formatLargeNumber(convertBitcoinPrice(data.s12.metrics.data.marketcap.realized_marketcap_usd), 2, false)}`
            : 'Loading...',
        activeBtc24h: data?.s12?.metrics?.data?.supply_activity?.supply_active_1d != null ? `${formatNumber(data.s12.metrics.data.supply_activity.supply_active_1d, 0)}` : 'Loading...',
        activeBtc30d: data?.s12?.metrics?.data?.supply_activity?.supply_active_30d != null ? `${formatNumber(data.s12.metrics.data.supply_activity.supply_active_30d, 0)}` : 'Loading...',
        adjustedNVTRatio: data?.s12?.metrics?.data?.blockchain_stats_24_hours?.adjusted_nvt != null ? formatNumber(data.s12.metrics.data.blockchain_stats_24_hours.adjusted_nvt, 2) : 'Loading...',
        lightningNodes: data?.s11?.lightning?.latest?.node_count != null ? `${formatNumber(data.s11.lightning.latest.node_count, 0)}` : 'Loading...',
        lightningAvgChannelSize: data?.s11?.lightning?.latest?.total_capacity != null && data?.s11?.lightning?.latest?.channel_count != null
            ? `${formatNumber((data.s11.lightning.latest.total_capacity / data.s11.lightning.latest.channel_count) / 100000000, 6)}`
            : 'Loading...',
        utxosInProfit: data?.s12?.metrics?.data?.on_chain_data?.utxo_in_profit_count != null ? `${formatLargeNumber(data.s12.metrics.data.on_chain_data.utxo_in_profit_count, 1, true)}` : 'Loading...',
        utxosInLoss: data?.s12?.metrics?.data?.on_chain_data?.utxo_in_loss_count != null ? `${formatLargeNumber(data.s12.metrics.data.on_chain_data.utxo_in_loss_count, 1, true)}` : 'Loading...',
        medianTxValue24h: data?.s12?.metrics?.data?.blockchain_stats_24_hours?.median_tx_value != null && bitcoinPrice != null
            ? `${selectedCurrency === 'usd' ? '$' : ''}${formatNumber(convertBitcoinPrice(data.s12.metrics.data.blockchain_stats_24_hours.median_tx_value), 2)}`
            : 'Loading...',
        largestTxValue24h: data?.s12?.metrics?.data?.market_data?.volume_last_24_hours != null && bitcoinPrice != null
            ? `${selectedCurrency === 'usd' ? '$' : ''}${formatLargeNumber(convertBitcoinPrice(data.s12.metrics.data.market_data.volume_last_24_hours), 2, false)}`
            : 'Loading...',
    };

    // Available metric options
    const metricOptions = [
        { id: 'blockHeight', label: 'Block Height' },
        { id: 'hashRate7d', label: 'Hash Rate 7d' },
        { id: 'unconfirmedTxs', label: 'Unconfirmed' },
        { id: 'highFeePerVbyte', label: 'Fastest Fee' },
        { id: 'miningRevenue24h', label: 'Mining Rev 24h' },
        { id: 'satsPerDollar', label: 'Sats~Fiat' },
        { id: 'blocksToHalving', label: 'Blks to Halving' },
        { id: 'daysToHalving', label: 'Next Halving' },
        { id: 'blocksToDiffAdj', label: 'Blks to Diff Adj' },
        { id: 'marketCap', label: 'Market Cap' },
        { id: 'btcVol24H', label: 'Vol 24H' },
        { id: 'low24H', label: 'Low 24H' },
        { id: 'high24H', label: 'High 24H' },
        { id: 'changePercent24H', label: '% Chng 24H' },
        { id: 'change24H', label: '$ Chng 24H' },
        { id: 'change200d', label: '% Chng 200d' },
        { id: 'change1y', label: '% Chng 1Y' },
        { id: 'changeAth', label: '% from ATH' },
        { id: 'mempoolTotalSize', label: 'Mempool Size' },
        { id: 'flowInExchange', label: 'BTC Exch In' },
        { id: 'flowOutExchange', label: 'BTC Exch Out' },
        { id: 'assetAge', label: 'BTC Age (D)' },
        { id: 'avgUtxoAge', label: 'Avg UTXO Age' },
        { id: 'avgBlockTime', label: 'Avg Block Time' },
        { id: 'predictedNextDiff', label: 'Next Diff' },
        { id: 'newBlocks24h', label: 'New Blocks 24h' },
        { id: 'addressesOver1Btc', label: '1+ BTC Bags' },
        { id: 'silverToBtc', label: 'Silver~BTC' },
        { id: 'goldToBtc', label: 'Gold~BTC' },
        { id: 'bigMacToBtc', label: 'Big Macs~BTC' },
        { id: 'lightningChannels', label: 'LN Channels' },
        { id: 'lightningCapacity', label: 'LN Capacity' },
        { id: 'transactions24h', label: '# of TX 24h' },
        { id: 'avgTxValue', label: 'Avg TX Value' },
        { id: 'txAllTime', label: 'Total TXs' },
        { id: 'sixOneFive', label: '6.15 BTC Value' },
        { id: 'nextDifficultyEstimate', label: 'Next Diff Est' },
        { id: 'circulatingSupply', label: 'Circulating Supply' },
        { id: 'percentageIssued', label: '% Issued' },
        { id: 'realizedPrice', label: 'Realized Price' },
        { id: 'realizedCap', label: 'Realized Cap' },
        { id: 'activeBtc24h', label: 'Active BTC 24h' },
        { id: 'activeBtc30d', label: 'Active BTC 30d' },
        { id: 'coinDaysDestroyed24h', label: 'CDD 24h' },
        { id: 'adjustedNVTRatio', label: 'Adj NVT Ratio' },
        { id: 'lightningNodes', label: 'LN Nodes' },
        { id: 'lightningAvgChannelSize', label: 'LN Avg Channel' },
        { id: 'utxosInProfit', label: 'UTXOs in Profit' },
        { id: 'utxosInLoss', label: 'UTXOs in Loss' },
        { id: 'medianTxValue24h', label: 'Median TX 24h' },
        { id: 'largestTxValue24h', label: 'Largest TX 24h' },
    ];


    // Group metrics into rows for display
    const groupedMetrics = useMemo(() => {
        const maxItemsPerRow = 7;
        const groups = [];

        for (let i = 0; i < selectedMetrics.length; i += maxItemsPerRow) {
            const group = selectedMetrics.slice(i, i + maxItemsPerRow);
            groups.push(group);
        }

        return groups;
    }, [selectedMetrics]);

    const renderSectionHeader = (text, icon) => (
        <Flex align="center" gap={2}>
            <FontAwesomeIcon icon={icon} />
            <Text fontWeight="bold" fontSize="lg">{text}</Text>
        </Flex>
    );


    // renderSectionList
    const renderSectionList = () => (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={(event) => {
                const { active, over } = event;
                if (active.id !== over.id) {
                    const oldIndex = sectionOrder.indexOf(active.id);
                    const newIndex = sectionOrder.indexOf(over.id);
                    setSectionOrder(arrayMove(sectionOrder, oldIndex, newIndex));
                }
            }}
        >
            <SortableContext items={sectionOrder} strategy={verticalListSortingStrategy}>
                <VStack
                    spacing={3}
                    align="stretch"
                    mb={6}
                >
                    {sectionOrder.map((sectionId) => (
                        <SortableItem
                            key={sectionId}
                            id={sectionId}
                            section={sectionConfig[sectionId]}
                            visibility={sectionVisibility}
                            onToggleVisibility={(id) => {
                                setSectionVisibility(prev => ({
                                    ...prev,
                                    [id]: !prev[id]
                                }));
                            }}

                        />
                    ))}
                </VStack>
            </SortableContext>
        </DndContext>
    );

    // Render section based on type
    const renderSection = (sectionId) => {
        if (!sectionVisibility[sectionId]) return null;

        switch (sectionId) {
            case 'charts':
                return (
                    <SectionContainer isVisible={sectionVisibility.charts} mb={3}>
                        {data && (showPriceChart || showRewardsChart) && (
                            <SimpleGrid
                                columns={{
                                    base: 1,
                                    md: (showPriceChart && showRewardsChart) ? 2 : 1
                                }}
                                spacing={3}
                                width="100%"
                            >
                                {showPriceChart && (
                                    <Box
                                        position="relative"
                                        height="500px"
                                        sx={{
                                            '& > div': {
                                                height: '500px !important',
                                                width: '100% !important',
                                            },
                                            '.gray-border-custom': {
                                                height: '100%'
                                            },
                                            '.gray-border-custom.gray-border:before': {
                                                height: '100% !important',
                                                bottom: '0 !important',
                                                borderRadius: '3px !important'
                                            },
                                            '.apexcharts-canvas': {
                                                width: '100% !important',
                                                height: '100% !important'
                                            },
                                            '& > div > div': {
                                                height: '100% !important',
                                                maxHeight: '500px !important'
                                            }
                                        }}
                                    >
                                        <HashRatePriceTile
                                            title="Bitcoin Price & Hashrate"
                                            subtitle="Historical BTC/USD Price"
                                            icon={<FontAwesomeIcon icon={faChartLine} />}
                                            data={data}
                                            uniqueChartId="btc-price-chart"
                                            formatNumber={formatNumber}
                                            formatLargeNumber={formatLargeNumber}
                                            animate={animate}
                                        />
                                    </Box>
                                )}

                                {showRewardsChart && (
                                    <Box
                                        position="relative"
                                        height="500px"
                                        sx={{
                                            '& > div': {
                                                height: '500px !important',
                                                width: '100% !important',
                                            },
                                            '.apexcharts-canvas': {
                                                width: '100% !important',
                                                height: '100% !important'
                                            },
                                            '& > div > div': {
                                                height: '100% !important',
                                                maxHeight: '500px !important'
                                            }
                                        }}
                                    >
                                        <RewardsToFeesTile
                                            title="Block Rewards & Fees"
                                            subtitle="Distribution of block rewards"
                                            icon={<FontAwesomeIcon icon={faCoins} />}
                                            data={data}
                                            uniqueChartId="rewards-fees-chart"
                                            formatLargeNumber={formatLargeNumber}
                                            animate={animate}
                                        />
                                    </Box>
                                )}
                            </SimpleGrid>
                        )}
                    </SectionContainer>
                );

            case 'dataTiles':
                return (
                    <SectionContainer isVisible={true} mb={1}>
                        <Flex
                            flexDirection={{ base: 'column', md: 'row' }}
                            flexWrap="wrap"
                            columnGap={{ base: 0, md: 3 }}
                        >
                            {groupedMetrics.map((row, rowIndex) => (
                                <Flex
                                    key={rowIndex}
                                    flex={{ base: '1 0 100%', md: '1 1 100%' }}
                                    flexDirection="row"
                                    flexWrap="wrap"
                                    gap={3}
                                    mb={3}
                                >
                                    {row.map((metricId) => {
                                        const metric = metricOptions.find(m => m.id === metricId);
                                        const flexBasis = `${100 / row.length - 1}%`;
                                        return (
                                            <Flex
                                                key={metricId}
                                                flex={{ base: '1 0 48%', md: `1 1 ${flexBasis}` }}
                                            >
                                                <DataTile
                                                    label={metric?.label || metricId}
                                                    value={valueMap[metricId]}
                                                    onClick={() => handleMetricClick(metricId)}
                                                    animate={animate}
                                                />
                                            </Flex>
                                        );
                                    })}
                                </Flex>
                            ))}
                        </Flex>
                    </SectionContainer>
                );


            case 'progressTiles':
                return (
                    <SectionContainer isVisible={sectionVisibility.progressTiles} mb={0}>
                        <Grid
                            templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
                            gap={3}
                        >
                            {lastMempoolBlockData ? (
                                <DifficultyAdjustmentTile
                                    title="Next Difficulty Adjustment"
                                    subtitle="Network difficulty adjustment progress"
                                    icon={<FontAwesomeIcon icon={faGears} />}
                                    lastMempoolBlockData={lastMempoolBlockData}
                                    da={da}
                                    animate={animate}
                                />
                            ) : (
                                <Box p={4} textAlign="center">Loading...</Box>
                            )}

                            {lastMempoolBlockData && da ? (
                                <ProgressTile
                                    title="Bitcoin Halving Progress"
                                    subtitle="Countdown to next Bitcoin halving"
                                    icon={<FontAwesomeIcon icon={faScissors} />}
                                    lastMempoolBlockData={lastMempoolBlockData}
                                    da={da}
                                    animate={animate}
                                />
                            ) : (
                                <Box p={4} textAlign="center">Loading...</Box>
                            )}
                        </Grid>
                    </SectionContainer>
                );

            case 'recentBlocks':
                return (
                    <SectionContainer isVisible={sectionVisibility.recentBlocks} mb={0}>
                        {data && bitcoinPrice && (
                            <RecentBlocks
                                animate={animate}
                                bitcoinPrice={bitcoinPrice}
                                data={data}
                            />
                        )}
                    </SectionContainer>
                );

            case 'dataTables':
                return (
                    <SectionContainer isVisible={sectionVisibility.dataTables} mb={0}>
                        <Flex
                            flexDirection={{ base: 'column', md: 'row' }}
                            flexWrap="wrap"
                            columnGap={{ base: 0, md: 3 }}
                        >
                            {tableOrder.map(tableId => {
                                if (!selectedTables.includes(tableId)) return null;

                                const TableComponent = {
                                    halvingTable: () => (
                                        <BitcoinHalvingTable
                                            data={data}
                                            bitcoinPrice={bitcoinPrice}
                                            conversionRates={conversionRates}
                                            selectedCurrency={selectedCurrency}
                                            animate={animate}
                                            onOpenLexicon={handleOpenLexicon}
                                        />
                                    ),
                                    networkTable: () => (
                                        <BitcoinNetworkTable
                                            data={data}
                                            bitcoinPrice={bitcoinPrice}
                                            conversionRates={conversionRates}
                                            selectedCurrency={selectedCurrency}
                                            animate={animate}
                                            onOpenLexicon={handleOpenLexicon}
                                            mempoolInfo={mempoolInfo}
                                        />
                                    ),
                                    securityTable: () => (
                                        <NetworkSecurityTable
                                            data={data}
                                            bitcoinPrice={bitcoinPrice}
                                            conversionRates={conversionRates}
                                            selectedCurrency={selectedCurrency}
                                            animate={animate}
                                            onOpenLexicon={handleOpenLexicon}
                                            fees={fees}
                                        />
                                    ),
                                    marketTable: () => (
                                        <MarketDataTable
                                            data={data}
                                            bitcoinPrice={bitcoinPrice}
                                            conversionRates={conversionRates}
                                            selectedCurrency={selectedCurrency}
                                            animate={animate}
                                            onOpenLexicon={handleOpenLexicon}
                                        />
                                    ),
                                    supplyTable: () => (
                                        <BitcoinSupplyTable
                                            data={data}
                                            bitcoinPrice={bitcoinPrice}
                                            animate={animate}
                                            onOpenLexicon={handleOpenLexicon}
                                        />
                                    ),
                                    miningTable: () => (
                                        <MiningInfoTable
                                            data={data}
                                            bitcoinPrice={bitcoinPrice}
                                            conversionRates={conversionRates}
                                            selectedCurrency={selectedCurrency}
                                            animate={animate}
                                            onOpenLexicon={handleOpenLexicon}
                                            da={da}
                                            fees={fees}
                                        />
                                    ),
                                    percentagesTable: () => (
                                        <ChangePercentagesTable
                                            data={data}
                                            bitcoinPrice={bitcoinPrice}
                                            conversionRates={conversionRates}
                                            selectedCurrency={selectedCurrency}
                                            animate={animate}
                                            onOpenLexicon={handleOpenLexicon}
                                        />
                                    ),
                                    futureHalvingTable: () => (
                                        <FutureBitcoinHalvingsTable
                                            data={data}
                                            bitcoinPrice={bitcoinPrice}
                                            animate={animate}
                                            onOpenLexicon={handleOpenLexicon}
                                        />
                                    ),
                                    velocityTable: () => (
                                        <VelocityTable
                                            data={data}
                                            bitcoinPrice={bitcoinPrice}
                                            conversionRates={conversionRates}
                                            selectedCurrency={selectedCurrency}
                                            animate={animate}
                                            onOpenLexicon={handleOpenLexicon}
                                        />
                                    ),
                                    mempoolTable: () => (
                                        <MempoolTable
                                            data={data}
                                            bitcoinPrice={bitcoinPrice}
                                            conversionRates={conversionRates}
                                            selectedCurrency={selectedCurrency}
                                            animate={animate}
                                            onOpenLexicon={handleOpenLexicon}
                                            mempoolInfo={mempoolInfo}
                                            fees={fees}
                                        />
                                    ),
                                    difficultyTable: () => (
                                        <DifficultyAdjustmentTable
                                            data={data}
                                            da={da}
                                            animate={animate}
                                            onOpenLexicon={handleOpenLexicon}
                                        />
                                    ),
                                    apiProvidersTable: () => (
                                        <ApiProvidersTable
                                            data={data}
                                            animate={animate}
                                            onOpenLexicon={handleOpenLexicon}
                                        />
                                    ),
                                    oneBtcCanBuyTable: () => (
                                        <OneBtcCanBuyTable
                                            data={data}
                                            bitcoinPrice={bitcoinPrice}
                                            conversionRates={conversionRates}
                                            selectedCurrency={selectedCurrency}
                                            animate={animate}
                                            onOpenLexicon={handleOpenLexicon}
                                        />
                                    ),
                                    ownershipEconomicsTable: () => (
                                        <OwnershipEconomicsTable
                                            data={data}
                                            bitcoinPrice={bitcoinPrice}
                                            conversionRates={conversionRates}
                                            selectedCurrency={selectedCurrency}
                                            animate={animate}
                                            onOpenLexicon={handleOpenLexicon}
                                        />
                                    ),
                                }[tableId];

                                return TableComponent ? (
                                    <Flex key={tableId} flex={{ base: '1 0 100%', md: '1 1 24.5%' }}>
                                        {TableComponent()}
                                    </Flex>
                                ) : null;
                            })}
                        </Flex>
                    </SectionContainer>
                );

            default:
                return null;
        }
    };


    return (
        <Box>
            {/* DataBanner Section */}
            <Box mb={6}>
                <DataBanner
                    formatNumber={formatNumber}
                    formatLargeNumber={formatLargeNumber}
                    lastMempoolBlockData={lastMempoolBlockData}
                    fees={fees}
                    mempoolInfo={mempoolInfo}
                    data={data}
                    bitcoinPrice={bitcoinPrice}
                    da={da}
                />
            </Box>

            {/* Main Content Container */}
            <Box px={5} width="100%">
                {/* Header Section */}
                <Flex
                    as="header"
                    align="center"
                    justify="space-between"
                    wrap="wrap"
                    mb={6}
                    width="100%"
                >
                    <Flex align="center" flex="1">
                        {logoUrl ? (
                            logoLinkUrl ? (
                                <a href={logoLinkUrl} target="_blank" rel="noopener noreferrer">
                                    <Image
                                        src={logoUrl}
                                        alt="Dashboard Logo"
                                        height="80px"
                                        maxWidth="200px"
                                        mr={4}
                                        objectFit="contain"
                                        cursor="pointer"
                                        _hover={{ opacity: 0.8 }}
                                    />
                                </a>
                            ) : (
                                <Image
                                    src={logoUrl}
                                    alt="Dashboard Logo"
                                    height="80px"
                                    maxWidth="200px"
                                    mr={4}
                                    objectFit="contain"
                                />
                            )
                        ) : (
                            <FontAwesomeIcon icon={faRocket} size="xl" />
                        )}
                        <Heading
                            as="h1"
                            size="md"
                            ml={2}
                            fontFamily="'Ubuntu', sans-serif"
                            fontWeight="bold"
                            flex="1"
                        >
                            {message}
                        </Heading>
                        <Button
                            as="a"
                            href="https://timechainstats.com"
                            size="xs"
                            colorScheme="gray"
                            variant="outline"
                            mr={2}
                            display={{ base: "none", md: "inline-flex" }}
                        >
                            Full Dashboard
                        </Button>
                        <IconButton
                            aria-label="Settings"
                            icon={<FontAwesomeIcon icon={faGear} size="lg" />}
                            onClick={() => setIsEditModalOpen(true)}
                            variant="ghost"
                            color="orange.500"
                            _hover={{ bg: 'orange.100' }}
                            size="lg"
                            fontSize="20px"
                            p={4}
                        />
                    </Flex>
                </Flex>

                {/* Render sections in order */}
                <Box width="100%">
                    {sectionOrder.map((sectionId) => renderSection(sectionId))}
                </Box>

                {/* Edit Modal */}
                <Modal
                    isOpen={isEditModalOpen}
                    onClose={() => setIsEditModalOpen(false)}
                    size={{ base: "sm", md: "6xl" }}
                    scrollBehavior="inside"
                >
                    <ModalOverlay backgroundColor="rgba(0, 0, 0, 0.8)" />
                    <ModalContent
                        bg={modalBgColor}
                        color={modalTextColor}
                        borderWidth="2px"
                        borderStyle="solid"
                        borderColor={modalBorderColor}
                        borderRadius="6px"
                        p={6}
                    >
                        <ModalHeader>
                            <Flex justify="space-between" align="center">
                                <Text>Dashboard Settings</Text>
                                <Tooltip label="Reset to default layout">
                                    <IconButton
                                        icon={<FontAwesomeIcon icon={faRotateLeft} />}
                                        variant="ghost"
                                        colorScheme="orange"
                                        onClick={handleResetSections}
                                        aria-label="Reset layout"
                                    />
                                </Tooltip>
                            </Flex>
                        </ModalHeader>
                        <ModalCloseButton />

                        <ModalBody>
                            {/* Main two-column grid */}
                            <Grid templateColumns={{ base: '1fr', lg: '1fr 1fr' }} gap={8} mb={8}>
                                {/* Left Column */}
                                <Box>
                                    {/* Section Management */}
                                    <Box mb={8}>
                                        <Flex justify="space-between" align="center" mb={4}>
                                            {renderSectionHeader("Layout", faSliders)}
                                            <Text fontSize="sm" color="gray">
                                                Drag sections to reorder
                                            </Text>
                                        </Flex>
                                        {renderSectionList()}
                                    </Box>

                                    <Divider my={6} />

                                    {/* Chart Settings */}
                                    {sectionVisibility.charts && (
                                        <Box mb={8}>
                                            {renderSectionHeader("Chart Options", faChartArea)}
                                            <VStack align="stretch" spacing={4} mt={4}>
                                                <Flex justify="space-between" align="center">
                                                    <Box>
                                                        <Text>Price & Hashrate Chart</Text>
                                                        <Text fontSize="sm" color="gray">
                                                            Historical price and network hashrate
                                                        </Text>
                                                    </Box>
                                                    <Switch
                                                        colorScheme="orange"
                                                        isChecked={showPriceChart}
                                                        onChange={(e) => setShowPriceChart(e.target.checked)}
                                                    />
                                                </Flex>

                                                <Flex justify="space-between" align="center">
                                                    <Box>
                                                        <Text>Rewards & Fees Chart</Text>
                                                        <Text fontSize="sm" color="gray">
                                                            Block rewards and transaction fees
                                                        </Text>
                                                    </Box>
                                                    <Switch
                                                        colorScheme="orange"
                                                        isChecked={showRewardsChart}
                                                        onChange={(e) => setShowRewardsChart(e.target.checked)}
                                                    />
                                                </Flex>
                                            </VStack>
                                            <Divider my={7} />
                                        </Box>
                                    )}

                                    {/* Branding Settings */}
                                    <Box>
                                        {renderSectionHeader("Optional Branding", faPaintBrush)}
                                        <VStack spacing={4} align="stretch" mt={4}>
                                            <FormControl>
                                                <FormLabel>Logo URL</FormLabel>
                                                <Flex gap={2}>
                                                    <Input
                                                        value={logoUrl}
                                                        onChange={(e) => setLogoUrl(e.target.value)}
                                                        placeholder="Enter logo URL"
                                                    />
                                                    <Button
                                                        colorScheme="red"
                                                        size="md"
                                                        onClick={handleClearLogo}
                                                        variant="ghost"
                                                    >
                                                        Clear
                                                    </Button>
                                                </Flex>
                                            </FormControl>

                                            <FormControl>
                                                <FormLabel>Logo Link URL</FormLabel>
                                                <Flex gap={2}>
                                                    <Input
                                                        value={logoLinkUrl}
                                                        onChange={(e) => setLogoLinkUrl(e.target.value)}
                                                        onBlur={(e) => {
                                                            const normalizedUrl = normalizeUrl(e.target.value);
                                                            setLogoLinkUrl(normalizedUrl);
                                                        }}
                                                        placeholder="Enter URL (e.g., website.com or https://website.com)"
                                                    />
                                                    <Button
                                                        colorScheme="red"
                                                        size="md"
                                                        onClick={handleClearLogoLink}
                                                        variant="ghost"
                                                    >
                                                        Clear
                                                    </Button>
                                                </Flex>
                                            </FormControl>

                                            <FormControl>
                                                <FormLabel>Dashboard Message</FormLabel>
                                                <Flex gap={2}>
                                                    <Input
                                                        value={message}
                                                        onChange={(e) => setMessage(e.target.value)}
                                                        placeholder="Enter dashboard message"
                                                    />
                                                    <Button
                                                        colorScheme="red"
                                                        size="md"
                                                        onClick={handleResetMessage}
                                                        variant="ghost"
                                                    >
                                                        Reset
                                                    </Button>
                                                </Flex>
                                            </FormControl>
                                        </VStack>
                                    </Box>
                                </Box>

                                {/* Right Column */}
                                <Box>
                                    {/* Metric Selection */}
                                    <Box>
                                        <Flex justify="space-between" align="center" mb={4}>
                                            {renderSectionHeader("Key Metrics", faTable)}
                                            <Text fontSize="sm" color="gray">
                                                Select up to 21 metrics
                                            </Text>
                                        </Flex>

                                        <Grid
                                            templateColumns={{
                                                base: 'repeat(2, 1fr)',
                                                md: 'repeat(3, 1fr)'
                                            }}
                                            gap={4}
                                        >
                                            {metricOptions.map((metric) => (
                                                <Button
                                                    key={metric.id}
                                                    variant={selectedMetrics.includes(metric.id) ? 'solid' : 'outline'}
                                                    colorScheme="orange"
                                                    onClick={() => handleMetricToggle(metric.id)}
                                                    size="sm"
                                                    whiteSpace="normal"
                                                    height="auto"
                                                    py={2}
                                                >
                                                    {metric.label}
                                                </Button>
                                            ))}
                                        </Grid>
                                    </Box>
                                </Box>
                            </Grid>

                            {/* Data Tables Section - Full Width Below Grid */}
                            <Box width="100%">
                                <Flex justify="space-between" align="center" mb={4}>
                                    {renderSectionHeader("Data Tables", faTable)}
                                    <Text fontSize="sm" color="gray">
                                        Drag tables to reorder
                                    </Text>
                                </Flex>

                                <DndContext
                                    sensors={sensors}
                                    collisionDetection={closestCenter}
                                    onDragEnd={(event) => {
                                        const { active, over } = event;
                                        if (active.id !== over.id) {
                                            const oldIndex = tableOrder.indexOf(active.id);
                                            const newIndex = tableOrder.indexOf(over.id);
                                            setTableOrder(arrayMove(tableOrder, oldIndex, newIndex));
                                        }
                                    }}
                                >
                                    <SortableContext items={tableOrder} strategy={verticalListSortingStrategy}>
                                        <Grid templateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }} gap={4}>
                                            {tableOrder.map((tableId) => {
                                                const table = tableOptions.find(t => t.id === tableId);
                                                return (
                                                    <SortableTableItem
                                                        key={tableId}
                                                        id={tableId}
                                                        table={table}
                                                        isSelected={selectedTables.includes(tableId)}
                                                        onToggleVisibility={handleTableToggle}
                                                    />
                                                );
                                            })}
                                        </Grid>
                                    </SortableContext>
                                </DndContext>
                            </Box>
                        </ModalBody>
                    </ModalContent>
                </Modal>

                {/* Swap Modal */}
                <Modal
                    isOpen={isSwapModalOpen}
                    onClose={() => setIsSwapModalOpen(false)}
                    size={{ base: "sm", md: "3xl" }}
                >
                    <ModalOverlay backgroundColor="rgba(0, 0, 0, 0.8)" />
                    <ModalContent
                        bg={modalBgColor}
                        color={modalTextColor}
                        borderWidth="2px"
                        borderStyle="solid"
                        borderColor={modalBorderColor}
                        borderRadius="6px"
                        p={6}
                    >
                        <ModalHeader>Replace Metric</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Grid
                                templateColumns={{
                                    base: 'repeat(2, 1fr)',
                                    md: 'repeat(3, 1fr)',
                                    lg: 'repeat(6, 1fr)'
                                }}
                                gap={4}
                            >
                                {metricOptions.map((metric) => (
                                    <Button
                                        key={metric.id}
                                        variant={metric.id === currentlySwapping ? 'solid' : 'outline'}
                                        colorScheme="orange"
                                        onClick={() => handleSwap(metric.id)}
                                        size="sm"
                                        whiteSpace="normal"
                                        height="auto"
                                        py={2}
                                        isDisabled={metric.id === currentlySwapping}
                                    >
                                        {metric.label}
                                    </Button>
                                ))}
                            </Grid>
                        </ModalBody>
                    </ModalContent>
                </Modal>

                <Modal
                    isOpen={isMaxMetricsModalOpen}
                    onClose={() => setIsMaxMetricsModalOpen(false)}
                    isCentered
                    size="sm"
                >
                    <ModalOverlay backgroundColor="rgba(0, 0, 0, 0.8)" />
                    <ModalContent
                        bg={modalBgColor}
                        color={modalTextColor}
                        borderWidth="2px"
                        borderStyle="solid"
                        borderColor={modalBorderColor}
                        borderRadius="6px"
                        p={6}
                    >
                        <ModalHeader>Maximum Metrics Reached</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <Text>
                                You can only select up to 21 metrics. Please remove some metrics before adding more.
                            </Text>
                        </ModalBody>
                    </ModalContent>
                </Modal>

            </Box>
        </Box>
    );
};

export default CustomDashboard;