import { SingleDataValue } from '.';
import { ThemedFlex } from '../../theme/ThemedFlex';

export const MarketTileAthPercentage = ({ data, formatNumber, animate }) => {
  const numberValue = data?.s06?.bitcoin?.market_data?.ath_change_percentage?.usd != null
    ? `${formatNumber(data.s06.bitcoin.market_data.ath_change_percentage.usd, 2)}%`
    : '';

  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      className={animate ? 'gray-border-custom gray-border' : ''}
    >
      <SingleDataValue label="% From ATH" value={numberValue} />
    </ThemedFlex>
  );
};
