// src/components/data-display/DataTableHeader.jsx
import React from 'react';
import { Flex, Box, Text, useColorMode } from "@chakra-ui/react";

export const DataTableHeader = ({ title, subtitle, icon, animateLastBlock, onOpenLexicon, specificTerm }) => {
  const { colorMode } = useColorMode();
  const titleColor = colorMode === "dark" ? "white.500" : "primary.700";
  const subtitleColor = colorMode === "dark" ? "gray.700" : "gray.600";
  const animatedTitleColor =
    animateLastBlock && title === "Latest Block" ? "orange.500" : titleColor;

  return (
    <Flex justify="space-between" gap="30px" mb="7px">
      <Box>
        <Text
          fontSize="22px"
          fontWeight="700"
          color={animatedTitleColor}
          lineHeight="none"
          mb="7px"
          cursor={specificTerm ? "pointer" : "default"}
          onClick={() => specificTerm && onOpenLexicon(specificTerm)}
          _hover={specificTerm ? { textDecoration: 'underline' } : {}}
        >
          {title}
        </Text>
        <Text
          fontSize="14px"
          fontWeight="400"
          color={subtitleColor}
          lineHeight="shorter"
        >
          {subtitle}
        </Text>
      </Box>
      <Box>{icon}</Box>
    </Flex>
  );
};