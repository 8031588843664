import React from 'react';
import { DataTable } from '../../data-display/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAsia } from '@fortawesome/free-solid-svg-icons';
import { formatNumber } from '../../../utils';
import { currencySymbols } from '../../../utils/currencyConversion.utils';

const calculateCurrentPopulation = () => {
  const startPopulation = 8030868232;
  const dailyNetIncrease = 215000;
  const netIncreasePerMinute = dailyNetIncrease / (60 * 24);
  const startDate = new Date("2023-05-02");
  const today = new Date();
  const minutesElapsed = Math.floor((today - startDate) / (1000 * 60));
  return startPopulation + (minutesElapsed * netIncreasePerMinute);
};

export const OwnershipEconomicsTable = ({ 
  data, 
  bitcoinPrice, 
  conversionRates, 
  selectedCurrency, 
  animate,
  onOpenLexicon,
}) => {
  const tableData = data && bitcoinPrice && conversionRates && selectedCurrency
    ? [
        {
            label: 'Bitcoin Symbol',
            value: '₿ | BC | XBT | BTC',
            tooltipText: "There are many other cryptocurrency projects but none are Bitcoin, except for BTC... some are forked versions of bitcoin itself (like Litecoin (LTC)), others are completely separate systems and tokens (like Ethereum (ETH)), others are centralized protocols trying to work with banks (XRP), others are cheap clones of Bitcoin (Bitcoin Cash (BCH aka 'BCash' or 'BTrash') and/or Bitcoin SV (BSV aka 'Bitcoin Shit Version' or 'Bitcoin Scam Version')), but the vast majority of so-called 'altcoins' or 'shitcoins' are scams. There is only one true Bitcoin, and the name is 'Bitcoin' or 'BTC'. Anything else (like LTC, ETH, BCH or BSV) is not Bitcoin. Bitcoin = BTC!",
        },
        {
            label: '1 BTC Contains',
            value: '100,000,000 Sats',
            tooltipText: "The general unit structure of bitcoins has 1 bitcoin (BTC) equivalent to 1,000 millibitcoins (mBTC), 1,000,000 microbitcoins (μBTC), or 100,000,000 satoshis (commonly known as 'sats'). Every one (1) Bitcoin contains 100,000,000 individual units ('sats') and can be expressed with 8 decimal places (1.00000000 BTC).",
            clickableTerm: "Fungibility",
        },
        {
            label: 'Est. World Population',
            value: `${formatNumber(calculateCurrentPopulation(), 0)} humans`,
            tooltipText: "Estimated World Population as reported by US Census Bureau",
            clickableTerm: "Human Population",
        },
        {
            label: '21M BTC ÷ Fortune 500',
            value: `${formatNumber((21000000 / 500), 0)} BTC / Company`,
            tooltipText: `Dividing Bitcoin's maximum supply (20,999,999) by the number of companies in the Fortune 500 list (currently 500), resulting in an allocation of approximately ${formatNumber((21000000 / 500), 0)} BTC / Company`,
            clickableTerm: "BTC Per Fortune 500 Company",
        },
        {
            label: '21M BTC ÷ NYSE',
            value: `${formatNumber((21000000 / 2385), 0)} BTC / Company`,
            tooltipText: `Dividing Bitcoin's maximum supply (20,999,999) by the number of companies listed on the New York Stock Exchange (currently around 2400), resulting in an allocation of approximately ${formatNumber((21000000 / 2385), 0)} BTC / Company`,
            clickableTerm: "BTC Per NYSE Company",
        },
        {
            label: '21M BTC ÷ Top 0.1%',
            value: `${formatNumber((21000000 / (calculateCurrentPopulation() / 1000)), 8)} BTC`,
            tooltipText: "Dividing Bitcoin Max Supply (20,999,999) by (2022 World Population ÷ 1000)",
            clickableTerm: "Supply Distribution",
        },
        {
            label: '21M BTC ÷ Person',
            value: `${formatNumber((21000000 / calculateCurrentPopulation()), 8)} BTC`,
            tooltipText: "Dividing Bitcoin Max Supply (20,999,999) by (2022 World Population ÷ 1)",
            clickableTerm: "BTC Per Person",
        },
        {
            label: data && bitcoinPrice ? `${formatNumber((21000000 / calculateCurrentPopulation()), 4)} BTC in ${selectedCurrency.toUpperCase()}` : "Loading...",
            value: data && bitcoinPrice ? `${currencySymbols[selectedCurrency]}${formatNumber(((21000000 / calculateCurrentPopulation()) * bitcoinPrice * conversionRates[selectedCurrency]), 2)}` : "Loading...",
            tooltipText: data && bitcoinPrice ? `Multiplying Bitcoins per Person ${formatNumber((21000000 / calculateCurrentPopulation()), 4)} by Bitcoin Price $${formatNumber(bitcoinPrice, 2)} and then converting to ${selectedCurrency.toUpperCase()}` : "Loading...",
            clickableTerm: "BTC Per Person",
        },
        {
            label: 'UTXOs in Profit',
            value: data?.s12?.metrics?.data?.on_chain_data?.utxo_in_profit_count != null
              ? `${formatNumber(data.s12.metrics.data.on_chain_data.utxo_in_profit_count, 0)}`
              : "Loading...",
            tooltipText: "Number of UTXOs currently in profit",
            clickableTerm: "UTXO",
        }
    ]
    : [];

  return (
    <DataTable
      title="Ownership Economics"
      subtitle="Dividing BTC's total supply by world population"
      icon={<FontAwesomeIcon icon={faGlobeAsia} />}
      rows={tableData}
      animate={animate}
      onOpenLexicon={onOpenLexicon}
      rowsPerPage={9}
    />
  );
};