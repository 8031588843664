import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useColorMode,
  Text,
  VStack,
  Grid,
  Image,
  Link,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faGift } from '@fortawesome/free-solid-svg-icons';
import { ThemedDonateButton } from '../../theme/ThemedDonateButton';

const supportersData = [
  { link: 'https://twitter.com/PlebUnderground', imageSrc: 'https://timechainstats.com/img/2023pu.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/Meditation_Man', imageSrc: 'https://timechainstats.com/img/2023mm.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/honesthodl', imageSrc: 'https://timechainstats.com/img/2023uj.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/brian_trollz', imageSrc: 'https://timechainstats.com/img/2024shi.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/rwawoe', imageSrc: 'https://timechainstats.com/img/2023ro.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/Coinicarus', imageSrc: 'https://timechainstats.com/img/2023ci.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/HumbleBitcoiner', imageSrc: 'https://timechainstats.com/img/2023hb.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/AEHW1', imageSrc: 'https://timechainstats.com/img/2023wa.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/S0cratesz', imageSrc: 'https://timechainstats.com/img/2024pt.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/illiteratewithd', imageSrc: 'https://timechainstats.com/img/2023il.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/level39', imageSrc: 'https://timechainstats.com/img/2024l39.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/Producer_Jacob', imageSrc: 'https://timechainstats.com/img/2023jp.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/SADAMAFL', imageSrc: 'https://timechainstats.com/img/2023w.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/kavanhak', imageSrc: 'https://timechainstats.com/img/2023kv.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/alexstanczyk', imageSrc: 'https://timechainstats.com/img/2023as.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/TimechainCaL', imageSrc: 'https://timechainstats.com/img/2023tc.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/jerimican5445', imageSrc: 'https://timechainstats.com/img/2023je.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/BTCsessions', imageSrc: 'https://timechainstats.com/img/2023btcs.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/SatsforLife', imageSrc: 'https://timechainstats.com/img/2023ru.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/JaimeWGarcia', imageSrc: 'https://timechainstats.com/img/2023ja.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/Florida_Btc', imageSrc: 'https://timechainstats.com/img/2024fb.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/Clashdaddy69', imageSrc: 'https://timechainstats.com/img/2024cd.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/VailshireCap', imageSrc: 'https://timechainstats.com/img/2024jr.jpg', alt: 'Supporter' },
  { link: 'https://twitter.com/TomTaberHODL', imageSrc: 'https://timechainstats.com/img/2024tt.jpg', alt: 'Supporter' },
  
  // { link: '', imageSrc: 'https://timechainstats.com/img/.jpg', alt: 'Supporter' },
  // ... other supporters
];

const Supporter = ({ link, imageSrc, alt }) => (
  <Link href={link} target={'_blank'}>
    <Image
      width="65px"
      borderRadius={40}
      border={'2px'}
      borderColor={'gray.700'}
      objectFit="fill"
      src={imageSrc}
      alt={alt}
    />
  </Link>
);

const SupportersGrid = ({ supporters }) => (
  <Grid templateColumns={{ base: "repeat(4, 1fr)", md: "repeat(5, 1fr)" }} gap={{ base: 6, md: 9 }}>
    {supporters.map((supporter, index) => (
      <Supporter key={index} {...supporter} />
    ))}
  </Grid>
);

export const SupportersModal = ({ isOpen, onClose, onBecomeSupporter }) => {
  const { colorMode } = useColorMode();
  const bgColor = colorMode === 'dark' ? '#121212' : '#ffffff';
  const borderColor = colorMode === 'dark' ? '#ffffff' : '#121212';

  const [randomizedSupporters, setRandomizedSupporters] = React.useState([]);

  React.useEffect(() => {
    const shuffledSupporters = [...supportersData].slice(10); // Slice first 10
    shuffledSupporters.sort(() => Math.random() - 0.5); // Random sort
    setRandomizedSupporters([...supportersData.slice(0, 10), ...shuffledSupporters]);
  }, [isOpen]); // Add isOpen to dependency array

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{ base: "2xl", md: "2xl" }}>
      <ModalOverlay backgroundColor="rgba(16, 22, 26, 0.9)" />
      <ModalContent bg={bgColor} pb={6} borderWidth="2px" borderStyle="solid" borderColor={borderColor} borderRadius="6px" overflow="hidden">
        <ModalHeader textAlign="center"><FontAwesomeIcon icon={faGift} size={'lg'} /> &nbsp;Support TimechainStats</ModalHeader>
        <ModalCloseButton
          _hover={{
            bg: 'gray.500',
          }}
          _focus={{
            boxShadow: '0 0 0 1px rgba(66, 153, 225, 0.6)',
          }}
        />
        <ModalBody px={20} pb={25}>
          <VStack spacing={5} align="center">
            <Text mb={4} textAlign="center">TimechainStats is a free data resource and is made possible in part with help from our generous Supporters.</Text>
            <ThemedDonateButton onClick={onBecomeSupporter} size={'xl'} px={'8px'} fontSize={'12px'}>
              <FontAwesomeIcon icon={faAward} size={'lg'} /> &nbsp;Become a Supporter
            </ThemedDonateButton>
            <Text textAlign="center" fontSize={'xl'} fontWeight={'bold'} pt={7} pb={3}>TimechainStats Supporters</Text>
            <SupportersGrid supporters={randomizedSupporters} />
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
