import React from 'react';
import { DataTable } from '../../data-display/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFastForward } from '@fortawesome/free-solid-svg-icons';
import { formatNumber, formatLargeNumber } from '../../../utils';
import { currencySymbols } from '../../../utils/currencyConversion.utils';

export const VelocityTable = ({ 
  data, 
  bitcoinPrice, 
  conversionRates, 
  selectedCurrency, 
  animate,
  onOpenLexicon,
}) => {
  const tableData = data && bitcoinPrice && conversionRates && selectedCurrency
    ? [
        {
            label: 'Transaction Volume 24h',
            value: data?.s12?.metrics?.data?.on_chain_data?.txn_volume_last_24_hours_usd != null && bitcoinPrice != null
              ? `${currencySymbols[selectedCurrency]}${formatLargeNumber(data.s12.metrics.data.on_chain_data.txn_volume_last_24_hours_usd * conversionRates[selectedCurrency], 2, false)} ${selectedCurrency.toUpperCase()}`
              : "Loading...",
            tooltipText: "Total value of Bitcoin transactions in the last 24 hours",
            clickableTerm: "Volume",
        },
        {
            label: 'Velocity of Money 24h',
            value: data?.s12?.metrics?.data?.on_chain_data?.txn_volume_last_24_hours_usd != null && 
                   data?.s12?.metrics?.data?.supply_activity?.supply_active_1d != null
              ? `${formatNumber(data.s12.metrics.data.on_chain_data.txn_volume_last_24_hours_usd / data.s12.metrics.data.supply_activity.supply_active_1d, 0)}x`
              : "Loading...",
            tooltipText: "Applying the velocity of money formula: Velocity = Total Transaction Volume / Average Money Supply",
            clickableTerm: "Velocity of Money",
        },
        {
            label: 'Transactions/s 24h',
            value: data?.s12?.metrics?.data?.on_chain_data?.txn_per_second_count != null
              ? `${formatNumber(data.s12.metrics.data.on_chain_data.txn_per_second_count, 2)} TX/s`
              : "Loading...",
            tooltipText: "Average number of transactions per second in the last 24 hours",
            clickableTerm: "Transactions Per Second",
        },
        {
            label: 'Active Supply 24h',
            value: data?.s12?.metrics?.data?.supply_activity?.supply_active_1d != null
              ? `${formatNumber(data.s12.metrics.data.supply_activity.supply_active_1d, 0)} BTC`
              : "Loading...",
            tooltipText: "Amount of Bitcoin that has moved in the last 24 hours",
            clickableTerm: "Active Supply",
        },
        {
            label: 'Active Supply % 24h',
            value: data?.s12?.metrics?.data?.supply_activity?.supply_active_1d != null && 
                   data?.s12?.metrics?.data?.supply?.circulating != null
              ? `${formatNumber((data.s12.metrics.data.supply_activity.supply_active_1d / data.s12.metrics.data.supply.circulating) * 100, 2)}%`
              : "Loading...",
            tooltipText: "Percentage of circulating supply that has moved in the last 24 hours",
            clickableTerm: "Active Supply",
        },
        {
            label: 'Active Supply 30d',
            value: data?.s12?.metrics?.data?.supply_activity?.supply_active_30d != null
              ? `${formatNumber(data.s12.metrics.data.supply_activity.supply_active_30d, 0)} BTC`
              : "Loading...",
            tooltipText: "Amount of Bitcoin that has moved in the last 30 days",
            clickableTerm: "Active Supply",
        },
        {
            label: 'Active Supply % 30d',
            value: data?.s12?.metrics?.data?.supply_activity?.supply_active_30d != null && 
                   data?.s12?.metrics?.data?.supply?.circulating != null
              ? `${formatNumber((data.s12.metrics.data.supply_activity.supply_active_30d / data.s12.metrics.data.supply.circulating) * 100, 2)}%`
              : "Loading...",
            tooltipText: "Percentage of circulating supply that has moved in the last 30 days",
            clickableTerm: "Active Supply",
        },
        {
            label: 'TX > $100k USD 24h',
            value: data?.s08?.blockchain?.Data?.large_transaction_count != null
              ? `${formatNumber(data.s08.blockchain.Data.large_transaction_count, 0)} TX`
              : "Loading...",
            tooltipText: "Number of transactions greater than $100,000 in the last 24 hours",
            clickableTerm: "Transaction",
        },
        {
            label: 'Largest TX Value 24h',
            value: data?.s03?.bitcoin_stats?.bitcoin_data?.largest_transaction_24h?.value_usd != null && bitcoinPrice != null
              ? `${currencySymbols[selectedCurrency]}${formatLargeNumber(data.s03.bitcoin_stats.bitcoin_data.largest_transaction_24h.value_usd * conversionRates[selectedCurrency], 2, false)} ${selectedCurrency.toUpperCase()}`
              : "Loading...",
            tooltipText: "Value of the largest single transaction in the last 24 hours",
            clickableTerm: "Transaction",
        },
        {
            label: 'Coin Days Destroyed 24h',
            value: data?.s03?.bitcoin_stats?.bitcoin_data?.cdd_24h != null
              ? `${formatLargeNumber(data.s03.bitcoin_stats.bitcoin_data.cdd_24h, 0, true)} Coin Days`
              : "Loading...",
            tooltipText: "The number of coin days destroyed in the last 24 hours. When coins move, the coin days accumulated are 'destroyed', serving as a measure of economic activity.",
            clickableTerm: "Coin Days Destroyed",
        },
        {
            label: 'Adjusted NVT Ratio',
            value: data?.s12?.metrics?.data?.on_chain_data?.adjusted_nvt != null
              ? formatNumber(data.s12.metrics.data.on_chain_data.adjusted_nvt, 2)
              : "Loading...",
            tooltipText: "The Network Value to Transactions (NVT) Ratio adjusted for coin velocity",
            clickableTerm: "Adjusted NVT Ratio",
        }
    ]
    : [];

  return (
    <DataTable
      title="Bitcoin Velocity"
      subtitle="Network transaction velocity metrics"
      icon={<FontAwesomeIcon icon={faFastForward} />}
      rows={tableData}
      animate={animate}
      specificTerm="Velocity"
      onOpenLexicon={onOpenLexicon}
      rowsPerPage={9}
    />
  );
};