import { SingleDataValue } from '.';
import { ThemedFlex } from '../../theme/ThemedFlex';

export const NetworkTileAge = ({ data, formatNumber, animate }) => {

  const daysSince = (dateString) => {
    const startDate = new Date(dateString);
    const currentDate = new Date();
    const msPerDay = 24 * 60 * 60 * 1000;
    return Math.floor((currentDate - startDate) / msPerDay);
};

  const numberValue = data
    ? `${formatNumber(daysSince("2009-01-03")) ?? "Loading..."} Days`
    : '';

  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      className={animate ? 'gray-border-custom gray-border' : ''}
    >
      <SingleDataValue label="Network Age" value={numberValue} />
    </ThemedFlex>
  );
};
