import { Box, Text, Flex, Spacer, useColorMode } from "@chakra-ui/react";

export const HighlightedDataPoint = ({ label, value }) => {
    const { colorMode } = useColorMode();
    const bgColor = colorMode === "dark" ? "orange.500" : "blue.500";
    const textColor = colorMode === "dark" ? "primary.300" : "#fff";

    return (
        <Box
            bg={bgColor}
            p={2}
            mx={-2}
            mt={0}
            mb={0}
            borderRadius="3px"
        >
            <Flex>
                <Text fontWeight="bold" color={textColor} fontSize={'13px'}>
                    {label}
                </Text>
                <Spacer />
                <Text fontWeight="bold" color={textColor} fontSize={'15px'} fontFamily="'Ubuntu Mono', monospace">
                    {value}
                </Text>
            </Flex>
        </Box>
    );
};
