// src/components/games/TriviaNight.jsx
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Text,
  VStack,
  Box,
  useColorMode,
  Flex,
  useToast,
  Progress,
  Grid,
  GridItem,
  useTheme,
  Switch,
  InputGroup,
  InputLeftAddon,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGamepad, faCoins } from '@fortawesome/free-solid-svg-icons';
import { TriviaNightQuestions } from './TriviaNightQuestions';
import AchievementBadge from './components/AchievementBadge';
import { BADGE_CONFIGS } from './config/badgeConfigs';
import GameInfoTriviaNight from './GameInfoTriviaNight';

const INITIAL_CREDITS = 0;
const QUESTIONS_PER_GAME = 10;
const SPIN_COST = 150;
const MIN_CREDIT_BALANCE = 0;

const basicDifficultyLevels = {
  'Basics': 1,
  'History': 2,
  'Market Terms': 3,
  'Adoption': 4,
  'Blockchain Basics': 5,
};

const advancedDifficultyLevels = {
  'Basics': 1,
  'History': 2,
  'Market Terms': 3,
  'Adoption': 4,
  'Blockchain Basics': 5,
  'Wallets': 6,
  'Security': 7,
  'Mining': 8,
  'Privacy': 9,
  'Transactions': 10,
  'Scaling Solutions': 11,
  'Forks': 12,
  'Technical': 13,
  'BIPs': 14
};

const TriviaNight = ({ isOpen, onClose }) => {
  const [isManualSpin, setIsManualSpin] = useState(false);
  const { colorMode } = useColorMode();
  const theme = useTheme();
  const toast = useToast();

  const [survivorMode, setSurvivorMode] = useState(true);

  const categoryColors = useMemo(() => ({
    'Basics': theme.colors.orange[500],
    'History': theme.colors.blue[500],
    'Market Terms': theme.colors.green[500],
    'Adoption': theme.colors.purple[500],
    'Blockchain Basics': theme.colors.pink[500],
    'Wallets': theme.colors.orange[300],
    'Security': theme.colors.blue[300],
    'Mining': theme.colors.green[300],
    'Privacy': theme.colors.purple[300],
    'Transactions': theme.colors.pink[300],
    'Scaling Solutions': theme.colors.orange[700],
    'Forks': theme.colors.blue[700],
    'Technical': theme.colors.green[700],
    'BIPs': theme.colors.purple[700],
  }), [theme.colors]);

  const categories = useMemo(() =>
    survivorMode ? Object.keys(advancedDifficultyLevels) : Object.keys(basicDifficultyLevels),
    [survivorMode]);

  const [credits, setCredits] = useState(() => {
    const savedCredits = localStorage.getItem('triviaCredits');
    return savedCredits ? parseInt(savedCredits, 10) : INITIAL_CREDITS;
  });
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [score, setScore] = useState(0);
  const [questionsAsked, setQuestionsAsked] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [showBadge, setShowBadge] = useState(false);
  const [availableQuestions, setAvailableQuestions] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showCategory, setShowCategory] = useState(false);
  const [streak, setStreak] = useState(0);
  const [categoryMastery, setCategoryMastery] = useState({});
  const [isTimechainTriple, setIsTimechainTriple] = useState(false);
  const [timechainTripleBet, setTimechainTripleBet] = useState(0);
  const [timechainTripleBetSet, setTimechainTripleBetSet] = useState(false);
  const [timechainTriplesLeft, setTimechainTriplesLeft] = useState(survivorMode ? 2 : 1);
  const [timechainTripleOccurred, setTimechainTripleOccurred] = useState(false);
  const [usedQuestionIds, setUsedQuestionIds] = useState(new Set());

  const bgColor = useMemo(() => colorMode === 'dark' ? theme.colors.primary[700] : theme.colors.secondary[500], [colorMode, theme.colors]);
  const borderColor = useMemo(() => colorMode === 'dark' ? theme.colors.white[500] : theme.colors.black[500], [colorMode, theme.colors]);

  const getBadgeConfig = useCallback((score, questionsAsked, survivorMode) => {
    if (survivorMode) {
      if (questionsAsked >= 50) return BADGE_CONFIGS.triviaNightSurvivorLegend;
      if (questionsAsked >= 20) return BADGE_CONFIGS.triviaNightSurvivor20;
      if (questionsAsked >= 10) return BADGE_CONFIGS.triviaNightSurvivor10;
      if (questionsAsked >= 5) return BADGE_CONFIGS.triviaNightSurvivor5;
      return BADGE_CONFIGS.triviaNightLoser;
    } else {
      if (score === 10) return BADGE_CONFIGS.triviaNightPerfect;
      if (score >= 8) return BADGE_CONFIGS.triviaNightWinner;
      if (score >= 5) return BADGE_CONFIGS.triviaNightAverage;
      if (score >= 2) return BADGE_CONFIGS.triviaNightNovice;
      return BADGE_CONFIGS.triviaNightLoser;
    }
  }, []);

  const initializeQuestions = useCallback(() => {
    setAvailableQuestions([...TriviaNightQuestions]);
    setUsedQuestionIds(new Set());
  }, []);

  const getNextQuestion = useCallback(() => {
    if (availableQuestions.length === 0) {
      return null;
    }
    const unusedQuestions = availableQuestions.filter(q => !usedQuestionIds.has(q.id));
    if (unusedQuestions.length === 0) {
      return null;
    }
    const eligibleQuestions = survivorMode
      ? unusedQuestions
      : unusedQuestions.filter(q => basicDifficultyLevels.hasOwnProperty(q.category));
    const randomIndex = Math.floor(Math.random() * eligibleQuestions.length);
    const question = eligibleQuestions[randomIndex];
    setUsedQuestionIds(prevIds => new Set(prevIds).add(question.id));
    setAvailableQuestions(prevQuestions => prevQuestions.filter(q => q.id !== question.id));
    return {
      ...question,
      options: question.options.sort(() => Math.random() - 0.5)
    };
  }, [availableQuestions, usedQuestionIds, survivorMode]);

  const getNextQuestionSurvivor = useCallback(() => {
    if (availableQuestions.length === 0) {
      return null;
    }
    const currentDifficulty = Math.min(14, Math.floor(questionsAsked / 5) + 1);
    const eligibleQuestions = availableQuestions.filter(q =>
      advancedDifficultyLevels[q.category] <= currentDifficulty && !usedQuestionIds.has(q.id)
    );
    if (eligibleQuestions.length === 0) {
      return getNextQuestion(); // Fallback to regular selection if no eligible questions
    }
    const randomIndex = Math.floor(Math.random() * eligibleQuestions.length);
    const question = eligibleQuestions[randomIndex];
    setUsedQuestionIds(prevIds => new Set(prevIds).add(question.id));
    setAvailableQuestions(prevQuestions => prevQuestions.filter(q => q.id !== question.id));
    return {
      ...question,
      options: question.options.sort(() => Math.random() - 0.5)
    };
  }, [availableQuestions, questionsAsked, getNextQuestion, usedQuestionIds]);

  const startNewQuestion = useCallback(() => {
    setSpinning(true);
    setShowCategory(false);
    setCurrentQuestion(null);

    // Check for Timechain Triple opportunity
    const shouldTriggerTimechainTriple = () => {
      if (questionsAsked === 0) return false; // Never on first question
      if (survivorMode) {
        return timechainTriplesLeft > 0 && Math.random() < 0.3; // 30% chance, up to 2 times
      } else {
        if (timechainTripleOccurred) return false; // Already occurred in 10-question mode
        return questionsAsked >= 5 && Math.random() < 0.5; // 50% chance after 5th question
      }
    };

    if (shouldTriggerTimechainTriple()) {
      setIsTimechainTriple(true);
      setTimechainTriplesLeft(prev => prev - 1);
      if (!survivorMode) setTimechainTripleOccurred(true);
    } else {
      setIsTimechainTriple(false);
    }

    // Spin for 2 seconds
    setTimeout(() => {
      setSpinning(false);
      setIsManualSpin(false);
      const question = survivorMode ? getNextQuestionSurvivor() : getNextQuestion();
      setCurrentQuestion(question);
      setSelectedCategory(question ? question.category : null);
      setShowCategory(true);
    }, 2000);
  }, [getNextQuestion, getNextQuestionSurvivor, survivorMode, timechainTriplesLeft, questionsAsked, timechainTripleOccurred]);

  useEffect(() => {
    if (isOpen) {
      initializeQuestions();
      setScore(0);
      setQuestionsAsked(0);
      setGameOver(false);
      setTimechainTriplesLeft(survivorMode ? 2 : 1);
      setTimechainTripleOccurred(false);
    }
  }, [isOpen, initializeQuestions, survivorMode]);

  useEffect(() => {
    if (isOpen && !spinning && !currentQuestion && availableQuestions.length > 0) {
      startNewQuestion();
    }
  }, [isOpen, spinning, currentQuestion, availableQuestions, startNewQuestion]);

  useEffect(() => {
    localStorage.setItem('triviaCredits', credits.toString());
  }, [credits]);

  const calculateReward = useCallback((difficultyIndex, isCorrect, currentQuestionsAsked, currentTimechainTripleBet) => {
    if (!isCorrect) return 0;

    const baseReward = 50 * Math.pow(1.1, currentQuestionsAsked);
    const streakMultiplier = Math.min(1 + streak * 0.1, 2);
    const categoryBonus = (categoryMastery[currentQuestion?.category] || 0) * 50;

    let reward = baseReward * streakMultiplier + categoryBonus;

    if (isTimechainTriple) {
      reward = currentTimechainTripleBet * 3;
    }

    return Math.round(reward);
  }, [streak, categoryMastery, isTimechainTriple, currentQuestion]);

  const handleAnswer = useCallback((selectedOptionId) => {
    if (currentQuestion) {
      const isCorrect = selectedOptionId === currentQuestion.correctAnswer;
      const difficultyIndex = categories.indexOf(currentQuestion.category);
  
      if (isCorrect) {
        const reward = calculateReward(difficultyIndex, true, questionsAsked, timechainTripleBet);
        setCredits(prevCredits => prevCredits + reward);
        setScore(prevScore => prevScore + 1);
        setStreak(prevStreak => prevStreak + 1);
        setCategoryMastery(prev => ({
          ...prev,
          [currentQuestion.category]: (prev[currentQuestion.category] || 0) + 1
        }));
  
        toast({
          duration: 4000,
          position: "top",
          variant: "solid",
          render: () => (
            <Box color='white' p={3} bg='green.500' borderRadius={3}>
              Correct! You earned {reward} credits!
              {isTimechainTriple && " (Timechain Triple bonus!)"}
            </Box>
          ),
        });
      } else {
        if (survivorMode) {
          setCredits(MIN_CREDIT_BALANCE);
          setGameOver(true);
          setShowBadge(true);
          toast({
            duration: 4000,
            position: "top",
            variant: "solid",
            render: () => (
              <Box color='white' p={3} bg='red.500' borderRadius={3}>
                Game Over! You lost all your credits.
              </Box>
            ),
          });
          return;
        } else if (isTimechainTriple) {
          const penalty = timechainTripleBet;
          setCredits(prevCredits => Math.max(MIN_CREDIT_BALANCE, prevCredits - penalty));
          toast({
            duration: 4000,
            position: "top",
            variant: "solid",
            render: () => (
              <Box color='white' p={3} bg='red.500' borderRadius={3}>
                Incorrect. You lost your Timechain Triple bet of {penalty} credits.
              </Box>
            ),
          });
        } else {
          const penalty = Math.min(credits - MIN_CREDIT_BALANCE, 20 + difficultyIndex * 5);
          setCredits(prevCredits => Math.max(MIN_CREDIT_BALANCE, prevCredits - penalty));
          toast({
            duration: 4000,
            position: "top",
            variant: "solid",
            render: () => (
              <Box color='white' p={3} bg='red.500' borderRadius={3}>
                Incorrect. You lost {penalty} credits.
              </Box>
            ),
          });
        }
        setStreak(0);
      }
  
      setQuestionsAsked(prevQuestionsAsked => {
        const newQuestionsAsked = prevQuestionsAsked + 1;
        if (!survivorMode && newQuestionsAsked >= QUESTIONS_PER_GAME) {
          setGameOver(true);
          setShowBadge(true);
        } else {
          startNewQuestion();
        }
        return newQuestionsAsked;
      });
    }
  
    setIsTimechainTriple(false);
    setTimechainTripleBet(0);
    setTimechainTripleBetSet(false);
  }, [
    currentQuestion,
    categories,
    calculateReward,
    credits,
    survivorMode,
    startNewQuestion,
    toast,
    isTimechainTriple,
    questionsAsked,
    timechainTripleBet
  ]);

  const handleTimechainTripleBet = useCallback(() => {
    if (timechainTripleBet > 0 && timechainTripleBet <= credits) {
      setTimechainTripleBetSet(true);
    } else {
      toast({
        status: "error",
        duration: 4000,
        position: "top",
        variant: "solid",
        render: () => (
          <Box color='white' p={3} bg='red.500' borderRadius={3}>
            Invalid bet amount. Please enter a value between 1 and {credits}.
          </Box>
        ),
        isClosable: true,
      });
    }
  }, [timechainTripleBet, credits, toast]);

  const handleWheelClick = useCallback(() => {
    if (gameOver) return;

    if (credits >= SPIN_COST) {
      setCredits(prevCredits => prevCredits - SPIN_COST);
      setIsManualSpin(true);
      startNewQuestion();
    } else {
      toast({
        status: "error",
        duration: 4000,
        position: "top",
        variant: "solid",
        render: () => (
          <Box color='white' p={3} bg='red.500' borderRadius={3}>
            Insufficient Credits!<br />You need {SPIN_COST} credits to spin the wheel again!
          </Box>
        ),
        isClosable: true,
      });
    }
  }, [credits, gameOver, startNewQuestion, toast]);

  const restartGame = useCallback(() => {
    setCredits(INITIAL_CREDITS);
    setScore(0);
    setQuestionsAsked(0);
    setGameOver(false);
    setSurvivorMode(false);
    setStreak(0);
    setCategoryMastery({});
    setIsTimechainTriple(false);
    setTimechainTripleBet(0);
    setTimechainTriplesLeft(1);
    setShowBadge(false);
    initializeQuestions();
  }, [initializeQuestions]);

  const toggleSurvivorMode = useCallback(() => {
    setSurvivorMode(prev => !prev);
    setScore(0);
    setQuestionsAsked(0);
    setGameOver(false);
    setStreak(0);
    setCategoryMastery({});
    setIsTimechainTriple(false);
    setTimechainTripleBet(0);
    setTimechainTriplesLeft(prev => !prev ? 2 : 1);
    setShowBadge(false);
    initializeQuestions();
  }, [initializeQuestions]);

  const closeBadge = () => {
    setShowBadge(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay backgroundColor="rgba(0, 0, 0, 0.95)" />
      <ModalContent
        bg={bgColor}
        pb={6}
        borderWidth="2px"
        borderStyle="solid"
        borderColor={borderColor}
        borderRadius="6px"
      >
        <ModalHeader>
          <Flex align="left" justify="space-between">
            <Flex align="center">
              <FontAwesomeIcon icon={faGamepad} style={{ marginRight: '10px', color: theme.colors.icon[colorMode] }} />
              <Text mr={10}>Trivia Night</Text>
              <Text mr={2} fontSize={11}>Survivor Mode</Text>
              <Switch isChecked={survivorMode} onChange={toggleSurvivorMode} colorScheme="orange" size={'sm'} />
            </Flex>
          </Flex>
          <Text fontSize="sm" fontWeight="normal" color="gray">
            Test your knowledge about Bitcoin
          </Text>
        </ModalHeader>


        <GameInfoTriviaNight />
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <Flex justify="space-between" align="center">
              <InputGroup size="sm" width="auto">
                <InputLeftAddon>
                  <FontAwesomeIcon icon={faCoins} />
                </InputLeftAddon>
                <Input value={credits} isReadOnly width="100px" />
              </InputGroup>
              <Text fontWeight="bold" fontSize="md">Score: {score}</Text>
              {survivorMode ? (
                <Text fontSize="sm">Survivor Streak: {questionsAsked}</Text>
              ) : (
                <Text fontSize="sm">Question {questionsAsked + 1} of {QUESTIONS_PER_GAME}</Text>
              )}
            </Flex>
            {!survivorMode && (
              <Progress value={(questionsAsked / QUESTIONS_PER_GAME) * 100} colorScheme="orange" />
            )}
            {!gameOver ? (
              <Grid templateColumns={{ base: "1fr", md: "1fr 2fr" }} gap={4}>
                <GridItem display="flex" justifyContent="center" alignItems="center">
                  <Box
                    position="relative"
                    width="200px"
                    height="200px"
                    onClick={handleWheelClick}
                    cursor="pointer"
                    _hover={{ opacity: 0.8 }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                      <defs>
                        <style>
                          {`
                            @keyframes spin {
                              0% { transform: rotate(0deg); }
                              100% { transform: rotate(360deg); }
                            }
                            .wheel { transform-origin: center; }
                            .spinning { animation: spin 0.5s linear infinite; }
                          `}
                        </style>
                      </defs>
                      <g className={`wheel ${spinning ? 'spinning' : ''}`}>
                        <circle cx="100" cy="100" r="98" fill="none" stroke={theme.colors.gray[400]} strokeWidth="4" />
                        {categories.map((category, index) => (
                          <g key={category} transform={`rotate(${index * (360 / categories.length)} 100 100)`}>
                            <path d={`M100,100 L100,2 A98,98 0 0,1 ${100 + 98 * Math.sin(2 * Math.PI / categories.length)},${100 - 98 * Math.cos(2 * Math.PI / categories.length)} Z`} fill={categoryColors[category]} />
                          </g>
                        ))}
                      </g>
                    </svg>
                    <Text
                      position="absolute"
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%)"
                      fontSize="sm"
                      fontWeight="bold"
                      textAlign="center"
                    >
                      {/* Spin ({SPIN_COST} credits) */}
                    </Text>
                  </Box>
                </GridItem>
                <GridItem display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                  {spinning ? (
                    <Box textAlign="center">
                      <Text mt={4}>
                        {isManualSpin
                          ? `Spinning the wheel for ${SPIN_COST} credits...`
                          : "Spinning the wheel..."}
                      </Text>
                    </Box>
                  ) : (
                    <VStack align="stretch" spacing={3} width="100%">
                      {showCategory && (
                        <Text fontSize="md" textTransform="uppercase" textAlign="center" fontWeight="bold" color={`${theme.colors.orange[500]}`}>
                          {selectedCategory}
                        </Text>
                      )}
                      {currentQuestion && !isTimechainTriple && (
                        <Text fontWeight="bold" textAlign="center">{currentQuestion.question}</Text>
                      )}
                      {isTimechainTriple && timechainTripleBetSet && (
                        <Text fontWeight="bold" textAlign="center">{currentQuestion.question}</Text>
                      )}
                    </VStack>
                  )}
                </GridItem>
              </Grid>
            ) : (
              <VStack spacing={4}>
  <Text fontSize="2xl" fontWeight="bold">Game Over!</Text>
  <Text>Your final score: {score} questions answered</Text>
  <Button onClick={restartGame} colorScheme="orange">
    Play Again
  </Button>
</VStack>
            )}

            {!gameOver && currentQuestion && !isTimechainTriple && !timechainTripleBetSet && (
              <Box borderWidth="1px" borderRadius="lg" p={4}>
                <VStack align="stretch" spacing={2}>
                  {currentQuestion.options.map((option) => (
                    <Button
                      key={option.id}
                      onClick={() => handleAnswer(option.id)}
                      colorScheme="orange"
                      variant="outline"
                      whiteSpace="normal"
                      height="auto"
                      py={2}
                    >
                      {option.text}
                    </Button>
                  ))}
                </VStack>
              </Box>
            )}

            {isTimechainTriple && !timechainTripleBetSet && (
              <Box borderWidth="1px" borderRadius="lg" mt={4} p={4} bg={theme.colors.blue[500]}>
                <Text fontSize="lg" fontWeight="bold">Timechain Triple!</Text>
                <Text>How many credits will you risk?</Text>
                <Text fontSize="xs" mb={2}>{credits} Available</Text>
                <NumberInput
                  max={credits}
                  min={0}
                  value={timechainTripleBet}
                  onChange={(value) => setTimechainTripleBet(Number(value))}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Button mt={3} onClick={handleTimechainTripleBet} colorScheme="blue" size="sm">
                  Submit
                </Button>
              </Box>
            )}

            {isTimechainTriple && timechainTripleBetSet && (
              <Box borderWidth="1px" borderRadius="lg" p={4}>
                <VStack align="stretch" spacing={2}>
                  {currentQuestion.options.map((option) => (
                    <Button
                      key={option.id}
                      onClick={() => handleAnswer(option.id)}
                      colorScheme="orange"
                      variant="outline"
                      whiteSpace="normal"
                      height="auto"
                      py={2}
                    >
                      {option.text}
                    </Button>
                  ))}
                </VStack>
              </Box>
            )}

{showBadge && (
  <AchievementBadge
    onClose={closeBadge}
    badgeConfig={{
      ...getBadgeConfig(score, questionsAsked, survivorMode),
      subtitle: `You answered ${score} questions correctly.`
    }}
    downloadFileName={getBadgeConfig(score, questionsAsked, survivorMode).downloadFileName}
    shareText={getBadgeConfig(score, questionsAsked, survivorMode).shareText}
  />
)}


            <Text fontSize="xs" color={theme.colors.gray[300]} textAlign="justify">
              <strong>Disclaimer:</strong> The Bitcoin Trivia Night game is for educational purposes only. It does not involve real money and is designed to test and improve your knowledge of Bitcoin concepts.
            </Text>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TriviaNight;
