// src/utils/currencyConversion.utils.js

import React from 'react';

export const currencySymbols = {
  aud: '🇦🇺 ',  // Australian Dollar
  ars: '🇦🇷 ', // Argentine Peso
  brl: '🇧🇷 ',  // Brazilian Real
  cad: '🇨🇦 ',  // Canadian Dollar
  chf: '🇨🇭 ',  // Swiss Franc
  czk: '🇨🇿 ',  // Czech Koruna
  dkk: '🇩🇰 ',  // Danish Krone
  eur: '🇪🇺 ',  // Euro
  gbp: '🇬🇧 ',  // British Pound
  inr: '🇮🇳 ',  // Indian Rupee
  jpy: '🇯🇵 ',  // Japanese Yen
  krw: '🇰🇷 ',  // South Korean Won
  mxn: '🇲🇽 ',  // Mexican Peso
  nok: '🇳🇴 ',  // Norwegian Krone
  nzd: '🇳🇿 ',  // New Zealand Dollar
  php: '🇵🇭 ',  // Philippine Peso
  pln: '🇵🇱 ',  // Polish Złoty
  sek: '🇸🇪 ',  // Swedish Krona
  sgd: '🇸🇬 ',  // Singapore Dollar
  // twd: '',  // Taiwan New Dollar
  usd: '🇺🇸 ',  // United States Dollar
};

export const flags = {
  ar: <span style={{ fontSize: '22px', marginRight: '6px' }}>🇦🇷</span>, // Argentina
  au: <span style={{ fontSize: '22px', marginRight: '6px' }}>🇦🇺</span>, // Australia
  br: <span style={{ fontSize: '22px', marginRight: '6px' }}>🇧🇷</span>, // Brazil
  ca: <span style={{ fontSize: '22px', marginRight: '6px' }}>🇨🇦</span>, // Canada
  ch: <span style={{ fontSize: '22px', marginRight: '6px' }}>🇨🇭</span>, // Switzerland
  cz: <span style={{ fontSize: '22px', marginRight: '6px' }}>🇨🇿</span>, // Czech Republic
  dk: <span style={{ fontSize: '22px', marginRight: '6px' }}>🇩🇰</span>, // Denmark
  eu: <span style={{ fontSize: '22px', marginRight: '6px' }}>🇪🇺</span>, // European Union
  bp: <span style={{ fontSize: '22px', marginRight: '6px' }}>🇬🇧</span>, // Britain
  in: <span style={{ fontSize: '22px', marginRight: '6px' }}>🇮🇳</span>, // India
  jp: <span style={{ fontSize: '22px', marginRight: '6px' }}>🇯🇵</span>, // Japan
  kr: <span style={{ fontSize: '22px', marginRight: '6px' }}>🇰🇷</span>, // South Korea
  mx: <span style={{ fontSize: '22px', marginRight: '6px' }}>🇲🇽</span>, // Mexico
  no: <span style={{ fontSize: '22px', marginRight: '6px' }}>🇳🇴</span>, // Norway
  nz: <span style={{ fontSize: '22px', marginRight: '6px' }}>🇳🇿</span>, // New Zealand
  ph: <span style={{ fontSize: '22px', marginRight: '6px' }}>🇵🇭</span>, // Philippines
  pl: <span style={{ fontSize: '22px', marginRight: '6px' }}>🇵🇱</span>, // Poland
  se: <span style={{ fontSize: '22px', marginRight: '6px' }}>🇸🇪</span>, // Sweden
  sg: <span style={{ fontSize: '22px', marginRight: '6px' }}>🇸🇬</span>, // Singapore
  // tw: <span style={{ fontSize: '22px', marginRight: '6px' }}></span>, // Taiwan
  us: <span style={{ fontSize: '22px', marginRight: '6px' }}>🇺🇸</span>, // United States
};

export const createCurrencyConverter = (data) => {
  if (!data || !data.s06 || !data.s06.bitcoin || !data.s06.bitcoin.market_data || !data.s06.bitcoin.market_data.current_price) {
    throw new Error('Invalid data: Bitcoin price data not found');
  }

  const currentPrices = data.s06.bitcoin.market_data.current_price;
  const usdPrice = currentPrices.usd;

  if (!usdPrice) {
    throw new Error('USD price not found in the data');
  }

  let conversionRates = {};

  for (let currency in currentPrices) {
    conversionRates[currency] = currentPrices[currency] / usdPrice;
  }

  return conversionRates;
};

