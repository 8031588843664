import { Flex, Box, Image } from '@chakra-ui/react'

export const ImageTileImage = ({ src, width, height, link }) => {
    return (
        <Flex
            justify="space-between"
            gap="30px"
            mb="7px"
        >
            <Box>
            <a href={link} target="_blank" rel="noreferrer">
                <Image
                    width={width}
                    height={height}
                    mb="7px"
                    src={src}
                />
            </a>
            </Box>
        </Flex>
    )
}
