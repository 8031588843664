import React, { useEffect, useState, useRef } from 'react';
import ApexCharts from 'apexcharts';
import { DataTableHeader } from './DataTableHeader';
import { DataTableFooter } from './DataTableFooter';
import { ThemedFlex } from '../../theme/ThemedFlex'
import { Text, Box, useColorMode } from '@chakra-ui/react';
import { CustomSpinner } from './CustomSpinner';

export const RewardsToFeesBarTile = ({
  title,
  subtitle,
  icon,
  link,
  linkLabel = 'Learn More',
  data,
  formatLargeNumber,
}) => {
  const [chartData, setChartData] = useState([]);
  const chartRef = useRef(null);
  const { colorMode } = useColorMode();
  const labelColor = colorMode === 'dark' ? '#ffffff' : '#121212';

  useEffect(() => {
    if (data && data.ts11 && data.ts11.rewards144) {
      const rewards144 = data.ts11.rewards144;
      
      const formattedData = [
        {
          name: 'Total Reward (24 hours)',
          value: parseInt(rewards144.totalReward),
        },
        {
          name: 'Total Fee (24 hours)',
          value: parseInt(rewards144.totalFee),
        },
      ];
      setChartData(formattedData);
    }
  }, [data]);

  useEffect(() => {
    if (chartData.length > 0 && chartRef.current) {
      const options = {
        chart: {
          fontFamily: 'Ubuntu, sans-serif',
          type: 'bar',
          stacked: true,
          height: '100%',
          width: '100%',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '50%',
            borderRadius: 4,
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: [labelColor],
          },
          formatter: (value) => {
            return `${formatLargeNumber(value, 2)} sats`;
          },
        },
        legend: {
          position: 'bottom',
          labels: {
            colors: colorMode === 'dark' ? '#999' : '#333',
          },
        },
        series: [
          {
            name: 'Total Reward',
            data: [chartData[0].value],
          },
          {
            name: 'Total Fee',
            data: [chartData[1].value],
          },
        ],
        xaxis: {
          categories: ['~24 Hours (144 Blocks)'],
          labels: {
            style: {
              colors: colorMode === 'dark' ? '#999' : '#333',
            },
          },
        },
        yaxis: {
          min: 0,
          labels: {
            style: {
              colors: colorMode === 'dark' ? '#999' : '#333',
            },
            formatter: (value) => {
              if (value === null || value === undefined) {
                return '';
              }
              const formattedValue = formatLargeNumber(value, 0);
              return `${formattedValue} sats`;
            },
          },
        },
        colors: ["#f7931a", "#329239"],
        tooltip: {
          theme: colorMode,
          y: {
            formatter: (value) => {
              return `${formatLargeNumber(value, 2)} sats`;
            },
          },
        },
      };
      
      const chart = new ApexCharts(chartRef.current, options);
      chart.render();

      const handleResize = () => {
        chart.updateOptions({
          chart: {
            width: '100%',
            height: '100%',
          },
        });
      };

      window.addEventListener('resize', handleResize);

      return () => {
        chart.destroy();
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [chartData, formatLargeNumber, labelColor, colorMode]);

  if (!data || !data.ts11 || !data.ts11.rewards144) {
    console.error("Error: Data is null, undefined, or incorrectly structured");
    return <CustomSpinner />;
  }

  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      style={{ minHeight: '337px', maxHeight: '100%' }}
    >
      <DataTableHeader title={title} subtitle={subtitle} icon={icon} />
      <Box flexGrow={1} minHeight='337px'>
        <div ref={chartRef} id="chart10" style={{ height: '100%' }} />
      </Box>
      <Text textAlign={'center'} fontSize={'12px'} mt={1} mb={2}>Current Subsidy 312.5 M sats/block</Text>
      <DataTableFooter link={link} linkLabel={linkLabel} />
    </ThemedFlex>
  );
};