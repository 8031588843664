import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Text,
  VStack,
  HStack,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useColorMode,
  Progress,
  Tooltip,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  FormControl,
  FormLabel,
  useBreakpointValue,
  keyframes,
  SimpleGrid,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBoltLightning,
  faLock,
  faUnlock,
  faCoins,
  faServer,
  faUserSecret,
  faBan,
  faGamepad,
  faRefresh,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import { faBitcoin } from '@fortawesome/free-brands-svg-icons';
import AchievementBadge from './components/AchievementBadge';
import { BADGE_CONFIGS } from './config/badgeConfigs';
import GameInfoRoutingMan from './GameInfoRoutingMan';

const INITIAL_BALANCE = 100;
const TRANSACTION_AMOUNT = 200;
const CHANNEL_COST = 50;
const MINING_REWARD = 25;
const DIFFICULTY_INCREASE_INTERVAL = 30; // seconds
const COIN_VALUE = 10;
const FORCE_CLOSE_PENALTY = 100;
const MOVE_COST = 1; // Cost per move
const MOVES_BACK = 5; // Number of moves given back when hitting a node
const MAX_FORCE_CLOSES = 3; // Maximum number of force closes before game over
const TIME_PENALTY_THRESHOLD = 60; // seconds
const MOVE_PENALTY_THRESHOLD = 30; // moves
const TIME_PENALTY_AMOUNT = 5; // credits per second over threshold
const MOVE_PENALTY_AMOUNT = 2; // credits per move over threshold

const LightningMazeRunner = ({ isOpen, onClose }) => {
  const [goalReachedToastShown, setGoalReachedToastShown] = useState(false);
  const [level, setLevel] = useState(1);
  const [gridSize, setGridSize] = useState(5);
  const [player, setPlayer] = useState({ x: 0, y: 0 });
  const [goal, setGoal] = useState({ x: 4, y: 4 });
  const [maze, setMaze] = useState([]);
  const [balance, setBalance] = useState(INITIAL_BALANCE);
  const [channels, setChannels] = useState([]);
  const [showAchievementBadge, setShowAchievementBadge] = useState(null);
  const [gameStatus, setGameStatus] = useState('playing');
  const [movesCount, setMovesCount] = useState(0);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [isPowered, setIsPowered] = useState(false);
  const [difficulty, setDifficulty] = useState(1);
  const [miningProgress, setMiningProgress] = useState(0);
  const [hackers, setHackers] = useState([]);
  const [forceCloses, setForceCloses] = useState([]);
  const [message, setMessage] = useState('');
  const [timePenalty, setTimePenalty] = useState(0);
  const [movePenalty, setMovePenalty] = useState(0);
  const [forceCloseCount, setForceCloseCount] = useState(0);
  const [totalWinnings, setTotalWinnings] = useState(0);
  const [usedNodes, setUsedNodes] = useState([]);

  const { colorMode } = useColorMode();
  const toast = useToast();
  const showToast = useCallback((title, description, status) => {
    toast({
      duration: 4000,
      position: "bottom",
      variant: "solid",
      render: () => (
        <Box color='white' p={3} bg={status === 'success' ? 'green.500' : 'red.500'} borderRadius={3}>
          <Text fontWeight="bold">{title}</Text>
          <Text>{description}</Text>
        </Box>
      ),
    });
  }, [toast]);
  const modalSize = useBreakpointValue({ base: "full", md: "full" });

  const generateMaze = useCallback(() => {
    const newMaze = Array(gridSize).fill().map(() => Array(gridSize).fill(0));
    const obstacleChance = 0.1 + (level - 1) * 0.03;
    for (let i = 0; i < gridSize * gridSize / 3; i++) {
      const x = Math.floor(Math.random() * gridSize);
      const y = Math.floor(Math.random() * gridSize);
      if ((x !== 0 || y !== 0) && (x !== gridSize - 1 || y !== gridSize - 1)) {
        newMaze[y][x] = Math.random() < obstacleChance ? 1 : (Math.random() < 0.4 ? 2 : 3);
      }
    }
    setMaze(newMaze);
  }, [level, gridSize]);

  const generateObstacles = useCallback(() => {
    const newHackers = [];
    const newForceCloses = [];
    const obstacleCount = Math.min(2 + Math.floor(level / 3), Math.floor(gridSize / 2));
    for (let i = 0; i < obstacleCount; i++) {
      newHackers.push({
        x: Math.floor(Math.random() * gridSize),
        y: Math.floor(Math.random() * gridSize),
      });
      newForceCloses.push({
        x: Math.floor(Math.random() * gridSize),
        y: Math.floor(Math.random() * gridSize),
      });
    }
    setHackers(newHackers);
    setForceCloses(newForceCloses);
  }, [level, gridSize]);

  const generateRandomGoal = useCallback(() => {
    let newGoal;
    do {
      newGoal = {
        x: Math.floor(Math.random() * gridSize),
        y: Math.floor(Math.random() * gridSize)
      };
    } while (newGoal.x === 0 && newGoal.y === 0); // Ensure goal is not at the starting position
    return newGoal;
  }, [gridSize]);

  useEffect(() => {
    setGridSize(Math.min(5 + Math.floor(level / 2), 15));
    setGoal(generateRandomGoal());
    generateMaze();
    generateObstacles();
  }, [level, gridSize, generateMaze, generateObstacles, generateRandomGoal]);

  const moveObstacles = useCallback(() => {
    const moveEntity = (entity) => {
      const dx = Math.floor(Math.random() * 3) - 1;
      const dy = Math.floor(Math.random() * 3) - 1;
      const newX = Math.max(0, Math.min(gridSize - 1, entity.x + dx));
      const newY = Math.max(0, Math.min(gridSize - 1, entity.y + dy));
      return { x: newX, y: newY };
    };

    setHackers((prev) => prev.map(moveEntity));
    setForceCloses((prev) => prev.map(moveEntity));
  }, [gridSize]);

  useEffect(() => {
    let timer;
    if (gameStatus === 'playing') {
      timer = setInterval(() => {
        setTimeElapsed((prev) => {
          const newTime = prev + 1;
          if (newTime > TIME_PENALTY_THRESHOLD) {
            setTimePenalty((prevPenalty) => prevPenalty + TIME_PENALTY_AMOUNT);
            setBalance((prevBalance) => Math.max(0, prevBalance - TIME_PENALTY_AMOUNT));
          }
          return newTime;
        });
        if (timeElapsed % DIFFICULTY_INCREASE_INTERVAL === 0) {
          setDifficulty((prev) => prev + 1);
        }
        moveObstacles();
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [gameStatus, timeElapsed, moveObstacles]);

  const endGame = useCallback((won) => {
    setGameStatus(won ? 'won' : 'lost');
    const message = won
      ? `Congratulations! You've completed all levels. Total winnings: ${totalWinnings} credits.`
      : `Game Over. Total winnings: ${totalWinnings} credits.`;
    setMessage(message);
    showToast(
      won ? "Game Won!" : "Game Over",
      message,
      won ? "success" : "error"
    );
  }, [totalWinnings, showToast]);

  const movePlayer = useCallback((dx, dy) => {
    if (gameStatus !== 'playing') return;
  
    setPlayer((prev) => {
      const newX = Math.max(0, Math.min(gridSize - 1, prev.x + dx));
      const newY = Math.max(0, Math.min(gridSize - 1, prev.y + dy));
  
      const isProtected = maze[newY][newX] === 3 || (newX === goal.x && newY === goal.y);
  
      if (maze[newY][newX] !== 1 || isPowered) {
        setMovesCount((prevMoves) => {
          const newMoves = prevMoves + 1;
          if (newMoves > MOVE_PENALTY_THRESHOLD) {
            setMovePenalty((prevPenalty) => prevPenalty + MOVE_PENALTY_AMOUNT);
            setBalance((prevBalance) => Math.max(0, prevBalance - MOVE_PENALTY_AMOUNT));
          }
          return newMoves;
        });
        setBalance((prevBalance) => Math.max(0, prevBalance - MOVE_COST));
        setIsPowered(false);
  
        if (maze[newY][newX] === 2) {
          setBalance((prevBalance) => prevBalance + COIN_VALUE);
          showToast("Coin collected!", `You found ${COIN_VALUE} credits!`, "success");
          maze[newY][newX] = 0;
        } else if (maze[newY][newX] === 3) {
          const nodeKey = `${newX},${newY}`;
          if (!usedNodes.includes(nodeKey)) {
            setMiningProgress((prev) => Math.min(prev + 20, 100));
            setMovesCount((prevMoves) => Math.max(0, prevMoves - MOVES_BACK));
            setUsedNodes((prev) => [...prev, nodeKey]);
            showToast("Routing node found!", `${MOVES_BACK} moves returned.`, "success");
          } else {
            showToast("Used routing node", "This node has already been used.", "info");
          }
        }
        
        if (miningProgress === 100) {
          setBalance((prevBalance) => prevBalance + MINING_REWARD);
          setMiningProgress(0);
          showToast("Routing bonus!", `You earned ${MINING_REWARD} credits!`, "success");
        }
  
        if (newX === goal.x && newY === goal.y && !goalReachedToastShown) {
          showToast("Goal Reached!", "Collect remaining items or click the 'Complete Transaction' button to advance to the next level.", "success");
          setGoalReachedToastShown(true);
        }

        if (!isProtected) {
          [...hackers, ...forceCloses].forEach((obstacle) => {
            if (obstacle.x === newX && obstacle.y === newY) {
              const penalty = obstacle.hasOwnProperty('type') && obstacle.type === 'forceClose'
                ? FORCE_CLOSE_PENALTY
                : 50;
              setBalance((prevBalance) => Math.max(0, prevBalance - penalty));
              if (obstacle.hasOwnProperty('type') && obstacle.type === 'forceClose') {
                setForceCloseCount((prev) => prev + 1);
                if (forceCloseCount + 1 >= MAX_FORCE_CLOSES) {
                  endGame(false);
                }
              }
              showToast(
                obstacle.hasOwnProperty('type') && obstacle.type === 'forceClose' ? "Force Close!" : "Bad Actor Attack!",
                `You lost ${penalty} credits!`,
                "error"
              );
            }
          });
        }
  
        return { x: newX, y: newY };
      }
      return prev;
    });
  
    if (balance <= 0) {
      endGame(false);
    }
  }, [gridSize, maze, isPowered, miningProgress, hackers, forceCloses, balance, forceCloseCount, endGame, goal, gameStatus, showToast, goalReachedToastShown, usedNodes]);

  const handleKeyDown = useCallback((e) => {
    switch (e.key) {
      case 'ArrowUp': movePlayer(0, -1); break;
      case 'ArrowDown': movePlayer(0, 1); break;
      case 'ArrowLeft': movePlayer(-1, 0); break;
      case 'ArrowRight': movePlayer(1, 0); break;
      default: break;
    }
  }, [movePlayer]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  const openChannel = useCallback(() => {
    if (balance >= CHANNEL_COST) {
      setBalance((prev) => prev - CHANNEL_COST);
      setChannels((prev) => [...prev, { x: player.x, y: player.y }]);
      setIsPowered(true);
      showToast("Channel Opened", `You opened a Lightning channel at (${player.x}, ${player.y}). You can now cross one locked node!`, "success");
    } else {
      showToast("Insufficient Balance", "You don't have enough funds to open a channel", "error");
    }
  }, [player, balance, showToast]);

  const resetGame = useCallback((fullReset = true) => {
    setPlayer({ x: 0, y: 0 });
    setChannels([]);
    setGameStatus('playing');
    setMovesCount(0);
    setTimeElapsed(0);
    setIsPowered(false);
    setDifficulty(1);
    setMiningProgress(0);
    setTimePenalty(0);
    setMovePenalty(0);
    setForceCloseCount(0);
    setGoalReachedToastShown(false);
    setUsedNodes([]);
    setMessage(''); // Clear the message on reset
    if (fullReset) {
      setLevel(1);
      setGridSize(5);
      setBalance(INITIAL_BALANCE);
      setTotalWinnings(0);
    }
    generateMaze();
    generateObstacles();
  }, [generateMaze, generateObstacles]);

  const completeTransaction = useCallback(() => {
    if (player.x === goal.x && player.y === goal.y) {
      const baseReward = TRANSACTION_AMOUNT * difficulty;
      const totalPenalty = timePenalty + movePenalty;
      const finalReward = Math.max(0, baseReward - totalPenalty);

      setBalance((prev) => prev + finalReward);
      setTotalWinnings((prev) => prev + finalReward);
      setShowAchievementBadge(BADGE_CONFIGS.lightningMazeCompleted);
      setLevel((prev) => prev + 1);
      setGoalReachedToastShown(false);

      let message = `Level ${level} completed! You earned ${finalReward} credits.`;
      if (totalPenalty > 0) {
        message += ` (Penalties: ${totalPenalty} credits)`;
      }
      message += ` Starting level ${level + 1}...`;

      setMessage(message);

      showToast("Level Complete", message, "success");
      // Reset the game for the next level
      resetGame(false);

      // Clear the message after a short delay
      setTimeout(() => {
        setMessage('');
      }, 5000); // Clear message after 5 seconds
    } else {
      showToast("Transaction Failed", "You need to reach the destination node to complete the transaction", "error");
    }
  }, [player, goal, difficulty, level, timePenalty, movePenalty, resetGame, showToast]);




  const flashKeyframes = keyframes`
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
  `;

  const renderCell = (x, y) => {
    // Check if this is the player's position
    if (x === player.x && y === player.y) {
      return (
        <Box
          animation={isPowered ? `${flashKeyframes} 1s infinite` : 'none'}
          fontSize="24px"
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="relative"
        >
          <FontAwesomeIcon icon={faBoltLightning} color={isPowered ? "yellow" : "yellow"} />
          {/* If the player is on the goal, render the Bitcoin icon underneath */}
          {x === goal.x && y === goal.y && (
            <Box
              position="absolute"
              fontSize="28px"
              color="orange.500"
              width="100%"
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              opacity="0.5"  // Make it slightly transparent to show it's underneath
            >
              <FontAwesomeIcon icon={faBitcoin} />
            </Box>
          )}
        </Box>
      );
    }
    
    // If it's the goal cell and not the player's position, render only the Bitcoin icon
    if (x === goal.x && y === goal.y) {
      return (
        <Box
          fontSize="28px"
          color="orange.500"
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bg="transparent"
        >
          <FontAwesomeIcon icon={faBitcoin} />
        </Box>
      );
    }
  
    // For all other cells, render as before
    if (maze[y][x] === 1) return <FontAwesomeIcon icon={faLock} fontSize={18} />;
    if (maze[y][x] === 2) return <FontAwesomeIcon icon={faCoins} fontSize={18} />;
    if (maze[y][x] === 3) return <FontAwesomeIcon icon={faServer} fontSize={18} />;
    if (channels.some(channel => channel.x === x && channel.y === y)) return <FontAwesomeIcon icon={faUnlock} fontSize={18} />;
    if (hackers.some(hacker => hacker.x === x && hacker.y === y)) return <Box color="purple.500"><FontAwesomeIcon icon={faUserSecret} fontSize={18} /></Box>;
    if (forceCloses.some(forceClose => forceClose.x === x && forceClose.y === y)) return <Box color="red.500"><FontAwesomeIcon icon={faBan} fontSize={18} /></Box>;
    return '';
  };


  const bgColor = colorMode === 'dark' ? '#121212' : '#ffffff';
  const borderColor = colorMode === 'dark' ? '#ffffff' : '#121212';

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={modalSize}>
      <ModalOverlay backgroundColor="rgba(0, 0, 0, 0.95)" />
      <ModalContent
        bg={bgColor}
        pb={2}
        borderWidth="2px"
        borderStyle="solid"
        borderColor={borderColor}
        borderRadius="6px"
        overflow="hidden"
        maxHeight="95vh"
        maxWidth={["95vw", "90vw", "85vw"]}
        width="100%"
        display="flex"
        flexDirection="column"
      >
        <ModalHeader>
          <Flex align="center" justify="flex-start">
            <FontAwesomeIcon icon={faGamepad} style={{ marginRight: '10px' }} />
            <Text>NodeRunner</Text>
          </Flex>
          <Text fontSize="sm" fontWeight="normal" color="gray">
            Navigate the network and complete transactions!
          </Text>
        </ModalHeader>
        <GameInfoRoutingMan />
        <ModalCloseButton
          _hover={{
            bg: 'gray.500',
          }}
          _focus={{
            boxShadow: '0 0 0 1px rgba(66, 153, 225, 0.6)',
          }}
        />
        <ModalBody overflowY="auto" display="flex" flexDirection="column" flex="1" px={[2, 4]} py={2}>
          <VStack spacing={3} align="stretch" flex="1">
            <VStack spacing={[2, 2, 3]} align="stretch">
              {/* Arrow buttons */}
              <HStack spacing={1} justify="center" wrap="wrap">
              <Button onClick={() => movePlayer(-1, 0)} aria-label="Move Left" size="sm" p={1}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Button>
                <Button onClick={() => movePlayer(0, -1)} aria-label="Move Up" size="sm" p={1}>
                  <FontAwesomeIcon icon={faArrowUp} />
                </Button>
                <Button onClick={() => movePlayer(0, 1)} aria-label="Move Down" size="sm" p={1}>
                  <FontAwesomeIcon icon={faArrowDown} />
                </Button>
                
                <Button onClick={() => movePlayer(1, 0)} aria-label="Move Right" size="sm" p={1}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </HStack>

              {/* Action buttons */}
              <HStack spacing={1} justify="center" wrap="wrap">
                <Button
                  onClick={openChannel}
                  colorScheme="blue"
                  isDisabled={balance < CHANNEL_COST}
                  size="sm"
                  fontSize="sm"
                >
                  Open Channel ({CHANNEL_COST} credits)
                </Button>
                <Button
                  onClick={completeTransaction}
                  colorScheme="green"
                  isDisabled={!(player.x === goal.x && player.y === goal.y)}
                  size="sm"
                  fontSize="sm"
                >
                  Complete Transaction
                </Button>
              </HStack>
            </VStack>

            <Box
              width="100%"
              flex="1"
              minHeight="200px"
              position="relative"
              borderWidth="2px"
              borderRadius="lg"
              borderColor={'gray.500'}
              bg="black"
              overflow="hidden"
            >
              <Box
                position="absolute"
                top="0"
                left="0"
                right="0"
                bottom="0"
                display="grid"
                gridTemplateColumns={`repeat(${gridSize}, 1fr)`}
                gridTemplateRows={`repeat(${gridSize}, 1fr)`}
              >
                {maze.map((row, y) =>
                  row.map((cell, x) => (
                    <Box
                      key={`${x}-${y}`}
                      bg={x === goal.x && y === goal.y ? 'transparent' : (
                        cell === 1 ? 'gray.500' : (cell === 2 ? 'orange.500' : (cell === 3 ? 'blue.500' : 'transparent'))
                      )}
                      borderWidth="1px"
                      borderColor="gray.600"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      color="white"
                      fontSize={["8px", "10px", "12px"]}
                    >
                      {renderCell(x, y)}
                    </Box>
                  ))
                )}
              </Box>
            </Box>

            <Tooltip label="Mining Progress">
              <Progress value={miningProgress} width="100%" size="sm" />
            </Tooltip>

            <VStack spacing={2} align="stretch">
              <SimpleGrid columns={[2, 2, 3, 5]} spacing={2}>
                <FormControl>
                  <FormLabel fontSize="sm">Balance</FormLabel>
                  <InputGroup size="sm">
                    <InputLeftAddon>
                      <FontAwesomeIcon icon={faCoins} />
                    </InputLeftAddon>
                    <Input value={`${balance} credits`} isReadOnly borderWidth="1.5px" fontSize="sm" />
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="sm">Moves</FormLabel>
                  <Input value={movesCount} isReadOnly borderWidth="1.5px" fontSize="sm" size="sm" />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="sm">Time</FormLabel>
                  <Input value={`${timeElapsed}s`} isReadOnly borderWidth="1.5px" fontSize="sm" size="sm" />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="sm">Difficulty</FormLabel>
                  <Input value={difficulty} isReadOnly borderWidth="1.5px" fontSize="sm" size="sm" />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="sm">Level</FormLabel>
                  <Input value={level} isReadOnly borderWidth="1.5px" fontSize="sm" size="sm" />
                </FormControl>
              </SimpleGrid>
              <SimpleGrid columns={[2, 2, 3, 5]} spacing={2}>
                <FormControl>
                  <FormLabel fontSize="sm">Powered</FormLabel>
                  <Input value={isPowered ? "Yes" : "No"} isReadOnly borderWidth="1.5px" fontSize="sm" size="sm" />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="sm">Time Penalty</FormLabel>
                  <Input value={`${timePenalty} credits`} isReadOnly borderWidth="1.5px" fontSize="sm" size="sm" />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="sm">Move Penalty</FormLabel>
                  <Input value={`${movePenalty} credits`} isReadOnly borderWidth="1.5px" fontSize="sm" size="sm" />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="sm">Total Winnings</FormLabel>
                  <Input value={`${totalWinnings} credits`} isReadOnly borderWidth="1.5px" fontSize="sm" size="sm" />
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="sm">Force Closes</FormLabel>
                  <Input value={`${forceCloseCount} / ${MAX_FORCE_CLOSES}`} isReadOnly borderWidth="1.5px" fontSize="sm" size="sm" />
                </FormControl>
              </SimpleGrid>
            </VStack>

            <Button
              onClick={() => resetGame(true)}
              colorScheme="red"
              size="sm"
              width="120px"
            >
              <FontAwesomeIcon icon={faRefresh} /> &nbsp;Reset Game
            </Button>
            {gameStatus === 'completed' && (
              <Button onClick={() => resetGame(true)} colorScheme="orange" size="sm" fontSize="sm">
                New Game
              </Button>
            )}

            {message && (
              <Box borderWidth="2px" borderRadius="lg" p={2} borderColor={'gray.500'} bg="black" color="white">
                <Text fontSize="xs" fontWeight="bold" textAlign="center">
                  {message}
                </Text>
              </Box>
            )}

            <Text fontSize="10px" color="gray.500" textAlign="justify">
              <strong>Disclaimer:</strong>NodeRunner is a fictional game designed for entertainment and educational purposes only. It does not accurately represent real Lightning Network operations or economics. Game mechanics, including transaction routing, channel opening, and obstacle interactions, are greatly simplified. In-game "credits" have no real-world value. This simulation is not financial advice; do not apply video game strategies to real-world decisions. Actual use of the Lightning Network involves complex technical processes and potential risks not fully represented here. While the game introduces basic concepts, it should not be your sole source of information about the Lightning Network. No real transactions occur, and your device is not connected to any blockchain or payment network.
            </Text>
          </VStack>
        </ModalBody>
      </ModalContent>
      {showAchievementBadge && (
        <AchievementBadge
          onClose={() => setShowAchievementBadge(null)}
          badgeConfig={showAchievementBadge}
          downloadFileName="lightning_maze_badge.png"
          shareText="I completed the Lightning Network Maze Runner on TimechainStats.com! ⚡🏃‍♂️"
        />
      )}
    </Modal>
  );
};

export default LightningMazeRunner;