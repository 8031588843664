import React, { useState } from 'react';
import { Box, IconButton, Popover, PopoverTrigger, PopoverContent, PopoverBody, Text, VStack, useColorModeValue } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

const GameInfoBanHammer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const backgroundColor = useColorModeValue('gray.200', 'gray.500');

  const gameRules = [
    "• Click or drag to use the BanHammer!",
    "• Your mission: Try to stop Bitcoin's spread!",
    "• Orange cells represent Bitcoin users.",
    "• White cells represent fiat currency users.",
    "• Each cell has a chance of reverting to an adjacent color.",
    "• The probability of reverting decreases towards the center.",
    "• Cells can flip or revert before becoming permanently orange-pilled.",
    "• Adjust various parameters to simulate different adoption scenarios.",
    "• Educational purposes only! Does not predict real-world adoption rates.",
  ];

  return (
    <Box position="absolute" top="2" right="12">
      <Popover isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <PopoverTrigger>
          <IconButton
            icon={<FontAwesomeIcon icon={faQuestion} />}
            aria-label="Game Info"
            size="sm"
            onClick={() => setIsOpen(!isOpen)}
          />
        </PopoverTrigger>
        <PopoverContent mr={7} borderColor={'white'} borderWidth={2} p={2} pb={4} backgroundColor={backgroundColor}
        width="420px"  // Increased width
        maxWidth="90vw"  // Ensures it doesn't overflow on small screens
        >
          <PopoverBody>
            <VStack align="start" spacing={2}>
              <Text fontWeight="bold">Game Rules:</Text>
              {gameRules.map((rule, index) => (
                <Text key={index} fontSize="11px">{rule}</Text>
              ))}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default GameInfoBanHammer;