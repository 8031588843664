import { Button, Text, useColorMode } from '@chakra-ui/react';

export const ThemedNavbarButton = ({ children, filter, category, onClick, mr }) => {
  const { colorMode } = useColorMode();
  const textColor = colorMode === 'dark' ? 'gray.300' : 'gray.200';
  const activeColor = colorMode === 'dark' ? 'white.500' : 'blue.500';
  const borderColor = colorMode === 'dark' ? 'orange.500' : 'blue.500';

  return (
    <Button
      bg="transparent"
      color={filter === category ? activeColor : textColor}
      mr={mr}
      maxW={{base: '100%', xs: '100%', sm: '100%', 'sm2': '100%', md: 'initial', lg: 'initial', xl: 'initial', '2xl': 'initial'}}
    flexGrow={{ base: 1, md: 0 }}
      mb={0.5}
      fontWeight={'bold'}
      textTransform={'uppercase'}
      borderRadius="0"
      borderBottomWidth="4px"
      borderBottomColor={filter === category ? borderColor : 'transparent'}
      _hover={{ borderBottomColor: borderColor, color: activeColor }}
      onClick={onClick}
    >
      <Text fontSize="12px">{children}</Text>
    </Button>
  );
};