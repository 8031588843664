import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, useColorMode } from '@chakra-ui/react';
import TradingViewWidget from './TradingViewWidget';

export const TradingViewModal = ({ isOpen, onClose }) => {
  const { colorMode } = useColorMode();
  const bgColor = colorMode === 'dark' ? '#121212' : '#ffffff';

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay backgroundColor="rgba(16, 22, 26, 0.9)" />
      <ModalContent maxW="85%" h="calc(100% - 140px)" bg={bgColor} border="1px solid #444" borderRadius="6px" overflow="hidden">
        <ModalCloseButton
          zIndex={1}
          _hover={{
            bg: 'gray.500',
          }}
          _focus={{
            boxShadow: '0 0 0 1px rgba(66, 153, 225, 0.6)',
          }}
        />
        <ModalBody h="100%" p={{ base: "7px", md: "44px" }}>
          <TradingViewWidget />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
