import React, { useEffect, useState, useRef } from 'react';
import ApexCharts from 'apexcharts';
import { DataTableHeader } from './DataTableHeader';
import { DataTableFooter } from './DataTableFooter';
import { ThemedFlex } from '../../theme/ThemedFlex'
import { Box, useColorMode } from '@chakra-ui/react';
import { CustomSpinner } from './CustomSpinner';

export const PoolsTopTenTile = ({
  title,
  subtitle,
  icon,
  link,
  linkLabel = 'Learn More',
  data,
  uniqueChartId,
  formatNumber
}) => {
  const [chartData, setChartData] = useState([]);
  const chartRef = useRef(null);
  const { colorMode } = useColorMode();

  useEffect(() => {
    if (data && data.ts09 && data.ts09.pools && data.ts09.pools.pools) {
      const sortedData = data.ts09.pools.pools.sort((a, b) => b.blockCount - a.blockCount);
      const topTenPools = sortedData.slice(0, 10);
      const processedData = topTenPools.map(pool => ({
        y: pool.blockCount,
        x: pool.name,
      }));
      setChartData(processedData);
    }
  }, [data]);

  useEffect(() => {
    if (chartData.length > 0 && chartRef.current) {
      const options = {
        chart: {
          fontFamily: 'Ubuntu, sans-serif',
          type: 'bar',
          height: '100%',
          width: '100%',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            borderRadius: 4,
            dataLabels: {
              position: 'center',
            },
          },
        },
        grid: {
          borderColor: colorMode === 'dark' ? '#555555' : '#E5E5E5',
          position: 'back',
        },
        series: [
          {
            name: 'Block Count',
            data: chartData,
          },
        ],
        xaxis: {
          categories: chartData.map(data => data.x),
          labels: {
            style: {
              colors: colorMode === 'dark' ? '#999' : '#333',
            },
            formatter: (value) => {
              return formatNumber(value, 0);
            },
          },
        },
        yaxis: {
          opposite: true,
          tooltip: {
            enabled: false,
          },
          title: {
            text: 'Mining Pools',
            style: {
              color: colorMode === 'dark' ? '#999' : '#333',
            },
          },
          labels: {
            style: {
              colors: colorMode === 'dark' ? '#999' : '#333',
            },
          },
        },
        colors: ['#ffa500'],
        stroke: {
          width: 2,
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: [colorMode === 'dark' ? '#fff' : '#000'],
          },
          formatter: (value) => {
            return `${formatNumber(value)}`;
          },
        },
        tooltip: {
          theme: colorMode,
          y: {
            formatter: (value) => {
              return formatNumber(value);
            },
          },
        },
      };
      
      const chart = new ApexCharts(chartRef.current, options);
      chart.render();

      const handleResize = () => {
        chart.updateOptions({
          chart: {
            width: '100%',
            height: '100%',
          },
        });
      };

      window.addEventListener('resize', handleResize);

      return () => {
        chart.destroy();
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [chartData, formatNumber, colorMode]);

  if (!data || !data.ts09 || !data.ts09.pools || !data.ts09.pools.pools) {
    console.error("Error: Data is null, undefined, or incorrectly structured");
    return <CustomSpinner />;
  }

  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      style={{ minHeight: '337px', maxHeight: '100%' }}
    >
      <DataTableHeader title={title} subtitle={subtitle} icon={icon} />
      <Box flexGrow={1} minHeight='337px'>
        <Box ref={chartRef} className={`chart-container-${uniqueChartId}`} style={{ height: '100%' }} />
      </Box>
      <DataTableFooter link={link} linkLabel={linkLabel} />
    </ThemedFlex>
  );
};