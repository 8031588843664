import { useEffect, useState, useRef } from 'react';
import { Select, Box } from '@chakra-ui/react';
import ApexCharts from 'apexcharts';
import { DataTableHeader } from './DataTableHeader';
import { DataTableFooter } from './DataTableFooter';
import { ThemedFlex } from '../../theme/ThemedFlex';
import { CustomSpinner } from './CustomSpinner';

export const HashRateMempoolSizeTile = ({
  title,
  subtitle,
  icon,
  link,
  linkLabel = 'Learn More',
  data,
  uniqueChartId,
}) => {
  const [hashRateData, setHashRateData] = useState([]);
  const chartRef = useRef(null);
  const [mempoolSizeData, setMempoolSizeData] = useState([]);
  const [timeframe, setTimeframe] = useState('3y');
  const handleTimeframeChange = (event) => {
    setTimeframe(event.target.value);
  };

  useEffect(() => {
    if (data && data.ts04 && data.ts04.hashrate && data.ts13 && data.ts13.mempool_size) {
      const hashRateData = data.ts04.hashrate.hashrates.map(({ timestamp, avgHashrate }) => ({
        x: new Date(timestamp * 1000),
        y: avgHashrate / 1e18, // Convert to EH/s
      }));
    
      const mempoolSizeData = data.ts13.mempool_size.values.map(({ x, y }) => ({
        x: new Date(x * 1000),
        y: y / 1e6, // Convert to MB
      }));
      
      setMempoolSizeData(mempoolSizeData);
      const days = {
        '3y': 1095,
        '2y': 730,
        '1y': 365,
        '6m': 182,
        '3m': 91,
        '7d': 7,
      };

      const filteredHashRateData = hashRateData.filter((dataPoint) => {
        const now = new Date();
        const daysAgo = now.setDate(now.getDate() - days[timeframe]);
        return dataPoint.x >= daysAgo;
      });

      const filteredMempoolSizeData = mempoolSizeData.filter((dataPoint) => {
        const now = new Date();
        const daysAgo = now.setDate(now.getDate() - days[timeframe]);
        return dataPoint.x >= daysAgo;
      });

      setHashRateData(filteredHashRateData);
      setMempoolSizeData(filteredMempoolSizeData);
    }
  }, [data, timeframe]);



  useEffect(() => {
    if (hashRateData.length > 0 && mempoolSizeData.length > 0) {
      const options = {
        chart: {
          type: 'line',
          height: '100%',
          width: '100%',
          toolbar: {
            show: false,
          },
        },
        series: [
          {
            name: 'Bitcoin Hash Rate',
            data: hashRateData,
          },
          {
            name: 'Mempool Size',
            data: mempoolSizeData,
          },
        ],
        legend: {
          position: 'bottom',
          labels: {
            colors: '#999',
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            style: {
              colors: '#999',
            },
          },
        },
        yaxis: [
          {
            opposite: true,
            tooltip: {
              enabled: false,
            },
            title: {
              text: 'Hash Rate',
              style: {
                color: '#999',
              },
            },
            labels: {
              formatter: function (value) {
                return value.toFixed(0) + ' EH/s';
              },
              style: {
                colors: '#999',
              },
            },
          },
          {
            tooltip: {
              enabled: false,
            },
            title: {
              text: 'Transactions',
              style: {
                color: '#999',
              },
            },
            labels: {
              formatter: function (value) {
                return (value / 1e5).toFixed(0) + ' MB';
              },
              style: {
                colors: '#999',
              },
            },
          },
        ],
        colors: ["#f7931a", "#329239", "#0d579b", "#4d4d4d"],
        stroke: {
          width: 2,
        },
        tooltip: {
          theme: 'dark',
          style: {
            backgroundColor: '#000000',
            color: '#ffffff',
          },
          y: {
            formatter: (value, { seriesIndex }) => {
              if (seriesIndex === 0) {
                return value.toFixed(1) + ' EH/s';
              } else {
                return (value / 1e5).toFixed(2) + ' MB';
              }
            },
          },
        },
      };
      const chart = new ApexCharts(
        chartRef.current,
        options
      );
      chart.render();
      
      const handleResize = () => {
        chart.updateOptions({
          chart: {
            width: '100%',
            height: '100%',
          },
        });
      };

      window.addEventListener('resize', handleResize);

      
      return () => {
        chart.destroy();
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [hashRateData, mempoolSizeData]);

  if (!data) {
    if (data === null || data === undefined) {
      console.error("Error: Data is null or undefined");
    }

    return <CustomSpinner />;
  } else {
    return (
      <ThemedFlex
        direction="column"
        mb="12px"
        py="18px"
        px="20px"
        flex={1}
        borderRadius={3}
        style={{ minHeight: '337px', maxHeight: '100%' }}
      >
        <DataTableHeader title={title} subtitle={subtitle} icon={icon} />
        <Select
          value={timeframe}
          onChange={handleTimeframeChange}
          variant="filled"
          size="xs"
          alignSelf="flex-start"
          width="100px"
          mb={0}
        >
          <option value="3y">3 Years</option>
          <option value="2y">2 Years</option>
          <option value="1y">1 Year</option>
          <option value="6m">6 Months</option>
          <option value="3m">3 Months</option>
          <option value="7d">7 Day</option>
        </Select>
        <Box flexGrow={1} minHeight='337px'>
          <div ref={chartRef} className={`chart-container-${uniqueChartId}`} style={{ height: '100%' }} />

        </Box>
        <DataTableFooter link={link} linkLabel={linkLabel} />
      </ThemedFlex>
    );
  };
};
