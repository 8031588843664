import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { Select, Box } from '@chakra-ui/react';
import ApexCharts from 'apexcharts';
import { DataTableHeader } from './DataTableHeader';
import { DataTableFooter } from './DataTableFooter';
import { ThemedFlex } from '../../theme/ThemedFlex';
import { CustomSpinner } from './CustomSpinner';

export const HashRatePriceTile = React.memo(({
  title,
  subtitle,
  icon,
  link,
  linkLabel = 'Learn More',
  data,
  uniqueChartId,
  animate
}) => {
  const [chartData, setChartData] = useState({ hashRate: [], price: [] });
  const [timeframe, setTimeframe] = useState('3y');
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const handleTimeframeChange = useCallback((event) => {
    setTimeframe(event.target.value);
  }, []);

  const filterDataByTimeframe = useCallback((allData, timeframe) => {
    const days = {
      '3y': 1095, '2y': 730, '1y': 365, '6m': 180, '3m': 90, '7d': 7, '2d': 2,
    };
    const now = new Date();
    const daysAgo = now.setDate(now.getDate() - days[timeframe]);
    return allData.filter((dataPoint) => dataPoint.x >= daysAgo);
  }, []);

  const processChartData = useCallback(() => {
    if (data && data.ts04 && data.ts04.hashrate && data.ts15 && data.ts15.price) {
      const allHashRateData = data.ts04.hashrate.hashrates.map(({ timestamp, avgHashrate }) => ({
        x: new Date(timestamp * 1000),
        y: avgHashrate / 1e18,
      }));

      const allPriceData = data.ts15.price.values.map(({ x, y }) => ({
        x: new Date(x * 1000),
        y: y,
      }));

      const filteredHashRateData = filterDataByTimeframe(allHashRateData, timeframe);
      const filteredPriceData = filterDataByTimeframe(allPriceData, timeframe);

      return { hashRate: filteredHashRateData, price: filteredPriceData };
    }
    return { hashRate: [], price: [] };
  }, [data, timeframe, filterDataByTimeframe]);

  useEffect(() => {
    const newChartData = processChartData();
    setChartData(newChartData);
  }, [processChartData]);

  const chartOptions = useMemo(() => {
    return {
      chart: {
        offsetX: -12,
        fontFamily: 'Ubuntu, sans-serif',
        type: 'line',
        height: '100%',
        width: '100%',
        toolbar: {
          offsetY: -10,
          tools: { download: false },
          padding: { bottom: 20 },
          color: '#78909C',
        },
      },
      grid: {
        borderColor: '#555555',
        position: 'back',
        padding: { top: 0, right: -10, bottom: 0, left: 10 },
      },
      series: [
        { name: 'Bitcoin Total Hash Rate (EH/s)', data: chartData.hashRate },
        { name: 'BTC~USD Price', data: chartData.price },
      ],
      legend: {
        position: 'bottom',
        labels: { colors: '#999' },
      },
      xaxis: {
        type: 'datetime',
        labels: { style: { colors: '#999' } },
      },
      yaxis: [
        {
          type: 'logarithmic',
          opposite: true,
          tooltip: { enabled: false },
          title: { text: 'Hash Rate (EH/s)', style: { color: '#999' } },
          labels: {
            formatter: (value) => new Intl.NumberFormat('en-US', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(value),
            style: { colors: '#999' },
            offsetX: -20,
          },
        },
        {
          type: 'logarithmic',
          tooltip: { enabled: false },
          title: { text: 'BTC~USD Price', style: { color: '#999' } },
          labels: {
            formatter: (value) => '$' + (value / 1000).toFixed(0) + 'k',
            style: { colors: '#999' },
          },
        },
      ],
      colors: ["#f7931a", "#329239", "#0d579b", "#4d4d4d"],
      stroke: { width: 2 },
      tooltip: {
        theme: 'dark',
        style: { backgroundColor: '#000000', color: '#ffffff' },
        y: {
          formatter: (value, { seriesIndex }) => {
            const formattedValue = new Intl.NumberFormat('en-US', {
              minimumFractionDigits: value < 1000 ? 2 : 0,
              maximumFractionDigits: 2,
            }).format(value);
            return seriesIndex === 1 ? '$' + formattedValue : formattedValue;
          },
        },
      },
    };
  }, [chartData]);

  useEffect(() => {
    if (chartData.hashRate.length > 0 && chartData.price.length > 0 && chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.updateOptions(chartOptions);
      } else {
        chartInstance.current = new ApexCharts(chartRef.current, chartOptions);
        chartInstance.current.render();
      }
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
        chartInstance.current = null;
      }
    };
  }, [chartOptions, chartData.hashRate.length, chartData.price.length]);

  if (!data) {
    if (data === null || data === undefined) {
      console.error("Error: Data is null or undefined");
    }
    return <CustomSpinner />;
  }

  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      style={{ minHeight: '337px', maxHeight: '100%', width: '100%' }}
      className={animate ? 'gray-border-custom gray-border' : ''}
    >
      <DataTableHeader title={title} subtitle={subtitle} icon={icon} />
      <Select
        value={timeframe}
        onChange={handleTimeframeChange}
        variant="filled"
        size="xs"
        alignSelf="flex-start"
        width="100px"
        mb={0}
      >
        <option value="3y">3 Years</option>
        <option value="2y">2 Years</option>
        <option value="1y">1 Year</option>
        <option value="6m">6 Months</option>
        <option value="3m">3 Months</option>
        <option value="7d">7 Day</option>
        <option value="2d">2 Day</option>
      </Select>
      <Box flexGrow={1} minHeight='337px'>
        <div ref={chartRef} className={`chart-container-${uniqueChartId}`} style={{ height: '100%', width: '100%' }} />
      </Box>
      <DataTableFooter link={link} linkLabel={linkLabel} />
    </ThemedFlex>
  );
});