import { Flex, Text, useColorMode } from '@chakra-ui/react';

export const FooterBigText = ({ children, ...rest }) => {
  const { colorMode } = useColorMode();
  const textColor = colorMode === 'dark' ? 'white.500' : 'primary.700';

  return (
    <Text fontWeight="700" fontSize="16px" lineHeight="16px" mb={5} ml={2} color={textColor} {...rest}>
      {children}
    </Text>
  );
};

export const FinePrintText = ({ children, ...rest }) => {
  const { colorMode } = useColorMode();
  const textColor = colorMode === 'dark' ? 'gray.500' : 'gray.500';

  return (
    <Text fontSize="xs" lineHeight="14px" mb={2} color={textColor} {...rest}>
      {children}
    </Text>
  );
};

export const FooterStatus = ({ children, ...rest }) => {
  const { colorMode } = useColorMode();
  const borderColor = colorMode === 'dark' ? 'gray.500' : 'gray.700';
  const textColor = colorMode === 'dark' ? 'gray.500' : 'gray.700';

  return (
    <Flex
      px="10px"
      mb={0}
      borderLeftWidth="1px"
      borderColor={borderColor}
      direction="column"
      justify="center"
      {...rest}
      _first={{
        borderLeft: 'none',
      }}
    >
      <Text fontSize="xs" fontWeight="700" color={textColor}>
        {children}
      </Text>
    </Flex>
  );
};
