import React, { useState } from 'react';
import { DataTableHeader } from './DataTableHeader';
import { ThemedFlex } from '../../theme/ThemedFlex';
import { Text, useColorMode, useBreakpointValue } from '@chakra-ui/react';

export const TradingViewChart2 = (
    {
        title,
        subtitle,
        icon,
    }
) => {
    const [symbol, setSymbol] = useState('NASDAQ:AAPL/BITSTAMP:BTCUSD');

    const { colorMode } = useColorMode();
    const themeColor = colorMode === 'dark' ? 'dark' : 'light';

    const iframeHeight = useBreakpointValue({ base: '400px', md: '630px' });

    const containerStyle = {
        width: '100%',
        height: '100%',
    };

    const dropdownStyle = {
        marginTop: '7px',
        marginBottom: '20px',
        padding: '7px',
        borderRadius: '3px',
        fontSize: '12px',
    };

    const handleSelectChange = (e) => {
        setSymbol(e.target.value);
    }

    return (
        <ThemedFlex
            direction="column"
            mb="12px"
            py="18px"
            px="20px"
            flex={1}
            borderRadius={3}
        >
            <div style={containerStyle}>
                <DataTableHeader title={title} subtitle={subtitle} icon={icon} />

                <select style={dropdownStyle} onChange={handleSelectChange}>
                    <option value="NASDAQ:AAPL/BITSTAMP:BTCUSD">Apple vs BTC</option>
                    <option value="NASDAQ:AMZN/BITSTAMP:BTCUSD">Amazon vs BTC</option>
                    <option value="NYSE:AMC/BITSTAMP:BTCUSD">AMC Entertainment vs BTC</option>
                    <option value="NYSE:AXP/BITSTAMP:BTCUSD">American Express vs BTC</option>
                    <option value="NASDAQ:AMGN/BITSTAMP:BTCUSD">Amgen vs BTC</option>
                    <option value="NYSE:BAC/BITSTAMP:BTCUSD">Bank of America vs BTC</option>
                    <option value="NYSE:BRK.A/BITSTAMP:BTCUSD">Berkshire Hathaway vs BTC</option>
                    <option value="NYSE:BA/BITSTAMP:BTCUSD">Boeing vs BTC</option>
                    <option value="NASDAQ:CSCO/BITSTAMP:BTCUSD">Cisco Systems vs BTC</option>
                    <option value="NYSE:KO/BITSTAMP:BTCUSD">Coca-Cola vs BTC</option>
                    <option value="NYSE:DOW/BITSTAMP:BTCUSD">Dow Inc vs BTC</option>
                    <option value="NASDAQ:EBAY/BITSTAMP:BTCUSD">Ebay vs BTC</option>
                    <option value="AMEX:GBTC/BITSTAMP:BTCUSD">GBTC vs BTC</option>
                    <option value="NYSE:GME/BITSTAMP:BTCUSD">GameStop vs BTC</option>
                    <option value="NYSE:GS/BITSTAMP:BTCUSD">Goldman Sachs vs BTC</option>
                    <option value="NASDAQ:GOOG/BITSTAMP:BTCUSD">Google vs BTC</option>
                    <option value="NYSE:HD/BITSTAMP:BTCUSD">Home Depot vs BTC</option>
                    <option value="NASDAQ:HUT/BITSTAMP:BTCUSD">Hut 8 vs BTC</option>
                    <option value="NYSE:IBM/BITSTAMP:BTCUSD">IBM vs BTC</option>
                    <option value="NASDAQ:INTC/BITSTAMP:BTCUSD">Intel vs BTC</option>
                    <option value="NYSE:JNJ/BITSTAMP:BTCUSD">Johnson & Johnson vs BTC</option>
                    <option value="NYSE:JPM/BITSTAMP:BTCUSD">JPMorgan Chase vs BTC</option>
                    <option value="NASDAQ:TLT/INDEX:BTCUSD">Long Term Treasuries vs BTC</option>
                    <option value="NASDAQ:MARA/BITSTAMP:BTCUSD">Marathon vs BTC</option>
                    <option value="NYSE:MA/BITSTAMP:BTCUSD">MasterCard vs BTC</option>
                    <option value="NYSE:MCD/BITSTAMP:BTCUSD">McDonald's vs BTC</option>
                    <option value="NYSE:MRK/BITSTAMP:BTCUSD">Merck vs BTC</option>
                    <option value="NASDAQ:META/BITSTAMP:BTCUSD">Meta (Facebook) vs BTC</option>
                    <option value="NASDAQ:MSFT/BITSTAMP:BTCUSD">Microsoft vs BTC</option>
                    <option value="NASDAQ:MSTR/BITSTAMP:BTCUSD">MicroStrategy vs BTC</option>
                    <option value="NYSE:NKE/BITSTAMP:BTCUSD">Nike vs BTC</option>
                    <option value="NYSE:NYT/BITSTAMP:BTCUSD">New York Times vs BTC</option>
                    <option value="NASDAQ:NVDA/BITSTAMP:BTCUSD">Nvidia vs BTC</option>
                    <option value="NYSE:PG/BITSTAMP:BTCUSD">Procter & Gamble vs BTC</option>
                    <option value="NASDAQ:RIOT/BITSTAMP:BTCUSD">Riot vs BTC</option>
                    <option value="NYSE:CRM/BITSTAMP:BTCUSD">Salesforce vs BTC</option>
                    <option value="GLD/BITSTAMP:BTCUSD">SPDR Gold Trust (GLD) vs BTC</option>
                    <option value="NYSE:TRV/BITSTAMP:BTCUSD">The Travelers Companies vs BTC</option>
                    <option value="NYSE:UNH/BITSTAMP:BTCUSD">UnitedHealth Group vs BTC</option>
                    <option value="NYSE:VZ/BITSTAMP:BTCUSD">Verizon vs BTC</option>
                    <option value="NYSE:V/BITSTAMP:BTCUSD">Visa vs BTC</option>
                    <option value="NASDAQ:WBA/BITSTAMP:BTCUSD">Walgreens Boots Alliance vs BTC</option>
                    <option value="NYSE:WMT/BITSTAMP:BTCUSD">Walmart vs BTC</option>
                    <option value="NYSE:DIS/BITSTAMP:BTCUSD">Walt Disney vs BTC</option>
                    <option value="NYSE:WFC/BITSTAMP:BTCUSD">Wells Fargo Bank vs BTC</option>
                    {/* Add more options here */}
                </select>

                <Text
                    verticalAlign={'middle'}
                    mb={'3'}
                    fontSize={'12px'}
                    color={'gray.700'}
                    float={'right'}
                >
                    Select a stock and see the performance vs Bitcoin over time using a 1:1 ratio.
                    Ex: If a stock = 1 BTC, then the ratio is 1.
                </Text>

                <div>
                    <iframe
                        title="TradingView Chart"
                        src={`https://www.tradingview.com/widgetembed/?frameElementId=tradingview_7882b&symbol=${symbol}&interval=W&symboledit=0&saveimage=1&toolbarbg=f1f3f6&studies=%5B%22BollingerBands%40tv-basicstudies%22%5D&theme=${themeColor}&style=2&timezone=Etc%2FUTC&withdateranges=1&hideideas=1&studies_overrides=%7B%7D&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&locale=en&utm_source=localhost&utm_medium=widget&utm_campaign=chart&utm_term=${symbol}`}
                        width="100%"
                        height={iframeHeight}
                        frameBorder="0"
                        allowtransparency="true"
                        scrolling="no"
                        allowfullscreen=""
                    ></iframe>
                    <Text
                        mt={2}
                        fontSize={'10px'}
                        color={'gray.700'}
                    >
                        Educational purposes only. Not for financial decisions.
                    </Text>
                </div>
            </div>
        </ThemedFlex>
    );
};

