// src/components/dashboard/tables/BitcoinNetworkTable.jsx

import React from 'react';
import { DataTable } from '../../data-display/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBitcoinSign } from '@fortawesome/free-solid-svg-icons';
import { formatNumber, formatLargeNumber } from '../../../utils';

export const BitcoinNetworkTable = ({ 
  data, 
  bitcoinPrice, 
  conversionRates, 
  selectedCurrency, 
  animate,
  onOpenLexicon,
  mempoolInfo 
}) => {
  const tableData = data && bitcoinPrice && conversionRates && selectedCurrency && mempoolInfo
    ? [
        {
            label: 'Timechain Explorer',
            value: 'https://mempool.space/',
            displayValue: 'Mempool.space',
            type: 'link',
            tooltipText: "To provide some basic terms, a block explorer is a timechain search engine that allows you to search for a particular piece of information on the timechain. The activities carried out on bitcoin's timechain are known as transactions, which occur when sats are sent to and from wallet addresses.",
            clickableTerm: "Block Explorer",
        },
        {
            label: 'New Blocks (24h)',
            value: data?.s12?.metrics?.data?.blockchain_stats_24_hours?.count_of_blocks_added 
              ? `${formatNumber(data.s12.metrics.data.blockchain_stats_24_hours.count_of_blocks_added)} Blocks`
              : "Loading...",
            tooltipText: "The count of new blocks added to the timechain in the last 24 hours.",
            clickableTerm: "Block",
        },
        {
            label: 'Active Addresses (24h)',
            value: data?.s12?.metrics?.data?.on_chain_data?.active_addresses 
              ? formatNumber(data.s12.metrics.data.on_chain_data.active_addresses, 0)
              : "Loading...",
            tooltipText: "Number of unique addresses that were active in the network (either as a sender or receiver) in the last 24 hours",
            clickableTerm: "Active Address",
        },
        {
            label: 'New Addresses (24h)',
            value: data?.s12?.metrics?.data?.on_chain_data?.addresses_count 
              ? formatNumber(data.s12.metrics.data.on_chain_data.addresses_count, 0)
              : "Loading...",
            tooltipText: "Number of new addresses created in the last 24 hours",
            clickableTerm: "New Address",
        },
        {
            label: 'UTXO Count',
            value: data?.s12?.metrics?.data?.on_chain_data?.utxo_count_last_24_hours
              ? `${formatLargeNumber(data.s12.metrics.data.on_chain_data.utxo_count_last_24_hours, 2, false)} UTXOs`
              : "Loading...",
            tooltipText: "The total number of valid unspent transaction outputs (UTXOs).",
            clickableTerm: "UTXO Count",
        },
        {
            label: 'Avg UTXO Age',
            value: data?.s12?.metrics?.data?.on_chain_data?.average_utxo_age
              ? `${formatNumber(data.s12.metrics.data.on_chain_data.average_utxo_age / 365, 1)}y (${formatNumber(data.s12.metrics.data.on_chain_data.average_utxo_age, 0)}d)`
              : "Loading...",
            tooltipText: "The average age of a UTXO since its creation, in days.",
            clickableTerm: "UTXO",
        },
        {
            label: 'Timechain Reachable?',
            value: 'TRUE',
            tooltipText: "Checks the Bitcoin timechain uptime. Timechain is a distributed database existing on multiple computers at the same time. It is constantly growing as new sets of recordings, or 'blocks', are added to it. Each block contains a timestamp and a link to the previous block, so they actually form a chain.",
            clickableTerm: "Timechain",
        },
        {
            label: 'Miners Active?',
            value: 'TRUE',
            tooltipText: "Bitcoin mining is the process of creating new bitcoin by solving a computational puzzle. Bitcoin mining is necessary to maintain the ledger of transactions upon which bitcoin is based.",
            clickableTerm: "Mining",
        },
        {
            label: 'Nodes Found',
            value: data?.s01?.latest_snapshot 
              ? `${formatNumber(data.s01.latest_snapshot.total_nodes, 0)} Nodes`
              : "Loading...",
            tooltipText: "A node is a computer connected to other computers which follows rules and shares information. A 'full node' is a computer in Bitcoin's peer-to-peer network which hosts and synchronises a copy of the entire Bitcoin timechain.",
            clickableTerm: "Node",
        },
        {
            label: 'Lightning Channels',
            value: data?.s11?.lightning?.latest 
              ? `${formatNumber(data.s11.lightning.latest.channel_count, 0)} Channels`
              : "Loading...",
            tooltipText: "Number of Lightning channels found on the Lightning network.",
            clickableTerm: "Lightning Channel",
        },
        {
            label: 'Mempool Transactions',
            value: mempoolInfo ? formatNumber(mempoolInfo.size, 0) : "Loading...",
            tooltipText: "The current number of unconfirmed transactions in the mempool.",
            clickableTerm: "Mempool Transaction",
        },
        {
            label: 'Mempool Size',
            value: mempoolInfo ? `${formatNumber(mempoolInfo.usage / 1000000, 2)} MB / ${formatNumber(mempoolInfo.maxmempool / 1000000, 0)} MB` : "Loading...",
            tooltipText: "The current size of the mempool in megabytes.",
            clickableTerm: "Mempool Size",
        }
    ]
    : [];

  return (
    <DataTable
      title="Bitcoin Network"
      subtitle="Information about the BTC network"
      icon={<FontAwesomeIcon icon={faBitcoinSign} />}
      rows={tableData}
      animate={animate}
      specificTerm="Bitcoin Network"
      onOpenLexicon={onOpenLexicon}
      rowsPerPage={9}
    />
  );
};