import React, { useState, useEffect } from 'react';
import {
    Text,
    Center,
    useBreakpointValue,
    Input,
    Button,
    Link,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './BtcPayForm.css';
import { faHeart, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';

export function BtcPayForm({ onClose }) {
    const width = useBreakpointValue({ base: '100%', md: '168px' });
    const textWidth = useBreakpointValue({ base: '100%', md: '70%' });

    const [price, setPrice] = useState(1);
    const [twitterHandle, setTwitterHandle] = useState('');
    const [showDMLink, setShowDMLink] = useState(false);

    const handleSliderChange = (event) => {
        setPrice(parseInt(event.target.value));
    };

    const handlePriceChange = (event) => {
        let newPrice = parseInt(event.target.value.replace(/,/g, ''));
        if (isNaN(newPrice)) {
            newPrice = 1;
        } else if (newPrice < 1) {
            newPrice = 1;
        } else if (newPrice > 500000000) {
            newPrice = 500000000;
        }
        setPrice(newPrice);
    };

    const handleTwitterHandleChange = (event) => {
        setTwitterHandle(event.target.value);
    };

    const formatPrice = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    useEffect(() => {
        setShowDMLink(price > 249999 && twitterHandle.trim() !== '');
    }, [price, twitterHandle]);

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        
        // Proceed with BTCPay Server donation process
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
            if (this.readyState === 4 && this.status === 200 && this.responseText) {
                window.btcpay.appendInvoiceFrame(JSON.parse(this.responseText).invoiceId);
                onClose();
            }
        };
        xhttp.open('POST', event.target.getAttribute('action'), true);
        xhttp.send(new FormData(event.target));
    };

    useEffect(() => {
        if (!window.btcpay) {
            var script = document.createElement('script');
            script.src = "https://btcpay609804.lndyn.com/modal/btcpay.js";
            document.getElementsByTagName('head')[0].append(script);
        }
    }, []);

    const renderHearts = () => {
        const heartLevels = [
            { limit: 249999, hearts: 1 },
            { limit: 499999, hearts: 2 },
            { limit: 4999999, hearts: 3 },
            { limit: 99999999, hearts: 4 },
            { limit: 100000000, hearts: 5 },
            { limit: 500000000, hearts: 5 }
        ];

        let heartCount = 0;
        for (const level of heartLevels) {
            if (price <= level.limit) {
                heartCount = level.hearts;
                break;
            }
        }

        return (
            <>
                {Array(heartCount).fill(null).map((_, index) => (
                    <FontAwesomeIcon key={index} icon={faHeart} color={'orange'} />
                ))}
            </>
        );
    };

    return (
        <form method="POST" action="https://btcpay609804.lndyn.com/api/v1/invoices" className="btcpay-form btcpay-form--block" onSubmit={handleFormSubmit}>
            <input type="hidden" name="storeId" value="5R4jnTVxzbQgoN9WWQX2A99K7b1A8qMuS4A2hFz7dxfq" />
            <input type="hidden" name="jsonResponse" value="true" />
            <input type="hidden" name="checkoutDesc" value="TimechainStats Tips" />
            <input type="hidden" name="currency" value="SATS" />
            <div className="btcpay-custom-container">
                <input
                    className="btcpay-input-price"
                    type="text"
                    name="price"
                    min="1"
                    max="500000000"
                    step="1"
                    value={formatPrice(price)}
                    onChange={handlePriceChange}
                    style={{ width }}
                />
                <p style={{ fontSize: '12px', textAlign: 'center', marginTop: '3px' }}>SATS</p>
                <input
                    type="range"
                    className="btcpay-input-range"
                    min="1"
                    max="500000"
                    step="1"
                    value={price}
                    onChange={handleSliderChange}
                    style={{ width, marginBottom: '15px' }}
                />

                <Center mt={1} mb={2} flexDirection="column" gap={2}>
                    <Text fontSize={'sm'} width={textWidth}>Thank you for your support! {renderHearts()}</Text>
                </Center>

                {price > 249999 && (
                    <Center mt={1} mb={2} flexDirection="column" gap={2}>
                        <Text fontSize={'xs'} width={textWidth}>Optional: Enter your Twitter handle to be added as a Supporter</Text>
                        <Input
                            value={twitterHandle}
                            onChange={handleTwitterHandleChange}
                            placeholder="@YourTwitterHandle"
                            width={textWidth}
                        />
                    </Center>
                )}

                {showDMLink && (
                    <Center mt={1} mb={2} flexDirection="column" gap={2}>
                        <Text fontSize={'sm'} width={textWidth}></Text>
                        <Link href={`https://twitter.com/messages/compose?recipient_id=940362018929901568&text=Hi, I just donated ${formatPrice(price)} sats to TimechainStats!`} isExternal>
                            <Button colorScheme="twitter" leftIcon={<FontAwesomeIcon icon={faTwitter} />}>
                                Send Twitter DM
                            </Button>
                        </Link>
                    </Center>
                )}
            </div>
            <button type="submit" className="submit" name="submit" style={{ minWidth: '168px', minHeight: '46px', backgroundColor: '#0f3b21', cursor: 'pointer' }} title="Pay with BTCPay Server, a Self-Hosted Bitcoin Payment Processor">
                <span style={{ color: '#fff', fontSize: '16px' }}> <FontAwesomeIcon icon={faQrcode} /> &nbsp;Request Invoice</span>
            </button>
        </form>
    );
}