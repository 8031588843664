import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useColorMode, useToken } from "@chakra-ui/react";

export const ThemedIcon = (props) => {
  const { colorMode } = useColorMode();
  const [white500, primary700] = useToken("colors", ["white.500", "primary.700"]);
  const iconColor = colorMode === "dark" ? white500 : primary700;

  return <FontAwesomeIcon {...props} color={iconColor} />;
};
