import React from 'react';
import { DataTable } from '../../data-display/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { formatNumber, formatLargeNumber } from '../../../utils';
import { currencySymbols } from '../../../utils/currencyConversion.utils';

export const MempoolTable = ({ 
  data, 
  bitcoinPrice, 
  conversionRates, 
  selectedCurrency, 
  animate,
  onOpenLexicon,
  mempoolInfo,
  fees
}) => {
  const tableData = data && bitcoinPrice && conversionRates && selectedCurrency && mempoolInfo && fees
    ? [
        {
            label: 'Mempool Size',
            value: mempoolInfo ? `${formatLargeNumber(mempoolInfo.usage, 2, true)}B / 300 MB` : "Loading...",
            tooltipText: "Current size of the mempool in megabytes",
            clickableTerm: "Mempool Size",
        },
        {
            label: 'Mempool Usage',
            value: mempoolInfo ? `${formatNumber((mempoolInfo.usage / mempoolInfo.maxmempool) * 100, 2)}%` : "Loading...",
            tooltipText: "The current mempool usage as a percentage of the maximum allowed size",
            clickableTerm: "Mempool Usage",
        },
        {
            label: 'Mempool Transactions',
            value: mempoolInfo ? `${formatNumber(mempoolInfo.size, 0)} TX` : "Loading...",
            tooltipText: "Number of unconfirmed transactions in the mempool",
            clickableTerm: "Mempool Transaction",
        },
        {
            label: 'Mempool Fees BTC',
            value: mempoolInfo ? `${formatNumber(mempoolInfo.total_fee, 8)} BTC` : "Loading...",
            tooltipText: "Total fees in the mempool in BTC",
            clickableTerm: "Mempool Fees",
        },
        {
            label: 'Value of Mempool Fees',
            value: mempoolInfo ? `${currencySymbols[selectedCurrency]}${formatNumber((bitcoinPrice * conversionRates[selectedCurrency] * mempoolInfo.total_fee), 2)}` : "Loading...",
            tooltipText: "Total value of fees in the mempool",
            clickableTerm: "Mempool Fees",
        },
        {
            label: 'Mempool Minimum Fee',
            value: mempoolInfo ? `${formatNumber(mempoolInfo.mempoolminfee, 8)} BTC` : "Loading...",
            tooltipText: "Minimum fee in the mempool",
            clickableTerm: "Mempool Minimum Fee",
        },
        {
            label: 'Incremental Relay Fee',
            value: mempoolInfo ? `${formatNumber(mempoolInfo.incrementalrelayfee * 100000000, 0)} sats/vB` : "Loading...",
            tooltipText: "The minimum fee rate increment for replacement transactions in sats/vB",
            clickableTerm: "Incremental Relay Fee",
        },
        {
            label: 'Minimum Relay TX Fee',
            value: mempoolInfo ? `${formatNumber(mempoolInfo.minrelaytxfee * 100000000, 0)} sats/vB` : "Loading...",
            tooltipText: "The minimum fee rate for relay of transactions in sats/vB",
            clickableTerm: "Minimum Relay TX Fee",
        },
        {
            label: 'High Fee per vByte',
            value: fees ? `${formatNumber((fees.fastestFee), 0)} Sats/vByte` : "Loading...",
            tooltipText: 'Recommended High Fee per vByte',
            clickableTerm: "Transaction Fee",
        },
        {
            label: 'Medium Fee per vByte',
            value: fees ? `${formatNumber((fees.hourFee), 0)} Sats/vByte` : "Loading...",
            tooltipText: 'Recommended Medium Fee per vByte',
            clickableTerm: "Transaction Fee",
        },
        {
            label: 'Low Fee per vByte',
            value: fees ? `${formatNumber((fees.minimumFee), 0)} Sats/vByte` : "Loading...",
            tooltipText: 'Recommended Low Fee per vByte',
            clickableTerm: "Transaction Fee",
        },
        {
            label: 'Mempool TPS',
            value: data?.s03?.bitcoin_stats?.bitcoin_data?.mempool_tps != null 
              ? `~${formatNumber(data.s03.bitcoin_stats.bitcoin_data.mempool_tps, 1)} TX/s` 
              : "Loading...",
            tooltipText: "Transactions per second entering the mempool",
            clickableTerm: "Mempool TPS",
        }
    ]
    : [];

  return (
    <DataTable
      title="Bitcoin Mempool"
      subtitle="Memory pool transaction metrics"
      icon={<FontAwesomeIcon icon={faClockRotateLeft} />}
      rows={tableData}
      animate={animate}
      specificTerm="Mempool"
      onOpenLexicon={onOpenLexicon}
      rowsPerPage={9}
    />
  );
};