import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  SimpleGrid,
  Box,
  useColorMode,
  useBreakpointValue,
  Flex,
  Badge,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFutbol,
  faPlane,
  faShip,
  faHome,
  faCar,
  faPaintBrush,
  faStopwatch
} from '@fortawesome/free-solid-svg-icons';
import { faBitcoin } from '@fortawesome/free-brands-svg-icons';

const iconMap = {
  "Football Team": faFutbol,
  "Private Jet": faPlane,
  "Luxury Yacht": faShip,
  "Mansion": faHome,
  "Sports Car": faCar,
  "Fine Art Piece": faPaintBrush,
  "Designer Watch": faStopwatch,
  "Bitcoin Stack": faBitcoin,
};

const CollectedItems = ({ isOpen, onClose, items }) => {
  const { colorMode } = useColorMode();
  const bgColor = colorMode === 'dark' ? '#121212' : '#ffffff';
  const color = colorMode === 'dark' ? 'white' : '#121212';
  const borderColor = colorMode === 'dark' ? '#ffffff' : '#121212';
  const columns = useBreakpointValue({ base: 2, sm: 3, md: 4 });

  // Group items by name and count occurrences
  const itemCounts = items.reduce((acc, item) => {
    acc[item.name] = (acc[item.name] || 0) + 1;
    return acc;
  }, {});

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent bg={bgColor} color={color} borderWidth="2px" borderStyle="solid" borderColor={borderColor} borderRadius="6px" mt={5} pb={5}>
        <ModalHeader>Your Luxury Collection</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {Object.keys(itemCounts).length === 0 ? (
            <Text>You haven't collected any luxury items yet. Spend some credits to start your collection!</Text>
          ) : (
            <SimpleGrid columns={columns} spacing={4}>
              {Object.entries(itemCounts).map(([itemName, count], index) => (
                <Box key={index} textAlign="center">
                  <Flex justifyContent="center" alignItems="center" position="relative">
                    <FontAwesomeIcon
                      icon={iconMap[itemName] || faBitcoin}
                      size="3x"
                      style={{ marginBottom: '0.5rem' }}
                    />
                    {count > 1 && (
                      <Badge 
                        colorScheme="green" 
                        borderRadius="full" 
                        px="2" 
                        position="absolute" 
                        top="-8px" 
                        right="-8px"
                      >
                        {count}
                      </Badge>
                    )}
                  </Flex>
                  <Text fontSize="sm">{itemName}</Text>
                </Box>
              ))}
            </SimpleGrid>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CollectedItems;