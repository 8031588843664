import { Button, Text, useColorMode } from '@chakra-ui/react';

export const CurrencyModalButton = ({ children, onClick }) => {
  const { colorMode } = useColorMode();
  const textColor = colorMode === 'dark' ? 'gray.300' : 'gray.700';
  const activeColor = colorMode === 'dark' ? 'white.500' : 'blue.500';
  const borderColor = colorMode === 'dark' ? 'orange.500' : 'blue.500';

  return (
    <Button
      bg="transparent"
      color={textColor}
      mr={2}
      mb={0.5}
      fontWeight={'bold'}
      textTransform={'uppercase'}
      borderRadius="0"
      borderBottomWidth="4px"
      borderBottomColor={'borderColor'}
      _hover={{ borderBottomColor: borderColor, color: activeColor }}
      onClick={onClick}
      maxW={'100%'}
      textAlign="center"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Text fontSize="48px" mb={5}>{children}</Text>
    </Button>
  );
};
