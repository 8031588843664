import { useEffect, useState } from 'react';
import ApexCharts from 'apexcharts';
import { DataTableHeader } from './DataTableHeader';
import { DataTableFooter } from './DataTableFooter';
import { ThemedFlex } from '../../theme/ThemedFlex';
import { Select, Box } from '@chakra-ui/react';
import { CustomSpinner } from './CustomSpinner'

export const AvgRewardsOverTimeTile = ({
  title,
  subtitle,
  icon,
  link,
  linkLabel = 'Learn More',
  data,
}) => {
  const [chartData, setChartData] = useState([]);
  const [latestAvgReward, setLatestAvgReward] = useState(null);
  const [timeframe, setTimeframe] = useState('3y');
  const handleTimeframeChange = (event) => {
    setTimeframe(event.target.value);
  };

  useEffect(() => {
    if (data) {
      const allData = data.ts10.rewards.map(({ timestamp, avgRewards }) => ({
        x: new Date(timestamp * 1000),
        y: avgRewards / 1e8,
      }));

      const days = {
        '3y': 1095,
        '2y': 730,
        '1y': 365,
        '6m': 182,
        '3m': 91,
        '7d': 7,
        '2d': 3,
      };

      const filteredData = allData.filter((dataPoint) => {
        const now = new Date();
        const daysAgo = now.setDate(now.getDate() - days[timeframe]);
        return dataPoint.x >= daysAgo;
      });

      setChartData(filteredData);
      setLatestAvgReward(filteredData[filteredData.length - 1].y);
    }
  }, [data, timeframe]);

  useEffect(() => {
    if (chartData.length > 0) {
      const options = {
        chart: {
          fontFamily: 'Ubuntu, sans-serif',
          type: 'line',
          height: '100%',
          toolbar: {
            offsetY: -10,
            tools: {
              download: false,
            },
            padding: {
              bottom: 20,
            },
            color: '#78909C',
          },
        },
        annotations: {
          yaxis: [
            {
              y: latestAvgReward,
              borderColor: '#f7931a',
              borderWidth: 0,
              fillColor: '#f7931a',
              label: {
                borderColor: '#f7931a',
                borderWidth: 2,
                style: {
                  color: '#000000',
                  background: '#f7931a',
                  fontWeight: 'bold',
                  fontSize: '13px',
                },
                text: `${latestAvgReward.toFixed(2)} BTC`,
                offsetY: 0,
                offsetX: 5,
                textAnchor: 'start',
              },
            },
          ],
        },
        grid: {
          borderColor: '#555555',
          position: 'back',
        },
        series: [
          {
            name: 'Average Block Rewards',
            data: chartData,
          },
        ],
        xaxis: {
          type: 'datetime',
          labels: {
            style: {
              colors: '#999',
            },
          },
        },
        yaxis: [
          {
            opposite: true,
            tooltip: {
              enabled: false,
            },
            title: {
              text: 'Average Block Rewards',
              style: {
                color: '#999',
              },
            },
            labels: {
              formatter: function (value) {
                return value.toFixed(2) + ' BTC';
              },
              style: {
                colors: '#999',
              },
            },
          },
        ],
        colors: ['#f7931a'],
        stroke: {
          width: 2,
        },
        tooltip: {
          theme: 'dark',
          style: {
            backgroundColor: '#000000',
            color: '#ffffff',
          },
        },
      };
      const chart = new ApexCharts(
        document.querySelector('#chart12'),
        options
      );
      chart.render();
      return () => {
        chart.destroy();
      };
    }
  }, [chartData, latestAvgReward]);
  if (!data) {
    if (data === null || data === undefined) {
      console.error("Error: Data is null or undefined");
    }

    return <CustomSpinner />;
  } else {
    return (
      <ThemedFlex
        direction="column"
        mb="12px"
        py="18px"
        px="20px"
        flex={1}
        borderRadius={3}
      >
        <DataTableHeader title={title} subtitle={subtitle} icon={icon} />
        <Select
          value={timeframe}
          onChange={handleTimeframeChange}
          variant="filled"
          size="xs"
          alignSelf="flex-start"
          width="100px"
          mb={0}
        >
          <option value="3y">3 Years</option>
          <option value="2y">2 Years</option>
          <option value="1y">1 Year</option>
          <option value="6m">6 Months</option>
          <option value="3m">3 Months</option>
          <option value="7d">7 Days</option>
          <option value="2d">2 Days</option>
        </Select>
        <Box flexGrow={1} minHeight='360px'>
          <div id="chart12" style={{ height: '100%' }} />
        </Box>
        <DataTableFooter link={link} linkLabel={linkLabel} />
      </ThemedFlex>
    );
  };
}
