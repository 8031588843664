import React, { useEffect, useState, useRef } from 'react';
import ApexCharts from 'apexcharts';
import { DataTableHeader } from './DataTableHeader';
import { DataTableFooter } from './DataTableFooter';
import { ThemedFlex } from '../../theme/ThemedFlex'
import { Box, useColorMode } from '@chakra-ui/react';
import { CustomSpinner } from './CustomSpinner';

const getBreakpoint = (width) => {
  if (width < 768) return 'base';
  if (width >= 768 && width < 992) return 'md';
  if (width >= 992 && width < 1200) return 'lg';
  return 'xl';
};

const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(() => getBreakpoint(window.innerWidth));

  useEffect(() => {
    const handleResize = () => setBreakpoint(getBreakpoint(window.innerWidth));
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return breakpoint;
};

export const PoolsTopTenPieTile = ({
  title,
  subtitle,
  icon,
  link,
  linkLabel = 'Learn More',
  data,
  uniqueChartId,
}) => {
  const [chartData, setChartData] = useState([]);
  const chartRef = useRef(null);
  const { colorMode } = useColorMode();
  const borderColor = colorMode === 'dark' ? '#121212' : '#ffffff';

  useEffect(() => {
    if (data && data.ts09 && data.ts09.pools && data.ts09.pools.pools) {
      const sortedData = data.ts09.pools.pools.sort((a, b) => b.blockCount - a.blockCount);
      const topTenPools = sortedData.slice(0, 10);
      const processedData = topTenPools.map(pool => ({
        y: pool.blockCount,
        x: pool.name,
      }));
      setChartData(processedData);
    }
  }, [data]);

  const breakpoint = useBreakpoint();

  useEffect(() => {
    if (chartData.length > 0 && chartRef.current) {
      const options = {
        chart: {
          fontFamily: 'Ubuntu, sans-serif',
          type: 'pie',
          height: '100%',
          width: '100%',
          toolbar: {
            show: false,
          },
        },
        labels: chartData.map(data => data.x),
        series: chartData.map(data => data.y),
        colors: ["#f7931a", "#329239", "#0d579b", "#4d4d4d", "#7e57c2", "#ff5722", "#03a9f4", "#ffc107", "#9c27b0", "#795548"],
        legend: {
          labels: {
            colors: colorMode === 'dark' ? '#999' : '#333',
          },
          show: breakpoint === 'md' || breakpoint === 'lg' || breakpoint === 'xl',
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          colors: [borderColor],
        },
        tooltip: {
          theme: colorMode,
          y: {
            formatter: (value) => {
              return value.toLocaleString() + ' blocks';
            },
          },
        },
      };
      
      const chart = new ApexCharts(chartRef.current, options);
      chart.render();

      const handleResize = () => {
        chart.updateOptions({
          chart: {
            width: '100%',
            height: '100%',
          },
        });
      };

      window.addEventListener('resize', handleResize);

      return () => {
        chart.destroy();
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [chartData, borderColor, breakpoint, colorMode]);

  if (!data || !data.ts09 || !data.ts09.pools || !data.ts09.pools.pools) {
    console.error("Error: Data is null, undefined, or incorrectly structured");
    return <CustomSpinner />;
  }

  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      style={{ minHeight: '337px', maxHeight: '100%' }}
    >
      <DataTableHeader title={title} subtitle={subtitle} icon={icon} />
      <Box flexGrow={1} minHeight='337px'>
        <div ref={chartRef} className={`chart-container-${uniqueChartId}`} style={{ height: '100%' }} />
      </Box>
      <DataTableFooter link={link} linkLabel={linkLabel} />
    </ThemedFlex>
  );
};