import { SingleDataValue } from '.';
import { ThemedFlex } from '../../theme/ThemedFlex';

export const NetworkTileBlocks24h = ({ data, formatNumber, animate }) => {
  const numberValue = data?.s12?.metrics?.data?.blockchain_stats_24_hours?.count_of_blocks_added != null
    ? `${formatNumber(data.s12.metrics.data.blockchain_stats_24_hours.count_of_blocks_added) ?? "Loading..."} Blocks`
    : '';

  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      className={animate ? 'gray-border-custom gray-border' : ''}
    >
      <SingleDataValue label="New Blocks 24h" value={numberValue} />
    </ThemedFlex>
  );
};
