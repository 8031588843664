import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useColorMode, useToken } from "@chakra-ui/react";

export const ThemedNavIcon = ({ color, ...props }) => {
  const { colorMode } = useColorMode();
  const [white500] = useToken("colors", ["white.500"]);
  const iconColor = color || (colorMode === "dark" ? white500 : white500);

  return <FontAwesomeIcon {...props} color={iconColor} />;
};
