// src/components/games/components/AchievementBadge.jsx
import React, { useRef, useEffect } from 'react';
import { Box, Button, HStack, VStack, CloseButton } from '@chakra-ui/react';
import { drawBadge } from '../utils/badgeDrawing';

const AchievementBadge = ({
  onClose,
  badgeConfig,
  downloadFileName,
  shareText
}) => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const container = containerRef.current;
    const ctx = canvas.getContext('2d');

    const updateCanvasSize = () => {
      const displayWidth = container.clientWidth;
      const displayHeight = container.clientHeight;
      const scaleFactor = window.devicePixelRatio || 1;

      canvas.width = displayWidth * scaleFactor;
      canvas.height = displayHeight * scaleFactor;
      canvas.style.width = `${displayWidth}px`;
      canvas.style.height = `${displayHeight}px`;
      ctx.scale(scaleFactor, scaleFactor);
    };

    updateCanvasSize();
    drawBadge(ctx, badgeConfig, canvas.width / window.devicePixelRatio, canvas.height / window.devicePixelRatio);

    window.addEventListener('resize', updateCanvasSize);
    return () => {
      window.removeEventListener('resize', updateCanvasSize);
    };
  }, [badgeConfig]);

  const handleDownload = () => {
    const canvas = canvasRef.current;
    const image = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = image;
    link.download = downloadFileName;
    link.click();
  };

  const handleShare = async () => {
    const canvas = canvasRef.current;
    const image = canvas.toDataURL('image/png');
    const blob = await (await fetch(image)).blob();
    const file = new File([blob], downloadFileName, { type: 'image/png' });
    const shareData = {
      text: shareText,
      url: window.location.href,
      files: [file]
    };

    if (navigator.share && navigator.canShare(shareData)) {
      try {
        await navigator.share(shareData);
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(window.location.href)}`;
      window.open(twitterUrl, '_blank');
    }
  };

  return (
    <Box
      position="fixed"
      top="40%"
      left="50%"
      transform="translate(-50%, -50%)"
      bg="gray.900"
      color="white"
      p={4}
      borderRadius="md"
      textAlign="center"
      zIndex={1000}
      boxShadow={`0 0 10px ${badgeConfig.shadowColor}`}
    >
      <CloseButton position="absolute" right="0px" top="0px" onClick={onClose} color="#121212" />
      <VStack spacing={4}>
        <Box ref={containerRef} position="relative" width="320px" height="260px">
          <canvas
            ref={canvasRef}
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </Box>
        <HStack spacing={2}>
          <Button onClick={handleDownload} bg={badgeConfig.buttonColor} size={'sm'} color="white" _hover={{ bg: badgeConfig.buttonHoverColor }}>
            Download Badge
          </Button>
          <Button onClick={handleShare} bg={badgeConfig.buttonColor} size={'sm'} color="white" _hover={{ bg: badgeConfig.buttonHoverColor }}>
            Share on Twitter
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};

export default AchievementBadge;