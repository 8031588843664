// src/components/dashboard/tables/BitcoinHalvingTable.jsx
import React from 'react';
import { DataTable } from '../../data-display/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScissors } from '@fortawesome/free-solid-svg-icons';
import { formatNumber, formatLargeNumber, daysLeft } from '../../../utils';
import { currencySymbols } from '../../../utils/currencyConversion.utils';

export const BitcoinHalvingTable = ({ 
  data, 
  bitcoinPrice, 
  conversionRates, 
  selectedCurrency, 
  animate,
  onOpenLexicon,
}) => {
  const tableData = data && bitcoinPrice && conversionRates && selectedCurrency
    ? [
        {
            label: 'Blocks Per Halving',
            value: data ? `210,000 Blocks` : "Loading...",
            tooltipText: "Number of blocks for each halving cycle.",
            clickableTerm: "Bitcoin Halving",
        },
        {
            label: 'Blocks to Next Halving',
            value: data?.s03?.halvening?.bitcoin_data?.blocks_left != null 
              ? `${formatNumber(data.s03.halvening.bitcoin_data.blocks_left, 0)} Blocks` 
              : "Loading...",
            tooltipText: "Number of blocks to go until the next Bitcoin Halving event. New blocks are mined roughly every 10 minutes.",
            clickableTerm: "Bitcoin Halving",
        },
        {
            label: 'Seconds to Next Halving',
            value: data?.s03?.halvening?.bitcoin_data?.seconds_left != null 
              ? `${formatNumber(data.s03.halvening.bitcoin_data.seconds_left, 0)} Seconds` 
              : "Loading...",
            tooltipText: "Number of seconds left until the next Bitcoin Halving event. New blocks are mined roughly every 10 minutes.",
            clickableTerm: "Bitcoin Halving",
        },
        {
            label: 'Days Until Halving',
            value: data?.s03?.halvening?.bitcoin_data?.halvening_time != null 
              ? `~${formatNumber(daysLeft(data.s03.halvening.bitcoin_data.halvening_time), 0)} Days` 
              : "Loading...",
            tooltipText: "Estimated number of days left until the next Bitcoin Halving event. New blocks are mined roughly every 10 minutes.",
            clickableTerm: "Bitcoin Halving",
        },
        {
            label: 'Est. Date / Next Halving',
            value: data?.s03?.halvening?.bitcoin_data?.halvening_time != null 
              ? `${data.s03.halvening.bitcoin_data.halvening_time}` 
              : "Loading...",
            tooltipText: "Estimated date of the next Bitcoin Halving in UTC",
            clickableTerm: "Bitcoin Halving",
        },
        {
            label: 'Current Subsidy',
            value: data ? `3.125 BTC / Block` : "Loading...",
            tooltipText: "Current block reward for successfully mining a Bitcoin block. This number is automatically halved every 210,000 blocks... or about every 4 years... in an event called a 'Bitcoin Halving.'",
            clickableTerm: "Subsidy",
        },
        {
            label: 'Post-Halving Subsidy',
            value: data ? `1.5625 BTC / Block` : "Loading...",
            tooltipText: "The number of bitcoins a miner who successfully mines a block will receive after the next Bitcoin Halving",
            clickableTerm: "Block Reward",
        },
        {
            label: 'BTC Added 4th Epoch',
            value: '1,312,500 BTC',
            tooltipText: 'Total number of Bitcoins mined in the fourth Reward Era (2020-2024)',
        },
        {
            label: 'BTC Added 3rd Epoch',
            value: '2,625,000 BTC',
            tooltipText: 'Total number of Bitcoins mined in the third Reward Era (2016-2020)',
        },
        {
            label: 'BTC Added 2nd Epoch',
            value: '5,250,000 BTC',
            tooltipText: 'Total number of Bitcoins mined in the third Reward Era (2012-2016)',
        },
        {
            label: 'BTC Added 1st Epoch',
            value: '10,500,000 BTC',
            tooltipText: 'Total number of Bitcoins mined in the third Reward Era (2009-2012)',
        },
        {
            label: 'Bitcoins Mined/Hour',
            value: '~18.75 BTC / Hour',
            tooltipText: "Total amount of Bitcoins mined every hour.",
            clickableTerm: "Mining",
        },
        {
            label: 'Bitcoins Mined/Day',
            value: '~450 BTC / Day',
            tooltipText: "Total amount of Bitcoins mined in a single day.",
            clickableTerm: "Mining",
        },
        {
            label: 'Bitcoins Mined/Week',
            value: '~3,150 BTC / Day',
            tooltipText: "Total amount of Bitcoins mined in one week.",
            clickableTerm: "Mining",
        },
        {
            label: 'Bitcoins Mined/Month',
            value: '~312,600 BTC / Day',
            tooltipText: "Total amount of Bitcoins mined in one month.",
            clickableTerm: "Mining",
        },
        {
            label: 'Bitcoins Mined/Year',
            value: '~164.3k BTC / Year',
            tooltipText: "Total amount of Bitcoins mined in one year.",
            clickableTerm: "Mining",
        },
        {
            label: 'Final Halving Year',
            value: 'Year 2140',
            tooltipText: "The estimated year when the last Bitcoin will be mined, based on the halving schedule.",
            clickableTerm: "Final Halving Year",
        },
        {
            label: 'Total Number of Halvings',
            value: '33',
            tooltipText: "The total number of halvings that will occur before Bitcoin reaches its maximum supply.",
            clickableTerm: "Number of Halvings",
        },
        {
            label: 'Halvings Passed',
            value: '4 so far',
            tooltipText: "The total number of halvings that have happened in Bitcoin's history.",
        },
        {
            label: 'Genesis Block Date',
            value: 'January 3, 2009',
            tooltipText: "The date when the first Bitcoin block (Genesis block) was mined by Satoshi Nakamoto.",
            clickableTerm: "2009 Genesis Block",
        },
        {
            label: 'First Halving Date',
            value: 'November 28, 2012',
            tooltipText: "The date when the first Bitcoin halving occurred, reducing the block reward from 50 BTC to 25 BTC.",
            clickableTerm: "First Halving Date",
        },
        {
            label: 'Second Halving Date',
            value: 'July 9, 2016',
            tooltipText: "The date when the second Bitcoin halving occurred, reducing the block reward from 25 BTC to 12.5 BTC.",
            clickableTerm: "Second Halving Date",
        },
        {
            label: 'Third Halving Date',
            value: 'May 11, 2020',
            tooltipText: "The date when the third Bitcoin halving occurred, reducing the block reward from 12.5 BTC to 6.25 BTC.",
            clickableTerm: "Third Halving Date",
        },
        {
            label: 'Fourth Halving Date',
            value: 'April 18, 2024',
            tooltipText: "The date when the fourth Bitcoin halving occurred, reducing the block reward from 6.25 BTC to 3.125 BTC.",
            clickableTerm: "Fourth Halving Date",
        },
        {
            label: 'Halving Cycle',
            value: data?.s12?.metrics?.data?.on_chain_data?.block_height != null 
              ? `${Math.floor(data.s12.metrics.data.on_chain_data.block_height / 210000) + 1}th Epoch` 
              : "Loading...",
            tooltipText: "The current halving cycle number. Each cycle lasts 210,000 blocks.",
            clickableTerm: "Halving Cycle",
        },
        {
            label: 'Epoch Coins Left',
            value: data?.s12?.metrics?.data?.on_chain_data?.block_height != null 
              ? formatNumber((210000 - (data.s12.metrics.data.on_chain_data.block_height % 210000)) * 3.125, 3) + ' BTC' 
              : "Loading...",
            tooltipText: "The amount of Bitcoin that will be mined before the next halving occurs.",
            clickableTerm: "Epoch Coins Left",
        },
        {
            label: 'Epoch Coins Left Value',
            value: data?.s12?.metrics?.data?.on_chain_data?.block_height != null && 
                   data?.s12?.metrics?.data?.market_data?.price_usd != null 
              ? `${currencySymbols[selectedCurrency]}${formatLargeNumber((data.s12.metrics.data.market_data.price_usd * conversionRates[selectedCurrency]) * ((210000 - (data.s12.metrics.data.on_chain_data.block_height % 210000)) * 3.125), 2, false)} ${selectedCurrency.toUpperCase()}` 
              : "Loading...",
            tooltipText: "The estimated value of Bitcoin that will be mined before the next halving occurs.",
            clickableTerm: "Epoch Coins Left",
        }
    ]
    : [];

  return (
    <DataTable
      title="Bitcoin Halving"
      subtitle="Details about Bitcoin halvings"
      icon={<FontAwesomeIcon icon={faScissors} />}
      rows={tableData}
      animate={animate}
      specificTerm="Halving"
      onOpenLexicon={onOpenLexicon}
      rowsPerPage={9}
    />
  );
};
