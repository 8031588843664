// src/components/data-display/DataTableFooter.jsx
import React from 'react';
import { Flex, Button } from '@chakra-ui/react';
import { LearnMoreButton } from '../buttons';

export const DataTableFooter = ({ 
  link, 
  linkLabel = 'Learn More', 
  footerIcon, 
  showIcon, 
  pagination,
  specificTerm,
  onOpenLexicon
}) => {
  const { currentPage, setCurrentPage, pageCount } = pagination || {};

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <Flex mt={2} justifyContent="space-between" alignItems="center">
      {specificTerm ? (
        <LearnMoreButton 
          linkLabel={`Learn more`} 
          icon={footerIcon} 
          showIcon={showIcon} 
          onClick={() => onOpenLexicon(specificTerm)}
        />
      ) : link ? (
        <LearnMoreButton link={link} linkLabel={linkLabel} icon={footerIcon} showIcon={showIcon} />
      ) : null}
      {pageCount > 1 && (
        <Flex>
          <Button size="xs" mr={2} onClick={handlePrevPage} disabled={currentPage === 1} borderRadius={3}>
            Prev
          </Button>
          <Button size="xs" onClick={handleNextPage} disabled={currentPage === pageCount} borderRadius={3}>
            Next
          </Button>
        </Flex>
      )}
    </Flex>
  );
};