import React, { useState } from 'react';
import { Box, IconButton, Popover, PopoverTrigger, PopoverContent, PopoverBody, Text, VStack, useColorModeValue } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

const GameInfoTriviaNight = () => {
  const [isOpen, setIsOpen] = useState(false);
  const backgroundColor = useColorModeValue('gray.200', 'gray.500');

  const gameRules = [
    "• Answer Bitcoin-related trivia questions.",
    "• Earn credits for correct answers.",
    "• Questions get harder as you progress.",
    "• In Survivor Mode, one wrong answer ends the game.",
    "• In regular mode, answer 10 questions per game.",
    "• Timechain Triple offers 3x rewards (or losses).",
    "• Earn achievements based on your performance.",
    "• No real money involved - just for fun and learning!",
    "• Test and improve your Bitcoin knowledge."
  ];

  return (
    <Box position="absolute" top="2" right="12">
      <Popover isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <PopoverTrigger>
          <IconButton
            icon={<FontAwesomeIcon icon={faQuestion} />}
            aria-label="Game Info"
            size="sm"
            onClick={() => setIsOpen(!isOpen)}
          />
        </PopoverTrigger>
        <PopoverContent mr={7} borderColor={'white'} borderWidth={2} p={2} pb={4} backgroundColor={backgroundColor}>
          <PopoverBody>
            <VStack align="start" spacing={2}>
              <Text fontWeight="bold">Game Rules:</Text>
              {gameRules.map((rule, index) => (
                <Text key={index} fontSize="11px">{rule}</Text>
              ))}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default GameInfoTriviaNight;
