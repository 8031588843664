import React, { useEffect, useRef } from 'react';
import { useColorMode } from '@chakra-ui/react';

let tvScriptLoadingPromise;

export default function TradingViewWidget() {
  const onLoadScriptRef = useRef();

  const { colorMode } = useColorMode();
  const themeColor = colorMode === 'dark' ? 'dark' : 'light';

  useEffect(
    () => {
      onLoadScriptRef.current = createWidget;

      if (!tvScriptLoadingPromise) {
        tvScriptLoadingPromise = new Promise((resolve) => {
          const script = document.createElement('script');
          script.id = 'tradingview-widget-loading-script';
          script.src = 'https://s3.tradingview.com/tv.js';
          script.type = 'text/javascript';
          script.onload = resolve;

          document.head.appendChild(script);
        });
      }

      tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

      return () => onLoadScriptRef.current = null;

      function createWidget() {
        if (document.getElementById('tradingview_a2127') && 'TradingView' in window) {
          new window.TradingView.widget({
            autosize: true,
            symbol: "BITSTAMP:BTCUSD",
            interval: "D",
            timezone: "Etc/UTC",
            theme: "dark",
            style: "1",
            locale: "en",
            toolbar_bg: "#f1f3f6",
            enable_publishing: false,
            withdateranges: true,
            hide_side_toolbar: false,
            allow_symbol_change: true,
            studies: ["STD;Bollinger_Bands", "STD;MACD", "STD;RSI"],
            container_id: "tradingview_a2127"
          });
        }
      }
    },
    [themeColor]
  );

  const containerStyle = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  };

  return (
    <div className='tradingview-widget-container' style={containerStyle}>
      <div id='tradingview_a2127' style={{ flexGrow: 1 }} />
    </div>
  );
}
