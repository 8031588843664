import { Button, Text, useColorMode } from '@chakra-ui/react';

export const ThemedDonateButton = ({ children, onClick, size = 'xs', px = "6px", fontSize = "12px" }) => {
  const { colorMode } = useColorMode();
  const buttonColor = colorMode === 'dark' ? 'orange.500' : 'blue.500';
  const hoverColor = colorMode === 'dark' ? 'orange.300' : 'blue.400';
  const textColor = colorMode === 'dark' ? '#121212' : '#ffffff';

  return (
    <Button
      bg={buttonColor}
      color={textColor}
      mr={2}
      mb={0.5}
      fontWeight={'bold'}
      textTransform={'uppercase'}
      borderRadius="sm"
      borderWidth="4px"
      borderColor={buttonColor}
      _hover={{ bg: hoverColor, borderColor: hoverColor, color: textColor }}
      onClick={onClick}
      maxW={'100%'}
      textAlign="center"
      display="flex"
      justifyContent="center"
      alignItems="center"
      paddingTop="9px"
      paddingBottom="9px"
      px={px}
      size={size}
      fontSize={fontSize}
    >
      <Text>{children}</Text>
    </Button>
  );
};
