import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import ApexCharts from 'apexcharts';
import { Box, useColorMode, Select } from '@chakra-ui/react';
import { DataTableHeader } from './DataTableHeader';
import { DataTableFooter } from './DataTableFooter';
import { ChartTileFlex } from '../../theme/ChartTileFlex';
import { CustomSpinner } from './CustomSpinner';

// Custom hook for deep comparison
const useDeepCompareMemoize = (value) => {
  const ref = useRef();

  if (JSON.stringify(value) !== JSON.stringify(ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

export const BitcoinTxVsTotalFeesChart = ({
  uniqueChartId,
  title,
  subtitle,
  icon,
  link,
  linkLabel = 'Learn More',
  blocks,
  formatNumber,
  formatLargeNumber
}) => {
  const [chartData, setChartData] = useState([]);
  const [timeframe, setTimeframe] = useState('1h');
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [loading, setLoading] = useState(true);
//   const [debugInfo, setDebugInfo] = useState('');

  const { colorMode } = useColorMode();
  const gridColor = colorMode === 'dark' ? '#555555' : '#CCCCCC';
  const labelColor = colorMode === 'dark' ? '#999' : '#666';
  const legendTextColor = colorMode === 'dark' ? '#FFFFFF' : '#121212';

  const handleTimeframeChange = useCallback((event) => {
    setTimeframe(event.target.value);
  }, []);

  const processData = useCallback((blocks, selectedTimeframe) => {
    const blockCount = selectedTimeframe === '1h' ? 6 : 12; // Adjust as needed
    const filteredBlocks = blocks
      .slice(-blockCount)
      .sort((a, b) => a.height - b.height)
      .filter(block => 
        block.height != null && 
        !isNaN(block.height) && 
        block.extras?.totalFees != null && 
        !isNaN(block.extras.totalFees) &&
        block.tx_count != null && 
        !isNaN(block.tx_count)
      );

    return filteredBlocks.map(block => ({
      x: block.height,
      totalFees: block.extras.totalFees / 100000000,
      txCount: block.tx_count,
    }));
  }, []);

  const memoizedBlocks = useDeepCompareMemoize(blocks);

  useEffect(() => {
    if (memoizedBlocks && memoizedBlocks.length > 0) {
      const processedData = processData(memoizedBlocks, timeframe);
      setChartData(processedData);
      setLoading(false);
    //   setDebugInfo(`Processed ${processedData.length} blocks`);
    } else {
    //   setDebugInfo('No blocks data available');
    }
  }, [memoizedBlocks, processData, timeframe]);

  const chartOptions = useMemo(() => {
    return {
      chart: {
        type: 'line',
        height: 350,
        toolbar: { show: false },
        zoom: { enabled: false },
        animations: { enabled: false },
        background: 'transparent'
      },
      tooltip: {
        theme: colorMode,
        shared: true,
        intersect: false,
        y: {
          formatter: function (value, { seriesIndex, dataPointIndex, w }) {
            const seriesName = w.config.series[seriesIndex].name;
            if (seriesName.includes('Fee')) {
              return formatNumber(value, 8) + ' BTC';
            } else if (seriesName.includes('Count')) {
              return Math.round(value);
            }
            return formatNumber(value, 2);
          }
        }
      },
      legend: { 
        position: 'top',
        labels: {
          colors: legendTextColor
        }
      },
      grid: { 
        borderColor: gridColor,
      },
      colors: ["#329239", "#f7931a", "#0d579b", "#4d4d4d"],
      stroke: {
        width: [0, 4],
        curve: 'smooth'
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
      type: 'numeric',
      categories: chartData.map(item => item.x),
      title: {
        text: 'Block Height',
        style: { color: labelColor },
      },
      labels: {
        style: { colors: labelColor },
        formatter: (value) => Math.floor(value),
      },
      tickPlacement: 'on',
    },
      yaxis: [
        {
          title: {
            text: 'Total Fees (BTC)',
            style: { color: labelColor },
          },
          labels: {
            formatter: (value) => formatNumber(value, 8),
            style: { colors: labelColor },
          },
        },
        {
          opposite: true,
          title: {
            text: 'Transaction Count',
            style: { color: labelColor },
          },
          labels: {
            formatter: (value) => Math.round(value),
            style: { colors: labelColor },
          },
        }
      ],
      plotOptions: {
        bar: {
          columnWidth: '50%'
        }
      }
    };
  }, [colorMode, gridColor, labelColor, legendTextColor, formatNumber, chartData]);

  useEffect(() => {
    if (chartData.length > 0 && chartRef.current) {
      const series = [
        {
          name: 'Total Fees',
          type: 'column',
          data: chartData.map(item => ({ x: item.x, y: item.totalFees }))
        },
        {
          name: 'Transaction Count',
          type: 'line',
          data: chartData.map(item => ({ x: item.x, y: item.txCount }))
        }
      ];

      const updateOptions = {
        ...chartOptions,
        series,
      };

      if (chartInstance.current) {
        chartInstance.current.updateOptions(updateOptions);
      } else {
        chartInstance.current = new ApexCharts(chartRef.current, updateOptions);
        chartInstance.current.render();
      }
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
        chartInstance.current = null;
      }
    };
  }, [chartData, chartOptions]);

  return (
    <ChartTileFlex
      direction="column"
      mb="12px"
      py="18px"
      flex={1}
      borderRadius={3}
      style={{ minHeight: '337px', maxHeight: '100%' }}
    >
      <DataTableHeader title={title} subtitle={subtitle} icon={icon} />
      <Box flexGrow={1} minHeight='337px' width="100%">
        <Select
          value={timeframe}
          onChange={handleTimeframeChange}
          variant="filled"
          size="xs"
          width="100px"
          mb={2}
        >
          <option value="1h">6 Blocks</option>
          {/* <option value="2h">12 Blocks</option> */}
        </Select>
        {loading ? <CustomSpinner /> : <div ref={chartRef} className={`chart-container-${uniqueChartId}`} style={{ height: '100%' }} />}
      </Box>
      <DataTableFooter link={link} linkLabel={linkLabel} />
      {/* <Text fontSize="xs" color="gray.500">{debugInfo}</Text> */}
    </ChartTileFlex>
  );
};