import React from 'react';
import { DataTable } from '../../data-display/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { formatNumber, formatLargeNumber } from '../../../utils';
import { currencySymbols } from '../../../utils/currencyConversion.utils';

export const MarketDataTable = ({ 
  data, 
  bitcoinPrice, 
  conversionRates, 
  selectedCurrency, 
  animate,
  onOpenLexicon 
}) => {
  const tableData = data && bitcoinPrice && conversionRates && selectedCurrency
    ? [
        {
            label: 'Exchange Flow-In 24h',
            value: data?.s12?.metrics?.data?.exchange_flows?.flow_in_exchange_native_units != null
              ? `${formatNumber(data.s12.metrics.data.exchange_flows.flow_in_exchange_native_units, 0)} BTC In`
              : "Loading...",
            tooltipText: "The amount of Bitcoins flowing into top exchanges",
            clickableTerm: "Exchange Flow-In",
        },
        {
            label: 'Exchange Flow-Out 24h',
            value: data?.s12?.metrics?.data?.exchange_flows?.flow_out_exchange_native_units != null
              ? `${formatNumber(data.s12.metrics.data.exchange_flows.flow_out_exchange_native_units, 0)} BTC Out`
              : "Loading...",
            tooltipText: "The amount of Bitcoins flowing out of top exchanges",
            clickableTerm: "Exchange Flow-Out",
        },
        {
            label: 'Supply on Exchanges',
            value: data?.s12?.metrics?.data?.exchange_flows?.supply_exchange_native_units != null
              ? `${formatNumber(data.s12.metrics.data.exchange_flows.supply_exchange_native_units, 0)} BTC`
              : "Loading...",
            tooltipText: "Total amount of Bitcoin held on exchanges",
            clickableTerm: "Exchange Supply",
        },
        {
            label: '% Supply on Exchanges',
            value: data?.s12?.metrics?.data?.exchange_flows?.supply_exchange_native_units != null && 
                   data?.s12?.metrics?.data?.supply?.circulating != null
              ? `${formatNumber((data.s12.metrics.data.exchange_flows.supply_exchange_native_units / data.s12.metrics.data.supply.circulating) * 100, 2)}%`
              : "Loading...",
            tooltipText: "Percentage of circulating Bitcoin supply held on exchanges",
            clickableTerm: "Exchange Supply",
        },
        {
            label: `Price (BTC/${selectedCurrency.toUpperCase()})`,
            value: bitcoinPrice && conversionRates
              ? `${currencySymbols[selectedCurrency]}${formatNumber(bitcoinPrice * conversionRates[selectedCurrency], 2)} ${selectedCurrency.toUpperCase()}`
              : "Loading...",
            tooltipText: `The current price of Bitcoin in ${selectedCurrency.toUpperCase()}.`,
            clickableTerm: "Bitcoin Fiat Price",
        },
        {
            label: 'Realized Price',
            value: data?.s12?.metrics?.data?.marketcap?.realized_marketcap_usd != null && 
                   data?.s12?.metrics?.data?.supply?.circulating != null && 
                   bitcoinPrice && conversionRates
              ? `${currencySymbols[selectedCurrency]}${formatNumber(((data.s12.metrics.data.marketcap.realized_marketcap_usd * conversionRates[selectedCurrency]) / data.s12.metrics.data.supply.circulating), 2)} ${selectedCurrency.toUpperCase()}`
              : "Loading...",
            tooltipText: "The realized price is calculated by dividing the realized cap by the current supply",
            clickableTerm: "Realized Price",
        },
        {
            label: 'Realized Cap',
            value: data?.s12?.metrics?.data?.marketcap?.realized_marketcap_usd != null && bitcoinPrice && conversionRates
              ? `${currencySymbols[selectedCurrency]}${formatLargeNumber(data.s12.metrics.data.marketcap.realized_marketcap_usd * conversionRates[selectedCurrency], 0, false)} ${selectedCurrency.toUpperCase()}`
              : "Loading...",
            tooltipText: "The realized capitalization values different parts of the supplies at different prices (instead of using the current daily close). Specifically, it is computed by valuing each UTXO by the price when it was last moved.",
            clickableTerm: "Realized Cap",
        },
        {
            label: 'Price in Sats',
            value: bitcoinPrice && conversionRates
              ? `${currencySymbols[selectedCurrency]} ${formatNumber(100000000 / (bitcoinPrice * conversionRates[selectedCurrency]), 0)} sats`
              : "Loading...",
            tooltipText: "The number of satoshis equivalent to one US dollar at the current price.",
            clickableTerm: "Satoshi",
        },
        {
            label: 'Selected Currency',
            value: `${currencySymbols[selectedCurrency]} ${selectedCurrency.toUpperCase()}`,
            tooltipText: "The currently selected currency for price display.",
        },
        {
            label: 'Conversion Rate',
            value: conversionRates ? formatNumber(conversionRates[selectedCurrency], 3) : "Loading...",
            tooltipText: `The current conversion rate from USD to ${selectedCurrency.toUpperCase()}.`,
        }
    ]
    : [];

  return (
    <DataTable
      title="Market Data"
      subtitle="BTC~USD exchange price data"
      icon={<FontAwesomeIcon icon={faChartLine} />}
      rows={tableData}
      animate={animate}
      specificTerm="Market Data"
      onOpenLexicon={onOpenLexicon}
      rowsPerPage={9}
    />
  );
};