import React, { useState } from 'react';
import { Box, IconButton, Popover, PopoverTrigger, PopoverContent, PopoverBody, Text, VStack, useColorModeValue } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

const GameInfoMinerTycoon = () => {
  const [isOpen, setIsOpen] = useState(false);
  const backgroundColor = useColorModeValue('gray.200', 'gray.500');

  const gameRules = [
    "• Build and manage your Bitcoin mining empire.",
    "• Buy and sell different types of miners.",
    "• Mine Bitcoin and convert it to credits.",
    "• Manage your energy consumption and costs.",
    "• Toggle miners on/off to optimize operations.",
    "• Use Fiat Mining to earn credits without miners.",
    "• Join mining pools for frequent but smaller rewards.",
    "• Beware! Random events will affect your operation!",
    "• Spend excess credits on luxurious collectibles.",
    "• No real money involved - just for fun and learning!"
  ];

  return (
    <Box position="absolute" top="2" right="12">
      <Popover isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <PopoverTrigger>
          <IconButton
            icon={<FontAwesomeIcon icon={faQuestion} />}
            aria-label="Game Info"
            size="sm"
            onClick={() => setIsOpen(!isOpen)}
          />
        </PopoverTrigger>
        <PopoverContent mr={7} borderColor={'white'} borderWidth={2} p={2} pb={4} backgroundColor={backgroundColor}>
          <PopoverBody>
            <VStack align="start" spacing={2}>
              <Text fontWeight="bold">Game Rules:</Text>
              {gameRules.map((rule, index) => (
                <Text key={index} fontSize="11px">{rule}</Text>
              ))}
            </VStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default GameInfoMinerTycoon;