// src/recoilState.js
import { atom } from 'recoil';

export const selectedCurrencyState = atom({
  key: 'selectedCurrencyState', // unique ID (with respect to other atoms/selectors)
  default: 'usd', // default value (aka initial value)
});

export const recentBlocksState = atom({
  key: 'recentBlocks',
  default: [],
});
