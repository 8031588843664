import React, { useEffect, useState, useRef } from 'react';
import ApexCharts from 'apexcharts';
import { DataTableHeader } from './DataTableHeader';
import { DataTableFooter } from './DataTableFooter';
import { ThemedFlex } from '../../theme/ThemedFlex'
import { Select, Box, useColorMode } from '@chakra-ui/react';
import { CustomSpinner } from './CustomSpinner';

const getBreakpoint = (width) => {
  if (width < 768) return 'base';
  if (width >= 768 && width < 992) return 'md';
  if (width >= 992 && width < 1200) return 'lg';
  return 'xl';
};

const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(() => getBreakpoint(window.innerWidth));

  useEffect(() => {
    const handleResize = () => setBreakpoint(getBreakpoint(window.innerWidth));
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return breakpoint;
};

export const PoolsTopTenHashPieTile = ({
  title,
  subtitle,
  icon,
  link,
  linkLabel = 'Learn More',
  data,
  uniqueChartId,
  formatLargeNumber
}) => {
  const [chartData, setChartData] = useState([]);
  const chartRef = useRef(null);
  const { colorMode } = useColorMode();
  const borderColor = colorMode === 'dark' ? '#121212' : '#ffffff';
  const [timeframe, setTimeframe] = useState('3y');

  const handleTimeframeChange = (event) => {
    setTimeframe(event.target.value);
  };

  useEffect(() => {
    if (data && data.ts05 && data.ts05.hashrate_pools) {
      const days = {
        '3y': 1095, '2y': 730, '1y': 365, '6m': 180, '3m': 90, '7d': 7, '3d': 3,
      };

      const now = new Date();
      const daysAgo = new Date(now.setDate(now.getDate() - days[timeframe]));

      const filteredData = data.ts05.hashrate_pools.filter((dataPoint) => {
        return new Date(dataPoint.timestamp * 1000) >= daysAgo;
      });

      const poolHashrates = filteredData.reduce((acc, pool) => {
        acc[pool.poolName] = (acc[pool.poolName] || 0) + pool.avgHashrate;
        return acc;
      }, {});

      const sortedData = Object.entries(poolHashrates)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 10);

      const processedData = sortedData.map(pool => ({
        y: pool[1],
        x: pool[0],
      }));

      setChartData(processedData);
    }
  }, [data, timeframe]);

  const breakpoint = useBreakpoint();

  useEffect(() => {
    if (chartData.length > 0 && chartRef.current) {
      const options = {
        chart: {
          fontFamily: 'Ubuntu, sans-serif',
          type: 'pie',
          height: '100%',
          width: '100%',
          toolbar: {
            show: false,
          },
        },
        labels: chartData.map(data => data.x),
        series: chartData.map(data => data.y),
        colors: ["#f7931a", "#329239", "#0d579b", "#4d4d4d", "#7e57c2", "#ff5722", "#03a9f4", "#ffc107", "#9c27b0", "#795548"],
        legend: {
          labels: {
            colors: colorMode === 'dark' ? '#999' : '#333',
          },
          show: breakpoint === 'md' || breakpoint === 'lg' || breakpoint === 'xl',
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          colors: [borderColor],
        },
        tooltip: {
          theme: colorMode,
          y: {
            formatter: (value) => {
              return `${formatLargeNumber(value, 2, true)}H/s`;
            },
          },
        },
      };
      
      const chart = new ApexCharts(chartRef.current, options);
      chart.render();

      const handleResize = () => {
        chart.updateOptions({
          chart: {
            width: '100%',
            height: '100%',
          },
        });
      };

      window.addEventListener('resize', handleResize);

      return () => {
        chart.destroy();
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [chartData, formatLargeNumber, borderColor, breakpoint, colorMode]);

  if (!data || !data.ts05 || !data.ts05.hashrate_pools) {
    console.error("Error: Data is null, undefined, or incorrectly structured");
    return <CustomSpinner />;
  }

  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      style={{ minHeight: '337px', maxHeight: '100%' }}
    >
      <DataTableHeader title={title} subtitle={subtitle} icon={icon} />
      <Select
        value={timeframe}
        onChange={handleTimeframeChange}
        variant="filled"
        size="xs"
        alignSelf="flex-start"
        width="100px"
        mb={2}
      >
        <option value="3y">3 Years</option>
        <option value="2y">2 Years</option>
        <option value="1y">1 Year</option>
        <option value="6m">6 Months</option>
        <option value="3m">3 Months</option>
        <option value="7d">7 Days</option>
        <option value="3d">3 Days</option>
      </Select>
      <Box flexGrow={1} minHeight="337px">
        <div ref={chartRef} className={`chart-container-${uniqueChartId}`} style={{ height: '100%' }} />
      </Box>
      <DataTableFooter link={link} linkLabel={linkLabel} />
    </ThemedFlex>
  );
};