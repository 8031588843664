import { Flex, Text } from "@chakra-ui/react";
import { ThemedLogoIcon } from "../../../theme/ThemedLogoIcon";
import { ThemedLogo } from "../../../theme/ThemedLogo";
import { faBtc } from "@fortawesome/free-brands-svg-icons";

export const AppLogo = () => {
  return (
    <Flex align="center">
      <ThemedLogoIcon icon={faBtc} fontSize="23px" />
      <ThemedLogo
        href="https://timechainstats.com"
        _hover={{ textDecoration: "none" }}
        fontSize={{ base: "21px", md: "22px" }}
        ml="5px"
      >
        <Text as="span">
          <span style={{ fontWeight: "bold" }}>Timechain</span>
          <span>Stats</span>
        </Text>
      </ThemedLogo>
    </Flex>
  );
};