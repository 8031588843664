import { useEffect, useState, useRef } from 'react';
import { Select, Box } from '@chakra-ui/react';
import ApexCharts from 'apexcharts';
import { DataTableHeader } from './DataTableHeader';
import { DataTableFooter } from './DataTableFooter';
import { ThemedFlex } from '../../theme/ThemedFlex'
import { CustomSpinner } from './CustomSpinner';

export const TransactionsAndHashRateTile = ({
  title,
  subtitle,
  icon,
  link,
  linkLabel = 'Learn More',
  data,
  uniqueChartId,
}) => {
  const [transactionsData, setTransactionsData] = useState([]);
  const [hashRateData, setHashRateData] = useState([]);

  const chartRef = useRef(null);

  const [timeframe, setTimeframe] = useState('3y');
  const handleTimeframeChange = (event) => {
    setTimeframe(event.target.value);
  };

  useEffect(() => {
    if (data && data.ts14 && data.ts04) {
      const allTransactionsData = data.ts14.transactions.values.map(({ x, y }) => ({
        x: new Date(x * 1000),
        y,
      }));

      const allHashRateData = data.ts04.hashrate.hashrates.map(({ timestamp, avgHashrate }) => ({
        x: new Date(timestamp * 1000),
        y: avgHashrate / 1e18, // Convert to EH/s
      }));

      const days = {
        '3y': 1095,
        '2y': 730,
        '1y': 365,
        '6m': 182,
        '3m': 91,
        '7d': 7,
      };

      const filteredTransactionsData = allTransactionsData.filter((dataPoint) => {
        const now = new Date();
        const daysAgo = now.setDate(now.getDate() - days[timeframe]);
        return dataPoint.x >= daysAgo;
      });

      const filteredHashRateData = allHashRateData.filter((dataPoint) => {
        const now = new Date();
        const daysAgo = now.setDate(now.getDate() - days[timeframe]);
        return dataPoint.x >= daysAgo;
      });

      setTransactionsData(filteredTransactionsData);
      setHashRateData(filteredHashRateData);
    }
  }, [data, timeframe]);

  useEffect(() => {
    if (transactionsData.length > 0 && hashRateData.length > 0) {
      const options = {
        chart: {
          fontFamily: 'Ubuntu, sans-serif',
          type: 'line',
          height: '100%',
          width: '100%',
          toolbar: {
            offsetY: -10,
            tools: {
              download: false,
            },
            padding: {
              bottom: 20,
            },
            color: '#78909C',
          },
        },
        series: [
          {
            name: 'Bitcoin Transactions',
            data: transactionsData,
          },
          {
            name: 'Bitcoin Hash Rate (EH/s)',
            data: hashRateData,
          },
        ],
        legend: {
          position: 'bottom',
          offsetY: 10,
          labels: {
            colors: '#999',
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            style: {
              colors: '#999',
            },
          },
        },
        yaxis: [
          {
            opposite: true,
            title: {
              text: 'Transactions',
              style: {
                color: '#999',
              },
            },
            labels: {
              style: {
                colors: '#999',
              },
              formatter: function (value) {
                return value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
              },
            },
          },
          {
            title: {
              text: 'Hash Rate (EH/s)',
              style: {
                color: '#999',
              },
            },
            labels: {
              style: {
                colors: '#999',
              },
              formatter: function (value) {
                return value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
              },
            },
          },
        ],
        colors: ["#f7931a", "#329239", "#0d579b", "#4d4d4d"],
        stroke: {
          width: 2,
        },
        tooltip: {
          theme: 'dark',
          style: {
            backgroundColor: '#000000',
            color: '#ffffff',
          },
          x: {
            format: 'dd MMM yyyy',
          },
          y: {
            formatter: function (value, { seriesIndex, dataPointIndex, w }) {
              if (seriesIndex === 0) {
                return value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + ' Transactions';
              } else {
                return value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' EH/s';
              }
            },
          },
        },
      };

      const chart = new ApexCharts(
        chartRef.current,
        options
      );
      chart.render();

      const handleResize = () => {
        chart.updateOptions({
          chart: {
            width: '100%',
            height: '100%',
          },
        });
      };

      window.addEventListener('resize', handleResize);

      return () => {
        chart.destroy();
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [transactionsData, hashRateData]);

  if (!data || !data.ts14 || !data.ts04) {
    console.error("Error: Data is missing or incomplete");
    return <CustomSpinner />;
  }

  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      style={{ minHeight: '337px', maxHeight: '100%' }}
    >
      <DataTableHeader title={title} subtitle={subtitle} icon={icon} />
      <Select
        onChange={handleTimeframeChange}
        value={timeframe}
        variant="filled"
        size="xs"
        alignSelf="flex-start"
        width="100px"
        mb={0}
      >
        <option value="3y">3 Years</option>
        <option value="2y">2 Years</option>
        <option value="1y">1 Year</option>
        <option value="6m">6 Months</option>
        <option value="3m">3 Months</option>
        <option value="7d">7 Days</option>
      </Select>
      <Box flexGrow={1} minHeight='337px'>
        <div ref={chartRef} className={`chart-container-${uniqueChartId}`} style={{ height: '100%' }} />
      </Box>
      <DataTableFooter link={link} linkLabel={linkLabel} />
    </ThemedFlex>
  );
};