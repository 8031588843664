import React, { useEffect, useState } from 'react';
import ApexCharts from 'apexcharts';
import { DataTableHeader } from './DataTableHeader';
import { DataTableFooter } from './DataTableFooter';
import { ThemedFlex } from '../../theme/ThemedFlex'
import { Select, Box, useColorMode } from '@chakra-ui/react';
import { CustomSpinner } from './CustomSpinner';

export const LightningCapacityByCountryTile = ({
  title,
  subtitle,
  icon,
  link,
  linkLabel = 'Learn More',
  data,
}) => {
  const [chartData, setChartData] = useState([]);
  const [countryNames, setCountryNames] = useState([]);

  const { colorMode } = useColorMode();
  const labelColor = colorMode === 'dark' ? '#ffffff' : '#121212';

  const [selectedOption, setSelectedOption] = useState('capacity');
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    if (data && data.ts07 && data.ts07.lightning_nodes_country) {
      const sortedData = data.ts07.lightning_nodes_country
        .sort((a, b) =>
          selectedOption === 'capacity'
            ? parseFloat(b.capacity) - parseFloat(a.capacity)
            : parseInt(b.count) - parseInt(a.count)
        )
        .slice(0, 12);

      const newData = {
        name: selectedOption === 'capacity' ? 'Capacity' : 'Count',
        data: sortedData.map(country =>
          selectedOption === 'capacity'
            ? parseFloat(country.capacity) / 100000000 // Convert sats to BTC
            : parseInt(country.count)
        ),
      };

      setChartData(newData);
      setCountryNames(sortedData.map(country => country.name.en));
    }
  }, [data, selectedOption]);

  useEffect(() => {
    if (chartData.data && chartData.data.length > 0) {
      const options = {
        chart: {
          fontFamily: 'Ubuntu, sans-serif',
          type: 'bar',
          height: '100%',
          width: '100%',
          toolbar: {
            offsetY: -10,
            tools: {
              download: false,
            },
            padding: {
              bottom: 20,
            },
            color: '#78909C',
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        series: [chartData],
        colors: ["#f7931a", "#329239", "#0d579b", "#4d4d4d"],
        legend: {
          labels: {
            colors: '#999',
          },
        },
        dataLabels: {
          enabled: true,
          formatter: (value) => {
            if (selectedOption === 'capacity') {
              return `${value.toFixed(2)} BTC`;
            } else {
              return value.toLocaleString();
            }
          },
          style: {
            colors: [labelColor],
          },
        },
        stroke: {
          colors: ['#121212'],
        },
        tooltip: {
          theme: 'dark',
          style: {
            backgroundColor: '#000000',
            color: '#ffffff',
          },
          y: {
            formatter: (value) => {
              if (selectedOption === 'capacity') {
                return `${value.toFixed(2)} BTC`;
              } else {
                return `${value.toLocaleString()} nodes`;
              }
            },
          },
        },
        xaxis: {
          categories: countryNames,
          labels: {
            style: {
              colors: labelColor,
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: labelColor,
            },
          },
        },
      };
      
      const chart = new ApexCharts(document.querySelector('#chart19'), options);
      chart.render();

      const handleResize = () => {
        chart.updateOptions({
          chart: {
            width: '100%',
            height: '100%',
          },
        });
      };

      window.addEventListener('resize', handleResize);

      return () => {
        chart.destroy();
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [chartData, countryNames, selectedOption, labelColor]);

  if (!data || !data.ts07 || !data.ts07.lightning_nodes_country) {
    console.error("Error: Data is null, undefined, or incorrectly structured");
    return <CustomSpinner />;
  }

  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      style={{ minHeight: '337px', maxHeight: '100%' }}
    >
      <DataTableHeader title={title} subtitle={subtitle} icon={icon} />
      <Select
        value={selectedOption}
        onChange={handleOptionChange}
        variant="filled"
        size="xs"
        alignSelf="flex-start"
        width="100px"
        mb={0}
      >
        <option value="capacity">Capacity</option>
        <option value="count">Count</option>
      </Select>
      <Box flexGrow={1} minHeight="360px">
        <div id="chart19" style={{ height: '100%' }} />
      </Box>
      <DataTableFooter link={link} linkLabel={linkLabel} />
    </ThemedFlex>
  );
};