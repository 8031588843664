import React from 'react';
import { DataTable } from '../../data-display/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGears } from '@fortawesome/free-solid-svg-icons';
import { formatNumber, formatLargeNumber } from '../../../utils';
import { formatDate } from '../../../utils/date.utils';

export const DifficultyAdjustmentTable = ({ 
  data, 
  da,
  animate,
  onOpenLexicon,
}) => {
  // Calculate time since last adjustment
  const calculateTimeSinceAdjustment = (blockHeight) => {
    if (!blockHeight) return "Loading...";
    const blocksSinceAdjustment = blockHeight % 2016;
    const estimatedMinutesSinceAdjustment = blocksSinceAdjustment * 10;
    const days = Math.floor(estimatedMinutesSinceAdjustment / (24 * 60));
    const hours = Math.floor((estimatedMinutesSinceAdjustment % (24 * 60)) / 60);
    const minutes = estimatedMinutesSinceAdjustment % 60;
    
    let timeString = "";
    if (days > 0) timeString += `${days}d `;
    if (hours > 0) timeString += `${hours}h `;
    if (minutes > 0) timeString += `${minutes}m`;
    
    return timeString.trim();
  };

  const tableData = data && da
    ? [
        {
            label: 'Diff. Adj. Schedule',
            value: '2016 blocks',
            tooltipText: "The amount of blocks between Difficulty Adjustments",
            clickableTerm: "Difficulty Adjustment",
        },
        {
            label: 'Diff. Adj. Frequency',
            value: '~2 weeks',
            tooltipText: "The estimated number of days between Difficulty Adjustments",
            clickableTerm: "Difficulty Adjustment",
        },
        {
            label: 'Min. Difficulty',
            value: '1',
            tooltipText: "The minimum difficulty value possible in the Bitcoin codebase",
            clickableTerm: "Minimum Difficulty",
        },
        {
            label: 'Theoretical Max. Diff.',
            value: '~2^224',
            tooltipText: "This is a theoretical maximum difficulty value",
            clickableTerm: "Theoretical Maximum Difficulty",
        },
        {
            label: 'Difficulty Formula',
            value: 'Diff = D1T / CT',
            tooltipText: 'The formula for calculating the mining difficulty of the Bitcoin network. "Diff" represents the new difficulty target, "D1T" denotes the previous difficulty target, and "CT" stands for the cumulative time taken to mine the most recent set of blocks.',
            clickableTerm: "Difficulty Formula",
        },
        {
            label: 'Current Difficulty',
            value: data?.s03?.bitcoin_stats?.bitcoin_data?.difficulty != null 
              ? formatLargeNumber(data.s03.bitcoin_stats.bitcoin_data.difficulty, 1, true) 
              : "Loading...",
            tooltipText: "Current mining difficulty",
            clickableTerm: "Difficulty",
        },
        {
            label: 'Estimated Next Difficulty',
            value: data?.s03?.bitcoin_stats?.bitcoin_data?.next_difficulty_estimate != null 
              ? formatLargeNumber(data.s03.bitcoin_stats.bitcoin_data.next_difficulty_estimate, 1, true) 
              : "Loading...",
            tooltipText: "Estimated difficulty for the next adjustment",
            clickableTerm: "Next Difficulty",
        },
        {
            label: 'Next Diff. Change',
            value: data?.s03?.bitcoin_stats?.bitcoin_data?.next_difficulty_estimate != null && 
                   data?.s03?.bitcoin_stats?.bitcoin_data?.difficulty != null 
              ? `${((data.s03.bitcoin_stats.bitcoin_data.next_difficulty_estimate - data.s03.bitcoin_stats.bitcoin_data.difficulty) / data.s03.bitcoin_stats.bitcoin_data.difficulty * 100).toFixed(2)}%` 
              : "Loading...",
            tooltipText: "Percentage change between the current difficulty and the predicted next difficulty",
            clickableTerm: "Difficulty Change",
        },
        {
            label: 'Time Since Adjustment',
            value: data?.s03?.bitcoin_stats?.bitcoin_data?.best_block_height != null 
              ? calculateTimeSinceAdjustment(data.s03.bitcoin_stats.bitcoin_data.best_block_height)
              : "Loading...",
            tooltipText: "Time elapsed since the last difficulty adjustment",
            clickableTerm: "Time Since Difficulty Adjustment",
        },
        {
            label: 'Next Adjustment',
            value: da ? `Block ${formatNumber(da.nextRetargetHeight, 0)}` : "Loading...",
            tooltipText: "Bitcoin's difficulty adjusts every 2016 blocks to maintain a 10-minute block time target",
            clickableTerm: "Next Adjustment",
        },
        {
            label: 'Blocks Until Retarget',
            value: da ? `${formatNumber(da.remainingBlocks, 0)} Blocks` : "Loading...",
            tooltipText: "Number of blocks remaining until the next difficulty adjustment",
            clickableTerm: "Blocks Until Retarget",
        },
        {
            label: 'Next Adjustment Date',
            value: da ? formatDate(da.estimatedRetargetDate, true) : "Loading...",
            tooltipText: "Estimated date of the next difficulty adjustment",
            clickableTerm: "Next Adjustment Date",
        },
        {
            label: 'Previous Adjustment',
            value: da ? `${formatNumber(da.previousRetarget, 2)}%` : "Loading...",
            tooltipText: "Percentage change of the previous difficulty adjustment",
            clickableTerm: "Previous Adjustment",
        },
        {
            label: 'Adjustment Progress',
            value: da ? `${formatNumber(da.progressPercent, 2)}%` : "Loading...",
            tooltipText: "Percentage progress towards the next difficulty adjustment",
            clickableTerm: "Adjustment Progress",
        }
    ]
    : [];

  return (
    <DataTable
      title="Difficulty Adjustment"
      subtitle="Bitcoin mining difficulty metrics"
      icon={<FontAwesomeIcon icon={faGears} />}
      rows={tableData}
      animate={animate}
      specificTerm="Difficulty Adjustment"
      onOpenLexicon={onOpenLexicon}
      rowsPerPage={9}
    />
  );
};