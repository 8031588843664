import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Text,
  VStack,
  Box,
  useToast,
  SimpleGrid,
  useColorMode,
  useBreakpointValue,
  Flex,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCoins, 
  faFutbol, 
  faPlane, 
  faShip, 
  faHome, 
  faCar, 
  faPaintBrush, 
  faStopwatch 
} from '@fortawesome/free-solid-svg-icons';
import { faBitcoin } from '@fortawesome/free-brands-svg-icons';

const luxuryItems = [
  { name: "Football Team", cost: 9000000, icon: faFutbol },
  { name: "Private Jet", cost: 5000000, icon: faPlane },
  { name: "Luxury Yacht", cost: 2500000, icon: faShip },
  { name: "Mansion", cost: 2000000, icon: faHome },
  { name: "Sports Car", cost: 500000, icon: faCar },
  { name: "Fine Art Piece", cost: 300000, icon: faPaintBrush },
  { name: "Designer Watch", cost: 100000, icon: faStopwatch },
  { name: "Bitcoin Stack", cost: 50000, icon: faBitcoin },
];

const LuxuryStore = ({ isOpen, onClose, credits, setCredits, addCollectedItem }) => {
  const toast = useToast();
  const { colorMode } = useColorMode();
  const bgColor = colorMode === 'dark' ? '#121212' : '#ffffff';
  const color = colorMode === 'dark' ? 'white' : '#121212';
  const borderColor = colorMode === 'dark' ? '#ffffff' : '#121212';
  const columns = useBreakpointValue({ base: 1, sm: 2, md: 3, lg: 4 });

  const purchaseItem = (item) => {
    if (credits >= item.cost) {
      setCredits(prevCredits => prevCredits - item.cost);
      addCollectedItem(item);
      toast({
        duration: 4000,
        position: "top",
        variant: "solid",
        render: () => (
          <Box color='white' p={3} bg='green.500' borderRadius={3}>
            You've acquired a {item.name}!
          </Box>
        ),
      });
    } else {
      toast({
        duration: 4000,
        position: "top",
        variant: "solid",
        render: () => (
          <Box color='white' p={3} bg='red.500' borderRadius={3}>
            Insufficient credits. You need {item.cost - credits} more credits for this purchase.
          </Box>
        ),
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{ base: "full", md: "3xl" }}>
      <ModalOverlay />
      <ModalContent
        bg={bgColor}
        color={color}
        borderWidth="2px"
        borderStyle="solid"
        borderColor={borderColor}
        borderRadius={{ base: "0", md: "6px" }}
        mt={{ base: '0', md: '5%' }}
        pb={{ base: '0', md: '2%' }}
        h={{ base: "100vh", md: "auto" }}
      >
        <ModalHeader>Spend Credits</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <Text mb={4}>
            Available Credits: <FontAwesomeIcon icon={faCoins} /> {credits.toFixed(2)}
          </Text>
          <SimpleGrid columns={columns} spacing={4}>
            {luxuryItems.map((item, index) => (
              <Box key={index} borderWidth="1px" borderRadius="lg" p={4}>
                <VStack align="stretch">
                  <Flex align="center" justify="center" mb={2}>
                    <FontAwesomeIcon icon={item.icon} size="2x" />
                  </Flex>
                  <Text fontWeight="bold" textAlign="center">{item.name}</Text>
                  <Text textAlign="center">
                    <FontAwesomeIcon icon={faCoins} /> {item.cost.toLocaleString()}
                  </Text>
                  <Button
                    onClick={() => purchaseItem(item)}
                    colorScheme="orange"
                    isDisabled={credits < item.cost}
                  >
                    Purchase
                  </Button>
                </VStack>
              </Box>
            ))}
          </SimpleGrid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default LuxuryStore;