import { extendTheme } from '@chakra-ui/react';

const breakpoints = {
  xs: '360px',
  sm: '480px',
  'sm2': '660px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  '2xl': '1600px',
};

export const theme = extendTheme({
  breakpoints,
  styles: {
    global: (props) => ({
      body: {
        bg: props.colorMode === 'dark' ? 'primary.700' : 'secondary.500',
        fontFamily: `'Ubuntu', sans-serif`,
      },
    }),
  },

  colors: {
    primary: {
      300: '#272727',
      500: '#1d1d1d',
      700: '#121212',
    },
    secondary: {
      300: '#0e0e0e',
      500: '#e8e8e8',
      700: '#f5f5f5',
    },
    gray: {
      100: '#e0e0e0',
      101: '#d7d7d7',
      200: '#999999',
      300: '#666666',
      500: '#4d4d4d',
      700: '#777777',
      900: '#e8e8e8',
    },
    orange: {
      100: '#fae1b1',
      300: '#ffc451',
      500: '#f7931a',
      700: '#be6500',
    },
    blue: {
      100: '#dfedff',
      300: '#b3d4fc',
      400: '#4a96d4',
      500: '#0d579b',
      700: '#002f6c',
    },
    green: {
      300: '#a5d6a7',
      500: '#329239',
      700: '#1b5e20',
    },
    purple: {
      300: '#ba68c8',
      500: '#8B00FF',
      700: '#6B238E',
    },
    pink: {
      300: '#ff77a9',
      500: '#FF007F',
      700: '#D70060',
    },
    black: {
      500: '#000000',
    },
    white: {
      500: '#ffffff',
    },
    icon: {
      light: '#f7931a',
      dark: '#ffffff',
    },
    link: {
      light: '#f7931a',
      dark: '#0d579b',
    },
  },
  fontSizes: {
    md: '14px',
    xs: '10px',
  },
  components: {
    Select: {
      variants: {
        filled: {
          field: {
            _focus: {
              borderColor: '#f7931a',
            },
          },
        },
      },
    },
  },
});
