import React, { useEffect, useState, useRef } from 'react';
import ApexCharts from 'apexcharts';
import { DataTableHeader } from './DataTableHeader';
import { DataTableFooter } from './DataTableFooter';
import { ThemedFlex } from '../../theme/ThemedFlex'
import { Select, Box, useColorMode } from '@chakra-ui/react';
import { CustomSpinner } from './CustomSpinner';

const getBreakpoint = (width) => {
  if (width < 768) return 'base';
  if (width >= 768 && width < 992) return 'md';
  if (width >= 992 && width < 1200) return 'lg';
  return 'xl';
};

const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(() => getBreakpoint(window.innerWidth));

  useEffect(() => {
    const handleResize = () => setBreakpoint(getBreakpoint(window.innerWidth));
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return breakpoint;
};

export const LightningNodesByCountryTile = ({
  title,
  subtitle,
  icon,
  link,
  linkLabel = 'Learn More',
  data,
  uniqueChartId,
}) => {
  const [chartData, setChartData] = useState([]);

  const chartRef = useRef(null);

  const { colorMode } = useColorMode();
  const borderColor = colorMode === 'dark' ? '#121212' : '#ffffff';

  const [selectedOption, setSelectedOption] = useState('capacity');
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const formatCapacity = (capacity) => {
    return (capacity / 100000000).toFixed(2) + ' BTC';
  };

  useEffect(() => {
    if (data && data.ts07 && data.ts07.lightning_nodes_country) {
      const sortedData = data.ts07.lightning_nodes_country
        .sort((a, b) => b.share - a.share)
        .slice(0, 12);
      const newData = sortedData.map(country => ({
        y: selectedOption === 'capacity' ? parseInt(country.capacity) : parseInt(country.count),
        x: country.name.en,
      }));

      setChartData(newData);
    }
  }, [data, selectedOption]);

  const breakpoint = useBreakpoint();

  useEffect(() => {
    if (chartData.length > 0) {
      const options = {
        chart: {
          fontFamily: 'Ubuntu, sans-serif',
          type: 'pie',
          height: '100%',
          width: '100%',
          toolbar: {
            offsetY: -10,
            tools: {
              download: false,
            },
            padding: {
              bottom: 20,
            },
            color: '#78909C',
          },
        },
        labels: chartData.map(data => data.x),
        series: chartData.map(data => data.y),
        colors: ["#f7931a", "#329239", "#0d579b", "#4d4d4d"],
        legend: {
          labels: {
            colors: '#999',
          },
          show: breakpoint === 'md' || breakpoint === 'lg' || breakpoint === 'xl',
        },
        dataLabels: {
          dropShadow: {
            enabled: false
          },
          style: {
            colors: ['#121212'],
          },
        },
        stroke: {
          colors: [borderColor],
        },
        tooltip: {
          theme: 'dark',
          style: {
            backgroundColor: '#000000',
            color: '#ffffff',
          },
          y: {
            formatter: (value) => {
              if (selectedOption === 'capacity') {
                return formatCapacity(value);
              } else {
                return `${new Intl.NumberFormat().format(value)} Nodes`;
              }
            },
          },
        },
      };
      
      const chart = new ApexCharts(chartRef.current, options);
      chart.render();

      const handleResize = () => {
        chart.updateOptions({
          chart: {
            width: '100%',
            height: '100%',
          },
        });
      };

      window.addEventListener('resize', handleResize);

      return () => {
        chart.destroy();
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [chartData, selectedOption, borderColor, breakpoint]);

  if (!data || !data.ts07 || !data.ts07.lightning_nodes_country) {
    console.error("Error: Data is null, undefined, or incorrectly structured");
    return <CustomSpinner />;
  }

  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      style={{ minHeight: '337px', maxHeight: '100%' }}
    >
      <DataTableHeader title={title} subtitle={subtitle} icon={icon} />
      <Select
        value={selectedOption}
        onChange={handleOptionChange}
        variant="filled"
        size="xs"
        alignSelf="flex-start"
        width="100px"
        mb={0}
      >
        <option value="capacity">Capacity</option>
        <option value="count">Count</option>
      </Select>

      <Box flexGrow={1} minHeight='337px'>
        <div ref={chartRef} className={`chart-container-${uniqueChartId}`} style={{ height: '100%' }} />
      </Box>
      <DataTableFooter link={link} linkLabel={linkLabel} />
    </ThemedFlex>
  );
};