import React from 'react';
import { DataTable } from '../../data-display/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGem } from '@fortawesome/free-solid-svg-icons';
import { formatNumber, formatLargeNumber } from '../../../utils';
import { currencySymbols } from '../../../utils/currencyConversion.utils';

export const MiningInfoTable = ({ 
  data, 
  bitcoinPrice, 
  conversionRates, 
  selectedCurrency, 
  animate,
  onOpenLexicon,
  da,
  fees
}) => {
  const tableData = data && bitcoinPrice && conversionRates && selectedCurrency && da && fees
    ? [
        {
            label: 'Genesis Block Date',
            value: data?.s06?.bitcoin?.genesis_date || "January 3, 2009",
            tooltipText: "Genesis Block is the name of the first block of Bitcoin ever mined—thus called 'Genesis.' The Genesis Block forms the foundation of the entire Bitcoin trading system.",
            clickableTerm: "Genesis Block",
        },
        {
            label: 'Mining Algorithm',
            value: data?.s06?.bitcoin?.hashing_algorithm || "SHA-256",
            tooltipText: "The SHA-256 hash algorithm takes input blocks of 512 bits and generates a 256-bit output. The algorithm consists of a relatively simple round repeated 64 times.",
            clickableTerm: "Mining Algorithm",
        },
        {
            label: 'Difficulty',
            value: data?.s03?.bitcoin_stats?.bitcoin_data
              ? formatLargeNumber(data.s03.bitcoin_stats.bitcoin_data.difficulty, 1, true)
              : "Loading...",
            tooltipText: "Difficulty is a value used to show how hard is it to find a hash that will be lower than target defined by system.",
            clickableTerm: "Difficulty",
        },
        {
            label: 'Predicted Next Diff.',
            value: data?.s03?.bitcoin_stats?.bitcoin_data
              ? formatLargeNumber(data.s03.bitcoin_stats.bitcoin_data.next_difficulty_estimate, 1, true)
              : "Loading...",
            tooltipText: "Estimated next Difficulty Adjustment based on current hash rate",
            clickableTerm: "Next Difficulty",
        },
        {
            label: 'Low Fee per vByte',
            value: fees ? `${formatNumber((fees.minimumFee), 0)} Sats/vByte` : "Loading...",
            tooltipText: 'Recommended Low Fee per vByte',
            clickableTerm: "Transaction Fee",
        },
        {
            label: 'Medium Fee per vByte',
            value: fees ? `${formatNumber((fees.hourFee), 0)} Sats/vByte` : "Loading...",
            tooltipText: 'Recommended Medium Fee per vByte',
            clickableTerm: "Transaction Fee",
        },
        {
            label: 'High Fee per vByte',
            value: fees ? `${formatNumber((fees.fastestFee), 0)} Sats/vByte` : "Loading...",
            tooltipText: 'Recommended High Fee per vByte',
            clickableTerm: "Transaction Fee",
        },
        {
            label: 'Previous Adjustment',
            value: da ? `${formatNumber(da.previousRetarget, 2)}%` : "Loading...",
            tooltipText: "Percentage change of the previous mining difficulty adjustment.",
            clickableTerm: "Difficulty Adjustment",
        },
        {
            label: 'Next Adjustment',
            value: da ? `${formatNumber(da.difficultyChange, 2)}%` : "Loading...",
            tooltipText: "Estimated percentage change for the next mining difficulty adjustment.",
            clickableTerm: "Difficulty Adjustment",
        },
        {
            label: 'Blocks Until Adjustment',
            value: da ? `${da.remainingBlocks}` : "Loading...",
            tooltipText: "Number of blocks remaining until the next difficulty adjustment.",
            clickableTerm: "Blocks Until Retarget",
        },
        {
            label: 'Adjustment Progress',
            value: da ? `${formatNumber(da.progressPercent, 2)}%` : "Loading...",
            tooltipText: "Percentage progress towards the next difficulty adjustment.",
            clickableTerm: "Difficulty Adjustment",
        },
        {
            label: 'Last Block Reward Value',
            value: data?.ts11?.rewards1 && bitcoinPrice
              ? `${currencySymbols[selectedCurrency]}${formatLargeNumber((data.ts11.rewards1.totalReward * (bitcoinPrice * conversionRates[selectedCurrency])) / 100000000, 2, false)} ${selectedCurrency.toUpperCase()}`
              : "Loading...",
            tooltipText: "Current value of the block reward",
            clickableTerm: "Block Reward",
        }
    ]
    : [];

  return (
    <DataTable
      title="Mining Info"
      subtitle="Informative datapoints: BTC mining"
      icon={<FontAwesomeIcon icon={faGem} />}
      rows={tableData}
      animate={animate}
      specificTerm="Mining"
      onOpenLexicon={onOpenLexicon}
      rowsPerPage={9}
    />
  );
};