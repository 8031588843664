import React from 'react';
import { DataTable } from '../../data-display/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { formatNumber, formatLargeNumber } from '../../../utils';
import { currencySymbols } from '../../../utils/currencyConversion.utils';

export const NetworkSecurityTable = ({ 
  data, 
  bitcoinPrice, 
  conversionRates, 
  selectedCurrency, 
  animate,
  onOpenLexicon,
  fees 
}) => {
  const tableData = data && bitcoinPrice && conversionRates && selectedCurrency && fees
    ? [
        {
            label: 'Avg Hash Rate 30d',
            value: data?.s12?.metrics?.data?.mining_stats?.hash_rate_30d_average 
              ? `${formatLargeNumber(data.s12.metrics.data.mining_stats.hash_rate_30d_average, 2, true)}H/s`
              : "Loading...",
            tooltipText: "30-day moving average of the Bitcoin network hash rate",
            clickableTerm: "Hash Rate",
        },
        {
            label: 'Mining Algorithm',
            value: 'SHA-256',
            tooltipText: "The SHA-256 hash algorithm takes input blocks of 512 bits, combines the data cryptographically, and generates a 256-bit output. The algorithm consists of a relatively simple round repeated 64 times.",
            clickableTerm: "Mining Algorithm",
        },
        {
            label: 'Hash Rate',
            value: data?.ts04?.hashrate?.currentHashrate != null
              ? `${formatLargeNumber(data.ts04.hashrate.currentHashrate, 0, true)}H/s` 
              : "Loading...",
            tooltipText: "The hash rate is the measuring unit of the processing power of the Bitcoin network.",
            clickableTerm: "Hash Rate",
        },
        {
            label: 'Mining Revenue 24h',
            value: data?.s12?.metrics?.data?.mining_stats?.mining_revenue_usd && bitcoinPrice && conversionRates
              ? `${currencySymbols[selectedCurrency]}${formatLargeNumber(data.s12.metrics.data.mining_stats.mining_revenue_usd * conversionRates[selectedCurrency], 1, false)} ${selectedCurrency.toUpperCase()}`
              : "Loading...",
            tooltipText: "Value of the total mining revenue (subsidy + fees) over the last 24h",
            clickableTerm: "Mining Revenue",
        },
        {
            label: 'Mining Revenue Fees %',
            value: data?.s12?.metrics?.data?.mining_stats?.mining_revenue_from_fees_percent_last_24_hours !== undefined
              ? `${formatNumber(data.s12.metrics.data.mining_stats.mining_revenue_from_fees_percent_last_24_hours, 2)}%`
              : "Loading...",
            tooltipText: "Percentage of mining revenue that comes from transaction fees in the last 24 hours",
            clickableTerm: "Mining Revenue",
        },
        {
            label: 'High Fee per vByte',
            value: fees ? `${formatNumber((fees.fastestFee), 0)} Sats/vByte` : "Loading...",
            tooltipText: 'Recommended High Fee per vByte',
            clickableTerm: "Transaction Fee",
        },
        {
            label: 'Timechain Size',
            value: data?.s03?.bitcoin_stats?.bitcoin_data?.blockchain_size != null
              ? `${formatLargeNumber(data.s03.bitcoin_stats.bitcoin_data.blockchain_size, 1, true)}B`
              : "Loading...",
            tooltipText: "Size of the Bitcoin blockchain in Bytes",
            clickableTerm: "Timechain",
        },
        {
            label: 'Average Block Time',
            value: data?.s12?.metrics?.data?.on_chain_data?.average_block_interval != null
              ? `${formatNumber(data.s12.metrics.data.on_chain_data.average_block_interval / 60, 1)} Minutes`
              : "Loading...",
            tooltipText: "The average block time in the last 24 hours.",
            clickableTerm: "Average Block Time",
        },
        {
            label: 'Max Supply Still 21M?',
            value: 'TRUE',
            tooltipText: "Bitcoin's maximum supply remains hard-capped at 21M BTC.",
            clickableTerm: "Scarcity",
        },
        {
            label: 'Victim of 51% Attack?',
            value: 'FALSE',
            tooltipText: "A 51% attack is a potential attack on a blockchain network, where a single entity or organization is able to control the majority of the hash rate. This metric tracks whether or not the Bitcoin network has been successfully 51% attacked.",
            clickableTerm: "51% Attack",
        }
    ]
    : [];

  return (
    <DataTable
      title="Network Security"
      subtitle="Protecting the Bitcoin timechain"
      icon={<FontAwesomeIcon icon={faLock} />}
      rows={tableData}
      animate={animate}
      specificTerm="Network Security"
      onOpenLexicon={onOpenLexicon}
      rowsPerPage={9}
    />
  );
};