// DonationsRoute.jsx
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DonationsModal } from './DonationsModal';

export const DonationsRoute = ({ isOpen, onClose, donateForm }) => {
  const location = useLocation();
  const navigate = useNavigate();

  // Effect to handle URL-based modal opening
  useEffect(() => {
    if (location.pathname === '/donate' && !isOpen) {
      onClose(true);
    }
  }, [location.pathname, isOpen, onClose]);

  const handleClose = () => {
    // First, update the modal state
    onClose(false);
    
    // Then handle navigation
    if (location.pathname === '/donate') {
      // Use replace instead of pushing to history to avoid duplicate entries
      navigate('/', { replace: true });
    }
  };

  return (
    <DonationsModal
      isOpen={isOpen}
      onClose={handleClose}
      donateForm={donateForm}
    />
  );
};