import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    VStack,
    HStack,
    Box,
    Text,
    Button,
    SimpleGrid,
    useColorMode,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Input,
    InputGroup,
    InputLeftAddon,
    useTheme,
    Flex,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faIndustry, faRedo, faArrowUp, faCheck, faExclamationCircle, faCoins, faGamepad } from '@fortawesome/free-solid-svg-icons';
import AchievementBadge from './components/AchievementBadge';
import { BADGE_CONFIGS } from './config/badgeConfigs';
import GameInfoNonceHunter from './GameInfoNonceHunter';

const BASE_GRID_SIZE = 9;
const BASE_MINE_COUNT = 10;
const BASE_NONCE_LENGTH = 4;
const BASE_MEGAHASH_DELAY = 1000;
const INITIAL_CREDITS = 1000;

const NonceHunter = ({ isOpen, onClose }) => {
    const { colorMode } = useColorMode();
    const theme = useTheme();

    const [credits, setCredits] = useState(() => {
        const savedCredits = localStorage.getItem('nonceHunterCredits');
        return savedCredits ? parseInt(savedCredits, 10) : INITIAL_CREDITS;
    });
    const [level, setLevel] = useState(1);
    const [gridSize, setGridSize] = useState(BASE_GRID_SIZE);
    const [mineCount, setMineCount] = useState(BASE_MINE_COUNT);
    const [nonceLength, setNonceLength] = useState(BASE_NONCE_LENGTH);
    const [hashCorpDelay, setHashCorpDelay] = useState(BASE_MEGAHASH_DELAY);
    const [grid, setGrid] = useState([]);
    const [nonce, setNonce] = useState('');
    const [playerGuess, setPlayerGuess] = useState('');
    const [hashCorpGuess, setHashCorpGuess] = useState('');
    const [gameStatus, setGameStatus] = useState('playing');
    const [revealedCells, setRevealedCells] = useState([]);
    const [turn, setTurn] = useState('player');
    const [showBadge, setShowBadge] = useState(null);

    const bgColor = useMemo(() => colorMode === 'dark' ? theme.colors.primary[700] : theme.colors.secondary[500], [colorMode, theme.colors]);
    const borderColor = useMemo(() => colorMode === 'dark' ? theme.colors.white[500] : theme.colors.black[500], [colorMode, theme.colors]);

    const calculateDifficulty = useCallback((size, length) => {
        let gridDifficulty = 0;
        let nonceDifficulty = 0;

        // Grid size difficulty
        if (size <= 9) gridDifficulty = 1;
        else if (size <= 10) gridDifficulty = 2;
        else if (size <= 11) gridDifficulty = 3;
        else gridDifficulty = 4;

        // Nonce length difficulty
        if (length <= 4) nonceDifficulty = 1;
        else if (length <= 5) nonceDifficulty = 2;
        else if (length <= 6) nonceDifficulty = 3;
        else nonceDifficulty = 4;

        // Combined difficulty
        const avgDifficulty = (gridDifficulty + nonceDifficulty) / 2;

        if (avgDifficulty <= 1.5) return 'Easy';
        if (avgDifficulty <= 2.5) return 'Medium';
        if (avgDifficulty <= 3.5) return 'Hard';
        return 'Expert';
    }, []);

    const difficulty = useMemo(() => calculateDifficulty(gridSize, nonceLength), [calculateDifficulty, gridSize, nonceLength]);


    const generateGrid = useCallback((size, mineCount) => {
        const newGrid = Array(size).fill().map(() => Array(size).fill(null));
        let minesPlaced = 0;
        while (minesPlaced < mineCount) {
            const row = Math.floor(Math.random() * size);
            const col = Math.floor(Math.random() * size);
            if (!newGrid[row][col]) {
                newGrid[row][col] = 'mine';
                minesPlaced++;
            }
        }
        return newGrid;
    }, []);

    const generateNonce = useCallback((length) => {
        return Math.random().toString(36).substring(2, 2 + length);
    }, []);

    const initializeGame = useCallback(() => {
        const newGrid = generateGrid(gridSize, mineCount);
        setGrid(newGrid);
        setNonce(generateNonce(nonceLength));
        setPlayerGuess('');
        setHashCorpGuess('');
        setGameStatus('playing');
        setRevealedCells(Array(gridSize).fill().map(() => Array(gridSize).fill(false)));
        setTurn('player');
    }, [generateGrid, generateNonce, gridSize, mineCount, nonceLength]);

    useEffect(() => {
        if (isOpen) {
            initializeGame();
        }
    }, [isOpen, initializeGame]);

    useEffect(() => {
        localStorage.setItem('nonceHunterCredits', credits.toString());
    }, [credits]);

    const hashCorpTurn = useCallback(() => {
        if (gameStatus !== 'playing') return;
        let row, col;
        do {
            row = Math.floor(Math.random() * gridSize);
            col = Math.floor(Math.random() * gridSize);
        } while (revealedCells[row][col]);

        const newRevealedCells = [...revealedCells];
        newRevealedCells[row][col] = true;
        setRevealedCells(newRevealedCells);

        if (grid[row][col] === 'mine') {
            const newHashCorpGuess = hashCorpGuess + nonce[hashCorpGuess.length];
            setHashCorpGuess(newHashCorpGuess);
            if (newHashCorpGuess.length === nonceLength) {
                setGameStatus('megahash_won');
                setCredits(prev => Math.max(0, prev - 100)); // Deduct credits for losing
            } else {
                setTurn('player');
            }
        } else {
            setTurn('player');
        }
    }, [gameStatus, gridSize, revealedCells, grid, hashCorpGuess, nonce, nonceLength]);

    useEffect(() => {
        if (turn === 'megahash' && gameStatus === 'playing') {
            const timer = setTimeout(hashCorpTurn, hashCorpDelay);
            return () => clearTimeout(timer);
        }
    }, [turn, gameStatus, hashCorpDelay, hashCorpTurn]);

    const handleCellClick = useCallback((row, col) => {
        if (gameStatus !== 'playing' || turn !== 'player' || revealedCells[row][col]) return;

        const newRevealedCells = [...revealedCells];
        newRevealedCells[row][col] = true;
        setRevealedCells(newRevealedCells);

        if (grid[row][col] === 'mine') {
            const newPlayerGuess = playerGuess + nonce[playerGuess.length];
            setPlayerGuess(newPlayerGuess);
            if (newPlayerGuess.length === nonceLength) {
                setGameStatus('player_won');
                setCredits(prev => prev + 200); // Award credits for winning
                if (level >= 5) {
                    setShowBadge(BADGE_CONFIGS.bitcoinMiningMaster);
                } else if (level >= 3) {
                    setShowBadge(BADGE_CONFIGS.bitcoinMiningExpert);
                } else {
                    setShowBadge(BADGE_CONFIGS.bitcoinMiningNovice);
                }
            } else {
                setTurn('megahash');
            }
        } else {
            setTurn('megahash');
        }
    }, [gameStatus, turn, revealedCells, grid, playerGuess, nonce, nonceLength, level]);

    const increaseDifficulty = useCallback(() => {
        setLevel(prevLevel => prevLevel + 1);
        setGridSize(prevSize => Math.min(prevSize + 1, 12));
        setMineCount(prevCount => Math.floor(prevCount * 1.2));
        setNonceLength(prevLength => Math.min(prevLength + 1, 8));
        setHashCorpDelay(prevDelay => Math.max(prevDelay - 100, 300));
    }, []);

    const restartGame = useCallback((nextLevel = false) => {
        if (nextLevel) {
            increaseDifficulty();
        }
        initializeGame();
    }, [increaseDifficulty, initializeGame]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="2xl">
    <ModalOverlay backgroundColor="rgba(0, 0, 0, 0.95)" />            <ModalContent
                bg={bgColor}
                pb={6}
                borderWidth="2px"
                borderStyle="solid"
                borderColor={borderColor}
                borderRadius="6px"
            >
                <ModalHeader><Flex align="center" justify="flex-start">
                    <FontAwesomeIcon icon={faGamepad} style={{ marginRight: '10px' }} />
                    <Text>Nonce Hunt</Text>
                </Flex>
                    <Text fontSize="sm" fontWeight="normal" color="gray">
                        Find the nonce before evil HashCorp can!
                    </Text>

                </ModalHeader>
                <GameInfoNonceHunter />
                <ModalCloseButton />
                <ModalBody>
                    <VStack spacing={4} align="stretch">
                        <HStack justify="space-between">
                            <InputGroup size="sm" width="auto">
                                <InputLeftAddon>
                                    <FontAwesomeIcon icon={faCoins} />
                                </InputLeftAddon>
                                <Input value={credits} isReadOnly width="100px" />
                            </InputGroup>
                        </HStack>
                        <SimpleGrid columns={2} spacing={2}>
                            <Text color={'orange.500'} fontWeight={'bold'}><FontAwesomeIcon icon={faUser} /> &nbsp;You: {playerGuess}</Text>
                            <Text color={'orange.500'} fontWeight={'bold'}><FontAwesomeIcon icon={faIndustry} /> &nbsp;HashCorp: {hashCorpGuess}</Text>
                        </SimpleGrid>

                        <Box>
                            <SimpleGrid columns={gridSize} spacing={1}>
                                {grid.map((row, rowIndex) =>
                                    row.map((cell, colIndex) => (
                                        <Button
                                            key={`${rowIndex}-${colIndex}`}
                                            size="sm"
                                            onClick={() => handleCellClick(rowIndex, colIndex)}
                                            isDisabled={gameStatus !== 'playing' || turn !== 'player' || revealedCells[rowIndex][colIndex]}
                                            bg={revealedCells[rowIndex][colIndex] ? (cell === 'mine' ? 'orange.500' : 'red.300') : 'gray.200'}
                                            _hover={{ bg: revealedCells[rowIndex][colIndex] ? (cell === 'mine' ? 'yellow.400' : 'red.400') : 'gray.300' }}
                                        >
                                            {revealedCells[rowIndex][colIndex] && (
                                                <FontAwesomeIcon icon={cell === 'mine' ? faCheck : faExclamationCircle} />
                                            )}
                                        </Button>
                                    ))
                                )}
                            </SimpleGrid>
                        </Box>

                        {gameStatus !== 'playing' && (
                            <Alert status={gameStatus === 'player_won' ? 'success' : 'error'}>
                                <AlertIcon />
                                <AlertTitle>{gameStatus === 'player_won' ? 'Congratulations!' : 'Game Over!'}</AlertTitle>
                                <AlertDescription>
                                    {gameStatus === 'player_won' ? "You found the nonce before HashCorp!" : "HashCorp found the nonce first."}
                                </AlertDescription>
                            </Alert>
                        )}

                        <SimpleGrid columns={4} spacing={2}>
                            <Text fontSize={'12px'}>Nonce Length: {nonceLength}</Text>
                            <Text fontSize={'12px'}>Difficulty: {difficulty}</Text>
                            <Text fontSize={'12px'}>Workers: {mineCount}</Text>
                            <Text fontSize={'12px'}>Turn: {turn === 'player' ? 'Player' : 'HashCorp'}</Text>
                        </SimpleGrid>

                        <HStack spacing={2}>
                            <Button leftIcon={<FontAwesomeIcon icon={faRedo} />} onClick={() => restartGame(false)}>
                                Restart Level
                            </Button>
                            {gameStatus === 'player_won' && (
                                <Button leftIcon={<FontAwesomeIcon icon={faArrowUp} />} onClick={() => restartGame(true)} variant="outline">
                                    Next Level
                                </Button>
                            )}
                        </HStack>
                    </VStack>

                    {showBadge && (
                        <AchievementBadge
                            onClose={() => setShowBadge(null)}
                            badgeConfig={showBadge}
                            downloadFileName={showBadge.downloadFileName}
                            shareText={showBadge.shareText}
                        />
                    )}

                    <Text fontSize="xs" color={theme.colors.gray[300]} textAlign="justify" mt={4}>
                        <strong>Disclaimer:</strong> The Nonce Hunter game is for educational purposes only. It does not involve real money and is designed to simulate the process of mining Bitcoin in a simplified manner.
                    </Text>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default NonceHunter;