import React, { useEffect, useRef, useState, useCallback } from "react";
import { Flex, Box, Text, useColorMode, Tooltip } from "@chakra-ui/react";
import { formatNumber, formatLargeNumber } from "../../utils";
import { recentBlocksState } from "../../recoilState";
import { useRecoilState, useRecoilValue } from "recoil";
import { differenceInSeconds, differenceInMinutes, differenceInHours, differenceInDays } from "date-fns";
import { selectedCurrencyState } from '../../recoilState';
import { createCurrencyConverter } from '../../utils/currencyConversion.utils';
import { ThemedIcon } from "../../theme/ThemedIcon";
import { faHourglassEnd } from "@fortawesome/free-solid-svg-icons";
import { CustomSpinner } from "./CustomSpinner";

const BlockTimeIndicator = ({ blockTime, expectedBlockTime, forceWhiteText = false }) => {
    const formatTime = (seconds) => {
        const minutes = Math.floor(Math.abs(seconds) / 60);
        const remainingSeconds = Math.abs(seconds) % 60;
        return `${minutes}m ${remainingSeconds}s`;
    };

    const timeDifference = blockTime - expectedBlockTime;
    const signedDifference = timeDifference > 0
        ? `+${formatTime(timeDifference)}`
        : `-${formatTime(Math.abs(timeDifference))}`;

    const color = forceWhiteText ? "white" : (timeDifference > 0 ? "red.500" : "green.500");

    const tooltipText =
        `Block Time: ${formatTime(blockTime)}
    Expected: ${formatTime(expectedBlockTime)}
    Difference: ${signedDifference}`;

    return (
        <Tooltip
            label={tooltipText}
            aria-label="Block Time Tooltip"
            fontSize="13px"
            lineHeight="20px"
            bg="#000"
            color="#fff"
            p="10px 20px"
            border="1px solid #fff"
            borderRadius="3px"
            boxShadow="0 0 4px #000"
            whiteSpace="pre-line"
        >
            <Flex alignItems="center" justifyContent="center">
                <ThemedIcon icon={faHourglassEnd} size="sm" style={{ marginRight: '4px' }} />
                <Text
                    as="span"
                    lineHeight={"1.2em"}
                    fontWeight="700"
                    color={color}
                    className="unselectable"
                >
                    {formatTime(blockTime)}
                </Text>
            </Flex>
        </Tooltip>
    );
};

export const RecentBlocks = ({
    bitcoinPrice = 0,
    animate = false,
    data = {},
}) => {
    const scrollRef = useRef(null);
    const recentBlocks = useRecoilValue(recentBlocksState);
    const { colorMode } = useColorMode();
    const [selectedCurrency] = useRecoilState(selectedCurrencyState);
    const [conversionRates, setConversionRates] = useState({});

    const boxShadow = colorMode === 'dark' ? '0 1px 3px rgba(0, 0, 0, 0.12)' : '0 1px 2px rgba(0, 0, 0, 0.24)';
    const altTextColor = colorMode === 'dark' ? '#ffffff' : '#121212';
    const boxColor = colorMode === 'dark' ? 'primary.300' : '#ffffff';
    const latestBlockColor = colorMode === 'dark' ? "orange.500" : "orange.500";
    const latestBlockTextColor = colorMode === 'dark' ? "white" : "white";
    const emptyBlockTransactionCount = 1;

    useEffect(() => {
        if (data) {
            try {
                const newConversionRates = createCurrencyConverter(data);
                setConversionRates(newConversionRates);
            } catch (error) {
                console.error("Error creating currency converter:", error);
            }
        }
    }, [data]);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollLeft = scrollRef.current.scrollWidth;
        }
    }, [scrollRef]);

    const convertToSelectedCurrency = useCallback((satoshis) => {
        if (!bitcoinPrice || !conversionRates[selectedCurrency]) {
            return 0;
        }
        const btcAmount = satoshis / 100000000;
        const usdAmount = btcAmount * bitcoinPrice;
        return usdAmount * (conversionRates[selectedCurrency] || 1);
    }, [bitcoinPrice, conversionRates, selectedCurrency]);

    const getCurrencyDisplay = useCallback((amount, useLargeNumberFormat = false) => {
        const currencySymbols = {
            aud: '🇦🇺 A$', ars: '🇦🇷 $', brl: '🇧🇷 R$', cad: '🇨🇦 C$', chf: '🇨🇭 Fr',
            czk: '🇨🇿 Kč', dkk: '🇩🇰 kr', eur: '🇪🇺 €', gbp: '🇬🇧 £', inr: '🇮🇳 ₹',
            jpy: '🇯🇵 ¥', krw: '🇰🇷 ₩', mxn: '🇲🇽 $', nok: '🇳🇴 kr', nzd: '🇳🇿 NZ$',
            php: '🇵🇭 ₱', pln: '🇵🇱 zł', sek: '🇸🇪 kr', sgd: '🇸🇬 S$', usd: '🇺🇸 $'
        };

        const symbol = currencySymbols[selectedCurrency] || '';
        const value = convertToSelectedCurrency(amount);

        let formattedValue;
        if (useLargeNumberFormat) {
            formattedValue = formatLargeNumber(value, 1, false);
        } else {
            switch (selectedCurrency) {
                case 'jpy':
                case 'krw':
                    formattedValue = Math.round(value).toLocaleString();
                    break;
                case 'brl':
                case 'pln':
                    formattedValue = value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                    break;
                default:
                    formattedValue = value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            }
        }

        return `${symbol}${formattedValue}`;
    }, [selectedCurrency, convertToSelectedCurrency]);

    // Data Availability Check
    if (!recentBlocks || recentBlocks.length === 0 || !bitcoinPrice || !data || !selectedCurrency) {
        return <CustomSpinner />;
    }

    const getTimeSinceBlock = (blockTimestamp) => {
        const now = new Date();
        const blockDate = new Date(blockTimestamp * 1000);
        const secondsDiff = differenceInSeconds(now, blockDate);
        const minutesDiff = differenceInMinutes(now, blockDate);
        const hoursDiff = differenceInHours(now, blockDate);
        const daysDiff = differenceInDays(now, blockDate);

        if (daysDiff > 0) {
            return `${daysDiff}d ${hoursDiff % 24}h ago`;
        } else if (hoursDiff > 0) {
            return `${hoursDiff}h ${minutesDiff % 60}m ago`;
        } else if (minutesDiff > 0) {
            return `${minutesDiff}m ago`;
        } else {
            return `${secondsDiff}s ago`;
        }
    };

    const blockTimes = recentBlocks.map((block, index) => {
        if (index === 0) {
            return null;
        } else {
            return Math.abs(block.timestamp - recentBlocks[index - 1].timestamp);
        }
    });

    const displayedBlocks = recentBlocks.slice(-6);
    const displayedBlockTimes = blockTimes.slice(-6);

    return (
        <Flex
            ref={scrollRef}
            width="full"
            justifyContent="space-between"
            flexDirection="row"
            gap="10px"
            mb={'12px'}
            overflowX={{ base: 'auto', md: 'hidden' }}
        >
            <Flex
                minW="100%"
                gap={3}
            >
                {displayedBlocks.map((block, index) => {
                    if (!block || typeof block !== 'object') {
                        return null; // Skip this iteration if block is invalid
                    }
                    const { id, height, tx_count, extras, timestamp, size } = block;
                    // Add checks for each property
                    if (!id || !height || tx_count === undefined || !extras || !timestamp || !size) {
                        return null; // Skip this block if any required property is missing
                    }
                    const isLatestBlock = index === displayedBlocks.length - 1;
                    const isEmptyBlock = tx_count === emptyBlockTransactionCount;
                    const bg = isEmptyBlock
                        ? 'transparent'
                        : isLatestBlock
                            ? latestBlockColor
                            : boxColor;
                    const color = isEmptyBlock
                        ? 'orange.500'
                        : isLatestBlock
                            ? latestBlockTextColor
                            : altTextColor;
                    const outline = isEmptyBlock
                        ? '6px solid #f7931a'
                        : 'none';

                    return (
                        <Box
                            fontWeight={'700'}
                            key={id}
                            minW={{ base: "269px", md: "269px" }}
                            bg={bg}
                            color={color}
                            outline={outline}
                            outlineOffset={'-6px'}
                            borderRadius="3px"
                            flexGrow={1}
                            flexShrink={0}
                            textAlign={'center'}
                            boxShadow={boxShadow}
                            pb={'18px'}
                            onClick={() => window.open(`https://mempool.space/block/${id}`, '_blank')}
                            cursor={'pointer'}
                            _hover={{
                                opacity: 0.7,
                                transition: "opacity 0.25s ease-in-out"
                            }}
                            className={animate ? 'gray-border-custom gray-border' : ''}
                            position="relative"
                            overflow="hidden"
                        >
                            <Box pt={'20px'} px="20px">
                                <Text mb={2} fontSize={18} fontWeight="bold" color={isLatestBlock ? "white" : "orange.500"}>
                                    {height}
                                </Text>
                                <Text fontSize={16} fontWeight="bold" mb={0}>
                                    {formatNumber(tx_count, 0)} TX • {formatLargeNumber(size, 2, true)}B
                                </Text>
                                {isEmptyBlock ? (
                                    <>
                                        <Text fontWeight="bold" fontSize={'16px'}>
                                            EMPTY BLOCK!
                                        </Text>
                                        <Text mb={2} fontSize={'12px'}>
                                            No fees included in this block
                                        </Text>
                                    </>
                                ) : (
                                    <>
                                        <Text fontSize={16} fontWeight="bold">
                                            ~{formatNumber(extras?.avgFeeRate ?? 0, 0)} sats/vB
                                        </Text>
                                        <Text mb={2} fontSize={'12px'}>
                                            {formatNumber(extras?.feeRange?.[0] ?? 0, 0)} - {formatNumber(extras?.feeRange?.[6] ?? 0, 0)} Sats/vB
                                        </Text>
                                    </>
                                )}
                                <Text fontSize={'12px'}>
                                    Fees {formatNumber(extras?.totalFees / 100000000 ?? 0, 8)} BTC • {getCurrencyDisplay(extras?.totalFees ?? 0, true)}
                                </Text>
                                <Text fontSize={'12px'} mb={2}>
                                    Subsidy {formatNumber(3.125, 3)} BTC • {getCurrencyDisplay(312500000, true)}
                                </Text>
                                <Text mb={0} fontSize={'12px'}>
                                    Found ~{getTimeSinceBlock(timestamp)}
                                </Text>
                                <Text mb={2} fontSize={'12px'}>
                                    {displayedBlockTimes[index] !== null && (
                                        <BlockTimeIndicator
                                            blockTime={displayedBlockTimes[index]}
                                            expectedBlockTime={10 * 60}
                                            blockHeight={height}
                                            forceWhiteText={isLatestBlock}
                                        />
                                    )}
                                </Text>
                                <Text fontSize={'14px'} fontWeight="bold">
                                    {extras?.pool?.name ?? 'Unknown'}
                                </Text>
                            </Box>
                        </Box>
                    );
                })}
            </Flex>
        </Flex>
    );
};
