// src/utils/number.utils.js
export const truncateMiddle = (value, length = 8) => {
    if (value.length <= 2 * length) {
        return value;
    }

    const start = value.slice(0, length);
    const end = value.slice(-length);

    return `${start}......${end}`;
}

export const formatNumber = (value, decimals = 0, truncateLength = null) => {
    const parsedValue = parseFloat(value);
    const integerPart = Math.floor(parsedValue);
    const decimalPart = parsedValue - integerPart;
    const formattedInteger = integerPart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const formattedDecimal = decimals > 0 ? decimalPart.toFixed(decimals).substring(1) : "";
    let formattedNumber = formattedInteger + formattedDecimal;

    if (truncateLength) {
        formattedNumber = truncateMiddle(formattedNumber, truncateLength);
    }

    return formattedNumber;
}

export const formatLargeNumber = (value, decimals = 2, useSIUnits = false, unit = null) => {
    if (value === 0) {
        return '0';
    }

    const siUnits = [
        { value: 1e24, symbol: "Y" },
        { value: 1e21, symbol: "Z" },
        { value: 1e18, symbol: "E" },
        { value: 1e15, symbol: "P" },
        { value: 1e12, symbol: "T" },
        { value: 1e9, symbol: "G" },
        { value: 1e6, symbol: "M" },
        { value: 1e3, symbol: "k" },
        { value: 1e0, symbol: "" },
    ];

    const financialUnits = [
        { value: 1e15, symbol: "Qu" },
        { value: 1e12, symbol: "T" },
        { value: 1e9, symbol: "B" },
        { value: 1e6, symbol: "M" },
        { value: 1e3, symbol: "k" },
    ];

    const units = useSIUnits ? siUnits : financialUnits;
    const parsedValue = Math.abs(parseFloat(value));

    let selectedUnit = unit;
    let isNegative = value < 0;

    if (!selectedUnit) {
        for (const u of units) {
            if (parsedValue >= u.value) {
                selectedUnit = u.symbol;
                break;
            }
        }
    }

    // Don't show decimals if the selected SI unit is 1e0
    if (selectedUnit === "") {
        decimals = 0;
    }

    const divisor = units.find(u => u.symbol === selectedUnit)?.value || 1;

    return (isNegative ? '-' : '') + (parsedValue / divisor).toFixed(decimals) + " " + selectedUnit;
}
