import { SingleDataValue } from '../data-display';
import { ThemedFlex } from '../../theme/ThemedFlex';

export const ResourceTileCypherpunkManifesto = ({ animate }) => {

  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      className={animate ? 'gray-border-custom gray-border' : ''}
    >
      <SingleDataValue
        label="Eric Hughes"
        value="Cypherpunk's Manifesto"
        link="https://www.activism.net/cypherpunk/manifesto.html"
      />
    </ThemedFlex>
  );
};
