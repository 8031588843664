import { SingleDataValue } from '.';
import { ThemedFlex } from '../../theme/ThemedFlex';

export const MarketTileAllTimeLow = ({ data, animate }) => {
  const numberValue = data
    ? `$0.0008 USD`
    : '';

  return (
    <ThemedFlex
      direction="column"
      mb="12px"
      py="18px"
      px="20px"
      flex={1}
      borderRadius={3}
      className={animate ? 'gray-border-custom gray-border' : ''}
    >
      <SingleDataValue label="All-Time Low" value={numberValue} />
    </ThemedFlex>
  );
};
