import { Flex, useColorMode } from "@chakra-ui/react";

export const ContentWrapper = ({ children }) => {
  const { colorMode } = useColorMode();
  const bgColor = colorMode === "dark" ? "primary.700" : "secondary.500";
  const px = { base: '12px', xs: '12px', 'sm2': '12px', sm: '12px', md: '20px', lg: '20px', xl: '20px', '2xl': '20px' }

  return (
    <Flex
      flexGrow={1}
      direction="column"
      px={px}
      bg={bgColor}
    >
      {children}
    </Flex>
  );
};
