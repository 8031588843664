export const TriviaNightQuestions = [
  {
    id: 1,
    question: "What is the maximum number of bitcoins that will ever be created?",
    options: [
      { id: "a", text: "21 million" },
      { id: "b", text: "18 million" },
      { id: "c", text: "25 million" },
      { id: "d", text: "There is no maximum" }
    ],
    correctAnswer: "a",
    category: "Basics"
  },
  {
    id: 2,
    question: "Who is the pseudonymous creator of Bitcoin?",
    options: [
      { id: "a", text: "Vitalik Buterin" },
      { id: "b", text: "Satoshi Nakamoto" },
      { id: "c", text: "Charlie Lee" },
      { id: "d", text: "Nick Szabo" }
    ],
    correctAnswer: "b",
    category: "History"
  },
  {
    id: 3,
    question: "What is the name of the first major Bitcoin fork that occurred in August 2017?",
    options: [
      { id: "a", text: "Bitcoin Gold" },
      { id: "b", text: "Bitcoin Diamond" },
      { id: "c", text: "Bitcoin Cash" },
      { id: "d", text: "Bitcoin SV" }
    ],
    correctAnswer: "c",
    category: "Forks"
  },
  {
    id: 4,
    question: "What is the primary purpose of the Lightning Network?",
    options: [
      { id: "a", text: "To increase Bitcoin's block size" },
      { id: "b", text: "To enable faster and cheaper transactions" },
      { id: "c", text: "To add smart contract functionality to Bitcoin" },
      { id: "d", text: "To improve Bitcoin's mining algorithm" }
    ],
    correctAnswer: "b",
    category: "Technical"
  },
  {
    id: 5,
    question: "Which BIP (Bitcoin Improvement Proposal) introduced Segregated Witness (SegWit)?",
    options: [
      { id: "a", text: "BIP 32" },
      { id: "b", text: "BIP 39" },
      { id: "c", text: "BIP 141" },
      { id: "d", text: "BIP 173" }
    ],
    correctAnswer: "c",
    category: "BIPs"
  },
  {
    id: 6,
    question: "What is the average time between Bitcoin blocks?",
    options: [
      { id: "a", text: "1 minute" },
      { id: "b", text: "5 minutes" },
      { id: "c", text: "10 minutes" },
      { id: "d", text: "1 hour" }
    ],
    correctAnswer: "c",
    category: "Technical"
  },
  {
    id: 7,
    question: "What Bitcoin exchange was originally a collectable trading card website?",
    options: [
      { id: "a", text: "Coinbase" },
      { id: "b", text: "Mt. Gox" },
      { id: "c", text: "Bitstamp" },
      { id: "d", text: "Kraken" }
    ],
    correctAnswer: "b",
    category: "History"
  },
  {
    id: 8,
    question: "What is the term for the event when Bitcoin's block reward is cut in half?",
    options: [
      { id: "a", text: "Halving" },
      { id: "b", text: "Forking" },
      { id: "c", text: "Splitting" },
      { id: "d", text: "Doubling" }
    ],
    correctAnswer: "a",
    category: "Basics"
  },
  {
    id: 9,
    question: "Which consensus algorithm does Bitcoin use?",
    options: [
      { id: "a", text: "Proof of Stake" },
      { id: "b", text: "Delegated Proof of Stake" },
      { id: "c", text: "Proof of Work" },
      { id: "d", text: "Proof of Authority" }
    ],
    correctAnswer: "c",
    category: "Technical"
  },
  {
    id: 10,
    question: "What is the name of the Bitcoin whitepaper?",
    options: [
      { id: "a", text: "Bitcoin: A Peer-to-Peer Electronic Cash System" },
      { id: "b", text: "Bitcoin: Digital Gold for the Digital Age" },
      { id: "c", text: "Bitcoin: The Future of Money" },
      { id: "d", text: "Bitcoin: A Decentralized Cryptocurrency" }
    ],
    correctAnswer: "a",
    category: "History"
  },
  {
    id: 11,
    question: "What is the smallest unit of Bitcoin called?",
    options: [
      { id: "a", text: "Bit" },
      { id: "b", text: "Satoshi" },
      { id: "c", text: "Wei" },
      { id: "d", text: "Gwei" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 12,
    question: "Which programming language is primarily used for Bitcoin Core development?",
    options: [
      { id: "a", text: "Java" },
      { id: "b", text: "Python" },
      { id: "c", text: "C++" },
      { id: "d", text: "Rust" }
    ],
    correctAnswer: "c",
    category: "Technical"
  },
  {
    id: 13,
    question: "What is the name of the first real-world Bitcoin transaction for a physical item?",
    options: [
      { id: "a", text: "Bitcoin Pizza Day" },
      { id: "b", text: "Silk Road Purchase" },
      { id: "c", text: "WikiLeaks Donation" },
      { id: "d", text: "First Bitcoin ATM Withdrawal" }
    ],
    correctAnswer: "a",
    category: "History"
  },
  {
    id: 14,
    question: "What is the term for the process of generating new bitcoins?",
    options: [
      { id: "a", text: "Forging" },
      { id: "b", text: "Minting" },
      { id: "c", text: "Mining" },
      { id: "d", text: "Printing" }
    ],
    correctAnswer: "c",
    category: "Basics"
  },
  {
    id: 15,
    question: "What is the name of the scripting language used in Bitcoin?",
    options: [
      { id: "a", text: "Solidity" },
      { id: "b", text: "Script" },
      { id: "c", text: "Bitcoin Script" },
      { id: "d", text: "BitLang" }
    ],
    correctAnswer: "b",
    category: "Technical"
  },
  {
    id: 16,
    question: "Which of these is NOT a type of Bitcoin address?",
    options: [
      { id: "a", text: "P2PKH" },
      { id: "b", text: "P2SH" },
      { id: "c", text: "Bech32" },
      { id: "d", text: "P2PK2" }
    ],
    correctAnswer: "d",
    category: "Technical"
  },
  {
    id: 17,
    question: "What is the term for a sustained period of decreasing cryptocurrency prices?",
    options: [
      { id: "a", text: "Bear market" },
      { id: "b", text: "Bull trap" },
      { id: "c", text: "Dead cat bounce" },
      { id: "d", text: "HODL season" }
    ],
    correctAnswer: "a",
    category: "Market Terms"
  },
  {
    id: 18,
    question: "Which country was the first to adopt Bitcoin as legal tender?",
    options: [
      { id: "a", text: "Japan" },
      { id: "b", text: "Switzerland" },
      { id: "c", text: "El Salvador" },
      { id: "d", text: "Malta" }
    ],
    correctAnswer: "c",
    category: "Adoption"
  },
  {
    id: 19,
    question: "What is the name of the Bitcoin scaling solution that uses recursive SNARKs?",
    options: [
      { id: "a", text: "Plasma" },
      { id: "b", text: "Optimistic Rollups" },
      { id: "c", text: "ZK-Rollups" },
      { id: "d", text: "State Channels" }
    ],
    correctAnswer: "c",
    category: "Scaling Solutions"
  },
  {
    id: 20,
    question: "What is the term for the first block in a blockchain?",
    options: [
      { id: "a", text: "Origin block" },
      { id: "b", text: "Genesis block" },
      { id: "c", text: "Root block" },
      { id: "d", text: "Zero block" }
    ],
    correctAnswer: "b",
    category: "Blockchain Basics"
  },
  {
    id: 21,
    question: "What is the maximum block size in Bitcoin after the SegWit upgrade?",
    options: [
      { id: "a", text: "1 MB" },
      { id: "b", text: "2 MB" },
      { id: "c", text: "4 MB" },
      { id: "d", text: "8 MB" }
    ],
    correctAnswer: "c",
    category: "Technical"
  },
  {
    id: 22,
    question: "Which of these is NOT a type of cryptocurrency wallet?",
    options: [
      { id: "a", text: "Hardware wallet" },
      { id: "b", text: "Paper wallet" },
      { id: "c", text: "Software wallet" },
      { id: "d", text: "Quantum wallet" }
    ],
    correctAnswer: "d",
    category: "Wallets"
  },
  {
    id: 23,
    question: "What is the name of the attack where a miner attempts to reverse a transaction by mining a longer chain?",
    options: [
      { id: "a", text: "Sybil attack" },
      { id: "b", text: "51% attack" },
      { id: "c", text: "Double spend attack" },
      { id: "d", text: "Eclipse attack" }
    ],
    correctAnswer: "b",
    category: "Security"
  },
  {
    id: 24,
    question: "Which of these is NOT a major Bitcoin mining pool?",
    options: [
      { id: "a", text: "F2Pool" },
      { id: "b", text: "AntPool" },
      { id: "c", text: "SlushPool" },
      { id: "d", text: "DeepMine" }
    ],
    correctAnswer: "d",
    category: "Mining"
  },
  {
    id: 25,
    question: "What is the term for the unique identifier of a Bitcoin transaction?",
    options: [
      { id: "a", text: "Transaction ID (TXID)" },
      { id: "b", text: "Block hash" },
      { id: "c", text: "Public key" },
      { id: "d", text: "Nonce" }
    ],
    correctAnswer: "a",
    category: "Transactions"
  },
  {
    id: 26,
    question: "What is the name of the privacy-enhancing technology that obscures the sender and recipient of a Bitcoin transaction?",
    options: [
      { id: "a", text: "CoinJoin" },
      { id: "b", text: "RingCT" },
      { id: "c", text: "zk-SNARKs" },
      { id: "d", text: "Bulletproofs" }
    ],
    correctAnswer: "a",
    category: "Privacy"
  },
  {
    id: 27,
    question: "Which BIP introduced the concept of Pay-to-Script-Hash (P2SH) addresses?",
    options: [
      { id: "a", text: "BIP 13" },
      { id: "b", text: "BIP 16" },
      { id: "c", text: "BIP 32" },
      { id: "d", text: "BIP 44" }
    ],
    correctAnswer: "b",
    category: "BIPs"
  },
  {
    id: 28,
    question: "What is the name of the first Bitcoin Improvement Proposal (BIP) to introduce Schnorr signatures?",
    options: [
      { id: "a", text: "BIP 340" },
      { id: "b", text: "BIP 341" },
      { id: "c", text: "BIP 342" },
      { id: "d", text: "BIP 343" }
    ],
    correctAnswer: "a",
    category: "BIPs"
  },
  {
    id: 29,
    question: "What was the initial block reward when Bitcoin was first launched?",
    options: [
      { id: "a", text: "25 BTC" },
      { id: "b", text: "50 BTC" },
      { id: "c", text: "100 BTC" },
      { id: "d", text: "12.5 BTC" }
    ],
    correctAnswer: "b",
    category: "History"
  },
  {
    id: 30,
    question: "Which of these is NOT a type of Bitcoin node?",
    options: [
      { id: "a", text: "Full node" },
      { id: "b", text: "Pruned node" },
      { id: "c", text: "SPV node" },
      { id: "d", text: "Quantum node" }
    ],
    correctAnswer: "d",
    category: "Technical"
  },
  {
    id: 31,
    question: "What is the name of the Bitcoin upgrade that introduced Taproot?",
    options: [
      { id: "a", text: "London" },
      { id: "b", text: "Berlin" },
      { id: "c", text: "Segwit" },
      { id: "d", text: "Schnorr/Taproot" }
    ],
    correctAnswer: "d",
    category: "Forks"
  },
  {
    id: 32,
    question: "Which cryptographic hash function does Bitcoin use?",
    options: [
      { id: "a", text: "MD5" },
      { id: "b", text: "SHA-256" },
      { id: "c", text: "Keccak-256" },
      { id: "d", text: "RIPEMD-160" }
    ],
    correctAnswer: "b",
    category: "Technical"
  },
  {
    id: 33,
    question: "What is the term for a Bitcoin transaction that has at least one input and one output from the same address?",
    options: [
      { id: "a", text: "Circular transaction" },
      { id: "b", text: "Self-transfer" },
      { id: "c", text: "Change output" },
      { id: "d", text: "UTXO consolidation" }
    ],
    correctAnswer: "c",
    category: "Transactions"
  },
  {
    id: 34,
    question: "Which BIP introduced multi-signature addresses?",
    options: [
      { id: "a", text: "BIP 11" },
      { id: "b", text: "BIP 16" },
      { id: "c", text: "BIP 32" },
      { id: "d", text: "BIP 39" }
    ],
    correctAnswer: "a",
    category: "BIPs"
  },
  {
    id: 35,
    question: "What is the name of the attack where a malicious actor isolates a node from the rest of the network?",
    options: [
      { id: "a", text: "Sybil attack" },
      { id: "b", text: "Eclipse attack" },
      { id: "c", text: "Denial of Service attack" },
      { id: "d", text: "Man-in-the-middle attack" }
    ],
    correctAnswer: "b",
    category: "Security"
  },
  {
    id: 36,
    question: "What is the name of the algorithm used to adjust Bitcoin's mining difficulty?",
    options: [
      { id: "a", text: "Nakamoto Consensus" },
      { id: "b", text: "Proof of Work" },
      { id: "c", text: "Difficulty Adjustment Algorithm (DAA)" },
      { id: "d", text: "Hashcash" }
    ],
    correctAnswer: "c",
    category: "Technical"
  },
  {
    id: 37,
    question: "Which BIP introduced Hierarchical Deterministic (HD) wallets?",
    options: [
      { id: "a", text: "BIP 32" },
      { id: "b", text: "BIP 39" },
      { id: "c", text: "BIP 44" },
      { id: "d", text: "BIP 141" }
    ],
    correctAnswer: "a",
    category: "BIPs"
  },
  {
    id: 38,
    question: "What is the name of the first Bitcoin client released by Satoshi Nakamoto?",
    options: [
      { id: "a", text: "Bitcoin-Qt" },
      { id: "b", text: "Bitcoin Core" },
      { id: "c", text: "Satoshi Client" },
      { id: "d", text: "Bitcoin-0.1.0" }
    ],
    correctAnswer: "d",
    category: "History"
  },
  {
    id: 39,
    question: "Which of these is NOT a part of a Bitcoin transaction?",
    options: [
      { id: "a", text: "Input" },
      { id: "b", text: "Output" },
      { id: "c", text: "Signature" },
      { id: "d", text: "Smart Contract" }
    ],
    correctAnswer: "d",
    category: "Transactions"
  },
  {
    id: 40,
    question: "What is the term for the process of combining multiple small inputs into a single larger output?",
    options: [
      { id: "a", text: "UTXO consolidation" },
      { id: "b", text: "Input aggregation" },
      { id: "c", text: "Coin joining" },
      { id: "d", text: "Output merging" }
    ],
    correctAnswer: "a",
    category: "Transactions"
  },
  {
    id: 41,
    question: "Which layer of the Bitcoin network is responsible for peer discovery and data propagation?",
    options: [
      { id: "a", text: "Application layer" },
      { id: "b", text: "Consensus layer" },
      { id: "c", text: "Network layer" },
      { id: "d", text: "Mining layer" }
    ],
    correctAnswer: "c",
    category: "Technical"
  },
  {
    id: 42,
    question: "What is the name of the Bitcoin upgrade that introduced native segregated witness (SegWit) addresses?",
    options: [
      { id: "a", text: "P2WPKH" },
      { id: "b", text: "Bech32" },
      { id: "c", text: "P2SH-P2WPKH" },
      { id: "d", text: "Taproot" }
    ],
    correctAnswer: "b",
    category: "Technical"
  },
  {
    id: 43,
    question: "Which of these is NOT a valid Bitcoin Improvement Proposal (BIP) status?",
    options: [
      { id: "a", text: "Draft" },
      { id: "b", text: "Accepted" },
      { id: "c", text: "Final" },
      { id: "d", text: "Implemented" }
    ],
    correctAnswer: "d",
    category: "BIPs"
  },
  {
    id: 44,
    question: "What is the name of the Bitcoin concept that allows for the creation of payment channels?",
    options: [
      { id: "a", text: "Sidechains" },
      { id: "b", text: "Lightning Network" },
      { id: "c", text: "Atomic Swaps" },
      { id: "d", text: "Schnorr Signatures" }
    ],
    correctAnswer: "b",
    category: "Scaling Solutions"
  },
  {
    id: 45,
    question: "Which of these is NOT a step in the Bitcoin mining process?",
    options: [
      { id: "a", text: "Collecting transactions" },
      { id: "b", text: "Calculating the Merkle root" },
      { id: "c", text: "Finding a valid nonce" },
      { id: "d", text: "Verifying digital signatures" }
    ],
    correctAnswer: "d",
    category: "Mining"
  },
  {
    id: 46,
    question: "What is the name of the Bitcoin soft fork that activated in November 2021?",
    options: [
      { id: "a", text: "SegWit" },
      { id: "b", text: "Taproot" },
      { id: "c", text: "Lightning" },
      { id: "d", text: "Schnorr" }
    ],
    correctAnswer: "b",
    category: "Forks"
  },
  {
    id: 47,
    question: "Which BIP introduced the concept of BIP39 mnemonic phrases for wallet seed generation?",
    options: [
      { id: "a", text: "BIP 32" },
      { id: "b", text: "BIP 38" },
      { id: "c", text: "BIP 39" },
      { id: "d", text: "BIP 44" }
    ],
    correctAnswer: "c",
    category: "BIPs"
  },
  {
    id: 48,
    question: "What is the name of the attack where an attacker creates multiple identities to gain a disproportionate influence in a network?",
    options: [
      { id: "a", text: "Eclipse attack" },
      { id: "b", text: "Sybil attack" },
      { id: "c", text: "51% attack" },
      { id: "d", text: "Finney attack" }
    ],
    correctAnswer: "b",
    category: "Security"
  },
  {
    id: 49,
    question: "Which of these is NOT a type of Bitcoin script?",
    options: [
      { id: "a", text: "P2PKH" },
      { id: "b", text: "P2SH" },
      { id: "c", text: "P2WPKH" },
      { id: "d", text: "P2ZK" }
    ],
    correctAnswer: "d",
    category: "Technical"
  },
  {
    id: 50,
    question: "What is the name of the Bitcoin development mailing list where BIPs are discussed?",
    options: [
      { id: "a", text: "bitcoin-dev" },
      { id: "b", text: "bitcoin-core-dev" },
      { id: "c", text: "bitcoin-talk" },
      { id: "d", text: "bitcoin-improve" }
    ],
    correctAnswer: "a",
    category: "Technical"
  },
  {
    id: 51,
    question: "Which year was the first Bitcoin halving event?",
    options: [
      { id: "a", text: "2009" },
      { id: "b", text: "2012" },
      { id: "c", text: "2016" },
      { id: "d", text: "2020" }
    ],
    correctAnswer: "b",
    category: "History"
  },
  {
    id: 52,
    question: "What is the maximum number of bytes allowed in the OP_RETURN field of a Bitcoin transaction?",
    options: [
      { id: "a", text: "40 bytes" },
      { id: "b", text: "80 bytes" },
      { id: "c", text: "100 bytes" },
      { id: "d", text: "256 bytes" }
    ],
    correctAnswer: "b",
    category: "Technical"
  },
  {
    id: 53,
    question: "Which BIP introduced the concept of CheckSequenceVerify (CSV)?",
    options: [
      { id: "a", text: "BIP 65" },
      { id: "b", text: "BIP 68" },
      { id: "c", text: "BIP 112" },
      { id: "d", text: "BIP 125" }
    ],
    correctAnswer: "c",
    category: "BIPs"
  },
  {
    id: 54,
    question: "What is the name of the Bitcoin testnet that is reset periodically?",
    options: [
      { id: "a", text: "Testnet" },
      { id: "b", text: "Regtest" },
      { id: "c", text: "Signet" },
      { id: "d", text: "Devnet" }
    ],
    correctAnswer: "c",
    category: "Technical"
  },
  {
    id: 55,
    question: "Which of these is NOT a valid Bitcoin address format?",
    options: [
      { id: "a", text: "P2PKH" },
      { id: "b", text: "P2SH" },
      { id: "c", text: "Bech32" },
      { id: "d", text: "P2PK" }
    ],
    correctAnswer: "d",
    category: "Technical"
  },
  {
    id: 56,
    question: "What is the name of the Bitcoin Core feature that allows for faster initial block download?",
    options: [
      { id: "a", text: "Fast Sync" },
      { id: "b", text: "Turbo Boost" },
      { id: "c", text: "Assumevalid" },
      { id: "d", text: "Quickstart" }
    ],
    correctAnswer: "c",
    category: "Technical"
  },
  {
    id: 57,
    question: "Which BIP introduced Replace-By-Fee (RBF)?",
    options: [
      { id: "a", text: "BIP 112" },
      { id: "b", text: "BIP 125" },
      { id: "c", text: "BIP 141" },
      { id: "d", text: "BIP 174" }
    ],
    correctAnswer: "b",
    category: "BIPs"
  },
  {
    id: 58,
    question: "What is the name of the Bitcoin mixing technique that uses CoinJoin-based privacy enhancement?",
    options: [
      { id: "a", text: "Wasabi Wallet" },
      { id: "b", text: "Samourai Wallet" },
      { id: "c", text: "Whirlpool" },
      { id: "d", text: "JoinMarket" }
    ],
    correctAnswer: "c",
    category: "Privacy"
  },
  {
    id: 59,
    question: "Which of these is NOT a type of Bitcoin node pruning?",
    options: [
      { id: "a", text: "Manual pruning" },
      { id: "b", text: "Automatic pruning" },
      { id: "c", text: "Block pruning" },
      { id: "d", text: "Transaction pruning" }
    ],
    correctAnswer: "d",
    category: "Technical"
  },
  {
    id: 60,
    question: "What is the name of the proposed Bitcoin protocol upgrade that aims to increase privacy and scalability?",
    options: [
      { id: "a", text: "Dandelion" },
      { id: "b", text: "Erlay" },
      { id: "c", text: "Taproot" },
      { id: "d", text: "Graftroot" }
    ],
    correctAnswer: "a",
    category: "Technical"
  },
  {
    id: 61,
    question: "Which Bitcoin Improvement Proposal (BIP) introduced the concept of Partially Signed Bitcoin Transactions (PSBT)?",
    options: [
      { id: "a", text: "BIP 174" },
      { id: "b", text: "BIP 179" },
      { id: "c", text: "BIP 181" },
      { id: "d", text: "BIP 186" }
    ],
    correctAnswer: "a",
    category: "BIPs"
  },
  {
    id: 62,
    question: "What is the name of the attack where a miner includes their own transactions in a block without broadcasting them to the network?",
    options: [
      { id: "a", text: "Selfish mining" },
      { id: "b", text: "Block withholding" },
      { id: "c", text: "Transaction censoring" },
      { id: "d", text: "Fee sniping" }
    ],
    correctAnswer: "a",
    category: "Security"
  },
  {
    id: 63,
    question: "Which of these is NOT a valid Bitcoin Signature Hash (SIGHASH) type?",
    options: [
      { id: "a", text: "SIGHASH_ALL" },
      { id: "b", text: "SIGHASH_NONE" },
      { id: "c", text: "SIGHASH_SINGLE" },
      { id: "d", text: "SIGHASH_DOUBLE" }
    ],
    correctAnswer: "d",
    category: "Technical"
  },
  {
    id: 64,
    question: "What is the name of the Bitcoin protocol that allows for atomic swaps between different cryptocurrencies?",
    options: [
      { id: "a", text: "Lightning Network" },
      { id: "b", text: "Submarine Swaps" },
      { id: "c", text: "Hash Time-Locked Contracts (HTLC)" },
      { id: "d", text: "Schnorr Signatures" }
    ],
    correctAnswer: "c",
    category: "Technical"
  },
  {
    id: 65,
    question: "Which year was the Bitcoin whitepaper published?",
    options: [
      { id: "a", text: "2007" },
      { id: "b", text: "2008" },
      { id: "c", text: "2009" },
      { id: "d", text: "2010" }
    ],
    correctAnswer: "b",
    category: "History"
  },
  {
    id: 66,
    question: "What is the name of the Bitcoin upgrade that introduced the concept of 'witness versioning'?",
    options: [
      { id: "a", text: "SegWit" },
      { id: "b", text: "Taproot" },
      { id: "c", text: "Schnorr" },
      { id: "d", text: "Merkelized Abstract Syntax Trees (MAST)" }
    ],
    correctAnswer: "a",
    category: "Technical"
  },
  {
    id: 67,
    question: "Which BIP introduced the concept of Hierarchical Deterministic (HD) wallets?",
    options: [
      { id: "a", text: "BIP 32" },
      { id: "b", text: "BIP 39" },
      { id: "c", text: "BIP 44" },
      { id: "d", text: "BIP 141" }
    ],
    correctAnswer: "a",
    category: "BIPs"
  },
  {
    id: 68,
    question: "What is the name of the Bitcoin Core feature that allows users to estimate appropriate transaction fees?",
    options: [
      { id: "a", text: "Fee Bumping" },
      { id: "b", text: "Smart Fee" },
      { id: "c", text: "Fee Estimation" },
      { id: "d", text: "Dynamic Fees" }
    ],
    correctAnswer: "b",
    category: "Technical"
  },
  {
    id: 69,
    question: "Which of these is NOT a type of Bitcoin Improvement Proposal (BIP)?",
    options: [
      { id: "a", text: "Standards Track" },
      { id: "b", text: "Informational" },
      { id: "c", text: "Process" },
      { id: "d", text: "Experimental" }
    ],
    correctAnswer: "d",
    category: "BIPs"
  },
  {
    id: 70,
    question: "What is the name of the attack where a miner creates a block with a timestamp far in the future?",
    options: [
      { id: "a", text: "Time warp attack" },
      { id: "b", text: "Future block attack" },
      { id: "c", text: "Timestamp manipulation" },
      { id: "d", text: "Chronos attack" }
    ],
    correctAnswer: "a",
    category: "Security"
  },
  {
    id: 71,
    question: "Which BIP introduced the concept of Child Pays for Parent (CPFP)?",
    options: [
      { id: "a", text: "BIP 68" },
      { id: "b", text: "BIP 125" },
      { id: "c", text: "BIP 152" },
      { id: "d", text: "There is no specific BIP for CPFP" }
    ],
    correctAnswer: "d",
    category: "BIPs"
  },
  {
    id: 72,
    question: "What is the name of the Bitcoin protocol that aims to improve block propagation efficiency?",
    options: [
      { id: "a", text: "Compact Blocks" },
      { id: "b", text: "Fast Blocks" },
      { id: "c", text: "Thin Blocks" },
      { id: "d", text: "Xtreme Thin Blocks" }
    ],
    correctAnswer: "a",
    category: "Technical"
  },
  {
    id: 73,
    question: "Which of these is NOT a valid Bitcoin transaction version?",
    options: [
      { id: "a", text: "Version 1" },
      { id: "b", text: "Version 2" },
      { id: "c", text: "Version 3" },
      { id: "d", text: "Version 4" }
    ],
    correctAnswer: "d",
    category: "Transactions"
  },
  {
    id: 74,
    question: "What is the name of the Bitcoin scaling solution that proposes to use fraud proofs for faster and cheaper transactions?",
    options: [
      { id: "a", text: "Lightning Network" },
      { id: "b", text: "Plasma" },
      { id: "c", text: "TrueBit" },
      { id: "d", text: "Drivechain" }
    ],
    correctAnswer: "b",
    category: "Scaling Solutions"
  },
  {
    id: 75,
    question: "Which cryptographic primitive does Bitcoin use for digital signatures?",
    options: [
      { id: "a", text: "RSA" },
      { id: "b", text: "ECDSA" },
      { id: "c", text: "EdDSA" },
      { id: "d", text: "DSA" }
    ],
    correctAnswer: "b",
    category: "Technical"
  },
  {
    id: 76,
    question: "What is the name of the Bitcoin upgrade that introduced a new transaction serialization format?",
    options: [
      { id: "a", text: "SegWit" },
      { id: "b", text: "Taproot" },
      { id: "c", text: "MAST" },
      { id: "d", text: "Schnorr" }
    ],
    correctAnswer: "a",
    category: "Technical"
  },
  {
    id: 77,
    question: "Which BIP introduced the concept of Bech32 addresses?",
    options: [
      { id: "a", text: "BIP 141" },
      { id: "b", text: "BIP 173" },
      { id: "c", text: "BIP 174" },
      { id: "d", text: "BIP 340" }
    ],
    correctAnswer: "b",
    category: "BIPs"
  },
  {
    id: 78,
    question: "What is the name of the Bitcoin protocol that allows for the creation of sidechains?",
    options: [
      { id: "a", text: "Liquid" },
      { id: "b", text: "RSK" },
      { id: "c", text: "Drivechain" },
      { id: "d", text: "Rootstock" }
    ],
    correctAnswer: "c",
    category: "Scaling Solutions"
  },
  {
    id: 79,
    question: "Which of these is NOT a type of Bitcoin script opcode category?",
    options: [
      { id: "a", text: "Constants" },
      { id: "b", text: "Flow control" },
      { id: "c", text: "Stack" },
      { id: "d", text: "Encryption" }
    ],
    correctAnswer: "d",
    category: "Technical"
  },
  {
    id: 80,
    question: "What is the name of the Bitcoin Core feature that allows for the validation of blocks in parallel?",
    options: [
      { id: "a", text: "Parallel Validation" },
      { id: "b", text: "AssumeValid" },
      { id: "c", text: "Chain Parallelization" },
      { id: "d", text: "Multi-threaded Verification" }
    ],
    correctAnswer: "a",
    category: "Technical"
  },
  {
    id: 81,
    question: "Which BIP introduced the concept of Pay-to-Taproot (P2TR)?",
    options: [
      { id: "a", text: "BIP 340" },
      { id: "b", text: "BIP 341" },
      { id: "c", text: "BIP 342" },
      { id: "d", text: "BIP 350" }
    ],
    correctAnswer: "b",
    category: "BIPs"
  },
  {
    id: 82,
    question: "What is the name of the attack where a miner attempts to censor specific transactions?",
    options: [
      { id: "a", text: "Blacklisting attack" },
      { id: "b", text: "Transaction filtering" },
      { id: "c", text: "Censorship attack" },
      { id: "d", text: "Miner extractable value (MEV)" }
    ],
    correctAnswer: "c",
    category: "Security"
  },
  {
    id: 83,
    question: "Which of these is NOT a valid Bitcoin script type?",
    options: [
      { id: "a", text: "P2PKH" },
      { id: "b", text: "P2SH" },
      { id: "c", text: "P2WPKH" },
      { id: "d", text: "P2SK" }
    ],
    correctAnswer: "d",
    category: "Technical"
  },
  {
    id: 84,
    question: "What is the name of the Bitcoin protocol that aims to improve transaction privacy by obscuring transaction graphs?",
    options: [
      { id: "a", text: "CoinJoin" },
      { id: "b", text: "PayJoin" },
      { id: "c", text: "CoinSwap" },
      { id: "d", text: "TumbleBit" }
    ],
    correctAnswer: "b",
    category: "Privacy"
  },
  {
    id: 85,
    question: "Which year did Bitcoin reach its first $1,000 USD price milestone?",
    options: [
      { id: "a", text: "2011" },
      { id: "b", text: "2013" },
      { id: "c", text: "2015" },
      { id: "d", text: "2017" }
    ],
    correctAnswer: "b",
    category: "History"
  },
  {
    id: 86,
    question: "What is the name of the Bitcoin Core feature that allows for faster transaction relay?",
    options: [
      { id: "a", text: "Compact Block Relay" },
      { id: "b", text: "Fast Relay" },
      { id: "c", "text": "Turbo Propagation" },
      { id: "d", text: "Swift Broadcast" }
    ],
    correctAnswer: "a",
    category: "Technical"
  },
  {
    id: 87,
    question: "Which BIP introduced the concept of Segregated Witness (SegWit)?",
    options: [
      { id: "a", text: "BIP 91" },
      { id: "b", text: "BIP 141" },
      { id: "c", text: "BIP 148" },
      { id: "d", text: "BIP 9" }
    ],
    correctAnswer: "b",
    category: "BIPs"
  },
  {
    id: 88,
    question: "What is the name of the attack where a miner intentionally forks the blockchain to double-spend coins?",
    options: [
      { id: "a", text: "51% attack" },
      { id: "b", text: "Finney attack" },
      { id: "c", text: "Race attack" },
      { id: "d", text: "Vector76 attack" }
    ],
    correctAnswer: "a",
    category: "Security"
  },
  {
    id: 89,
    question: "Which of these is NOT a valid Bitcoin Opcodes category?",
    options: [
      { id: "a", text: "Arithmetic" },
      { id: "b", text: "Crypto" },
      { id: "c", text: "Stack" },
      { id: "d", text: "Network" }
    ],
    correctAnswer: "d",
    category: "Technical"
  },
  {
    id: 90,
    question: "What is the name of the Bitcoin protocol that aims to improve transaction privacy by mixing coins?",
    options: [
      { id: "a", text: "CoinJoin" },
      { id: "b", text: "TumbleBit" },
      { id: "c", text: "CoinShuffle" },
      { id: "d", text: "MimbleWimble" }
    ],
    correctAnswer: "a",
    category: "Privacy"
  },
  {
    id: 91,
    question: "Which year was the first Bitcoin conference held?",
    options: [
      { id: "a", text: "2009" },
      { id: "b", text: "2011" },
      { id: "c", text: "2013" },
      { id: "d", text: "2015" }
    ],
    correctAnswer: "b",
    category: "History"
  },
  {
    id: 92,
    question: "What is the name of the Bitcoin upgrade that introduced a new signature scheme?",
    options: [
      { id: "a", text: "SegWit" },
      { id: "b", text: "Taproot" },
      { id: "c", text: "Schnorr" },
      { id: "d", text: "MAST" }
    ],
    correctAnswer: "c",
    category: "Technical"
  },
  {
    id: 93,
    question: "Which BIP introduced the concept of Peer-to-Peer Alert System?",
    options: [
      { id: "a", text: "BIP 0" },
      { id: "b", text: "BIP 11" },
      { id: "c", text: "BIP 30" },
      { id: "d", text: "BIP 50" }
    ],
    correctAnswer: "a",
    category: "BIPs"
  },
  {
    id: 94,
    question: "What is the name of the Bitcoin scaling solution that proposes to use fraud proofs and validity proofs?",
    options: [
      { id: "a", text: "Lightning Network" },
      { id: "b", text: "Plasma" },
      { id: "c", text: "Rollups" },
      { id: "d", text: "Sidechains" }
    ],
    correctAnswer: "c",
    category: "Scaling Solutions"
  },
  {
    id: 95,
    question: "Which of these is NOT a type of Bitcoin multisignature address?",
    options: [
      { id: "a", text: "2-of-3" },
      { id: "b", text: "3-of-5" },
      { id: "c", text: "5-of-7" },
      { id: "d", text: "1-of-2" }
    ],
    correctAnswer: "d",
    category: "Technical"
  },
  {
    id: 96,
    question: "What is the name of the Bitcoin Core feature that allows for the pruning of old blockchain data?",
    options: [
      { id: "a", text: "Blockchain Compression" },
      { id: "b", text: "Chain Pruning" },
      { id: "c", text: "Block Trimming" },
      { id: "d", text: "Data Reduction" }
    ],
    correctAnswer: "b",
    category: "Technical"
  },
  {
    id: 97,
    question: "Which BIP introduced the concept of Version Bits for soft fork deployment?",
    options: [
      { id: "a", text: "BIP 9" },
      { id: "b", text: "BIP 16" },
      { id: "c", text: "BIP 34" },
      { id: "d", text: "BIP 65" }
    ],
    correctAnswer: "a",
    category: "BIPs"
  },
  {
    id: 98,
    question: "What is the name of the attack where a miner creates empty or near-empty blocks?",
    options: [
      { id: "a", text: "Block withholding attack" },
      { id: "b", text: "Selfish mining" },
      { id: "c", text: "SPV mining" },
      { id: "d", text: "Fee sniping" }
    ],
    correctAnswer: "c",
    category: "Mining"
  },
  {
    id: 99,
    question: "Which of these is NOT a valid Bitcoin transaction locktime type?",
    options: [
      { id: "a", text: "Block height" },
      { id: "b", text: "Unix timestamp" },
      { id: "c", text: "Relative timelock" },
      { id: "d", text: "Absolute timelock" }
    ],
    correctAnswer: "c",
    category: "Transactions"
  },
  {
    id: 100,
    question: "What is the name of the Bitcoin protocol that allows for the creation of payment channels without on-chain transactions?",
    options: [
      { id: "a", text: "Eltoo" },
      { id: "b", text: "Plasma" },
      { id: "c", text: "State Channels" },
      { id: "d", text: "Atomic Swaps" }
    ],
    correctAnswer: "a",
    category: "Scaling Solutions"
  },
  {
    id: 101,
    question: "Which year did the infamous Mt. Gox exchange collapse?",
    options: [
      { id: "a", text: "2011" },
      { id: "b", text: "2013" },
      { id: "c", text: "2014" },
      { id: "d", text: "2015" }
    ],
    correctAnswer: "c",
    category: "History"
  },
  {
    id: 102,
    question: "What is the name of the Bitcoin upgrade that introduced a new scripting language?",
    options: [
      { id: "a", text: "SegWit" },
      { id: "b", text: "Taproot" },
      { id: "c", text: "Schnorr" },
      { id: "d", text: "Simplicity" }
    ],
    correctAnswer: "d",
    category: "Technical"
  },
  {
    id: 103,
    question: "Which BIP introduced the concept of HD wallets for deterministic key generation?",
    options: [
      { id: "a", text: "BIP 32" },
      { id: "b", text: "BIP 39" },
      { id: "c", text: "BIP 44" },
      { id: "d", text: "BIP 49" }
    ],
    correctAnswer: "a",
    category: "BIPs"
  },
  {
    id: 104,
    question: "What is the name of the Bitcoin scaling solution that proposes to use recursive SNARKs?",
    options: [
      { id: "a", text: "Plasma" },
      { id: "b", text: "zk-Rollups" },
      { id: "c", text: "Optimistic Rollups" },
      { id: "d", text: "Validium" }
    ],
    correctAnswer: "b",
    category: "Scaling Solutions"
  },
  {
    id: 105,
    question: "Which of these is NOT a type of Bitcoin node?",
    options: [
      { id: "a", text: "Full node" },
      { id: "b", text: "Pruned node" },
      { id: "c", text: "SPV node" },
      { id: "d", text: "Master node" }
    ],
    correctAnswer: "d",
    category: "Technical"
  },
  {
    id: 106,
    question: "What is the name of the Bitcoin upgrade that introduced a new transaction format to fix transaction malleability?",
    options: [
      { id: "a", text: "SegWit" },
      { id: "b", text: "Taproot" },
      { id: "c", text: "Schnorr" },
      { id: "d", text: "MAST" }
    ],
    correctAnswer: "a",
    category: "Technical"
  },
  {
    id: 107,
    question: "Which BIP introduced the concept of Pay-to-Script-Hash (P2SH)?",
    options: [
      { id: "a", text: "BIP 13" },
      { id: "b", text: "BIP 16" },
      { id: "c", text: "BIP 32" },
      { id: "d", text: "BIP 39" }
    ],
    correctAnswer: "b",
    category: "BIPs"
  },
  {
    id: 108,
    question: "What is the name of the first major Bitcoin hard fork that occurred in 2017?",
    options: [
      { id: "a", text: "Bitcoin Cash" },
      { id: "b", text: "Bitcoin Gold" },
      { id: "c", text: "Bitcoin SV" },
      { id: "d", text: "Bitcoin Diamond" }
    ],
    correctAnswer: "a",
    category: "Forks"
  },
  {
    id: 109,
    question: "Which cryptographic primitive does Bitcoin use for its proof-of-work algorithm?",
    options: [
      { id: "a", text: "SHA-256" },
      { id: "b", text: "Scrypt" },
      { id: "c", text: "Ethash" },
      { id: "d", text: "X11" }
    ],
    correctAnswer: "a",
    category: "Technical"
  },
  {
    id: 110,
    question: "What is the name of the attack where a miner attempts to replace confirmed blocks with their own blocks?",
    options: [
      { id: "a", text: "Double spend attack" },
      { id: "b", text: "51% attack" },
      { id: "c", text: "Sybil attack" },
      { id: "d", text: "Eclipse attack" }
    ],
    correctAnswer: "b",
    category: "Security"
  },
  {
    id: 111,
    question: "Which BIP introduced the concept of Hierarchical Deterministic (HD) wallets?",
    options: [
      { id: "a", text: "BIP 32" },
      { id: "b", text: "BIP 39" },
      { id: "c", text: "BIP 44" },
      { id: "d", text: "BIP 49" }
    ],
    correctAnswer: "a",
    category: "BIPs"
  },
  {
    id: 112,
    question: "What is the name of the Bitcoin scaling solution that uses off-chain payment channels?",
    options: [
      { id: "a", text: "Sidechains" },
      { id: "b", text: "Lightning Network" },
      { id: "c", text: "Plasma" },
      { id: "d", text: "Rollups" }
    ],
    correctAnswer: "b",
    category: "Scaling Solutions"
  },
  {
    id: 113,
    question: "Which year was the first Bitcoin halving event?",
    options: [
      { id: "a", text: "2010" },
      { id: "b", text: "2012" },
      { id: "c", text: "2014" },
      { id: "d", text: "2016" }
    ],
    correctAnswer: "b",
    category: "History"
  },
  {
    id: 114,
    question: "What is the name of the Bitcoin upgrade that introduced Merkelized Abstract Syntax Trees (MAST)?",
    options: [
      { id: "a", text: "SegWit" },
      { id: "b", text: "Taproot" },
      { id: "c", text: "Schnorr" },
      { id: "d", text: "Graftroot" }
    ],
    correctAnswer: "b",
    category: "Technical"
  },
  {
    id: 115,
    question: "Which BIP introduced the concept of Bech32 addresses?",
    options: [
      { id: "a", text: "BIP 173" },
      { id: "b", text: "BIP 174" },
      { id: "c", text: "BIP 340" },
      { id: "d", text: "BIP 341" }
    ],
    correctAnswer: "a",
    category: "BIPs"
  },
  {
    id: 116,
    question: "What is the name of the privacy-enhancing technique that obscures the connection between inputs and outputs in a transaction?",
    options: [
      { id: "a", text: "CoinJoin" },
      { id: "b", text: "Ring signatures" },
      { id: "c", text: "Confidential Transactions" },
      { id: "d", text: "Stealth addresses" }
    ],
    correctAnswer: "a",
    category: "Privacy"
  },
  {
    id: 117,
    question: "Which year did the Bitcoin network process its first transaction?",
    options: [
      { id: "a", text: "2008" },
      { id: "b", text: "2009" },
      { id: "c", text: "2010" },
      { id: "d", text: "2011" }
    ],
    correctAnswer: "b",
    category: "History"
  },
  {
    id: 118,
    question: "What is the name of the Bitcoin protocol that allows for the creation of sidechains?",
    options: [
      { id: "a", text: "Liquid" },
      { id: "b", text: "RSK" },
      { id: "c", text: "Drivechain" },
      { id: "d", text: "Plasma" }
    ],
    correctAnswer: "c",
    category: "Scaling Solutions"
  },
  {
    id: 119,
    question: "Which BIP introduced the concept of CheckSequenceVerify (CSV)?",
    options: [
      { id: "a", text: "BIP 65" },
      { id: "b", text: "BIP 68" },
      { id: "c", text: "BIP 112" },
      { id: "d", text: "BIP 125" }
    ],
    correctAnswer: "c",
    category: "BIPs"
  },
  {
    id: 120,
    question: "What is the name of the attack where a miner creates blocks with invalid timestamps to manipulate difficulty?",
    options: [
      { id: "a", text: "Time warp attack" },
      { id: "b", text: "Difficulty bomb" },
      { id: "c", text: "Timejacking" },
      { id: "d", text: "Chronos attack" }
    ],
    correctAnswer: "a",
    category: "Security"
  },
  {
    id: 121,
    question: "Which of these is NOT a valid Bitcoin Improvement Proposal (BIP) status?",
    options: [
      { id: "a", text: "Draft" },
      { id: "b", text: "Proposed" },
      { id: "c", text: "Final" },
      { id: "d", text: "Rejected" }
    ],
    correctAnswer: "b",
    category: "BIPs"
  },
  {
    id: 122,
    question: "What is the name of the Bitcoin Core feature that allows for faster initial block download?",
    options: [
      { id: "a", text: "Fast Sync" },
      { id: "b", text: "Assumevalid" },
      { id: "c", text: "Turbo Boost" },
      { id: "d", text: "Quickstart" }
    ],
    correctAnswer: "b",
    category: "Technical"
  },
  {
    id: 123,
    question: "Which year did the Bitcoin network experience its first major unintentional fork?",
    options: [
      { id: "a", text: "2010" },
      { id: "b", text: "2013" },
      { id: "c", text: "2015" },
      { id: "d", text: "2017" }
    ],
    correctAnswer: "b",
    category: "History"
  },
  {
    id: 124,
    question: "What is the name of the Bitcoin upgrade that introduced a new signature scheme to improve privacy and efficiency?",
    options: [
      { id: "a", text: "SegWit" },
      { id: "b", text: "Taproot" },
      { id: "c", text: "Schnorr" },
      { id: "d", text: "MAST" }
    ],
    correctAnswer: "c",
    category: "Technical"
  },
  {
    id: 125,
    question: "Which BIP introduced the concept of Replace-By-Fee (RBF)?",
    options: [
      { id: "a", text: "BIP 112" },
      { id: "b", text: "BIP 125" },
      { id: "c", text: "BIP 141" },
      { id: "d", text: "BIP 174" }
    ],
    correctAnswer: "b",
    category: "BIPs"
  },
  {
    id: 126,
    question: "What is the name of the Bitcoin protocol that aims to improve transaction privacy by obfuscating IP addresses?",
    options: [
      { id: "a", text: "Tor" },
      { id: "b", text: "I2P" },
      { id: "c", text: "Dandelion" },
      { id: "d", text: "Kovri" }
    ],
    correctAnswer: "c",
    category: "Privacy"
  },
  {
    id: 127,
    question: "Which of these is NOT a type of Bitcoin script?",
    options: [
      { id: "a", text: "P2PKH" },
      { id: "b", text: "P2SH" },
      { id: "c", text: "P2WPKH" },
      { id: "d", text: "P2PK2" }
    ],
    correctAnswer: "d",
    category: "Technical"
  },
  {
    id: 128,
    question: "What is the name of the Bitcoin Core feature that allows for parallel validation of blocks?",
    options: [
      { id: "a", text: "AssumeValid" },
      { id: "b", text: "Parallel Validation" },
      { id: "c", text: "Multi-threaded Verification" },
      { id: "d", text: "Concurrent Block Processing" }
    ],
    correctAnswer: "b",
    category: "Technical"
  },
  {
    id: 129,
    question: "Which year did the first Bitcoin ATM appear?",
    options: [
      { id: "a", text: "2011" },
      { id: "b", text: "2013" },
      { id: "c", text: "2015" },
      { id: "d", text: "2017" }
    ],
    correctAnswer: "b",
    category: "History"
  },
  {
    id: 130,
    question: "What is the maximum number of Bitcoin that will ever be mined?",
    options: [
      { id: "a", text: "18 million" },
      { id: "b", text: "21 million" },
      { id: "c", text: "25 million" },
      { id: "d", text: "30 million" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 131,
    question: "What is the name of the Bitcoin improvement proposal that introduced Compact Block Relay?",
    options: [
      { id: "a", text: "BIP 132" },
      { id: "b", text: "BIP 152" },
      { id: "c", text: "BIP 174" },
      { id: "d", text: "BIP 199" }
    ],
    correctAnswer: "b",
    category: "BIPs"
  },
  {
    id: 132,
    question: "Which year did the Bitcoin network reach a hash rate of 1 exahash per second for the first time?",
    options: [
      { id: "a", text: "2013" },
      { id: "b", text: "2015" },
      { id: "c", text: "2016" },
      { id: "d", text: "2017" }
    ],
    correctAnswer: "c",
    category: "History"
  },
  {
    id: 133,
    question: "What is the name of the Bitcoin soft fork that activated in July 2017 to signal readiness for SegWit?",
    options: [
      { id: "a", text: "BIP 91" },
      { id: "b", text: "BIP 148" },
      { id: "c", text: "BIP 9" },
      { id: "d", text: "UASF" }
    ],
    correctAnswer: "a",
    category: "Forks"
  },
  {
    id: 134,
    question: "Which of these is NOT a type of Bitcoin network attack?",
    options: [
      { id: "a", text: "Sybil attack" },
      { id: "b", text: "Eclipse attack" },
      { id: "c", text: "Quantum attack" },
      { id: "d", text: "Timejacking attack" }
    ],
    correctAnswer: "c",
    category: "Security"
  },
  {
    id: 135,
    question: "What is the name of the privacy-enhancing technology that allows for confidential transactions in Bitcoin?",
    options: [
      { id: "a", text: "RingCT" },
      { id: "b", text: "Confidential Transactions" },
      { id: "c", text: "MimbleWimble" },
      { id: "d", text: "zk-SNARKs" }
    ],
    correctAnswer: "b",
    category: "Privacy"
  },
  {
    id: 136,
    question: "Which Bitcoin opcode was disabled due to security concerns and later re-enabled with restrictions?",
    options: [
      { id: "a", text: "OP_CAT" },
      { id: "b", text: "OP_GROUP" },
      { id: "c", text: "OP_RETURN" },
      { id: "d", text: "OP_CHECKLOCKTIMEVERIFY" }
    ],
    correctAnswer: "c",
    category: "Technical"
  },
  {
    id: 137,
    question: "What is the name of the Bitcoin development mailing list where technical discussions take place?",
    options: [
      { id: "a", text: "bitcoin-dev" },
      { id: "b", text: "bitcoin-core-dev" },
      { id: "c", text: "bitcoin-technical" },
      { id: "d", text: "bitcoin-discuss" }
    ],
    correctAnswer: "a",
    category: "Technical"
  },
  {
    id: 138,
    question: "Which BIP introduced the concept of Segregated Witness (SegWit)?",
    options: [
      { id: "a", text: "BIP 9" },
      { id: "b", text: "BIP 91" },
      { id: "c", text: "BIP 141" },
      { id: "d", text: "BIP 148" }
    ],
    correctAnswer: "c",
    category: "BIPs"
  },
  {
    id: 139,
    question: "What was the nickname given to the bug found in Bitcoin Core in September 2018 that could have allowed the creation of new bitcoins?",
    options: [
      { id: "a", text: "Heartbleed" },
      { id: "b", text: "Shellshock" },
      { id: "c", text: "CVE-2018-17144" },
      { id: "d", text: "The Bitcoin Inflation Bug" }
    ],
    correctAnswer: "c",
    category: "History"
  },
  {
    id: 140,
    question: "Which of these is NOT a Bitcoin fork?",
    options: [
      { id: "a", text: "Bitcoin Cash" },
      { id: "b", text: "Bitcoin Gold" },
      { id: "c", text: "Bitcoin Diamond" },
      { id: "d", text: "Bitcoin Platinum" }
    ],
    correctAnswer: "d",
    category: "Forks"
  },
  {
    id: 141,
    question: "What is the name of the attack where a miner deliberately orphans blocks of other miners?",
    options: [
      { id: "a", text: "Selfish mining" },
      { id: "b", text: "Block withholding" },
      { id: "c", text: "51% attack" },
      { id: "d", text: "Double spending" }
    ],
    correctAnswer: "a",
    category: "Security"
  },
  {
    id: 142,
    question: "Which privacy-enhancing technology was proposed to improve Bitcoin's fungibility?",
    options: [
      { id: "a", text: "CoinJoin" },
      { id: "b", text: "TumbleBit" },
      { id: "c", text: "Confidential Transactions" },
      { id: "d", text: "All of the above" }
    ],
    correctAnswer: "d",
    category: "Privacy"
  },
  {
    id: 143,
    question: "What is the maximum size of a standard Bitcoin transaction?",
    options: [
      { id: "a", text: "100 KB" },
      { id: "b", text: "1 MB" },
      { id: "c", text: "4 MB" },
      { id: "d", text: "There is no fixed maximum" }
    ],
    correctAnswer: "a",
    category: "Technical"
  },
  {
    id: 144,
    question: "Which BIP introduced the concept of Check Lock Time Verify (CLTV)?",
    options: [
      { id: "a", text: "BIP 65" },
      { id: "b", text: "BIP 68" },
      { id: "c", text: "BIP 112" },
      { id: "d", text: "BIP 125" }
    ],
    correctAnswer: "a",
    category: "BIPs"
  },
  {
    id: 145,
    question: "In which year was the first Bitcoin Improvement Proposal (BIP) introduced?",
    options: [
      { id: "a", text: "2009" },
      { id: "b", text: "2010" },
      { id: "c", text: "2011" },
      { id: "d", text: "2012" }
    ],
    correctAnswer: "c",
    category: "History"
  },
  {
    id: 146,
    question: "What is the name of the Bitcoin fork that aims to be ASIC-resistant?",
    options: [
      { id: "a", text: "Bitcoin Cash" },
      { id: "b", text: "Bitcoin Gold" },
      { id: "c", text: "Bitcoin Diamond" },
      { id: "d", text: "Bitcoin SV" }
    ],
    correctAnswer: "b",
    category: "Forks"
  },
  {
    id: 147,
    question: "Which of these is NOT a type of Bitcoin network message?",
    options: [
      { id: "a", text: "inv" },
      { id: "b", text: "getdata" },
      { id: "c", text: "mempool" },
      { id: "d", text: "validate" }
    ],
    correctAnswer: "d",
    category: "Technical"
  },
  {
    id: 148,
    question: "What is the name of the Bitcoin scaling proposal that suggests increasing the block size to 2MB while also activating SegWit?",
    options: [
      { id: "a", text: "SegWit2x" },
      { id: "b", text: "BIP 148" },
      { id: "c", text: "Bitcoin Unlimited" },
      { id: "d", text: "Bitcoin XT" }
    ],
    correctAnswer: "a",
    category: "Scaling Solutions"
  },
  {
    id: 149,
    question: "Which cryptographic primitive does Bitcoin use for its address generation?",
    options: [
      { id: "a", text: "SHA-256" },
      { id: "b", text: "RIPEMD-160" },
      { id: "c", text: "Scrypt" },
      { id: "d", text: "Keccak" }
    ],
    correctAnswer: "b",
    category: "Technical"
  },
  {
    id: 150,
    question: "What is the name of the Bitcoin protocol upgrade that aims to increase privacy and reduce blockchain bloat?",
    options: [
      { id: "a", text: "Confidential Transactions" },
      { id: "b", text: "MimbleWimble" },
      { id: "c", text: "Dandelion" },
      { id: "d", text: "Schnorr signatures" }
    ],
    correctAnswer: "c",
    category: "Privacy"
  },
  {
    id: 151,
    question: "Which BIP introduced the concept of Partially Signed Bitcoin Transactions (PSBT)?",
    options: [
      { id: "a", text: "BIP 174" },
      { id: "b", text: "BIP 179" },
      { id: "c", text: "BIP 340" },
      { id: "d", text: "BIP 341" }
    ],
    correctAnswer: "a",
    category: "BIPs"
  },
  {
    id: 152,
    question: "In which year did the 'Bitcoin Pizza Day' transaction occur?",
    options: [
      { id: "a", text: "2009" },
      { id: "b", text: "2010" },
      { id: "c", text: "2011" },
      { id: "d", text: "2012" }
    ],
    correctAnswer: "b",
    category: "History"
  },
  {
    id: 153,
    question: "What is the name of the Bitcoin implementation written in Rust?",
    options: [
      { id: "a", text: "Bitcoin Core" },
      { id: "b", text: "btcd" },
      { id: "c", text: "Bitcoin Knots" },
      { id: "d", text: "Rust Bitcoin" }
    ],
    correctAnswer: "d",
    category: "Technical"
  },
  {
    id: 154,
    question: "Which of these is NOT a valid Bitcoin Signature Hash (SIGHASH) flag?",
    options: [
      { id: "a", text: "SIGHASH_ALL" },
      { id: "b", text: "SIGHASH_NONE" },
      { id: "c", text: "SIGHASH_SINGLE" },
      { id: "d", text: "SIGHASH_DOUBLE" }
    ],
    correctAnswer: "d",
    category: "Technical"
  },
  {
    id: 155,
    question: "What is the name of the attack where a miner attempts to reverse a transaction by mining a longer chain?",
    options: [
      { id: "a", text: "Double spend attack" },
      { id: "b", text: "51% attack" },
      { id: "c", text: "Race attack" },
      { id: "d", text: "Finney attack" }
    ],
    correctAnswer: "b",
    category: "Security"
  },
  {
    id: 156,
    question: "What is the name of the Bitcoin upgrade that introduced the concept of 'key aggregation' for multi-signature transactions?",
    options: [
      { id: "a", text: "SegWit" },
      { id: "b", text: "Taproot" },
      { id: "c", text: "Schnorr" },
      { id: "d", text: "MuSig" }
    ],
    correctAnswer: "d",
    category: "Technical"
  },
  {
    id: 157,
    question: "Which BIP introduced the concept of 'Signature of Composition' for more efficient multi-signature schemes?",
    options: [
      { id: "a", text: "BIP 340" },
      { id: "b", text: "BIP 341" },
      { id: "c", text: "BIP 342" },
      { id: "d", text: "BIP 327" }
    ],
    correctAnswer: "d",
    category: "BIPs"
  },
  {
    id: 158,
    question: "What was the name of the Bitcoin scaling debate that occurred in 2017?",
    options: [
      { id: "a", text: "The Block Size War" },
      { id: "b", text: "The SegWit Debate" },
      { id: "c", text: "The UASF Controversy" },
      { id: "d", text: "The New York Agreement" }
    ],
    correctAnswer: "a",
    category: "History"
  },
  {
    id: 159,
    question: "Which Bitcoin fork aimed to increase the block size to 32MB?",
    options: [
      { id: "a", text: "Bitcoin Cash" },
      { id: "b", text: "Bitcoin SV" },
      { id: "c", text: "Bitcoin Gold" },
      { id: "d", text: "Bitcoin Diamond" }
    ],
    correctAnswer: "b",
    category: "Forks"
  },
  {
    id: 160,
    question: "What is the name of the attack where a miner publishes a block but withholds a part of it?",
    options: [
      { id: "a", text: "Selfish mining" },
      { id: "b", text: "Block withholding" },
      { id: "c", text: "SPV mining" },
      { id: "d", text: "Spy mining" }
    ],
    correctAnswer: "c",
    category: "Security"
  },
  {
    id: 161,
    question: "Which privacy-enhancing technology uses blind signatures to create untraceable cash-like tokens?",
    options: [
      { id: "a", text: "CoinJoin" },
      { id: "b", text: "Confidential Transactions" },
      { id: "c", text: "Chaumian E-Cash" },
      { id: "d", text: "Ring Signatures" }
    ],
    correctAnswer: "c",
    category: "Privacy"
  },
  {
    id: 162,
    question: "What is the name of the Bitcoin protocol that allows for the creation of statechains?",
    options: [
      { id: "a", text: "RGB" },
      { id: "b", text: "Elements" },
      { id: "c", text: "Mercury" },
      { id: "d", text: "Liquid" }
    ],
    correctAnswer: "c",
    category: "Technical"
  },
  {
    id: 163,
    question: "Which BIP introduced the concept of 'Ephemeral Anchors' for more efficient Lightning Network channel opens?",
    options: [
      { id: "a", text: "BIP 118" },
      { id: "b", text: "BIP 119" },
      { id: "c", text: "BIP 120" },
      { id: "d", text: "BIP 121" }
    ],
    correctAnswer: "b",
    category: "BIPs"
  },
  {
    id: 164,
    question: "In which year did the 'Bitcoin Beach' project in El Salvador start?",
    options: [
      { id: "a", text: "2017" },
      { id: "b", text: "2018" },
      { id: "c", text: "2019" },
      { id: "d", text: "2020" }
    ],
    correctAnswer: "c",
    category: "History"
  },
  {
    id: 165,
    question: "What is the name of the Bitcoin fork that aimed to be ASIC-resistant and GPU-friendly?",
    options: [
      { id: "a", text: "Bitcoin Gold" },
      { id: "b", text: "Bitcoin Diamond" },
      { id: "c", text: "Bitcoin Private" },
      { id: "d", text: "Bitcoin Interest" }
    ],
    correctAnswer: "a",
    category: "Forks"
  },
  {
    id: 166,
    question: "Which attack involves a miner intentionally creating a fork to double-spend transactions?",
    options: [
      { id: "a", text: "Sybil attack" },
      { id: "b", text: "Eclipse attack" },
      { id: "c", text: "51% attack" },
      { id: "d", text: "Timejacking attack" }
    ],
    correctAnswer: "c",
    category: "Security"
  },
  {
    id: 167,
    question: "What privacy-enhancing technology was proposed by Adam Back for Bitcoin?",
    options: [
      { id: "a", text: "Confidential Transactions" },
      { id: "b", text: "CoinJoin" },
      { id: "c", text: "Stealth Addresses" },
      { id: "d", text: "Ring Signatures" }
    ],
    correctAnswer: "a",
    category: "Privacy"
  },
  {
    id: 168,
    question: "Which Bitcoin Improvement Proposal (BIP) introduced the concept of 'Package Relay' for transaction propagation?",
    options: [
      { id: "a", text: "BIP 324" },
      { id: "b", text: "BIP 331" },
      { id: "c", text: "BIP 339" },
      { id: "d", text: "BIP 350" }
    ],
    correctAnswer: "b",
    category: "BIPs"
  },
  {
    id: 169,
    question: "What was the nickname given to the bug found in Bitcoin Core in 2018 that could have allowed an inflation of the supply?",
    options: [
      { id: "a", text: "Heartbleed" },
      { id: "b", text: "Shellshock" },
      { id: "c", text: "InfinityGate" },
      { id: "d", text: "CVE-2018-17144" }
    ],
    correctAnswer: "d",
    category: "History"
  },
  {
    id: 170,
    question: "Which Bitcoin fork aimed to implement smart contract functionality similar to Ethereum?",
    options: [
      { id: "a", text: "Bitcoin Cash" },
      { id: "b", text: "RSK" },
      { id: "c", text: "Bitcoin Gold" },
      { id: "d", text: "Bitcoin Diamond" }
    ],
    correctAnswer: "b",
    category: "Forks"
  },
  {
    id: 171,
    question: "What is the name of the attack where a miner attempts to disrupt the network by mining empty blocks?",
    options: [
      { id: "a", text: "Selfish mining" },
      { id: "b", text: "Block withholding" },
      { id: "c", text: "Fee sniping" },
      { id: "d", text: "Grinding attack" }
    ],
    correctAnswer: "c",
    category: "Security"
  },
  {
    id: 172,
    question: "Which privacy-enhancing technology was implemented in the Wasabi Wallet for Bitcoin?",
    options: [
      { id: "a", text: "CoinJoin" },
      { id: "b", text: "Confidential Transactions" },
      { id: "c", text: "Ring Signatures" },
      { id: "d", text: "MimbleWimble" }
    ],
    correctAnswer: "a",
    category: "Privacy"
  },
  {
    id: 173,
    question: "What is the name of the Bitcoin protocol that allows for the creation of colored coins?",
    options: [
      { id: "a", text: "Omni Layer" },
      { id: "b", text: "Counterparty" },
      { id: "c", text: "RGB" },
      { id: "d", text: "Liquid" }
    ],
    correctAnswer: "b",
    category: "Technical"
  },
  {
    id: 174,
    question: "Which BIP introduced the concept of 'Compact Block Filters' for light clients?",
    options: [
      { id: "a", text: "BIP 157" },
      { id: "b", text: "BIP 158" },
      { id: "c", text: "BIP 159" },
      { id: "d", text: "BIP 160" }
    ],
    correctAnswer: "b",
    category: "BIPs"
  },
  {
    id: 175,
    question: "In which year was the first Bitcoin futures contract launched on a major U.S. exchange?",
    options: [
      { id: "a", text: "2015" },
      { id: "b", text: "2016" },
      { id: "c", text: "2017" },
      { id: "d", text: "2018" }
    ],
    correctAnswer: "c",
    category: "History"
  },
  {
    id: 176,
    question: "What is the name of the Bitcoin implementation written in Rust?",
    options: [
      { id: "a", text: "Bitcoin Core" },
      { id: "b", text: "btcd" },
      { id: "c", text: "Bitcoin Knots" },
      { id: "d", text: "Rust Bitcoin" }
    ],
    correctAnswer: "d",
    category: "Technical"
  },
  {
    id: 177,
    question: "Which of these is NOT a type of Bitcoin Unspent Transaction Output (UTXO) commitment?",
    options: [
      { id: "a", text: "UTXO Set Hash" },
      { id: "b", text: "Merkle Mountain Range" },
      { id: "c", text: "Utreexo" },
      { id: "d", text: "UTXO Bloom Filter" }
    ],
    correctAnswer: "d",
    category: "Technical"
  },
  {
    id: 178,
    question: "What is the name of the Bitcoin protocol upgrade that aims to improve transaction privacy using zero-knowledge proofs?",
    options: [
      { id: "a", text: "Confidential Transactions" },
      { id: "b", text: "MimbleWimble" },
      { id: "c", text: "ZeroLink" },
      { id: "d", text: "Bulletproofs" }
    ],
    correctAnswer: "d",
    category: "Privacy"
  },
  {
    id: 179,
    question: "Which BIP introduced the concept of 'Lazy UTXO Set' for more efficient block validation?",
    options: [
      { id: "a", text: "BIP 329" },
      { id: "b", text: "BIP 330" },
      { id: "c", text: "BIP 331" },
      { id: "d", text: "BIP 332" }
    ],
    correctAnswer: "c",
    category: "BIPs"
  },
  {
    id: 180,
    question: "What was the name of the first Bitcoin-only hardware wallet?",
    options: [
      { id: "a", text: "Trezor" },
      { id: "b", text: "Ledger" },
      { id: "c", text: "ColdCard" },
      { id: "d", text: "KeepKey" }
    ],
    correctAnswer: "c",
    category: "History"
  },
  {
    id: 181,
    question: "What is the name of the Bitcoin upgrade that introduced the concept of 'Tapscript'?",
    options: [
      { id: "a", text: "SegWit" },
      { id: "b", text: "Taproot" },
      { id: "c", text: "Schnorr" },
      { id: "d", text: "MAST" }
    ],
    correctAnswer: "b",
    category: "Technical"
  },
  {
    id: 182,
    question: "Which BIP introduced the concept of 'Erlay' for more efficient transaction relay?",
    options: [
      { id: "a", text: "BIP 330" },
      { id: "b", text: "BIP 331" },
      { id: "c", text: "BIP 332" },
      { id: "d", text: "BIP 333" }
    ],
    correctAnswer: "d",
    category: "BIPs"
  },
  {
    id: 183,
    question: "In which year did the Bitcoin network experience the 'Value Overflow Incident'?",
    options: [
      { id: "a", text: "2009" },
      { id: "b", text: "2010" },
      { id: "c", text: "2011" },
      { id: "d", text: "2012" }
    ],
    correctAnswer: "b",
    category: "History"
  },
  {
    id: 184,
    question: "What is the name of the Bitcoin fork that aims to be quantum-resistant?",
    options: [
      { id: "a", text: "Bitcoin Post-Quantum" },
      { id: "b", text: "Quantum Bitcoin" },
      { id: "c", text: "Bitcoin Quantum" },
      { id: "d", text: "Bitcoin Q" }
    ],
    correctAnswer: "a",
    category: "Forks"
  },
  {
    id: 185,
    question: "Which attack involves manipulating the network time protocol to trick nodes?",
    options: [
      { id: "a", text: "Time warp attack" },
      { id: "b", text: "Timejacking attack" },
      { id: "c", text: "Chronos attack" },
      { id: "d", text: "Time dilation attack" }
    ],
    correctAnswer: "b",
    category: "Security"
  },
  {
    id: 186,
    question: "What privacy-enhancing technology was proposed by Greg Maxwell for Bitcoin?",
    options: [
      { id: "a", text: "CoinJoin" },
      { id: "b", text: "Confidential Transactions" },
      { id: "c", text: "Stealth Addresses" },
      { id: "d", text: "TumbleBit" }
    ],
    correctAnswer: "b",
    category: "Privacy"
  },
  {
    id: 187,
    question: "What is the name of the Bitcoin protocol that allows for the creation of non-fungible tokens (NFTs)?",
    options: [
      { id: "a", text: "Omni Layer" },
      { id: "b", text: "Counterparty" },
      { id: "c", text: "RGB" },
      { id: "d", text: "Liquid" }
    ],
    correctAnswer: "c",
    category: "Technical"
  },
  {
    id: 188,
    question: "Which BIP introduced the concept of 'Miniscript' for Bitcoin?",
    options: [
      { id: "a", text: "BIP 342" },
      { id: "b", text: "BIP 343" },
      { id: "c", text: "BIP 344" },
      { id: "d", text: "BIP 345" }
    ],
    correctAnswer: "c",
    category: "BIPs"
  },
  {
    id: 189,
    question: "In which year was the Bitcoin Lightning Network whitepaper published?",
    options: [
      { id: "a", text: "2013" },
      { id: "b", text: "2014" },
      { id: "c", text: "2015" },
      { id: "d", text: "2016" }
    ],
    correctAnswer: "c",
    category: "History"
  },
  {
    id: 190,
    question: "What is the name of the Bitcoin implementation written in Go?",
    options: [
      { id: "a", text: "Bitcoin Core" },
      { id: "b", text: "btcd" },
      { id: "c", text: "Bitcoin Knots" },
      { id: "d", text: "libbitcoin" }
    ],
    correctAnswer: "b",
    category: "Technical"
  },
  {
    id: 191,
    question: "Which of these is NOT a type of Bitcoin Schnorr signature?",
    options: [
      { id: "a", text: "Single-key signature" },
      { id: "b", text: "Multi-signature" },
      { id: "c", text: "Threshold signature" },
      { id: "d", text: "Ring signature" }
    ],
    correctAnswer: "d",
    category: "Technical"
  },
  {
    id: 192,
    question: "What is the name of the Bitcoin protocol upgrade that aims to improve transaction privacy using Confidential Transactions?",
    options: [
      { id: "a", text: "MimbleWimble" },
      { id: "b", text: "ZeroLink" },
      { id: "c", text: "Bulletproofs" },
      { id: "d", text: "Elements" }
    ],
    correctAnswer: "d",
    category: "Privacy"
  },
  {
    id: 193,
    question: "Which BIP introduced the concept of 'Signet' for Bitcoin testing?",
    options: [
      { id: "a", text: "BIP 320" },
      { id: "b", text: "BIP 325" },
      { id: "c", text: "BIP 330" },
      { id: "d", text: "BIP 335" }
    ],
    correctAnswer: "b",
    category: "BIPs"
  },
  {
    id: 194,
    question: "What was the name of the first Bitcoin mining pool?",
    options: [
      { id: "a", text: "Slush Pool" },
      { id: "b", text: "F2Pool" },
      { id: "c", text: "AntPool" },
      { id: "d", text: "BTC Guild" }
    ],
    correctAnswer: "a",
    category: "History"
  },
  {
    id: 195,
    question: "Which Bitcoin fork aimed to increase privacy by implementing zk-SNARKs?",
    options: [
      { id: "a", text: "Bitcoin Private" },
      { id: "b", text: "Bitcoin Zero" },
      { id: "c", text: "Zcash" },
      { id: "d", text: "Bitcoin Dark" }
    ],
    correctAnswer: "a",
    category: "Forks"
  },
  {
    id: 196,
    question: "What is the name of the attack where a miner attempts to monopolize network connections to isolate a node?",
    options: [
      { id: "a", text: "Sybil attack" },
      { id: "b", text: "Eclipse attack" },
      { id: "c", text: "BGP hijacking" },
      { id: "d", text: "Routing attack" }
    ],
    correctAnswer: "b",
    category: "Security"
  },
  {
    id: 197,
    question: "Which privacy-enhancing technology was implemented in the Samourai Wallet for Bitcoin?",
    options: [
      { id: "a", text: "CoinJoin" },
      { id: "b", text: "PayJoin" },
      { id: "c", text: "Ricochet" },
      { id: "d", text: "All of the above" }
    ],
    correctAnswer: "d",
    category: "Privacy"
  },
  {
    id: 198,
    question: "What is the name of the Bitcoin protocol that allows for the creation of Layer 2 sidechains?",
    options: [
      { id: "a", text: "Liquid" },
      { id: "b", text: "RSK" },
      { id: "c", text: "Drivechain" },
      { id: "d", text: "Plasma" }
    ],
    correctAnswer: "c",
    category: "Technical"
  },
  {
    id: 199,
    question: "Which BIP introduced the concept of 'Schnorr Signatures' for Bitcoin?",
    options: [
      { id: "a", text: "BIP 340" },
      { id: "b", text: "BIP 341" },
      { id: "c", text: "BIP 342" },
      { id: "d", text: "BIP 343" }
    ],
    correctAnswer: "a",
    category: "BIPs"
  },
  {
    id: 200,
    question: "In which year did the first Bitcoin halving occur?",
    options: [
      { id: "a", text: "2011" },
      { id: "b", text: "2012" },
      { id: "c", text: "2013" },
      { id: "d", text: "2014" }
    ],
    correctAnswer: "b",
    category: "History"
  },
  {
    id: 201,
    question: "What is the name of the Bitcoin Core feature that allows for faster signature validation?",
    options: [
      { id: "a", text: "libsecp256k1" },
      { id: "b", text: "OpenSSL" },
      { id: "c", text: "CryptoSign" },
      { id: "d", text: "FastSig" }
    ],
    correctAnswer: "a",
    category: "Technical"
  },
  {
    id: 202,
    question: "Which of these is NOT a type of Bitcoin UTXO commitment?",
    options: [
      { id: "a", text: "UTXO Set Hash" },
      { id: "b", text: "Merkle Mountain Range" },
      { id: "c", text: "Utreexo" },
      { id: "d", text: "UTXO Accumulator" }
    ],
    correctAnswer: "d",
    category: "Technical"
  },
  {
    id: 203,
    question: "What is the name of the Bitcoin protocol upgrade that aims to improve transaction privacy using Confidential Assets?",
    options: [
      { id: "a", text: "Elements" },
      { id: "b", text: "Liquid" },
      { id: "c", text: "Blockstream" },
      { id: "d", text: "Sidechain" }
    ],
    correctAnswer: "b",
    category: "Privacy"
  },
  {
    id: 204,
    question: "Which BIP introduced the concept of 'Taproot' for Bitcoin?",
    options: [
      { id: "a", text: "BIP 340" },
      { id: "b", text: "BIP 341" },
      { id: "c", text: "BIP 342" },
      { id: "d", text: "BIP 343" }
    ],
    correctAnswer: "b",
    category: "BIPs"
  },
  {
    id: 205,
    question: "In which year was the first Bitcoin Improvement Proposal (BIP) introduced?",
    options: [
      { id: "a", text: "2009" },
      { id: "b", text: "2010" },
      { id: "c", text: "2011" },
      { id: "d", text: "2012" }
    ],
    correctAnswer: "c",
    category: "History"
  },
  {
    id: 206,
    question: "What is the name of the attack where a miner attempts to reorganize the blockchain to double-spend coins?",
    options: [
      { id: "a", text: "51% attack" },
      { id: "b", text: "Reorganization attack" },
      { id: "c", text: "Chain split attack" },
      { id: "d", text: "Double-spend attack" }
    ],
    correctAnswer: "b",
    category: "Security"
  },
  {
    id: 207,
    question: "Which privacy-enhancing technology was proposed by Pieter Wuille for Bitcoin?",
    options: [
      { id: "a", text: "Confidential Transactions" },
      { id: "b", text: "Taproot" },
      { id: "c", text: "Graftroot" },
      { id: "d", text: "All of the above" }
    ],
    correctAnswer: "d",
    category: "Privacy"
  },
  {
    id: 208,
    question: "What is the name of the Bitcoin protocol that allows for the creation of synthetic derivatives?",
    options: [
      { id: "a", text: "Liquid" },
      { id: "b", text: "RSK" },
      { id: "c", text: "DLC (Discreet Log Contracts)" },
      { id: "d", text: "Counterparty" }
    ],
    correctAnswer: "c",
    category: "Technical"
  },
  {
    id: 209,
    question: "Which BIP introduced the concept of 'Compact Block Relay' for Bitcoin?",
    options: [
      { id: "a", text: "BIP 150" },
      { id: "b", text: "BIP 151" },
      { id: "c", text: "BIP 152" },
      { id: "d", text: "BIP 153" }
    ],
    correctAnswer: "c",
    category: "BIPs"
  },
  {
    id: 210,
    question: "In which year did the 'Bitcoin Pizza Day' transaction occur?",
    options: [
      { id: "a", text: "2009" },
      { id: "b", text: "2010" },
      { id: "c", text: "2011" },
      { id: "d", text: "2012" }
    ],
    correctAnswer: "b",
    category: "History"
  },
  {
    id: 211,
    question: "What is the name of the Bitcoin upgrade that introduced the concept of 'MAST' (Merkelized Abstract Syntax Trees)?",
    options: [
      { id: "a", text: "SegWit" },
      { id: "b", text: "Taproot" },
      { id: "c", text: "Schnorr" },
      { id: "d", text: "Graftroot" }
    ],
    correctAnswer: "b",
    category: "Technical"
  },
  {
    id: 212,
    question: "Which BIP introduced the concept of 'Bech32m' addresses?",
    options: [
      { id: "a", text: "BIP 173" },
      { id: "b", text: "BIP 174" },
      { id: "c", text: "BIP 350" },
      { id: "d", text: "BIP 351" }
    ],
    correctAnswer: "c",
    category: "BIPs"
  },
  {
    id: 213,
    question: "In which year did El Salvador adopt Bitcoin as legal tender?",
    options: [
      { id: "a", text: "2019" },
      { id: "b", text: "2020" },
      { id: "c", text: "2021" },
      { id: "d", text: "2022" }
    ],
    correctAnswer: "c",
    category: "History"
  },
  {
    id: 214,
    question: "What is the name of the Bitcoin fork that aimed to increase the block size to 8MB?",
    options: [
      { id: "a", text: "Bitcoin Cash" },
      { id: "b", text: "Bitcoin SV" },
      { id: "c", text: "Bitcoin Gold" },
      { id: "d", text: "Bitcoin Classic" }
    ],
    correctAnswer: "a",
    category: "Forks"
  },
  {
    id: 215,
    question: "Which attack involves creating multiple identities to subvert reputation systems in the Bitcoin network?",
    options: [
      { id: "a", text: "Sybil attack" },
      { id: "b", text: "Eclipse attack" },
      { id: "c", text: "Routing attack" },
      { id: "d", text: "Finney attack" }
    ],
    correctAnswer: "a",
    category: "Security"
  },
  {
    id: 216,
    question: "What privacy-enhancing technology was proposed by Adam Back and implemented in the Elements sidechain?",
    options: [
      { id: "a", text: "Confidential Transactions" },
      { id: "b", text: "Ring Signatures" },
      { id: "c", text: "zk-SNARKs" },
      { id: "d", text: "Bulletproofs" }
    ],
    correctAnswer: "a",
    category: "Privacy"
  },
  {
    id: 217,
    question: "What is the name of the Bitcoin protocol that allows for the creation of discreet log contracts?",
    options: [
      { id: "a", text: "RGB" },
      { id: "b", text: "DLC" },
      { id: "c", text: "Liquid" },
      { id: "d", text: "RSK" }
    ],
    correctAnswer: "b",
    category: "Technical"
  },
  {
    id: 218,
    question: "Which BIP introduced the concept of 'Schnorr Signatures' for Bitcoin?",
    options: [
      { id: "a", text: "BIP 340" },
      { id: "b", text: "BIP 341" },
      { id: "c", text: "BIP 342" },
      { id: "d", text: "BIP 343" }
    ],
    correctAnswer: "a",
    category: "BIPs"
  },
  {
    id: 219,
    question: "In which year was the Bitcoin whitepaper published?",
    options: [
      { id: "a", text: "2007" },
      { id: "b", text: "2008" },
      { id: "c", text: "2009" },
      { id: "d", text: "2010" }
    ],
    correctAnswer: "b",
    category: "History"
  },
  {
    id: 220,
    question: "What is the name of the Bitcoin upgrade that introduced a new transaction format to fix transaction malleability?",
    options: [
      { id: "a", text: "SegWit" },
      { id: "b", text: "Taproot" },
      { id: "c", text: "Schnorr" },
      { id: "d", text: "MAST" }
    ],
    correctAnswer: "a",
    category: "Technical"
  },
  {
    id: 221,
    question: "Which BIP introduced the concept of 'Pay to Contract' (P2C) for Bitcoin?",
    options: [
      { id: "a", text: "BIP 199" },
      { id: "b", text: "BIP 300" },
      { id: "c", text: "BIP 301" },
      { id: "d", text: "BIP 302" }
    ],
    correctAnswer: "a",
    category: "BIPs"
  },
  {
    id: 222,
    question: "What was the initial bug bounty offered by the Bitcoin Foundation in 2013?",
    options: [
      { id: "a", text: "1 BTC" },
      { id: "b", text: "5 BTC" },
      { id: "c", text: "10 BTC" },
      { id: "d", text: "20 BTC" }
    ],
    correctAnswer: "c",
    category: "History"
  },
  {
    id: 223,
    question: "Which Bitcoin fork aimed to implement a different Proof of Work algorithm called Equihash?",
    options: [
      { id: "a", text: "Bitcoin Cash" },
      { id: "b", text: "Bitcoin Gold" },
      { id: "c", text: "Bitcoin Diamond" },
      { id: "d", text: "Bitcoin Private" }
    ],
    correctAnswer: "b",
    category: "Forks"
  },
  {
    id: 224,
    question: "What is the name of the attack where a miner attempts to reverse a transaction by mining a longer chain in secret?",
    options: [
      { id: "a", text: "51% attack" },
      { id: "b", text: "Selfish mining" },
      { id: "c", text: "Double-spend attack" },
      { id: "d", text: "Block withholding attack" }
    ],
    correctAnswer: "b",
    category: "Security"
  },
  {
    id: 225,
    question: "Which privacy-enhancing technology was implemented in the JoinMarket protocol for Bitcoin?",
    options: [
      { id: "a", text: "CoinJoin" },
      { id: "b", text: "PayJoin" },
      { id: "c", text: "CoinSwap" },
      { id: "d", text: "TumbleBit" }
    ],
    correctAnswer: "a",
    category: "Privacy"
  },
  {
    id: 226,
    question: "What is the name of the Bitcoin protocol that allows for the creation of decentralized prediction markets?",
    options: [
      { id: "a", text: "Augur" },
      { id: "b", text: "Gnosis" },
      { id: "c", text: "Hivemind" },
      { id: "d", text: "Truthcoin" }
    ],
    correctAnswer: "c",
    category: "Technical"
  },
  {
    id: 227,
    question: "Which BIP introduced the concept of 'SIGHASH_ANYPREVOUT' for Bitcoin?",
    options: [
      { id: "a", text: "BIP 118" },
      { id: "b", text: "BIP 119" },
      { id: "c", text: "BIP 120" },
      { id: "d", text: "BIP 121" }
    ],
    correctAnswer: "a",
    category: "BIPs"
  },
  {
    id: 228,
    question: "In which year did the first Bitcoin conference take place?",
    options: [
      { id: "a", text: "2011" },
      { id: "b", text: "2012" },
      { id: "c", text: "2013" },
      { id: "d", text: "2014" }
    ],
    correctAnswer: "c",
    category: "History"
  },
  {
    id: 229,
    question: "What is the name of the Bitcoin upgrade that introduced a new scripting language called Simplicity?",
    options: [
      { id: "a", text: "SegWit" },
      { id: "b", text: "Taproot" },
      { id: "c", text: "Elements" },
      { id: "d", text: "Liquid" }
    ],
    correctAnswer: "c",
    category: "Technical"
  },
  {
    id: 230,
    question: "Which BIP introduced the concept of 'Erlay' for more efficient transaction relay in Bitcoin?",
    options: [
      { id: "a", text: "BIP 330" },
      { id: "b", text: "BIP 331" },
      { id: "c", text: "BIP 332" },
      { id: "d", text: "BIP 333" }
    ],
    correctAnswer: "d",
    category: "BIPs"
  },
  {
    id: 231,
    question: "What was the nickname given to the 2013 Bitcoin price bubble?",
    options: [
      { id: "a", text: "The Great Bubble" },
      { id: "b", text: "The Cyprus Incident" },
      { id: "c", text: "The First Moon" },
      { id: "d", text: "The Silk Road Spike" }
    ],
    correctAnswer: "b",
    category: "History"
  },
  {
    id: 232,
    question: "Which Bitcoin fork aimed to implement a hybrid Proof of Work and Proof of Stake consensus mechanism?",
    options: [
      { id: "a", text: "Bitcoin Cash" },
      { id: "b", text: "Bitcoin Gold" },
      { id: "c", text: "Bitcoin Diamond" },
      { id: "d", text: "Bitcoin Interest" }
    ],
    correctAnswer: "d",
    category: "Forks"
  },
  {
    id: 233,
    question: "What is the name of the attack where a miner intentionally creates invalid blocks to waste other miners' resources?",
    options: [
      { id: "a", text: "Selfish mining" },
      { id: "b", text: "Block withholding" },
      { id: "c", text: "Poisonous block attack" },
      { id: "d", text: "Fee sniping" }
    ],
    correctAnswer: "c",
    category: "Security"
  },
  {
    id: 234,
    question: "Which privacy-enhancing technology was proposed by Maxwell and Poelstra to improve Bitcoin's fungibility?",
    options: [
      { id: "a", text: "CoinJoin" },
      { id: "b", text: "Confidential Transactions" },
      { id: "c", text: "Dandelion" },
      { id: "d", text: "Bulletproofs" }
    ],
    correctAnswer: "b",
    category: "Privacy"
  },
  {
    id: 235,
    question: "What is the name of the Bitcoin protocol that allows for the creation of non-interactive Discreet Log Contracts?",
    options: [
      { id: "a", text: "NDLC" },
      { id: "b", text: "Adaptor Signatures" },
      { id: "c", text: "Scriptless Scripts" },
      { id: "d", text: "All of the above" }
    ],
    correctAnswer: "d",
    category: "Technical"
  },
  {
    id: 236,
    question: "Which BIP introduced the concept of 'Tapscript' for Bitcoin?",
    options: [
      { id: "a", text: "BIP 340" },
      { id: "b", text: "BIP 341" },
      { id: "c", text: "BIP 342" },
      { id: "d", text: "BIP 343" }
    ],
    correctAnswer: "c",
    category: "BIPs"
  },
  {
    id: 237,
    question: "In which year did the Bitcoin network process its first transaction?",
    options: [
      { id: "a", text: "2008" },
      { id: "b", text: "2009" },
      { id: "c", text: "2010" },
      { id: "d", text: "2011" }
    ],
    correctAnswer: "b",
    category: "History"
  },
  {
    id: 238,
    question: "What is the name of the Bitcoin upgrade that introduced the concept of 'Covenants'?",
    options: [
      { id: "a", text: "OP_CHECKTEMPLATEVERIFY" },
      { id: "b", text: "OP_CHECKSIGFROMSTACK" },
      { id: "c", text: "OP_CAT" },
      { id: "d", text: "OP_GROUP" }
    ],
    correctAnswer: "a",
    category: "Technical"
  },
  {
    id: 239,
    question: "Which BIP introduced the concept of 'Eltoo' for Bitcoin?",
    options: [
      { id: "a", text: "BIP 114" },
      { id: "b", text: "BIP 115" },
      { id: "c", text: "BIP 116" },
      { id: "d", text: "BIP 117" }
    ],
    correctAnswer: "a",
    category: "BIPs"
  },
  {
    id: 240,
    question: "What was the name of the first Bitcoin mixing service?",
    options: [
      { id: "a", text: "BitMixer" },
      { id: "b", text: "Bitcoin Fog" },
      { id: "c", text: "CoinJoin" },
      { id: "d", text: "Helix" }
    ],
    correctAnswer: "b",
    category: "Privacy"
  },
  {
    id: 241,
    question: "What is the name of the Bitcoin Core feature that allows for faster initial block download by skipping signature verification?",
    options: [
      { id: "a", text: "Fast Sync" },
      { id: "b", text: "Assumevalid" },
      { id: "c", text: "Turbo Boost" },
      { id: "d", text: "Quick Validation" }
    ],
    correctAnswer: "b",
    category: "Technical"
  },
  {
    id: 242,
    question: "Which BIP introduced the concept of 'Timelocks' for Bitcoin transactions?",
    options: [
      { id: "a", text: "BIP 65" },
      { id: "b", text: "BIP 68" },
      { id: "c", text: "BIP 112" },
      { id: "d", text: "BIP 113" }
    ],
    correctAnswer: "a",
    category: "BIPs"
  },
  {
    id: 243,
    question: "In which year was the first Bitcoin ETF approved in the United States?",
    options: [
      { id: "a", text: "2019" },
      { id: "b", text: "2020" },
      { id: "c", text: "2021" },
      { id: "d", text: "2022" }
    ],
    correctAnswer: "c",
    category: "History"
  },
  {
    id: 244,
    question: "What is the name of the Bitcoin fork that aimed to be ASIC-resistant by using the X11 algorithm?",
    options: [
      { id: "a", text: "Bitcoin Gold" },
      { id: "b", text: "Bitcoin Diamond" },
      { id: "c", text: "Bitcoin Dark" },
      { id: "d", text: "Bitcoin X11" }
    ],
    correctAnswer: "c",
    category: "Forks"
  },
  {
    id: 245,
    question: "Which attack involves a miner withholding a found block to gain an advantage in mining the next one?",
    options: [
      { id: "a", text: "Selfish mining" },
      { id: "b", text: "Block withholding" },
      { id: "c", text: "Stubborn mining" },
      { id: "d", text: "Spy mining" }
    ],
    correctAnswer: "a",
    category: "Security"
  },
  {
    id: 246,
    question: "What privacy-enhancing technology was proposed by Gregory Maxwell to improve Bitcoin's fungibility and privacy?",
    options: [
      { id: "a", text: "CoinJoin" },
      { id: "b", text: "Confidential Transactions" },
      { id: "c", text: "Dandelion" },
      { id: "d", text: "Bulletproofs" }
    ],
    correctAnswer: "a",
    category: "Privacy"
  },
  {
    id: 247,
    question: "What is the name of the Bitcoin protocol that allows for the creation of federated sidechains?",
    options: [
      { id: "a", text: "Liquid" },
      { id: "b", text: "RSK" },
      { id: "c", text: "Drivechain" },
      { id: "d", text: "Plasma" }
    ],
    correctAnswer: "a",
    category: "Technical"
  },
  {
    id: 248,
    question: "Which BIP introduced the concept of 'Bech32' addresses for SegWit?",
    options: [
      { id: "a", text: "BIP 173" },
      { id: "b", text: "BIP 174" },
      { id: "c", text: "BIP 175" },
      { id: "d", text: "BIP 176" }
    ],
    correctAnswer: "a",
    category: "BIPs"
  },
  {
    id: 249,
    question: "In which year did the Bitcoin network reach a hash rate of 1 exahash per second for the first time?",
    options: [
      { id: "a", text: "2015" },
      { id: "b", text: "2016" },
      { id: "c", text: "2017" },
      { id: "d", text: "2018" }
    ],
    correctAnswer: "b",
    category: "History"
  },
  {
    id: 250,
    question: "What is the name of the Bitcoin upgrade that introduced the concept of 'Graftroot'?",
    options: [
      { id: "a", text: "SegWit" },
      { id: "b", text: "Taproot" },
      { id: "c", text: "Schnorr" },
      { id: "d", text: "MAST" }
    ],
    correctAnswer: "b",
    category: "Technical"
  },
  {
    id: 251,
    question: "Which BIP introduced the concept of 'Signature of Composition' for multi-signature schemes?",
    options: [
      { id: "a", text: "BIP 340" },
      { id: "b", text: "BIP 341" },
      { id: "c", text: "BIP 342" },
      { id: "d", text: "BIP 327" }
    ],
    correctAnswer: "d",
    category: "BIPs"
  },
  {
    id: 252,
    question: "What was the name of the coordinated upgrade attempt in 2017 that aimed to increase Bitcoin's block size?",
    options: [
      { id: "a", text: "SegWit2x" },
      { id: "b", text: "The New York Agreement" },
      { id: "c", text: "Bitcoin XT" },
      { id: "d", text: "Bitcoin Unlimited" }
    ],
    correctAnswer: "b",
    category: "History"
  },
  {
    id: 253,
    question: "Which Bitcoin fork aimed to create a more centralized and regulated version of Bitcoin?",
    options: [
      { id: "a", text: "Bitcoin Cash" },
      { id: "b", text: "Bitcoin Gold" },
      { id: "c", text: "Bitcoin Diamond" },
      { id: "d", text: "Bitcoin XT" }
    ],
    correctAnswer: "d",
    category: "Forks"
  },
  {
    id: 254,
    question: "What is the name of the attack where a miner attempts to censor transactions by not including them in blocks?",
    options: [
      { id: "a", text: "Transaction filtering" },
      { id: "b", text: "Censorship attack" },
      { id: "c", text: "Blacklisting" },
      { id: "d", text: "Miner extractable value (MEV)" }
    ],
    correctAnswer: "b",
    category: "Security"
  },
  {
    id: 255,
    question: "Which privacy-enhancing technology was implemented in the Wasabi Wallet for coin selection?",
    options: [
      { id: "a", text: "CoinJoin" },
      { id: "b", text: "PayJoin" },
      { id: "c", text: "ZeroLink" },
      { id: "d", text: "CoinShuffle" }
    ],
    correctAnswer: "c",
    category: "Privacy"
  },
  {
    id: 256,
    question: "What is the name of the Bitcoin protocol that allows for the creation of Lightning Network channels?",
    options: [
      { id: "a", text: "Poon-Dryja Protocol" },
      { id: "b", text: "Eltoo" },
      { id: "c", text: "Hashed Timelock Contracts (HTLCs)" },
      { id: "d", text: "Sphinx" }
    ],
    correctAnswer: "a",
    category: "Technical"
  },
  {
    id: 257,
    question: "Which BIP introduced the concept of 'Native SegWit' addresses (Bech32)?",
    options: [
      { id: "a", text: "BIP 141" },
      { id: "b", text: "BIP 143" },
      { id: "c", text: "BIP 173" },
      { id: "d", text: "BIP 174" }
    ],
    correctAnswer: "c",
    category: "BIPs"
  },
  {
    id: 258,
    question: "In which year did the 'Bitcoin Improvement Proposal' (BIP) process begin?",
    options: [
      { id: "a", text: "2009" },
      { id: "b", text: "2010" },
      { id: "c", text: "2011" },
      { id: "d", text: "2012" }
    ],
    correctAnswer: "c",
    category: "History"
  },
  {
    id: 259,
    question: "What is the name of the Bitcoin upgrade that introduced the concept of 'Tapscript'?",
    options: [
      { id: "a", text: "SegWit" },
      { id: "b", text: "Taproot" },
      { id: "c", text: "Schnorr" },
      { id: "d", text: "MAST" }
    ],
    correctAnswer: "b",
    category: "Technical"
  },
  {
    id: 260,
    question: "Which BIP introduced the concept of 'Output Script Descriptors' for Bitcoin wallets?",
    options: [
      { id: "a", text: "BIP 380" },
      { id: "b", text: "BIP 381" },
      { id: "c", text: "BIP 382" },
      { id: "d", text: "BIP 383" }
    ],
    correctAnswer: "a",
    category: "BIPs"
  },
  {
    id: 261,
    question: "What was the name of the first Bitcoin hardware wallet?",
    options: [
      { id: "a", text: "Trezor" },
      { id: "b", text: "Ledger" },
      { id: "c", text: "KeepKey" },
      { id: "d", text: "BitBox" }
    ],
    correctAnswer: "a",
    category: "History"
  },
  {
    id: 262,
    question: "Which Bitcoin fork aimed to implement smart contract functionality similar to Ethereum?",
    options: [
      { id: "a", text: "Bitcoin Cash" },
      { id: "b", text: "RSK" },
      { id: "c", text: "Bitcoin Gold" },
      { id: "d", text: "Bitcoin Diamond" }
    ],
    correctAnswer: "b",
    category: "Forks"
  },
  {
    id: 263,
    question: "What is the name of the attack where a miner attempts to reverse a transaction by mining a longer chain?",
    options: [
      { id: "a", text: "Double spend attack" },
      { id: "b", text: "51% attack" },
      { id: "c", text: "Race attack" },
      { id: "d", text: "Finney attack" }
    ],
    correctAnswer: "b",
    category: "Security"
  },
  {
    id: 264,
    question: "Which privacy-enhancing technology was proposed by Adam Back for Bitcoin?",
    options: [
      { id: "a", text: "Confidential Transactions" },
      { id: "b", text: "CoinJoin" },
      { id: "c", text: "Stealth Addresses" },
      { id: "d", text: "Ring Signatures" }
    ],
    correctAnswer: "a",
    category: "Privacy"
  },
  {
    id: 265,
    question: "What is the name of the Bitcoin protocol that allows for the creation of Discreet Log Contracts (DLCs)?",
    options: [
      { id: "a", text: "Scriptless Scripts" },
      { id: "b", text: "Adaptor Signatures" },
      { id: "c", text: "Schnorr Signatures" },
      { id: "d", text: "All of the above" }
    ],
    correctAnswer: "d",
    category: "Technical"
  },
  {
    id: 266,
    question: "Which BIP introduced the concept of 'Compact Block Filters' for light clients?",
    options: [
      { id: "a", text: "BIP 157" },
      { id: "b", text: "BIP 158" },
      { id: "c", text: "BIP 159" },
      { id: "d", text: "BIP 160" }
    ],
    correctAnswer: "b",
    category: "BIPs"
  },
  {
    id: 267,
    question: "In which year did the Bitcoin network process its first non-coinbase transaction?",
    options: [
      { id: "a", text: "2008" },
      { id: "b", text: "2009" },
      { id: "c", text: "2010" },
      { id: "d", text: "2011" }
    ],
    correctAnswer: "b",
    category: "History"
  },
  {
    id: 268,
    question: "What is the name of the Bitcoin upgrade that introduced the concept of 'Covenants'?",
    options: [
      { id: "a", text: "OP_CHECKTEMPLATEVERIFY" },
      { id: "b", text: "OP_CHECKSIGFROMSTACK" },
      { id: "c", text: "OP_CAT" },
      { id: "d", text: "OP_GROUP" }
    ],
    correctAnswer: "a",
    category: "Technical"
  },
  {
    id: 269,
    question: "Which BIP introduced the concept of 'Miniscript' for Bitcoin?",
    options: [
      { id: "a", text: "BIP 342" },
      { id: "b", text: "BIP 343" },
      { id: "c", text: "BIP 344" },
      { id: "d", text: "BIP 345" }
    ],
    correctAnswer: "c",
    category: "BIPs"
  },
  {
    id: 270,
    question: "What was the name of the first decentralized Bitcoin exchange?",
    options: [
      { id: "a", text: "Bitsquare" },
      { id: "b", text: "LocalBitcoins" },
      { id: "c", text: "Hodl Hodl" },
      { id: "d", text: "Paxful" }
    ],
    correctAnswer: "a",
    category: "History"
  },
  {
    id: 271,
    question: "What is the name of the Bitcoin protocol that allows for the creation of non-interactive threshold signatures?",
    options: [
      { id: "a", text: "MuSig2" },
      { id: "b", text: "FROST" },
      { id: "c", text: "BLS" },
      { id: "d", text: "Threshold Schnorr" }
    ],
    correctAnswer: "b",
    category: "Technical"
  },
  {
    id: 272,
    question: "Which BIP introduced the concept of 'Vaults' for Bitcoin?",
    options: [
      { id: "a", text: "BIP 119" },
      { id: "b", text: "BIP 300" },
      { id: "c", text: "BIP 301" },
      { id: "d", text: "BIP 118" }
    ],
    correctAnswer: "a",
    category: "BIPs"
  },
  {
    id: 273,
    question: "In which year did the 'Bitcoin Wednesday' meetups start in Amsterdam?",
    options: [
      { id: "a", text: "2011" },
      { id: "b", text: "2013" },
      { id: "c", text: "2015" },
      { id: "d", text: "2017" }
    ],
    correctAnswer: "b",
    category: "History"
  },
  {
    id: 274,
    question: "What is the name of the Bitcoin fork that aimed to implement a new mining algorithm called X16R?",
    options: [
      { id: "a", text: "Bitcoin Gold" },
      { id: "b", text: "Ravencoin" },
      { id: "c", text: "Bitcoin Diamond" },
      { id: "d", text: "Bitcoin Private" }
    ],
    correctAnswer: "b",
    category: "Forks"
  },
  {
    id: 275,
    question: "Which attack involves manipulating the mempool to extract value from future block production?",
    options: [
      { id: "a", text: "Time-bandit attack" },
      { id: "b", text: "Miner Extractable Value (MEV)" },
      { id: "c", text: "Sandwich attack" },
      { id: "d", text: "Frontrunning attack" }
    ],
    correctAnswer: "b",
    category: "Security"
  },
  {
    id: 276,
    question: "What privacy-enhancing technology was proposed by Blockstream for sidechain confidential assets?",
    options: [
      { id: "a", text: "Confidential Assets" },
      { id: "b", text: "Bulletproofs" },
      { id: "c", text: "Ring CT" },
      { id: "d", text: "zk-SNARKs" }
    ],
    correctAnswer: "a",
    category: "Privacy"
  },
  {
    id: 277,
    question: "What is the name of the Bitcoin protocol that allows for the creation of stateless invoices?",
    options: [
      { id: "a", text: "LNURL" },
      { id: "b", text: "Bolt11" },
      { id: "c", text: "PayJoin" },
      { id: "d", text: "BIP70" }
    ],
    correctAnswer: "a",
    category: "Technical"
  },
  {
    id: 278,
    question: "Which BIP introduced the concept of 'Schnorr Taproot' for Bitcoin?",
    options: [
      { id: "a", text: "BIP 340" },
      { id: "b", text: "BIP 341" },
      { id: "c", text: "BIP 342" },
      { id: "d", text: "BIP 343" }
    ],
    correctAnswer: "b",
    category: "BIPs"
  },
  {
    id: 279,
    question: "In which year was the first Bitcoin Improvement Proposal (BIP) implemented in Bitcoin Core?",
    options: [
      { id: "a", text: "2010" },
      { id: "b", text: "2011" },
      { id: "c", text: "2012" },
      { id: "d", text: "2013" }
    ],
    correctAnswer: "b",
    category: "History"
  },
  {
    id: 280,
    question: "What is the name of the Bitcoin upgrade that introduced the concept of 'Cross-Input Signature Aggregation'?",
    options: [
      { id: "a", text: "SegWit" },
      { id: "b", text: "Taproot" },
      { id: "c", text: "Schnorr" },
      { id: "d", text: "CISA" }
    ],
    correctAnswer: "b",
    category: "Technical"
  },
  {
    id: 281,
    question: "Which BIP introduced the concept of 'Stratum V2' for Bitcoin mining?",
    options: [
      { id: "a", text: "BIP 310" },
      { id: "b", text: "BIP 320" },
      { id: "c", text: "BIP 330" },
      { id: "d", text: "BIP 340" }
    ],
    correctAnswer: "a",
    category: "BIPs"
  },
  {
    id: 282,
    question: "What was the name of the first Bitcoin-based prediction market platform?",
    options: [
      { id: "a", text: "Augur" },
      { id: "b", text: "Gnosis" },
      { id: "c", text: "Truthcoin" },
      { id: "d", text: "Hivemind" }
    ],
    correctAnswer: "c",
    category: "History"
  },
  {
    id: 283,
    question: "Which Bitcoin fork aimed to create a more ASIC-resistant version of Bitcoin?",
    options: [
      { id: "a", text: "Bitcoin Gold" },
      { id: "b", text: "Bitcoin Diamond" },
      { id: "c", text: "Bitcoin Private" },
      { id: "d", text: "Bitcoin Interest" }
    ],
    correctAnswer: "a",
    category: "Forks"
  },
  {
    id: 284,
    question: "What is the name of the attack where a miner deliberately forks the chain to orphan blocks mined by honest miners?",
    options: [
      { id: "a", text: "Selfish mining" },
      { id: "b", text: "Block withholding" },
      { id: "c", text: "Feather forking" },
      { id: "d", text: "Eclipse attack" }
    ],
    correctAnswer: "c",
    category: "Security"
  },
  {
    id: 285,
    question: "Which privacy-enhancing technology was proposed by Tim Ruffing for Bitcoin?",
    options: [
      { id: "a", text: "CoinJoin" },
      { id: "b", text: "PayJoin" },
      { id: "c", text: "CoinSwap" },
      { id: "d", text: "ValueShuffle" }
    ],
    correctAnswer: "d",
    category: "Privacy"
  },
  {
    id: 286,
    question: "What is the name of the Bitcoin protocol that allows for the creation of statechains?",
    options: [
      { id: "a", text: "RGB" },
      { id: "b", text: "Liquid" },
      { id: "c", text: "Mercury" },
      { id: "d", text: "Rootstock" }
    ],
    correctAnswer: "c",
    category: "Technical"
  },
  {
    id: 287,
    question: "Which BIP introduced the concept of 'Eltoo' for Bitcoin?",
    options: [
      { id: "a", text: "BIP 114" },
      { id: "b", text: "BIP 115" },
      { id: "c", text: "BIP 116" },
      { id: "d", text: "BIP 117" }
    ],
    correctAnswer: "a",
    category: "BIPs"
  },
  {
    id: 288,
    question: "In which year did the 'Scaling Bitcoin' conference series begin?",
    options: [
      { id: "a", text: "2013" },
      { id: "b", text: "2015" },
      { id: "c", text: "2017" },
      { id: "d", text: "2019" }
    ],
    correctAnswer: "b",
    category: "History"
  },
  {
    id: 289,
    question: "What is the name of the Bitcoin upgrade that introduced the concept of 'SIGHASH_ANYPREVOUT'?",
    options: [
      { id: "a", text: "SegWit" },
      { id: "b", text: "Taproot" },
      { id: "c", text: "Eltoo" },
      { id: "d", text: "MAST" }
    ],
    correctAnswer: "c",
    category: "Technical"
  },
  {
    id: 290,
    question: "Which BIP introduced the concept of 'Ephemeral Anchors' for Lightning Network?",
    options: [
      { id: "a", text: "BIP 118" },
      { id: "b", text: "BIP 119" },
      { id: "c", text: "BIP 120" },
      { id: "d", text: "BIP 121" }
    ],
    correctAnswer: "b",
    category: "BIPs"
  },
  {
    id: 291,
    question: "What was the name of the first Bitcoin-based decentralized exchange?",
    options: [
      { id: "a", text: "Bisq" },
      { id: "b", text: "OpenBazaar" },
      { id: "c", text: "Hodl Hodl" },
      { id: "d", text: "LocalBitcoins" }
    ],
    correctAnswer: "a",
    category: "History"
  },
  {
    id: 292,
    question: "Which Bitcoin fork aimed to implement a new Proof of Work algorithm called Equihash?",
    options: [
      { id: "a", text: "Bitcoin Gold" },
      { id: "b", text: "Zcash" },
      { id: "c", text: "Bitcoin Private" },
      { id: "d", text: "Bitcoin Diamond" }
    ],
    correctAnswer: "a",
    category: "Forks"
  },
  {
    id: 293,
    question: "What is the name of the attack where a miner attempts to control the network time to manipulate difficulty?",
    options: [
      { id: "a", text: "Time warp attack" },
      { id: "b", text: "Timejacking attack" },
      { id: "c", text: "Chronos attack" },
      { id: "d", text: "Time dilation attack" }
    ],
    correctAnswer: "b",
    category: "Security"
  },
  {
    id: 294,
    question: "Which privacy-enhancing technology was proposed by ZmnSCPxj for Lightning Network?",
    options: [
      { id: "a", text: "Rendez-vous routing" },
      { id: "b", text: "Onion routing" },
      { id: "c", text: "Sphinx" },
      { id: "d", text: "Hornet" }
    ],
    correctAnswer: "a",
    category: "Privacy"
  },
  {
    id: 295,
    question: "What is the name of the Bitcoin protocol that allows for the creation of non-interactive Discreet Log Contracts?",
    options: [
      { id: "a", text: "NDLC" },
      { id: "b", text: "Adaptor Signatures" },
      { id: "c", text: "Scriptless Scripts" },
      { id: "d", text: "MuSig" }
    ],
    correctAnswer: "b",
    category: "Technical"
  },
  {
    id: 296,
    question: "Which BIP introduced the concept of 'Package Relay' for Bitcoin?",
    options: [
      { id: "a", text: "BIP 324" },
      { id: "b", text: "BIP 330" },
      { id: "c", text: "BIP 331" },
      { id: "d", text: "BIP 339" }
    ],
    correctAnswer: "c",
    category: "BIPs"
  },
  {
    id: 297,
    question: "In which year was the first Bitcoin-backed loan issued?",
    options: [
      { id: "a", text: "2013" },
      { id: "b", text: "2015" },
      { id: "c", text: "2017" },
      { id: "d", text: "2019" }
    ],
    correctAnswer: "a",
    category: "History"
  },
  {
    id: 298,
    question: "What is the name of the Bitcoin upgrade that introduced the concept of 'Covenant-based Vaults'?",
    options: [
      { id: "a", text: "OP_CHECKTEMPLATEVERIFY" },
      { id: "b", text: "OP_TAPLEAF_UPDATE_VERIFY" },
      { id: "c", text: "OP_SECURETHEBAG" },
      { id: "d", text: "OP_VAULT" }
    ],
    correctAnswer: "c",
    category: "Technical"
  },
  {
    id: 299,
    question: "Which BIP introduced the concept of 'Lazy UTXO Set' for Bitcoin?",
    options: [
      { id: "a", text: "BIP 157" },
      { id: "b", text: "BIP 158" },
      { id: "c", text: "BIP 159" },
      { id: "d", text: "BIP 160" }
    ],
    correctAnswer: "c",
    category: "BIPs"
  },
  {
    id: 300,
    question: "What was the name of the first Bitcoin-only bank licensed in the United States?",
    options: [
      { id: "a", text: "Avanti Bank" },
      { id: "b", text: "Kraken Financial" },
      { id: "c", text: "Silvergate Bank" },
      { id: "d", text: "Signature Bank" }
    ],
    correctAnswer: "b",
    category: "History"
  },
  {
    id: 301,
    question: "What is Bitcoin?",
    options: [
      { id: "a", text: "A digital currency" },
      { id: "b", text: "A type of credit card" },
      { id: "c", text: "A stock market index" },
      { id: "d", text: "A social media platform" }
    ],
    correctAnswer: "a",
    category: "Basics"
  },
  {
    id: 302,
    question: "Who created Bitcoin?",
    options: [
      { id: "a", text: "Satoshi Nakamoto" },
      { id: "b", text: "Bill Gates" },
      { id: "c", text: "Mark Zuckerberg" },
      { id: "d", text: "Elon Musk" }
    ],
    correctAnswer: "a",
    category: "Basics"
  },
  {
    id: 303,
    question: "In what year was Bitcoin first introduced?",
    options: [
      { id: "a", text: "2005" },
      { id: "b", text: "2008" },
      { id: "c", text: "2010" },
      { id: "d", text: "2013" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 304,
    question: "What is the maximum number of bitcoins that will ever exist?",
    options: [
      { id: "a", text: "1 million" },
      { id: "b", text: "10 million" },
      { id: "c", text: "21 million" },
      { id: "d", text: "100 million" }
    ],
    correctAnswer: "c",
    category: "Basics"
  },
  {
    id: 305,
    question: "What is a Bitcoin wallet used for?",
    options: [
      { id: "a", text: "Storing physical coins" },
      { id: "b", text: "Storing digital Bitcoin" },
      { id: "c", text: "Mining new bitcoins" },
      { id: "d", text: "Printing Bitcoin certificates" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 306,
    question: "What is Bitcoin mining?",
    options: [
      { id: "a", text: "Digging for physical bitcoins" },
      { id: "b", text: "Creating new bitcoins through computational work" },
      { id: "c", text: "Exchanging other currencies for Bitcoin" },
      { id: "d", text: "Stealing bitcoins from others" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 307,
    question: "What technology underlies Bitcoin?",
    options: [
      { id: "a", text: "Artificial Intelligence" },
      { id: "b", text: "Virtual Reality" },
      { id: "c", text: "Blockchain" },
      { id: "d", text: "5G Networks" }
    ],
    correctAnswer: "c",
    category: "Basics"
  },
  {
    id: 308,
    question: "What is a Bitcoin block?",
    options: [
      { id: "a", text: "A physical coin" },
      { id: "b", text: "A group of transactions" },
      { id: "c", text: "A type of Bitcoin wallet" },
      { id: "d", text: "A Bitcoin mining machine" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 309,
    question: "How often does a Bitcoin 'halving' occur?",
    options: [
      { id: "a", text: "Every year" },
      { id: "b", text: "Every 2 years" },
      { id: "c", text: "Every 4 years" },
      { id: "d", text: "Every 10 years" }
    ],
    correctAnswer: "c",
    category: "Basics"
  },
  {
    id: 310,
    question: "What is a Bitcoin address?",
    options: [
      { id: "a", text: "An email address for receiving Bitcoin" },
      { id: "b", text: "A unique identifier for sending or receiving Bitcoin" },
      { id: "c", text: "The location of a Bitcoin mining facility" },
      { id: "d", text: "A Bitcoin exchange website" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 311,
    question: "What is the smallest unit of Bitcoin called?",
    options: [
      { id: "a", text: "Bit" },
      { id: "b", text: "Satoshi" },
      { id: "c", text: "Nano" },
      { id: "d", text: "Coin" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 312,
    question: "What does 'HODL' mean in the Bitcoin community?",
    options: [
      { id: "a", text: "Hold On for Dear Life (to not sell Bitcoin)" },
      { id: "b", text: "How Often Do Losses Limit" },
      { id: "c", text: "Huge Opportunity, Don't Leave" },
      { id: "d", text: "Help Others Download Ledgers" }
    ],
    correctAnswer: "a",
    category: "Basics"
  },
  {
    id: 313,
    question: "What is a Bitcoin 'fork'?",
    options: [
      { id: "a", text: "A utensil for eating Bitcoin" },
      { id: "b", text: "A split in the Bitcoin blockchain" },
      { id: "c", text: "A type of Bitcoin wallet" },
      { id: "d", text: "A Bitcoin mining tool" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 314,
    question: "What is the main advantage of Bitcoin over traditional currencies?",
    options: [
      { id: "a", text: "It's controlled by a central bank" },
      { id: "b", text: "It's backed by gold" },
      { id: "c", text: "It's decentralized and not controlled by any single entity" },
      { id: "d", text: "It's accepted in all countries" }
    ],
    correctAnswer: "c",
    category: "Basics"
  },
  {
    id: 315,
    question: "What is a Bitcoin 'node'?",
    options: [
      { id: "a", text: "A Bitcoin mining machine" },
      { id: "b", text: "A computer that participates in the Bitcoin network" },
      { id: "c", text: "A Bitcoin exchange" },
      { id: "d", text: "A Bitcoin wallet" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 316,
    question: "What is the purpose of Bitcoin's proof-of-work system?",
    options: [
      { id: "a", text: "To create new bitcoins" },
      { id: "b", text: "To secure the network and validate transactions" },
      { id: "c", text: "To send bitcoins faster" },
      { id: "d", text: "To convert bitcoins to other currencies" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 317,
    question: "What is a Bitcoin 'transaction fee'?",
    options: [
      { id: "a", text: "A fee paid to Bitcoin's creators" },
      { id: "b", text: "A fee paid to miners for processing transactions" },
      { id: "c", text: "A fee paid to convert Bitcoin to other currencies" },
      { id: "d", text: "A fee paid to use Bitcoin wallets" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 318,
    question: "What is the 'genesis block' in Bitcoin?",
    options: [
      { id: "a", text: "The most recent block in the blockchain" },
      { id: "b", text: "The first block ever mined in the Bitcoin blockchain" },
      { id: "c", text: "The largest block in the blockchain" },
      { id: "d", text: "The block that contains the most transactions" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 319,
    question: "What is a 'cold wallet' in Bitcoin?",
    options: [
      { id: "a", text: "A wallet stored in a refrigerator" },
      { id: "b", text: "A wallet that hasn't been used in a long time" },
      { id: "c", text: "A wallet stored offline for enhanced security" },
      { id: "d", text: "A wallet with a small balance" }
    ],
    correctAnswer: "c",
    category: "Basics"
  },
  {
    id: 320,
    question: "What is the purpose of Bitcoin's 21 million coin limit?",
    options: [
      { id: "a", text: "To make mining easier" },
      { id: "b", text: "To prevent inflation" },
      { id: "c", text: "To make transactions faster" },
      { id: "d", text: "To reduce energy consumption" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 321,
    question: "What is a 'private key' in Bitcoin?",
    options: [
      { id: "a", text: "A password to log into a Bitcoin exchange" },
      { id: "b", text: "A secret code that allows you to spend your bitcoins" },
      { id: "c", text: "The ID number of a Bitcoin transaction" },
      { id: "d", text: "A key to a physical Bitcoin vault" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 322,
    question: "What is the 'Lightning Network' in relation to Bitcoin?",
    options: [
      { id: "a", text: "A network of Bitcoin mining farms" },
      { id: "b", text: "A second-layer solution for faster and cheaper transactions" },
      { id: "c", text: "A Bitcoin exchange network" },
      { id: "d", text: "A network of Bitcoin ATMs" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 323,
    question: "What is a 'confirmation' in a Bitcoin transaction?",
    options: [
      { id: "a", text: "An email verifying the transaction" },
      { id: "b", text: "The inclusion of the transaction in a block on the blockchain" },
      { id: "c", text: "A receipt from a Bitcoin exchange" },
      { id: "d", text: "Approval from the Bitcoin creator" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 324,
    question: "What is 'Bitcoin Pizza Day'?",
    options: [
      { id: "a", text: "A day when pizza companies accept Bitcoin" },
      { id: "b", text: "The anniversary of the first real-world Bitcoin transaction for two pizzas" },
      { id: "c", text: "A day when Bitcoin miners are rewarded with pizza" },
      { id: "d", text: "The day Bitcoin reached its highest price" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 325,
    question: "What does 'UTXO' stand for in Bitcoin?",
    options: [
      { id: "a", text: "Unspent Transaction Output" },
      { id: "b", text: "Universal Transaction X-ray Operation" },
      { id: "c", text: "Unified Total X-change Order" },
      { id: "d", text: "Unconfirmed Transaction X-out" }
    ],
    correctAnswer: "a",
    category: "Basics"
  },
  {
    id: 326,
    question: "What is the main purpose of Bitcoin?",
    options: [
      { id: "a", text: "To replace all traditional currencies" },
      { id: "b", text: "To serve as a digital peer-to-peer payment system" },
      { id: "c", text: "To make banks obsolete" },
      { id: "d", text: "To fund space exploration" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 327,
    question: "How are new bitcoins created?",
    options: [
      { id: "a", text: "By government printing" },
      { id: "b", text: "Through the mining process" },
      { id: "c", text: "By converting other currencies" },
      { id: "d", text: "Through bank loans" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 328,
    question: "What is a 'hot wallet' in Bitcoin terms?",
    options: [
      { id: "a", text: "A wallet with a lot of bitcoins" },
      { id: "b", text: "A wallet connected to the internet" },
      { id: "c", text: "A wallet that's frequently used" },
      { id: "d", text: "A wallet with high transaction fees" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 329,
    question: "What is the role of miners in the Bitcoin network?",
    options: [
      { id: "a", text: "To create new wallets" },
      { id: "b", text: "To validate transactions and secure the network" },
      { id: "c", text: "To set Bitcoin prices" },
      { id: "d", text: "To convert Bitcoin to other currencies" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 330,
    question: "What is a 'public key' in Bitcoin?",
    options: [
      { id: "a", text: "A password to access your wallet" },
      { id: "b", text: "An identifier used to receive Bitcoin" },
      { id: "c", text: "The total number of bitcoins you own" },
      { id: "d", text: "A key to unlock a Bitcoin ATM" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 331,
    question: "What happens to transaction fees in Bitcoin?",
    options: [
      { id: "a", text: "They are burned and removed from circulation" },
      { id: "b", text: "They are collected by miners as a reward" },
      { id: "c", text: "They are redistributed to all Bitcoin users" },
      { id: "d", text: "They are donated to charity" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 332,
    question: "What is the purpose of a Bitcoin 'mempool'?",
    options: [
      { id: "a", text: "To store completed transactions" },
      { id: "b", text: "To hold unconfirmed transactions waiting to be mined" },
      { id: "c", text: "To keep track of Bitcoin prices" },
      { id: "d", text: "To manage Bitcoin wallets" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 333,
    question: "What is a 'full node' in the Bitcoin network?",
    options: [
      { id: "a", text: "A computer that only mines Bitcoin" },
      { id: "b", text: "A computer that stores the entire blockchain and validates transactions" },
      { id: "c", text: "A Bitcoin exchange with high trading volume" },
      { id: "d", text: "A wallet with a large balance" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 334,
    question: "What is the primary purpose of Bitcoin's difficulty adjustment?",
    options: [
      { id: "a", text: "To make mining more profitable" },
      { id: "b", text: "To keep block creation time consistent" },
      { id: "c", text: "To increase the total number of bitcoins" },
      { id: "d", text: "To reduce transaction fees" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 335,
    question: "What is a 'seed phrase' in Bitcoin?",
    options: [
      { id: "a", text: "A password to access exchanges" },
      { id: "b", text: "A list of words used to recover a wallet" },
      { id: "c", text: "A phrase that increases mining speed" },
      { id: "d", text: "A secret message in the blockchain" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 336,
    question: "What is the main difference between Bitcoin and traditional banking?",
    options: [
      { id: "a", text: "Bitcoin uses paper money" },
      { id: "b", text: "Bitcoin is decentralized with no central authority" },
      { id: "c", text: "Bitcoin transactions are always free" },
      { id: "d", text: "Bitcoin can only be used online" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 337,
    question: "What is a 'paper wallet' in Bitcoin?",
    options: [
      { id: "a", text: "A wallet made of special paper" },
      { id: "b", text: "A physical document containing Bitcoin private keys" },
      { id: "c", text: "A list of Bitcoin transactions" },
      { id: "d", text: "A paper receipt from a Bitcoin ATM" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 338,
    question: "What is 'block time' in Bitcoin?",
    options: [
      { id: "a", text: "The time it takes to spend Bitcoin" },
      { id: "b", text: "The average time between new blocks being added to the blockchain" },
      { id: "c", text: "The time a Bitcoin exchange is open" },
      { id: "d", text: "The lifetime of a Bitcoin address" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 339,
    question: "What is the purpose of a Bitcoin 'nonce' in mining?",
    options: [
      { id: "a", text: "To sign transactions" },
      { id: "b", text: "To create new Bitcoin addresses" },
      { id: "c", text: "To find a valid block hash" },
      { id: "d", text: "To encrypt messages in the blockchain" }
    ],
    correctAnswer: "c",
    category: "Basics"
  },
  {
    id: 340,
    question: "What is meant by Bitcoin's 'peer-to-peer' nature?",
    options: [
      { id: "a", text: "Only two people can use Bitcoin at a time" },
      { id: "b", text: "Transactions occur directly between users without intermediaries" },
      { id: "c", text: "Bitcoin can only be sent to your peers" },
      { id: "d", text: "You need two devices to send Bitcoin" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 341,
    question: "What is a 'multisignature' wallet in Bitcoin?",
    options: [
      { id: "a", text: "A wallet that holds multiple cryptocurrencies" },
      { id: "b", text: "A wallet that requires multiple signatures to authorize transactions" },
      { id: "c", text: "A wallet with a very long password" },
      { id: "d", text: "A wallet shared by multiple users" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 342,
    question: "What is the purpose of Bitcoin's 'scripting language'?",
    options: [
      { id: "a", text: "To create websites about Bitcoin" },
      { id: "b", text: "To specify conditions for spending bitcoins" },
      { id: "c", text: "To write messages on the blockchain" },
      { id: "d", text: "To program Bitcoin mining hardware" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 343,
    question: "What is a 'dusting attack' in Bitcoin?",
    options: [
      { id: "a", text: "When someone steals the dust from your computer" },
      { id: "b", text: "Sending tiny amounts of Bitcoin to break wallet anonymity" },
      { id: "c", text: "Using old, unused Bitcoin addresses" },
      { id: "d", text: "Attempting to mine with inadequate hardware" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 344,
    question: "What is the primary purpose of Bitcoin 'mixing' services?",
    options: [
      { id: "a", text: "To combine different cryptocurrencies" },
      { id: "b", text: "To increase transaction speed" },
      { id: "c", text: "To enhance privacy by obscuring transaction origins" },
      { id: "d", text: "To create new bitcoins" }
    ],
    correctAnswer: "c",
    category: "Basics"
  },
  {
    id: 345,
    question: "What is a 'satoshi'?",
    options: [
      { id: "a", text: "The creator of Bitcoin" },
      { id: "b", text: "A type of Bitcoin wallet" },
      { id: "c", text: "The smallest unit of Bitcoin" },
      { id: "d", text: "A Bitcoin mining pool" }
    ],
    correctAnswer: "c",
    category: "Basics"
  },
  {
    id: 346,
    question: "What is the main purpose of the Bitcoin 'mempool'?",
    options: [
      { id: "a", text: "To store Bitcoin private keys" },
      { id: "b", text: "To hold unconfirmed transactions" },
      { id: "c", text: "To track Bitcoin price fluctuations" },
      { id: "d", text: "To manage Bitcoin mining hardware" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 347,
    question: "What is a 'soft fork' in Bitcoin?",
    options: [
      { id: "a", text: "A utensil for eating Bitcoin" },
      { id: "b", text: "A backwards-compatible upgrade to the Bitcoin protocol" },
      { id: "c", text: "A decrease in Bitcoin's value" },
      { id: "d", text: "A type of Bitcoin wallet" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 348,
    question: "What is the purpose of 'ring signatures' in some Bitcoin-inspired cryptocurrencies?",
    options: [
      { id: "a", text: "To create unique wallet addresses" },
      { id: "b", text: "To enhance privacy by obscuring transaction sources" },
      { id: "c", text: "To increase transaction speed" },
      { id: "d", text: "To generate new coins" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 349,
    question: "What is a 'vanity address' in Bitcoin?",
    options: [
      { id: "a", text: "An address with a high balance" },
      { id: "b", text: "A custom address with specific characters chosen by the user" },
      { id: "c", text: "The most commonly used Bitcoin address" },
      { id: "d", text: "An address used by celebrities" }
    ],
    correctAnswer: "b",
    category: "Basics"
  },
  {
    id: 350,
    question: "What is the primary purpose of Bitcoin's 'testnet'?",
    options: [
      { id: "a", text: "To mine real bitcoins faster" },
      { id: "b", text: "To test new features without using real money" },
      { id: "c", text: "To store bitcoins more securely" },
      { id: "d", text: "To increase Bitcoin's transaction speed" }
    ],
    correctAnswer: "b",
    category: "Basics"
  }
];