import React from 'react';
import { DataTable } from '../../data-display/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { formatNumber } from '../../../utils';

export const ApiProvidersTable = ({ 
  data,
  animate,
  onOpenLexicon,
}) => {
  // Helper functions
  const findLowestPriceProvider = (data) => {
    if (!data.s09?.exchanges?.Data) return "N/A";
    const lowestPrice = Math.min(...data.s09.exchanges.Data.map(exchange => exchange.price));
    const lowestProvider = data.s09.exchanges.Data.find(exchange => exchange.price === lowestPrice);
    return lowestProvider ? lowestProvider.exchange : "N/A";
  };

  const findHighestPriceProvider = (data) => {
    if (!data.s09?.exchanges?.Data) return "N/A";
    const highestPrice = Math.max(...data.s09.exchanges.Data.map(exchange => exchange.price));
    const highestProvider = data.s09.exchanges.Data.find(exchange => exchange.price === highestPrice);
    return highestProvider ? highestProvider.exchange : "N/A";
  };

  const calculateAveragePrice = (data) => {
    if (!data.s09?.exchanges?.Data || data.s09.exchanges.Data.length === 0) return 0;
    const prices = data.s09.exchanges.Data.map(exchange => exchange.price);
    return prices.reduce((sum, price) => sum + price, 0) / prices.length;
  };

  const tableData = data
    ? [
        {
            label: data?.s09?.exchanges?.Data?.[0]?.exchange || "Loading...",
            value: data?.s09?.exchanges?.Data?.[0]?.price
              ? `$${formatNumber(data.s09.exchanges.Data[0].price, 2)} USD`
              : "Loading...",
            tooltipText: data?.s09?.exchanges?.Data?.[0]?.exchange
              ? `${data.s09.exchanges.Data[0].exchange} is an API provider. An API (Application Programming Interface) is a computing interface which defines interactions between multiple software intermediaries.`
              : "Loading...",
            clickableTerm: "API Provider",
        },
        {
            label: data?.s09?.exchanges?.Data?.[1]?.exchange || "Loading...",
            value: data?.s09?.exchanges?.Data?.[1]?.price
              ? `$${formatNumber(data.s09.exchanges.Data[1].price, 2)} USD`
              : "Loading...",
            tooltipText: data?.s09?.exchanges?.Data?.[1]?.exchange
              ? `${data.s09.exchanges.Data[1].exchange} is an API provider.`
              : "Loading...",
            clickableTerm: "API Provider",
        },
        {
            label: data?.s09?.exchanges?.Data?.[2]?.exchange || "Loading...",
            value: data?.s09?.exchanges?.Data?.[2]?.price
              ? `$${formatNumber(data.s09.exchanges.Data[2].price, 2)} USD`
              : "Loading...",
            tooltipText: data?.s09?.exchanges?.Data?.[2]?.exchange
              ? `${data.s09.exchanges.Data[2].exchange} is an API provider.`
              : "Loading...",
            clickableTerm: "API Provider",
        },
        {
            label: data?.s09?.exchanges?.Data?.[3]?.exchange || "Loading...",
            value: data?.s09?.exchanges?.Data?.[3]?.price
              ? `$${formatNumber(data.s09.exchanges.Data[3].price, 2)} USD`
              : "Loading...",
            tooltipText: data?.s09?.exchanges?.Data?.[3]?.exchange
              ? `${data.s09.exchanges.Data[3].exchange} is an API provider.`
              : "Loading...",
            clickableTerm: "API Provider",
        },
        {
            label: data?.s09?.exchanges?.Data?.[4]?.exchange || "Loading...",
            value: data?.s09?.exchanges?.Data?.[4]?.price
              ? `$${formatNumber(data.s09.exchanges.Data[4].price, 2)} USD`
              : "Loading...",
            tooltipText: data?.s09?.exchanges?.Data?.[4]?.exchange
              ? `${data.s09.exchanges.Data[4].exchange} is an API provider.`
              : "Loading...",
            clickableTerm: "API Provider",
        },
        {
            label: data?.s12?.metrics?.data?.market_data ? "Messari" : "Loading...",
            value: data?.s12?.metrics?.data?.market_data?.price_usd != null 
              ? `$${formatNumber(data.s12.metrics.data.market_data.price_usd, 2)} USD` 
              : "Loading...",
            tooltipText: data?.s12?.metrics?.data?.market_data 
              ? "Messari is an API provider." 
              : "Loading...",
            clickableTerm: "API Provider",
        },
        {
            label: data?.s04?.bitcoin?.data ? "Coincap" : "Loading...",
            value: data?.s04?.bitcoin?.data?.priceUsd != null 
              ? `$${formatNumber(parseFloat(data.s04.bitcoin.data.priceUsd), 2)} USD` 
              : "Loading...",
            tooltipText: data?.s04?.bitcoin?.data 
              ? "Coincap is an API provider." 
              : "Loading...",
            clickableTerm: "API Provider",
        },
        {
            label: 'Provider Count',
            value: data?.s09?.exchanges?.Data != null 
              ? formatNumber(data.s09.exchanges.Data.length + 2, 0) 
              : "Loading...",
            tooltipText: "Number of price data providers",
            clickableTerm: "API Provider",
        },
        {
            label: 'Lowest Provider',
            value: data.s09?.exchanges?.Data 
              ? findLowestPriceProvider(data) 
              : "Loading...",
            tooltipText: "The provider showing the lowest Bitcoin price",
            clickableTerm: "API Provider",
        },
        {
            label: 'Highest Provider',
            value: data.s09?.exchanges?.Data 
              ? findHighestPriceProvider(data) 
              : "Loading...",
            tooltipText: "The provider showing the highest Bitcoin price",
            clickableTerm: "API Provider",
        },
        {
            label: 'Average Price',
            value: data.s09?.exchanges?.Data 
              ? `$${formatNumber(calculateAveragePrice(data), 2)} USD` 
              : "Loading...",
            tooltipText: "The average Bitcoin price across all providers",
            clickableTerm: "Average Price",
        },
        {
            label: 'Price Range',
            value: data.s09?.exchanges?.Data 
              ? `$${formatNumber(Math.min(...data.s09.exchanges.Data.map(exchange => exchange.price)), 2)} - $${formatNumber(Math.max(...data.s09.exchanges.Data.map(exchange => exchange.price)), 2)}` 
              : "Loading...",
            tooltipText: "Range of prices across all providers",
            clickableTerm: "Price Range",
        }
    ]
    : [];

  return (
    <DataTable
      title="API Providers"
      subtitle="Bitcoin price API providers"
      icon={<FontAwesomeIcon icon={faDatabase} />}
      rows={tableData}
      animate={animate}
      specificTerm="API Provider"
      onOpenLexicon={onOpenLexicon}
      rowsPerPage={9}
    />
  );
};